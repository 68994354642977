import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  Image,
} from "@react-pdf/renderer";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Label,
  Input,
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  InputGroupAddon,
  InputGroup,
  Modal,
  Form,
  Button,
} from "reactstrap";
import { MDBDataTable } from "mdbreact";
import classnames from "classnames";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import logo from "../../assets/images/logo-dark.png";
import firebase from "firebase/app";
import "firebase/database";

/***/
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { shipping_data_read, detail_error } from "../../store/actions";
import Lottie from "react-lottie";
/***/

class Delivery extends Component {
  constructor() {
    super();
    this.state = {
      activeTab: "1",
      data_by_lot_no: [],
      ready: false,
      data_to_show_in_table: [],
    };
  }

  toggle() {
    this.setState(
      (prevState) => ({
        ready: false,
      }),
      () => {
        setTimeout(() => {
          this.setState({ ready: true });
        }, 1);
      }
    );
  }
  table_titles(data_taken) {
    var titles_to_show = [];

    var titles = [
      "HBL",
      "Lot No",
      "PCS",
      "Description",
      "Consignee Name",
      "Remarks",
    ];

    titles.forEach((element, i) => {
      var percentage = 100 / 6;
      percentage = percentage + "%";
      titles_to_show.push(
        <Text
          key={i}
          style={{
            width: percentage, //"20%",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
          }}
        >
          {element}
        </Text>
      );
    });
    return (
      <View
        style={{
          flexDirection: "row",
          borderBottomColor: "#CFD8DC",
          backgroundColor: "#CFD8DC",
          borderBottomWidth: 1,
          alignItems: "center",
          // height: 24,
          paddingTop: 10,
          paddingBotttom: 10,
          textAlign: "center",
          fontStyle: "bold",
        }}
      >
        {titles_to_show}
      </View>
    );
  }

  line_items(data_taken) {
    var list_to_show = [];
    var percentage = 100 / 6;
    percentage = percentage + "%";

    data_taken.forEach((element, i) => {
      list_to_show.push(
        <View
          style={{
            flexDirection: "row",
            borderBottomColor: "#CFD8DC",
            borderBottomWidth: 1,
            alignItems: "center",
            height: 24,
            fontStyle: "bold",
          }}
          key={i}
        >
          <Text
            style={{
              width: percentage, //"20%",
              textAlign: "center",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              paddingLeft: 8,
              fontSize: 8,
            }}
          >
            {element.HBL}
          </Text>
          <Text
            style={{
              width: percentage, //"20%",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              textAlign: "center",
              paddingRight: 8,
              fontSize: 8,
            }}
          >
            {element.Lot_No}
          </Text>
          <Text
            style={{
              width: percentage, //"20%",
              textAlign: "center",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              paddingRight: 8,
              fontSize: 8,
            }}
          >
            {element.Qty}
          </Text>
          <Text
            style={{
              width: percentage, //"20%",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              textAlign: "center",
              paddingRight: 8,
              fontSize: 8,
            }}
          >
            {element.description}
          </Text>
          <Text
            style={{
              width: percentage, //"20%",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              textAlign: "center",
              paddingRight: 8,
              fontSize: 8,
            }}
          >
            {element.Consignee}
          </Text>

          <Text
            style={{
              width: percentage, //"20%",
              textAlign: "center",
              paddingRight: 8,
              fontSize: 8,
            }}
          >
            {element.remarks}
          </Text>
        </View>
      );
    });

    return list_to_show;
  }
  show_report() {
    const { ready, data_to_show_in_table } = this.state;
    if (data_to_show_in_table.length > 0) {
      var data_taken = data_to_show_in_table;
      var title = "Zainco Delivery Report";

      var table_titles = this.table_titles(data_taken);

      var line_items = this.line_items(data_taken);

      return (
        <Document>
          <Page
            size="A4"
            style={{
              fontFamily: "Helvetica",
              fontSize: 11,
              paddingTop: 30,
              paddingLeft: 60,
              paddingRight: 60,
              lineHeight: 1.5,
              flexDirection: "column",
            }}
          >
            <Image
              style={{
                width: 74,
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={logo}
            />

            <View
              style={{
                flexDirection: "row",
                marginTop: 24,
              }}
            >
              <Text
                style={{
                  color: "#263238",
                  fontSize: 15,
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                {title}
              </Text>
            </View>

            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: 24,
                borderWidth: 1,
                borderColor: "#CFD8DC",
              }}
            >
              {table_titles}

              {line_items}
            </View>
          </Page>
        </Document>
      );
    } else {
      return (
        <Document>
          <Page
            size="A4"
            style={{
              fontFamily: "Helvetica",
              fontSize: 11,
              paddingTop: 30,
              paddingLeft: 60,
              paddingRight: 60,
              lineHeight: 1.5,
              flexDirection: "column",
            }}
          >
            <Image
              style={{
                width: 74,
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={logo}
            />
          </Page>
        </Document>
      );
    }
  }

  show_report_based_on_hbl() {
    // const { data } = this.props;
    const { shipping_data } = this.props;
    var data = shipping_data;

    data = _.map(data, (val, uid) => {
      return { ...val, uid };
    });
    var data_to_show_in_table = [];
    var show_data = [];
    data.forEach((element) => {
      if (element.full_detail !== undefined) {
        show_data.push(element);
      }
    });

    show_data.forEach((element) => {
      const {
        cargo_details,
        consignee_details,
        other_charges,
        payments_details,
        shipper_details,
      } = element.full_detail;

      var description = "";

      var array_description = [];
      if (element.full_detail.cargo_details !== undefined) {
        element.full_detail.cargo_details.forEach((element, i) => {
          array_description.push(element.description);
        });
      }
      if (array_description.length === 0) {
        description = "";
      } else {
        if (array_description.length === 1) {
          description = array_description[0];
        } else {
          description = array_description.join(", ");
        }
      }

      if (element.status === "Delivered") {
        var remarks_formatted = "";
        if (element.full_detail.other_charges !== undefined) {
          remarks_formatted = element.full_detail.other_charges.remarks_charges;
        }
        data_to_show_in_table.push({
          HBL: element.uid,
          Lot_No: element.Lot_No,
          Consignee: element.Consignee,
          Shipper: element.Shipper,
          Qty: element.Qty,
          remarks: remarks_formatted,
          description: description,
        });
      }
    });

    const table_data = {
      columns: [
        {
          label: "HBL",
          field: "HBL",
          sort: "asc",
          width: 150,
        },
        {
          label: "Lot No",
          field: "Lot_No",
          sort: "asc",
          width: 270,
        },
        {
          label: "PCS",
          field: "Qty",
          sort: "asc",
          width: 270,
        },
        {
          label: "Description",
          field: "description",
          sort: "asc",
          width: 100,
        },
        {
          label: "Consignee Name",
          field: "Consignee",
          sort: "asc",
          width: 200,
        }, //
        {
          label: "Remarks",
          field: "remarks",
          sort: "asc",
          width: 100,
        },
      ],
      rows: data_to_show_in_table,
    };

    const { ready } = this.state;

    const MyDocument = this.show_report();

    return (
      <div>
        <div>
          {ready && (
            <PDFDownloadLink document={MyDocument} fileName="PDF">
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <Button onClick={() => this.setState({ ready: false })}>
                    Download Report
                  </Button>
                )
              }
            </PDFDownloadLink>
          )}
          {!ready && (
            <Button
              onClick={() => {
                this.setState({ data_to_show_in_table });
                this.toggle();
              }}
            >
              Generate Report
            </Button>
          )}
        </div>
        <div
          style={{
            paddingTop: 20,
            paddingLeft: 15,
            paddingRight: 15,
          }}
        >
          <MDBDataTable responsive striped bordered data={table_data} />
        </div>
      </div>
    );
  }

  processing() {
    const { data } = this.props;

    var snapshot = data;
    var all_data_lot_no = _.map(snapshot, (val, uid) => {
      var data = {
        label: val.Lot_No,

        data: { uid, ...val },
      };

      return data;
    });

    var seen_all_data_lot_no = new Map();
    //
    all_data_lot_no = all_data_lot_no.filter((entry) => {
      var previous;
      if (seen_all_data_lot_no.hasOwnProperty(entry.label)) {
        previous = seen_all_data_lot_no[entry.label];
        previous.data.push(entry.data);

        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen_all_data_lot_no[entry.label] = entry;

      return true;
    });

    all_data_lot_no = _.fromPairs(
      _.map(all_data_lot_no, (i) => {
        return [i.label, i.data];
      })
    );

    return all_data_lot_no;
  }

  show_table_filtered_by_lot_no() {
    var data = this.state.data_by_lot_no;
    var data_to_show_in_table = [];
    var show_data = [];
    data.forEach((element) => {
      if (element.full_detail !== undefined) {
        show_data.push(element);
      }
    });

    show_data.forEach((element) => {
      const {
        cargo_details,
        consignee_details,
        other_charges,
        payments_details,
        shipper_details,
      } = element.full_detail;

      data_to_show_in_table.push({
        HBL: element.uid,
        Lot_No: element.Lot_No,
        Consignee: element.Consignee,
        Shipper: element.Shipper,
        Total_outstanding_balance: 0,
      });
    });

    const table_data = {
      columns: [
        {
          label: "HBL",
          field: "HBL",
          sort: "asc",
          width: 150,
        },
        {
          label: "Lot No",
          field: "Lot_No",
          sort: "asc",
          width: 270,
        },
        {
          label: "Consignee",
          field: "Consignee",
          sort: "asc",
          width: 200,
        },
        {
          label: "Shipper",
          field: "Shipper",
          sort: "asc",
          width: 100,
        },

        {
          label: "Outstanding Balance",
          field: "Total_outstanding_balance",
          sort: "asc",
          width: 100,
        },
      ],
      rows: data_to_show_in_table,
    };

    const { ready } = this.state;

    const MyDocument = this.show_report();
    return (
      <div>
        <div>
          {ready && (
            <PDFDownloadLink document={MyDocument} fileName="PDF">
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <Button onClick={() => this.setState({ ready: false })}>
                    Download Report
                  </Button>
                )
              }
            </PDFDownloadLink>
          )}
          {!ready && (
            <Button
              onClick={() => {
                this.setState({ data_to_show_in_table });
                this.toggle();
              }}
            >
              Generate Report
            </Button>
          )}
        </div>
        <div
          style={{
            paddingTop: 20,
            paddingLeft: 15,
            paddingRight: 15,
          }}
        >
          <MDBDataTable responsive striped bordered data={table_data} />
        </div>
      </div>
    );
  }
  show_report_based_on_lot_no() {
    var data_filtered = this.processing();

    var options_to_show = [];
    if (Object.keys(data_filtered).length > 0) {
      var data = _.map(data_filtered, (val, uid_lot) => {
        return { data: { ...val }, uid_lot };
      });

      var data_with_details = [];
      data.forEach((element) => {
        var first_element = element.data[0];
        if (first_element.full_detail !== undefined) {
          data_with_details.push(element);
        }
      });

      data_with_details.forEach((element, i) => {
        options_to_show.push(<option value={i}>{element.uid_lot}</option>);
      });
    }

    return (
      <div>
        <Form encType="multipart/form-data">
          <div data-repeater-list="group-a">
            <Row data-repeater-item>
              <Col lg="6" className="form-group">
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    <Label className="input-group-text">LOT NO</Label>
                  </InputGroupAddon>
                  <Input
                    className="form-control"
                    onChange={(event) => {
                      var value = event.target.value;

                      if (value !== "") {
                        if (data_with_details[value].data) {
                          var converted_data = _.map(
                            data_with_details[value].data,
                            (val) => {
                              return { ...val };
                            }
                          );

                          this.setState({
                            data_by_lot_no: converted_data,
                          });
                        }
                      }
                    }}
                    type="select"
                  >
                    <option value="">Select Lot No.</option>
                    {options_to_show}
                  </Input>
                </InputGroup>
                <p>
                  <span style={{ fontWeight: "bold" }}>NOTE:</span>{" "}
                  <span style={{ color: "red" }}>
                    This options is only for shipments created or updated using
                    this software.
                  </span>
                </p>
              </Col>
            </Row>
          </div>
        </Form>

        {this.state.data_by_lot_no.length > 0 ? (
          <div>{this.show_table_filtered_by_lot_no()}</div>
        ) : null}
      </div>
    );
  }

  render() {
    const { loading } = this.props;
    if (loading === true) {
      const loadingjson = {
        loop: true,
        autoplay: true,
        animationData: require("./JSON/loading.json"),

        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };

      return (
        <div className="page-content">
          <Container fluid>
            <Row className="justify-content-center mt-lg-5">
              <div className="text-center">
                <Row className="justify-content-center">
                  <div className="text-center">
                    <Lottie options={loadingjson} height={300} width={300} />
                  </div>
                </Row>
              </div>
            </Row>
          </Container>
        </div>
      );
    }
    return (
      <div>
        <TabContent activeTab={this.state.activeTab} className="mt-4">
          <TabPane tabId="1" id="buy-tab">
            {this.show_report_based_on_hbl()}
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { shipping_data, error, loading } = state.Database;

  return {
    shipping_data,
    error,
    loading,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    shipping_data_read,
    detail_error,
  })(withNamespaces()(Delivery))
);
