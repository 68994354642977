import _ from "lodash";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Badge,
  UncontrolledTooltip,
  NavItem,
  NavLink,
  FormGroup,
  Label,
  ModalBody,
  Modal,
  Container,
  Form,
  InputGroup,
  Media,
  Table,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";

import moment from "moment";

//i18n
import { withNamespaces } from "react-i18next";

// Redux
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import {
  user_data_read,
  shipping_data_read,
  // upload_data_write,
  detail_error,
} from "../../store/actions";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";

import firebase from "firebase/app";
import "firebase/database";

class DatatableTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      button_name: "Edit Delivery Status For All",
      show_alert: false,
      title: false,
      body: false,
      warning: false,
      error: false,
      success: false,
      showCancel: false,
      confirm_button_text: "Okay",
      activeTab: "1",
      //
      all_data_lot_no: null,
      by_date: null,
      by_month: null,
      by_year: null,
      selected_choice: "",
      show_selected_data: [],
      export_data: null,
      selected_uid: "",
      //
      modal_logs: false,
      logs: {},
      success_dlg: false,
      updated_status: "",
      date: "",
      entry_details: {},
      delivery_date: "",
      note: "",
    };

    // DatePicker
    this.handleDefault = this.handleDefault.bind(this);
    this.handleAutoClose = this.handleAutoClose.bind(this);
    this.handleStart = this.handleStart.bind(this);
    this.handleEnd = this.handleEnd.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
  }
  //DatePicker
  handleDefault(date) {
    this.setState({ default_date: date });
  }
  handleAutoClose(date) {
    this.setState({ auto_close: date });
  }
  handleStart(date) {
    this.setState({ start_date: date });
  }
  handleEnd(date) {
    this.setState({ end_date: date });
  }
  handleMonthChange(date) {
    this.setState({ monthDate: date });
  }
  handleYearChange(date) {
    this.setState({ yearDate: date });
  }

  renderModal() {
    const { entry_details } = this.state;

    var eta = "Not Available";
    if (entry_details.eta !== undefined) {
      // eta = entry_details.eta;
      eta = moment(entry_details.eta).format("DD-MM-YYYY");
    }

    if (entry_details.logs === undefined) {
      eta = "Not Available";
    }

    var shipment_pick_up_date = "";
    if (entry_details.Date) {
      shipment_pick_up_date = entry_details.Date; //moment(entry_details.Date).format("DD-MM-YYYY");
    }

    return (
      <Modal isOpen={this.state.modal_logs} toggle={this.state.modal_logs}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Log
          </h5>
          <button
            type="button"
            onClick={() => this.setState({ modal_logs: false })}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div
            style={{
              paddingTop: 10,
              // paddingBottom: 10,
              //  background: "#F5F5F5",
              // paddingLeft: 10,

              // paddingLeft: 15,
              // paddingRight: 15,
            }}
          >
            <h4>Shipment Details: </h4>

            <div>
              <div>
                <div
                //className="table-responsive"
                >
                  <div className="row">
                    <div className="col-md-4">
                      <p style={{ fontWeight: "bold" }}>Delivery :</p>
                    </div>

                    <div className="col-md-8">
                      <p>{entry_details.Delivery}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <p style={{ fontWeight: "bold" }}>HBL# :</p>
                    </div>

                    <div className="col-md-8">
                      <p>{entry_details.uid}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <p style={{ fontWeight: "bold" }}>Qty :</p>
                    </div>

                    <div className="col-md-8">
                      <p>{entry_details.Qty}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <p style={{ fontWeight: "bold" }}>Weight :</p>
                    </div>

                    <div className="col-md-8">
                      <p>{entry_details.Weight}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <p style={{ fontWeight: "bold" }}>
                        Shipment Pickup Date :
                      </p>
                    </div>

                    <div className="col-md-8">
                      <p>{shipment_pick_up_date}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <p style={{ fontWeight: "bold" }}>Delivery Date :</p>
                    </div>

                    <div className="col-md-8">
                      <p>{eta}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              paddingTop: 20,
            }}
          >
            <h4>Logs: </h4>
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              {this.render_logs()}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              this.setState({
                modal_logs: false,
              });
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }

  export_data(data) {
    if (data.length === 0) {
      return null;
    } else {
      var data_csv = [];

      data_csv.push([
        "No",
        "QTY",
        "Weight",
        "Lot No.",
        "Date",
        "Shipper",
        "Phone",
        "S_Town",
        "Consignee",
        "C_Phone",
        // "C_Town",
        // "Delivery",

        "Delivery",
        "C_Town",
        "Delivery Status",
      ]);

      data.forEach((element) => {
        var status = element.status;
        if (element.status === undefined) {
          status = "Processing";
        }

        data_csv.push([
          element.No,
          element.Qty,
          element.Weight,
          element.Lot_No,
          element.Date,
          element.Shipper,
          element.Phone,
          element.S_Town,
          element.Consignee,
          element.C_Phone,
          // element.C_Town,
          // element.Delivery,

          element.Delivery,
          element.C_Town,
          status,
        ]);
      });

      this.setState({
        export_data: data_csv,
      });
      //   this.state.show_selected_data
    }
  }
  processing() {
    var snapshot = this.props.shipping_data;

    var all_data_lot_no = _.map(snapshot, (val, uid) => {
      var data = {
        label: val.Lot_No,

        data: { uid, ...val },
      };

      return data;
    });

    var all_data_delivery = _.map(snapshot, (val, uid) => {
      var data = {
        label: val.Delivery,

        data: { uid, ...val },
      };

      return data;
    });

    var all_data_status = _.map(snapshot, (val, uid) => {
      var status = "";
      if (val.status) {
        status = val.status;
      } else {
        status = "Processing";
      }
      var data = {
        label: status, //val.status,
        data: { uid, ...val },
      };

      return data;
    });

    var all_data_snap_by_date = _.map(snapshot, (val, uid) => {
      var data = {
        label: moment(val.Date).format("MMMM Do YYYY"),
        data: { uid, ...val },
      };

      return data;
    });
    var all_data_snap_by_month = _.map(snapshot, (val, uid) => {
      var data = {
        label: moment(val.Date).format("MMMM YYYY"),
        data: { uid, ...val },
      };

      return data;
    });
    var all_data_snap_by_year = _.map(snapshot, (val, uid) => {
      var data = {
        label: moment(val.Date).format("YYYY"),
        data: { uid, ...val },
      };

      return data;
    });

    var seen_all_data_lot_no = new Map();
    //
    all_data_lot_no = all_data_lot_no.filter((entry) => {
      var previous;
      if (seen_all_data_lot_no.hasOwnProperty(entry.label)) {
        previous = seen_all_data_lot_no[entry.label];
        previous.data.push(entry.data);

        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen_all_data_lot_no[entry.label] = entry;

      return true;
    });

    all_data_lot_no = _.fromPairs(
      _.map(all_data_lot_no, (i) => {
        return [i.label, i.data];
      })
    );

    //

    //all_data_delivery

    var seen_all_data_delivery = new Map();
    //
    all_data_delivery = all_data_delivery.filter((entry) => {
      var previous;
      if (seen_all_data_delivery.hasOwnProperty(entry.label)) {
        previous = seen_all_data_delivery[entry.label];
        previous.data.push(entry.data);

        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen_all_data_delivery[entry.label] = entry;

      return true;
    });

    all_data_delivery = _.fromPairs(
      _.map(all_data_delivery, (i) => {
        return [i.label, i.data];
      })
    );

    //
    //

    var seen_all_data_status = new Map();
    //
    all_data_status = all_data_status.filter((entry) => {
      var previous;
      if (seen_all_data_status.hasOwnProperty(entry.label)) {
        previous = seen_all_data_status[entry.label];
        previous.data.push(entry.data);

        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen_all_data_status[entry.label] = entry;

      return true;
    });

    all_data_status = _.fromPairs(
      _.map(all_data_status, (i) => {
        return [i.label, i.data];
      })
    );

    //

    var seen_all_data_by_date = new Map();
    //
    all_data_snap_by_date = all_data_snap_by_date.filter((entry) => {
      var previous;
      if (seen_all_data_by_date.hasOwnProperty(entry.label)) {
        previous = seen_all_data_by_date[entry.label];
        previous.data.push(entry.data);

        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen_all_data_by_date[entry.label] = entry;

      return true;
    });

    all_data_snap_by_date = _.fromPairs(
      _.map(all_data_snap_by_date, (i) => {
        return [i.label, i.data];
      })
    );

    var seen_all_data_by_month = new Map();
    //
    all_data_snap_by_month = all_data_snap_by_month.filter((entry) => {
      var previous;
      if (seen_all_data_by_month.hasOwnProperty(entry.label)) {
        previous = seen_all_data_by_month[entry.label];
        previous.data.push(entry.data);

        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen_all_data_by_month[entry.label] = entry;

      return true;
    });

    all_data_snap_by_month = _.fromPairs(
      _.map(all_data_snap_by_month, (i) => {
        return [i.label, i.data];
      })
    );

    var seen_all_data_by_year = new Map();
    //
    all_data_snap_by_year = all_data_snap_by_year.filter((entry) => {
      var previous;
      if (seen_all_data_by_year.hasOwnProperty(entry.label)) {
        previous = seen_all_data_by_year[entry.label];
        previous.data.push(entry.data);

        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen_all_data_by_year[entry.label] = entry;

      return true;
    });

    all_data_snap_by_year = _.fromPairs(
      _.map(all_data_snap_by_year, (i) => {
        return [i.label, i.data];
      })
    );

    return {
      all_data_lot_no: all_data_lot_no,
      all_data_delivery: all_data_delivery,
      all_data_status: all_data_status,
      by_date: all_data_snap_by_date,
      by_month: all_data_snap_by_month,
      by_year: all_data_snap_by_year,
    };
  }
  render_filter() {
    return (
      <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
        <NavItem>
          <NavLink
            className={classnames({
              active: this.state.activeTab === "1",
            })}
            onClick={() => {
              this.setState({
                activeTab: "1",
                selected_choice: "",
                show_selected_data: [],
                export_data: null,
              });
            }}
          >
            Shipments By Lot No.
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: this.state.activeTab === "2",
            })}
            onClick={() => {
              this.setState({
                activeTab: "2",
                selected_choice: "",
                show_selected_data: [],
                export_data: null,
              });
            }}
          >
            Shipments By Delivery Location
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: this.state.activeTab === "3",
            })}
            onClick={() => {
              this.setState({
                activeTab: "3",
                selected_choice: "",
                show_selected_data: [],
                export_data: null,
              });
            }}
          >
            Shipments By Delivery Status
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: this.state.activeTab === "4",
            })}
            onClick={() => {
              this.setState({
                activeTab: "4",
                selected_choice: "",
                show_selected_data: [],
                export_data: null,
              });
            }}
          >
            Shipments By Date
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: this.state.activeTab === "5",
            })}
            onClick={() => {
              this.setState({
                activeTab: "5",
                selected_choice: "",
                show_selected_data: [],
                export_data: null,
              });
            }}
          >
            Shipments By Month
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: this.state.activeTab === "6",
            })}
            onClick={() => {
              this.setState({
                activeTab: "6",
                selected_choice: "",
                show_selected_data: [],
                export_data: null,
              });
            }}
          >
            Shipments By Year
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: this.state.activeTab === "7",
            })}
            onClick={() => {
              this.setState({
                activeTab: "7",
                selected_choice: "",
                show_selected_data: [],
              });

              const data_array = _.map(this.props.shipping_data, (val, uid) => {
                return val;
              });

              this.export_data(data_array);
            }}
          >
            All Shipments
          </NavLink>
        </NavItem>
      </ul>
    );
  }
  render_label_all() {
    const { activeTab } = this.state;

    if (activeTab === "1") {
      return <Label>Select Lot No:</Label>;
    } else if (activeTab === "2") {
      return <Label>Select Delivery Place:</Label>;
    } else if (activeTab === "3") {
      return <Label>Select Delivery Status:</Label>;
    } else if (activeTab === "4") {
      return <Label>Select Month:</Label>;
    } else {
      return <Label>Select Year:</Label>;
    }
  }
  render_content_tab_1_2_3_4() {
    const { show_selected_data } = this.state;

    if (show_selected_data !== undefined) {
      var shipping_data = [];

      const copiedArray = [...this.state.show_selected_data];

      copiedArray.forEach((second_ele) => {
        const element = { ...second_ele };

        var status = "";

        if (element.status === undefined) {
          status = "Processing";

          if (element.logs !== undefined) {
            element.status = (
              <div className="text-center">
                <Row className="text-center">
                  <Col lg="12" className="text-center">
                    <Badge
                      className={"font-size-12 badge-soft-" + "danger"}
                      color="danger"
                      pill
                    >
                      Processing
                    </Badge>
                  </Col>
                  <Col lg="12" className="text-center">
                    <a
                      className="text-center"
                      onClick={() => {
                        this.setState({
                          logs: element.logs,
                          modal_logs: true,
                          uid: element.uid,
                          entry_details: element,
                        });
                      }}
                    >
                      Check Logs
                    </a>
                  </Col>
                </Row>
              </div>
            );
          } else {
            element.status = (
              <div className="text-center">
                <Row className="text-center">
                  <Col lg="12" className="text-center">
                    <Badge
                      className={"font-size-12 badge-soft-" + "danger"}
                      color="danger"
                      pill
                    >
                      Processing
                    </Badge>
                  </Col>
                </Row>
              </div>
            );
          }
        } else {
          if (element.status === "Processing") {
            status = "Processing";

            if (element.logs !== undefined) {
              element.status = (
                <div className="text-center">
                  <Row className="text-center">
                    <Col lg="12" className="text-center">
                      <Badge
                        className={"font-size-12 badge-soft-" + "danger"}
                        color="danger"
                        pill
                      >
                        Processing
                      </Badge>
                    </Col>
                    <Col lg="12" className="text-center">
                      <a
                        className="text-center"
                        onClick={() => {
                          this.setState({
                            logs: element.logs,
                            modal_logs: true,
                            uid: element.uid,
                            entry_details: element,
                          });
                        }}
                      >
                        Check Logs
                      </a>{" "}
                    </Col>
                  </Row>
                </div>
              );
            } else {
              element.status = (
                <div className="text-center">
                  <Row className="text-center">
                    <Col lg="12" className="text-center">
                      <Badge
                        className={"font-size-12 badge-soft-" + "danger"}
                        color="danger"
                        pill
                      >
                        Processing
                      </Badge>
                    </Col>
                  </Row>
                </div>
              );
            }
          } // else {
          else if (element.status === "Delivered") {
            status = "Delivered";
            if (element.logs !== undefined) {
              element.status = (
                <div className="text-center">
                  <Row className="text-center">
                    <Col lg="12" className="text-center">
                      <Badge
                        className={"font-size-12 badge-soft-" + "success"}
                        color="success"
                        pill
                      >
                        {status}
                      </Badge>
                    </Col>
                    <Col lg="12" className="text-center">
                      <a
                        className="text-center"
                        onClick={() => {
                          this.setState({
                            logs: element.logs,
                            modal_logs: true,
                            uid: element.uid,
                            entry_details: element,
                          });
                        }}
                      >
                        Check Logs
                      </a>
                    </Col>
                  </Row>
                </div>
              );
            } else {
              element.status = (
                <div className="text-center">
                  <Row className="text-center">
                    <Col lg="12" className="text-center">
                      <Badge
                        className={"font-size-12 badge-soft-" + "danger"}
                        color="danger"
                        pill
                      >
                        Processing
                      </Badge>
                    </Col>
                  </Row>
                </div>
              );
            }
          }

          // if (element.status === "In Transit")
          else {
            //
            status = element.status; //"In Transit";

            if (element.logs !== undefined) {
              element.status = (
                <div className="text-center">
                  <Row className="text-center">
                    <Col lg="12" className="text-center">
                      <Badge
                        className={"font-size-12 badge-soft-" + "warning"}
                        color="warning"
                        pill
                      >
                        {status}
                      </Badge>
                    </Col>
                    <Col lg="12" className="text-center">
                      <a
                        className="text-center"
                        onClick={() => {
                          this.setState({
                            logs: element.logs,
                            modal_logs: true,
                            uid: element.uid,
                            entry_details: element,
                          });
                        }}
                      >
                        Check Logs
                      </a>
                    </Col>
                  </Row>
                </div>
              );
            } else {
              element.status = (
                <div className="text-center">
                  <Row className="text-center">
                    <Col lg="12" className="text-center">
                      <Badge
                        className={"font-size-12 badge-soft-" + "danger"}
                        color="danger"
                        pill
                      >
                        Processing
                      </Badge>
                    </Col>
                  </Row>
                </div>
              );
            }
          }
        }

        element.action = (
          <div>
            <Row>
              <Col lg="12" className="text-center">
                <Link
                  onClick={() => {
                    var body = (
                      <Row>
                        <Col lg="12">
                          <div className="form-group row">
                            <div className="col-md-12">
                              <Label style={{ paddingTop: 5 }}>
                                Choose Status:
                              </Label>
                              <select
                                className="form-control select2-search-disable"
                                onChange={(event) => {
                                  event.preventDefault();
                                  if (event.target.value !== "") {
                                    this.setState({
                                      type: "specific",
                                      updated_status: event.target.value,
                                      uid: second_ele.uid,
                                    });
                                  } else {
                                    this.setState({
                                      updated_status: "",
                                    });
                                  }
                                }}
                              >
                                <option value="">Select....</option>
                                {/*<option value="Dammam Terminal for Loading">
                                  Dammam Terminal for Loading
                                </option>*/}
                                <option value="Loading Now">Loading Now</option>
                                <option value="Riyadh Terminal for Loading">
                                  Riyadh Terminal for Loading
                                </option>
                                <option value="Jeddah Terminal for Loading">
                                  Jeddah Terminal for Loading
                                </option>
                                <option value="Jeddah Port">Jeddah Port</option>
                                <option value="High Seas Enroute To Mombasa Port">
                                  High Seas Enroute To Mombasa Port
                                </option>
                                <option value="Mombasa Port">
                                  Mombasa Port
                                </option>

                                <option value="Customs Clearance">
                                  Customs Clearance
                                </option>
                                <option value="Out Of Mombasa Port">
                                  Out Of Mombasa Port
                                </option>
                                <option value="Mombasa Warehouse Sorting">
                                  Mombasa Warehouse Sorting
                                </option>
                                <option value="Out For Delivery">
                                  Out For Delivery
                                </option>
                                <option value="Delivery In Progress">
                                  Delivery In Progress
                                </option>
                                <option value="Partially Delivered">
                                  Partially Delivered
                                </option>
                                <option value="Returned to the Warehouse">
                                  Returned to the Warehouse
                                </option>

                                <option value="Delivered">Delivered</option>
                              </select>
                            </div>
                          </div>
                        </Col>

                        <Col lg="12">
                          <div className="form-group row">
                            <div className="col-md-12">
                              <Label style={{ paddingTop: 5 }}>
                                Add Note (Optional):
                              </Label>

                              <input
                                style={{ paddingTop: 0 }}
                                className="form-control"
                                type="text"
                                onChange={(event) => {
                                  event.preventDefault();
                                  console.log(event.target.value);
                                  this.setState({
                                    note: event.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </Col>

                        <Col lg="12">
                          <div className="form-group row">
                            <div className="col-md-12">
                              <Label style={{ paddingTop: 5 }}>
                                Choose Date:
                              </Label>
                              <input
                                style={{ paddingTop: 0 }}
                                className="form-control"
                                type="date"
                                id="example-date-input"
                                onChange={(event) => {
                                  event.preventDefault();
                                  if (event.target.value !== "") {
                                    this.setState({
                                      date: event.target.value,
                                    });
                                  } else {
                                    console.log("empty");
                                    this.setState({
                                      date: "",
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </Col>

                        <Col lg="12">
                          <div className="form-group row">
                            <div className="col-md-12">
                              <Label style={{ paddingTop: 5 }}>
                                Delivery Date:
                              </Label>
                              <input
                                style={{ paddingTop: 0 }}
                                className="form-control"
                                type="date"
                                id="example-date-input"
                                onChange={(event) => {
                                  event.preventDefault();
                                  if (event.target.value !== "") {
                                    this.setState({
                                      delivery_date: event.target.value,
                                    });
                                  } else {
                                    console.log("empty");
                                    this.setState({
                                      delivery_date: "",
                                    });
                                  }
                                }}
                              />

                              <p
                                style={{
                                  color: "#B00020",
                                  paddingTop: 2,
                                }}
                              >
                                Note: If you don't select a 'Delivery Date', the
                                default option 'Not Available' will show on the
                                tracker.
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    );
                    this.setState({
                      type: "specific",
                      show_alert: true,
                      title: "Change Delivery Status",
                      body: body,
                      warning: true,
                      error: false,
                      success: false,
                      showCancel: true,
                      confirm_button_text: "Update Status",
                    });
                  }}
                  className="mr-3 text-primary text-center"
                >
                  <i
                    className="mdi mdi-pencil font-size-18 "
                    id="edittooltip"
                  ></i>
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit Delivery Status
                  </UncontrolledTooltip>
                </Link>
              </Col>
              <Col lg="12" className="text-center">
                <Link
                  to="#"
                  className="mr-3 text-danger text-center"
                  onClick={() => {
                    this.setState({
                      type: "delete",
                      uid: second_ele.uid,

                      show_alert: true,
                      title: "Are you sure?",
                      // title: "Test",
                      // body: "Test",
                      body: <div></div>,
                      warning: false,
                      error: true,
                      success: false,
                      showCancel: true,
                      confirm_button_text: "Delete Entry",
                    });
                  }}
                >
                  <i
                    className="mdi mdi-close font-size-18"
                    id="deletetooltip"
                  ></i>
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete Entry
                  </UncontrolledTooltip>
                </Link>
              </Col>
            </Row>
          </div>
        );

        shipping_data.push(element);
      });

      const data = {
        columns: [
          {
            label: "No",
            field: "No",
            sort: "asc",
            width: 150,
          },
          {
            label: "QTY",
            field: "Qty",
            sort: "asc",
            width: 270,
          },
          {
            label: "Weight",
            field: "Weight",
            sort: "asc",
            width: 200,
          },
          {
            label: "Lot No.",
            field: "Lot_No",
            sort: "asc",
            width: 100,
          },
          {
            label: "Date",
            field: "Date",
            sort: "asc",
            width: 150,
          },
          {
            label: "Shipper",
            field: "Shipper",
            sort: "asc",
            width: 100,
          },
          // {
          //   label: "Phone",
          //   field: "Phone",
          //   sort: "asc",
          //   width: 100,
          // },
          {
            label: "S_Town",
            field: "S_Town",
            sort: "asc",
            width: 100,
          },
          {
            label: "Consignee",
            field: "Consignee",
            sort: "asc",
            width: 100,
          },
          {
            label: "C_Phone",
            field: "C_Phone",
            sort: "asc",
            width: 100,
          },

          {
            label: "Delivery",
            field: "Delivery",
            sort: "asc",
            width: 100,
          },
          {
            label: "C_Town",
            field: "C_Town",
            sort: "asc",
            width: 100,
          },

          {
            label: "Delivery Status",
            field: "status",
            sort: "asc",
            width: 100,
          },
          {
            label: "Action",
            field: "action",
            sort: "asc",
            width: 100,
          },
        ],
        rows: shipping_data,
      };

      if (shipping_data.length === 0) {
        return null;
      }

      if (this.state.selected_choice === "") {
        return null;
      }
      return (
        <div
          style={{
            paddingTop: 20,
            paddingLeft: 15,
            paddingRight: 15,
          }}
        >
          <MDBDataTable responsive striped bordered data={data} />
        </div>
      );
    }
    return null;
  }

  render_tab_1_2_3_4() {
    var data_filtered = this.processing();
    var export_title = "";
    //all_data_status
    const { activeTab } = this.state;
    var data = {};
    if (activeTab === "1") {
      if (Object.keys(data_filtered.all_data_lot_no).length > 0) {
        data = data_filtered.all_data_lot_no;
        export_title = "Data_Export_By_Lot_No";
      }
    } else if (activeTab === "2") {
      if (Object.keys(data_filtered.by_date).length > 0) {
        data = data_filtered.all_data_delivery;
        export_title = "Data_Export_By_Delivery";
      }
    } else if (activeTab === "3") {
      if (Object.keys(data_filtered.all_data_status).length > 0) {
        data = data_filtered.all_data_status;
        export_title = "Data_Export_By_Delivery";
      }
    } else if (activeTab === "4") {
      if (Object.keys(data_filtered.by_date).length > 0) {
        data = data_filtered.by_date;
        export_title = "Data_Export_By_Date";
      }
    } else if (activeTab === "5") {
      if (Object.keys(data_filtered.by_month).length > 0) {
        data = data_filtered.by_month;
        export_title = "Data_Export_By_Month";
      }
    } else {
      if (Object.keys(data_filtered.by_year).length > 0) {
        data = data_filtered.by_year;
        export_title = "Data_Export_By_Year";
      }
    }

    var show_selection = false;
    var show_data_id_val = _.map(data, (val, uid) => {
      return { uid, val };
    });

    var selection = [];
    var export_data = this.state.export_data;
    if (Object.keys(data).length > 0) {
      if (show_data_id_val.length > 0) {
        // show_data_id_val
        show_selection = true;

        show_data_id_val.forEach((element, i) => {
          selection.push(
            <option value={i} key={i} data={element.val}>
              {element.uid}
            </option>
          );
        });
      } else {
        show_selection = false;
      }
      // console.log(show_data_id_val);
    }

    return (
      <div>
        <div className="row">
          <div className="col-xl col-sm-6">
            {this.render_label_all()}
            <FormGroup className="mt-0 mb-0">
              <select
                className="form-control select2-search-disable"
                value={this.state.selected_choice}
                onChange={(event) => {
                  event.preventDefault();

                  this.export_data(show_data_id_val[event.target.value].val);

                  this.setState({
                    selected_choice: event.target.value,
                    show_selected_data:
                      show_data_id_val[event.target.value].val,

                    selected_uid: show_data_id_val[event.target.value].uid,
                  });
                }}
              >
                <option value="" defaultValue>
                  Select...
                </option>

                {selection}
              </select>
            </FormGroup>
          </div>
          <div className="col-xl col-sm-6">
            {export_data === null ? null : (
              <div className="row mt-4">
                <div className="col-xl col-sm-6">
                  <div className="text-sm-right">
                    <CSVLink
                      filename={`${export_title}_${this.state.selected_uid}.csv`}
                      data={export_data}
                    >
                      <Button type="button" color="primary" className="w-md">
                        Export Data
                      </Button>
                    </CSVLink>
                  </div>
                </div>

                <div className="col-xl col-sm-6">
                  <div //className="text-sm-right"
                  >
                    <Button
                      type="button"
                      // color="success"

                      onClick={() => {
                        var body = (
                          <Row>
                            <Col lg="12">
                              <div className="form-group row">
                                <div className="col-md-12">
                                  <Label style={{ paddingTop: 5 }}>
                                    Choose Status:
                                  </Label>
                                  <select
                                    className="form-control select2-search-disable"
                                    //   value={status}
                                    onChange={(event) => {
                                      event.preventDefault();
                                      if (event.target.value !== "") {
                                        this.setState({
                                          type: "all",
                                          updated_status: event.target.value,
                                        });
                                      } else {
                                        this.setState({
                                          updated_status: "",
                                        });
                                      }
                                    }}
                                  >
                                    <option value="">Select....</option>

                                    {/*<option value="Dammam Terminal for Loading">
                                      Dammam Terminal for Loading
                                    </option>*/}
                                    <option value="Loading Now">
                                      Loading Now
                                    </option>
                                    <option value="Riyadh Terminal for Loading">
                                      Riyadh Terminal for Loading
                                    </option>
                                    <option value="Jeddah Terminal for Loading">
                                      Jeddah Terminal for Loading
                                    </option>
                                    <option value="Jeddah Port">
                                      Jeddah Port
                                    </option>
                                    <option value="High Seas Enroute To Mombasa Port">
                                      High Seas Enroute To Mombasa Port
                                    </option>
                                    <option value="Mombasa Port">
                                      Mombasa Port
                                    </option>

                                    <option value="Customs Clearance">
                                      Customs Clearance
                                    </option>
                                    <option value="Out Of Mombasa Port">
                                      Out Of Mombasa Port
                                    </option>
                                    <option value="Mombasa Warehouse Sorting">
                                      Mombasa Warehouse Sorting
                                    </option>
                                    <option value="Out For Delivery">
                                      Out For Delivery
                                    </option>

                                    <option value="Delivery In Progress">
                                      Delivery In Progress
                                    </option>

                                    <option value="Partially Delivered">
                                      Partially Delivered
                                    </option>
                                    <option value="Returned to the Warehouse">
                                      Returned to the Warehouse
                                    </option>

                                    <option value="Delivered">Delivered</option>
                                  </select>
                                </div>
                              </div>
                            </Col>

                            <Col lg="12">
                              <div className="form-group row">
                                <div className="col-md-12">
                                  <Label style={{ paddingTop: 5 }}>
                                    Add Note (Optional):
                                  </Label>

                                  <input
                                    style={{ paddingTop: 0 }}
                                    className="form-control"
                                    type="text"
                                    onChange={(event) => {
                                      event.preventDefault();
                                      console.log(event.target.value);

                                      this.setState({
                                        note: event.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>

                            <Col lg="12">
                              <div className="form-group row">
                                <div className="col-md-12">
                                  <Label style={{ paddingTop: 5 }}>
                                    Choose Date:
                                  </Label>
                                  <input
                                    style={{ paddingTop: 0 }}
                                    className="form-control"
                                    type="date"
                                    id="example-date-input"
                                    onChange={(event) => {
                                      event.preventDefault();
                                      if (event.target.value !== "") {
                                        this.setState({
                                          date: event.target.value,
                                        });
                                      } else {
                                        console.log("empty");
                                        this.setState({
                                          date: "",
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>

                            <Col lg="12">
                              <div className="form-group row">
                                <div className="col-md-12">
                                  <Label style={{ paddingTop: 5 }}>
                                    Delivery Date:
                                  </Label>
                                  <input
                                    style={{ paddingTop: 0 }}
                                    className="form-control"
                                    type="date"
                                    id="example-date-input"
                                    onChange={(event) => {
                                      event.preventDefault();
                                      if (event.target.value !== "") {
                                        this.setState({
                                          delivery_date: event.target.value,
                                        });
                                      } else {
                                        console.log("empty");
                                        this.setState({
                                          delivery_date: "",
                                        });
                                      }
                                    }}
                                  />
                                  <p
                                    style={{
                                      color: "#B00020",
                                      paddingTop: 2,
                                    }}
                                  >
                                    Note: If you don't select a 'Delivery Date',
                                    the default option 'Not Available' will show
                                    on the tracker.
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        );
                        this.setState({
                          show_alert: true,
                          title: "Change Delivery Status",
                          body: body,
                          warning: true,
                          error: false,
                          success: false,
                          showCancel: true,
                          confirm_button_text: "Update Status",
                          type: "all",
                        });
                      }}
                      outline
                      color="primary"
                      className=" waves-effect waves-light mb-2 mr-2"
                    >
                      {this.state.button_name}
                    </Button>
                  </div>

                  <div //className="text-sm-right"
                  >
                    <Button
                      type="button"
                      // color="success"

                      onClick={() => {
                        this.setState({
                          show_alert: true,
                          title: "Are you sure?",
                          body: "It will delete the all logs for your selection.",
                          warning: true,
                          error: false,
                          success: false,
                          showCancel: true,
                          confirm_button_text: "Delete",
                          type: "delete_all_status",
                        });
                      }}
                      outline
                      color="primary"
                      className=" waves-effect waves-light mb-2 mr-2"
                    >
                      Delete Delivery Status for All
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {this.render_content_tab_1_2_3_4()}
      </div>
    );
  }
  render_tab_5() {
    var shipping_data = [];
    var export_data = this.state.export_data;
    if (this.props.shipping_data) {
      const data_array = _.map(this.props.shipping_data, (val, uid) => {
        return { ...val, uid };
      });

      const copiedArray = data_array;

      copiedArray.forEach((second_ele) => {
        const element = { ...second_ele };

        var status = "";
        if (element.status === undefined) {
          status = "Processing";

          if (element.logs !== undefined) {
            element.status = (
              <div className="text-center">
                <Row className="text-center">
                  <Col lg="12" className="text-center">
                    <Badge
                      className={"font-size-12 badge-soft-" + "danger"}
                      color="danger"
                      pill
                    >
                      Processing
                    </Badge>
                  </Col>
                  <Col lg="12" className="text-center">
                    <a
                      className="text-center"
                      onClick={() => {
                        this.setState({
                          logs: element.logs,
                          modal_logs: true,
                          uid: element.uid,
                          entry_details: element,
                        });
                      }}
                    >
                      Check Logs
                    </a>
                  </Col>
                </Row>
              </div>
            );
          } else {
            element.status = (
              <div className="text-center">
                <Row className="text-center">
                  <Col lg="12" className="text-center">
                    <Badge
                      className={"font-size-12 badge-soft-" + "danger"}
                      color="danger"
                      pill
                    >
                      Processing
                    </Badge>
                  </Col>
                </Row>
              </div>
            );
          }
        } else {
          if (element.status === "Processing") {
            status = "Processing";

            if (element.logs !== undefined) {
              element.status = (
                <div className="text-center">
                  <Row className="text-center">
                    <Col lg="12" className="text-center">
                      <Badge
                        className={"font-size-12 badge-soft-" + "danger"}
                        color="danger"
                        pill
                      >
                        Processing
                      </Badge>
                    </Col>
                    <Col lg="12" className="text-center">
                      <a
                        className="text-center"
                        onClick={() => {
                          this.setState({
                            logs: element.logs,
                            modal_logs: true,
                            uid: element.uid,
                            entry_details: element,
                          });
                        }}
                      >
                        Check Logs
                      </a>
                    </Col>
                  </Row>
                </div>
              );
            } else {
              element.status = (
                <div className="text-center">
                  <Row className="text-center">
                    <Col lg="12" className="text-center">
                      <Badge
                        className={"font-size-12 badge-soft-" + "danger"}
                        color="danger"
                        pill
                      >
                        Processing
                      </Badge>
                    </Col>
                  </Row>
                </div>
              );
            }
          } else if (element.status === "Delivered") {
            status = "Delivered";
            if (element.logs !== undefined) {
              element.status = (
                <div className="text-center">
                  <Row className="text-center">
                    <Col lg="12" className="text-center">
                      <Badge
                        className={"font-size-12 badge-soft-" + "success"}
                        color="success"
                        pill
                      >
                        {status}
                      </Badge>
                    </Col>
                    <Col lg="12" className="text-center">
                      <a
                        className="text-center"
                        onClick={() => {
                          this.setState({
                            logs: element.logs,
                            modal_logs: true,
                            uid: element.uid,
                            entry_details: element,
                          });
                        }}
                      >
                        Check Logs
                      </a>
                    </Col>
                  </Row>
                </div>
              );
            } else {
              element.status = (
                <div className="text-center">
                  <Row className="text-center">
                    <Col lg="12" className="text-center">
                      <Badge
                        className={"font-size-12 badge-soft-" + "danger"}
                        color="danger"
                        pill
                      >
                        Processing
                      </Badge>
                    </Col>
                  </Row>
                </div>
              );
            }
          } else {
            status = element.status;
            if (element.logs !== undefined) {
              element.status = (
                <div className="text-center">
                  <Row className="text-center">
                    <Col lg="12" className="text-center">
                      <Badge
                        className={"font-size-12 badge-soft-" + "warning"}
                        color="warning"
                        pill
                      >
                        {status}
                      </Badge>
                    </Col>
                    <Col lg="12" className="text-center">
                      <a
                        className="text-center"
                        onClick={() => {
                          this.setState({
                            logs: element.logs,
                            modal_logs: true,
                            uid: element.uid,
                            entry_details: element,
                          });
                        }}
                      >
                        Check Logs
                      </a>
                    </Col>
                  </Row>
                </div>
              );
            } else {
              element.status = (
                <div className="text-center">
                  <Row className="text-center">
                    <Col lg="12" className="text-center">
                      <Badge
                        className={"font-size-12 badge-soft-" + "danger"}
                        color="danger"
                        pill
                      >
                        Processing
                      </Badge>
                    </Col>
                  </Row>
                </div>
              );
            }
          }
        }

        element.action = (
          <div>
            <Row>
              <Col lg="12" className="text-center">
                <Link
                  onClick={() => {
                    var body = (
                      <Row>
                        <Col lg="12">
                          <div className="form-group row">
                            <div className="col-md-12">
                              <Label style={{ paddingTop: 5 }}>
                                Choose Status:
                              </Label>
                              <select
                                className="form-control select2-search-disable"
                                onChange={(event) => {
                                  event.preventDefault();
                                  if (event.target.value !== "") {
                                    this.setState({
                                      type: "specific",
                                      updated_status: event.target.value,
                                      uid: second_ele.uid,
                                    });
                                  } else {
                                    this.setState({
                                      updated_status: "",
                                    });
                                  }
                                }}
                              >
                                <option value="">Select....</option>

                                {/*<option value="Dammam Terminal for Loading">
                                  Dammam Terminal for Loading
                                </option>*/}
                                <option value="Loading Now">Loading Now</option>
                                <option value="Riyadh Terminal for Loading">
                                  Riyadh Terminal for Loading
                                </option>
                                <option value="Jeddah Terminal for Loading">
                                  Jeddah Terminal for Loading
                                </option>
                                <option value="Jeddah Port">Jeddah Port</option>
                                <option value="High Seas Enroute To Mombasa Port">
                                  High Seas Enroute To Mombasa Port
                                </option>
                                <option value="Mombasa Port">
                                  Mombasa Port
                                </option>

                                <option value="Customs Clearance">
                                  Customs Clearance
                                </option>
                                <option value="Out Of Mombasa Port">
                                  Out Of Mombasa Port
                                </option>
                                <option value="Mombasa Warehouse Sorting">
                                  Mombasa Warehouse Sorting
                                </option>
                                <option value="Out For Delivery">
                                  Out For Delivery
                                </option>

                                <option value="Delivery In Progress">
                                  Delivery In Progress
                                </option>

                                <option value="Partially Delivered">
                                  Partially Delivered
                                </option>
                                <option value="Returned to the Warehouse">
                                  Returned to the Warehouse
                                </option>

                                <option value="Delivered">Delivered</option>
                              </select>
                            </div>
                          </div>
                        </Col>

                        <Col lg="12">
                          <div className="form-group row">
                            <div className="col-md-12">
                              <Label style={{ paddingTop: 5 }}>
                                Add Note (Optional):
                              </Label>

                              <input
                                style={{ paddingTop: 0 }}
                                className="form-control"
                                type="text"
                                onChange={(event) => {
                                  event.preventDefault();
                                  console.log(event.target.value);

                                  this.setState({
                                    note: event.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </Col>

                        <Col lg="12">
                          <div className="form-group row">
                            <div className="col-md-12">
                              <Label style={{ paddingTop: 5 }}>
                                Choose Date:
                              </Label>
                              <input
                                style={{ paddingTop: 0 }}
                                className="form-control"
                                type="date"
                                id="example-date-input"
                                onChange={(event) => {
                                  event.preventDefault();
                                  if (event.target.value !== "") {
                                    this.setState({
                                      date: event.target.value,
                                    });
                                  } else {
                                    console.log("empty");
                                    this.setState({
                                      date: "",
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </Col>

                        <Col lg="12">
                          <div className="form-group row">
                            <div className="col-md-12">
                              <Label style={{ paddingTop: 5 }}>
                                Delivery Date:
                              </Label>
                              <input
                                style={{ paddingTop: 0 }}
                                className="form-control"
                                type="date"
                                id="example-date-input"
                                onChange={(event) => {
                                  event.preventDefault();
                                  if (event.target.value !== "") {
                                    this.setState({
                                      delivery_date: event.target.value,
                                    });
                                  } else {
                                    console.log("empty");
                                    this.setState({
                                      delivery_date: "",
                                    });
                                  }
                                }}
                              />
                              <p
                                style={{
                                  color: "#B00020",
                                  paddingTop: 3,
                                }}
                              >
                                Note: If you don't select a 'Delivery Date', the
                                default option 'Not Available' will show on the
                                tracker.
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    );
                    this.setState({
                      show_alert: true,
                      title: "Change Delivery Status",
                      body: body,
                      warning: true,
                      error: false,
                      success: false,
                      showCancel: true,
                      confirm_button_text: "Update Status",
                    });
                  }}
                  className="mr-3 text-primary text-center"
                >
                  <i
                    className="mdi mdi-pencil font-size-18 "
                    id="edittooltip"
                  ></i>
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit Delivery Status
                  </UncontrolledTooltip>
                </Link>
              </Col>
              <Col lg="12" className="text-center">
                <Link
                  to="#"
                  className="mr-3 text-danger text-center"
                  onClick={() => {
                    this.setState({
                      type: "delete",
                      uid: second_ele.uid,
                      show_alert: true,
                      title: "Are you sure?",
                      // title: "Test",
                      body: <div></div>,
                      warning: false,
                      error: true,
                      success: false,
                      showCancel: true,
                      confirm_button_text: "Delete Entry",
                    });
                  }}
                >
                  <i
                    className="mdi mdi-close font-size-18"
                    id="deletetooltip"
                  ></i>
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete Entry
                  </UncontrolledTooltip>
                </Link>
              </Col>
            </Row>
          </div>
        );
        shipping_data.push(element);
      });
    }
    const data = {
      columns: [
        {
          label: "No",
          field: "No",
          sort: "asc",
          width: 150,
        },
        {
          label: "QTY",
          field: "Qty",
          sort: "asc",
          width: 270,
        },
        {
          label: "Weight",
          field: "Weight",
          sort: "asc",
          width: 200,
        },
        {
          label: "Lot No.",
          field: "Lot_No",
          sort: "asc",
          width: 100,
        },
        {
          label: "Date",
          field: "Date",
          sort: "asc",
          width: 150,
        },
        {
          label: "Shipper",
          field: "Shipper",
          sort: "asc",
          width: 100,
        },

        {
          label: "S_Town",
          field: "S_Town",
          sort: "asc",
          width: 100,
        },
        {
          label: "Consignee",
          field: "Consignee",
          sort: "asc",
          width: 100,
        },
        {
          label: "C_Phone",
          field: "C_Phone",
          sort: "asc",
          width: 100,
        },

        {
          label: "Delivery",
          field: "Delivery",
          sort: "asc",
          width: 100,
        },

        {
          label: "C_Town",
          field: "C_Town",
          sort: "asc",
          width: 100,
        },

        {
          label: "Delivery Status",
          field: "status",
          sort: "asc",
          width: 100,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: shipping_data,
    };
    return (
      <div>
        <div className="row mt-4">
          <div className="col-xl col-sm-12">
            <div className="text-sm-right">
              <CSVLink filename={`All_Shipments_Export.csv`} data={export_data}>
                <Button type="button" color="primary" className="w-md">
                  Export Data
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>

        <MDBDataTable responsive striped bordered data={data} />
      </div>
    );
  }

  delete_processing() {
    const { type, updated_status, uid } = this.state;
    firebase
      .database()
      .ref(
        `organization/${this.props.user_details.organization}/shipment_details/${uid}`
      )
      .remove()
      .then(() => {
        console.log("SUCCESS");
      })
      .catch((err) => {
        console.log(err);
      });
    this.props.shipping_data_read();

    this.setState({
      show_alert: false,
      success_dlg: true,
      dynamic_title: "Successful",
      dynamic_description: "Entry has been successfully deleted!",
      selected_choice: "",
    });
  }
  specfic_processing() {
    const { type, updated_status, uid } = this.state;

    var eta = "Not Available";

    if (this.state.date !== "" && updated_status !== "") {
      var date_entered = this.state.date;
      var updated_status_entered = updated_status;

      if (this.state.delivery_date !== "") {
        eta = this.state.delivery_date;
      }
      var send_data = {
        status: updated_status_entered,
        date: date_entered,
      };
      if (this.state.note !== "") {
        send_data = {
          status: updated_status_entered,
          date: date_entered,
          note: this.state.note,
        };
      }

      firebase
        .database()
        .ref(
          `organization/${this.props.user_details.organization}/shipment_details/${uid}`
        )
        .update({
          status: updated_status_entered,
          eta,
        });

      firebase
        .database()
        .ref(
          `organization/${this.props.user_details.organization}/shipment_details/${uid}/logs`
        )
        .push(send_data);

      this.props.shipping_data_read();

      this.setState({
        show_alert: false,
        success_dlg: true,
        dynamic_title: "Successful",
        dynamic_description: "Delivery status has been updated!",
        selected_choice: "",
        date: "",
        status: "",
        delivery_date: "",
      });
    } else {
      alert("Please ensure both field are changed before updating!");
    }
  }
  all_processing() {
    const { type, updated_status, uid } = this.state;

    var eta = "Not Available";

    if (updated_status !== "" && this.state.date !== "") {
      var date_entered = this.state.date;
      var updated_status_entered = updated_status;

      if (this.state.delivery_date !== "") {
        eta = this.state.delivery_date;
      }

      this.state.show_selected_data.forEach(async (element, i) => {
        var new_ele = { ...element };

        new_ele.status = updated_status;

        var send_data = {
          status: updated_status_entered,
          date: date_entered,
        };
        if (this.state.note !== "") {
          send_data = {
            status: updated_status_entered,
            date: date_entered,
            note: this.state.note,
          };
        }
        // console.log(this.state.note);

        // try {
        //   await firebase
        //     .database()
        //     .ref(
        //       `organization/${this.props.user_details.organization}/shipment_details/${new_ele.uid}`
        //     )
        //     .update({
        //       status: updated_status_entered,
        //       eta,
        //     });

        //   await firebase
        //     .database()
        //     .ref(
        //       `organization/${this.props.user_details.organization}/shipment_details/${new_ele.uid}/logs`
        //     )
        //     .push(send_data);
        // } catch (error) {
        //   console.log(error);
        // }

        firebase
          .database()
          .ref(
            `organization/${this.props.user_details.organization}/shipment_details/${new_ele.uid}`
          )
          .update({
            status: updated_status_entered,
            eta,
          });
        firebase
          .database()
          .ref(
            `organization/${this.props.user_details.organization}/shipment_details/${new_ele.uid}/logs`
          )
          .push(send_data);
      });

      this.setState({
        show_alert: false,
        success_dlg: true,
        dynamic_title: "Successful",
        dynamic_description: "Delivery status has been updated!",
        selected_choice: "",
        date: "",
        status: "",
        delivery_date: "",
      });

      this.props.shipping_data_read();
    } else {
      alert("Please ensure both field are changed before updating!");
    }
  }

  delete_all_status() {
    const { type, updated_status, uid } = this.state;

    this.state.show_selected_data.forEach(async (element, i) => {
      var new_ele = { ...element };

      firebase
        .database()
        .ref(
          `organization/${this.props.user_details.organization}/shipment_details/${new_ele.uid}/logs`
        )
        .remove();
    });

    this.setState({
      show_alert: false,
      success_dlg: true,
      dynamic_title: "Successful",
      dynamic_description: "Delivery status has been updated!",
      selected_choice: "",
      date: "",
      status: "",
      delivery_date: "",
    });

    this.props.shipping_data_read();
  }

  render_logs() {
    var data = _.map(this.state.logs, (val, uid) => {
      return { ...val, uid };
    });

    var show = [];
    data.forEach((element, i) => {
      var show_date = "";
      if (element.date) {
        show_date = moment(element.date).format("DD-MM-YYYY");
      }
      show.push(
        <li className="event-list">
          <div className="event-timeline-dot">
            <i className="bx bx-right-arrow-circle font-size-18"></i>
          </div>
          <Media>
            <div className="mr-3">
              <h5 className="font-size-14">
                {show_date}
                <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ml-2"></i>
              </h5>
            </div>
            <Media body>
              <div className="row">
                <div className="col-md-6">
                  <span>{element.status}</span>
                  <div>
                    {element.note ? (
                      <div>
                        <span style={{ fontWeight: "bold" }}>Note:</span>
                        <span>{element.note}</span>{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <a
                    className="text-center"
                    onClick={() => {
                      const { type, updated_status, uid } = this.state;

                      firebase
                        .database()
                        .ref(
                          `organization/${this.props.user_details.organization}/shipment_details/${this.state.uid}/logs/${element.uid}`
                        )
                        .remove()
                        .then(() => {
                          this.setState({
                            modal_logs: false,
                            show_alert: true,
                            title: "Status Deleted",
                            body: "Log has been updated",
                            warning: false,
                            error: false,
                            success: true,
                            showCancel: false,
                            confirm_button_text: "Okay",
                          });
                          this.props.shipping_data_read();
                        });
                    }}
                  >
                    Delete Status
                  </a>
                </div>
              </div>
            </Media>
          </Media>
        </li>
      );
    });
    return (
      <div>
        <ul className="verti-timeline list-unstyled">{show}</ul>
      </div>
    );
  }
  render() {
    if (this.props.shipping_data === null) {
      return (
        <div className="page-content">
          {this.renderModal()}
          <Container fluid>
            <Row className="justify-content-center mt-lg-5">
              <Col xl="5" sm="8">
                <Card>
                  <CardBody>
                    <div className="text-center">
                      <Row className="justify-content-center">
                        <Col lg="12">
                          <h4 className="mt-4 font-weight-semibold">
                            Shipments
                          </h4>
                          <br />
                          <p style={{ color: "#B00020" }}>
                            No Shipment Data Saved Yet!
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
    return (
      <React.Fragment>
        {this.renderModal()}

        {this.state.success_dlg ? (
          <SweetAlert
            success
            title={this.state.dynamic_title}
            onConfirm={() =>
              this.setState({
                success_dlg: false,

                modal_logs: false,
                show_alert: false,
                warning: false,
                error: false,
                success: false,
                showCancel: false,
              })
            }
          >
            {this.state.dynamic_description}
          </SweetAlert>
        ) : null}

        {this.state.show_alert ? (
          <SweetAlert
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            showCancel={this.state.showCancel}
            title={this.state.title}
            warning={this.state.warning}
            error={this.state.error}
            success={this.state.success}
            confirmBtnText={this.state.confirm_button_text}
            onConfirm={() => {
              const { type, updated_status, uid } = this.state;

              if (type === "specific") {
                this.specfic_processing();
              } else if (type === "delete") {
                this.delete_processing();
              } else if (type === "all") {
                this.all_processing();
              } else if (type === "delete_all_status") {
                this.delete_all_status();
              } else {
                this.setState({ show_alert: false });
              }
            }}
            onCancel={() => this.setState({ show_alert: false })}
          >
            {this.state.body}
          </SweetAlert>
        ) : null}

        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Tracking" breadcrumbItem="Shipment Tracking" />

            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-3">Shipment Tracking</h4>

                  {this.render_filter()}
                  {this.state.activeTab === "1" ||
                  this.state.activeTab === "2" ||
                  this.state.activeTab === "3" ||
                  this.state.activeTab === "4" ||
                  this.state.activeTab === "5" ||
                  this.state.activeTab === "6" ? (
                    <div
                      style={{
                        paddingTop: 20,
                        paddingLeft: 15,
                        paddingRight: 15,
                      }}
                    >
                      {this.render_tab_1_2_3_4()}
                    </div>
                  ) : (
                    <div
                      style={{
                        paddingTop: 20,
                        paddingLeft: 15,
                        paddingRight: 15,
                      }}
                    >
                      {this.render_tab_5()}
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(null, {
    user_data_read,
    shipping_data_read,
    detail_error,
  })(withNamespaces()(DatatableTables))
);
