import React, { Component } from "react";
import Main from "./Main";

//i18n
import { withNamespaces } from "react-i18next";

// Redux
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { user_data_read, detail_error } from "../../store/actions";
import Lottie from "react-lottie";

import firebase from "firebase/app";
import "firebase/database";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rates_data_extracted: {},
    };
  }

  componentDidMount() {
    firebase
      .database()
      .ref("organization/zaincocargo/rates/")
      .once("value")
      .then((snapshot) => {
        this.setState({
          rates_data_extracted: snapshot.val(),
        });
      });

    this.props.user_data_read();
  }

  render() {
    const { user_details } = this.props;
    return (
      <React.Fragment>
        <Main
          user_details={user_details}
          rates_data_extracted={this.state.rates_data_extracted}
        />
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user_details, error, loading } = state.Database;

  return {
    user_details,
    error,
    loading,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    user_data_read,
    detail_error,
  })(withNamespaces()(Index))
);
