import _ from "lodash";
import React, { Component, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
  UncontrolledTooltip,
  ////////
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  InputGroupAddon,
  InputGroup,
  Modal,
} from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";

import firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/database";
import SweetAlert from "react-bootstrap-sweetalert";

import classnames from "classnames";

// import "./CSS/surveyQR.css";
import "./CSS/surveyQR.css";

// Import Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//Import Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

class TasksCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      activeTab: "1",
      name: "",
      address: "",
      phone: "",
      town: "",
      country: "",
      //old
      old_data: [],
      old_name: "",
      old_address: "",
      old_phone: "",
      old_town: "",
      old_country: "",
      old_uid: "",

      //
      base: "SAR",
      quote: "",
      rate: "",
      old_base: "SAR",
      old_quote: "",
      old_rate: "",

      //
      body: <div></div>,
      show_alert: false,
      modal_standard: false,
      show_old_data: false,
      currency_uid: "",
    };
    this.startDateChange.bind(this);
    this.endDateChange.bind(this);

    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  startDateChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  endDateChange = (date) => {
    this.setState({
      endDate: date,
    });
  };

  search_fetch() {
    firebase
      .database()
      .ref("organization/zaincocargo/currency_converter/")
      .once("value")
      .then((snapshot) => {
        this.setState({
          base: snapshot.val().base_currency,
          quote: snapshot.val().quote_currency,
          rate: snapshot.val().rate,
        });
      });
  }

  componentDidMount() {
    firebase
      .database()
      .ref("organization/zaincocargo/currency_converter/")
      .once("value")
      .then((snapshot) => {
        this.setState({
          base: snapshot.val().base_currency,
          quote: snapshot.val().quote_currency,
          rate: snapshot.val().rate,
        });
      });
  }
  reset() {
    this.setState({
      name: "",
      address: "",
      phone: "",
      town: "",
      country: "",
      //old
      old_data: [],
      old_name: "",
      old_address: "",
      old_phone: "",
      old_town: "",
      old_country: "",
      old_uid: "",
      body: <div></div>,
      show_alert: false,
      modal_standard: false,
      show_old_data: false,
      //
      base: "SAR",
      quote: "",
      rate: "",
      old_base: "SAR",
      old_quote: "",
      old_rate: "",
      //
    });
  }
  addShipper() {
    const { base, quote, rate } = this.state;
    if (
      // name === "" ||
      // address === "" ||
      // phone === "" ||
      // town === "" ||
      // country === ""

      base === "" ||
      quote === "" ||
      rate === ""
    ) {
      alert("Please ensure no field are empty!");
    } else {
      firebase
        .database()
        .ref("organization/zaincocargo/currency_converter/")
        .update({
          base_currency: base,
          quote_currency: quote,
          rate: rate,
        })
        // .push({
        //   // name,
        //   // address,
        //   // phone,
        //   // town,
        //   // country,
        //   base_currency: base,
        //   quote_currency: quote,
        //   rate: rate,
        // })
        .then(() => {
          this.setState({
            base: base, // "SAR",
            quote: quote, //"",
            rate: rate, //"",
          });
          alert("Your currency pair was successfully added!");
        })
        .catch(() => {
          alert(
            "Oops! There was an error while adding your currency pair. Please try again!"
          );
        });
    }
  }

  updateShipper() {
    const { old_uid, old_base, old_quote, old_rate } = this.state;
    if (old_base === "" || old_quote === "" || old_rate === "") {
      alert("Please ensure no field are empty!");
    } else {
      // firebase
      //   .database()
      //   .ref(`organization/zaincocargo/currency_converter/${old_uid}`)
      firebase
        .database()
        .ref(`organization/zaincocargo/currency_converter/`)
        .update({
          base_currency: old_base,
          quote_currency: old_quote,
          rate: old_rate,
        })
        .then(() => {
          // firebase
          //   .database()
          //   .ref("organization/zaincocargo/currency_converter/")
          //   .once("value")
          //   .then((snapshot) => {
          //     var data_array = _.map(snapshot.val(), (val, uid) => {
          //       return { ...val, uid };
          //     });

          //     var base = data_array[0].base_currency;
          //     var quote = data_array[0].quote_currency;
          //     var rate = data_array[0].rate;
          //     var currency_uid = data_array[0].uid;

          //     this.setState({
          //       old_data: data_array,
          //       base,
          //       quote,
          //       rate,
          //       currency_uid,

          //       old_base: base,
          //       old_quote: quote,
          //       old_rate: rate,
          //     });
          //   });

          firebase
            .database()
            .ref("organization/zaincocargo/currency_converter/")
            .once("value")
            .then((snapshot) => {
              this.setState({
                base: snapshot.val().base_currency,
                quote: snapshot.val().quote_currency,
                rate: snapshot.val().rate,
              });
            });

          alert("Your currency pair was successfully updated!");
        })
        .catch(() => {
          // this.search_fetch();
          alert(
            "Oops! There was an error while updating your currency pair. Please try again!"
          );
        });
    }
  }

  tab1() {
    return (
      <Row>
        <Col lg="12">
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Base Currency</Label>
            </InputGroupAddon>
            <Input
              disabled
              type="text"
              className="form-control"
              value={this.state.base}
              readOnly
              onChange={(event) => {
                this.setState({ base: event.target.value });
              }}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Quote Currency</Label>
            </InputGroupAddon>
            <Input
              type="text"
              className="form-control"
              value={this.state.quote}
              readOnly
              onChange={(event) => {
                this.setState({ quote: event.target.value });
              }}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">rate</Label>
            </InputGroupAddon>
            <Input
              type="number"
              className="form-control"
              value={this.state.rate}
              onChange={(event) => {
                this.setState({ rate: event.target.value });
              }}
            />
          </InputGroup>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.show_alert ? (
          <SweetAlert
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            showCancel={this.state.showCancel}
            title={this.state.title}
            warning={this.state.warning}
            error={this.state.error}
            success={this.state.success}
            confirmBtnText={this.state.confirm_button_text}
            onConfirm={() => {
              const { type, updated_status, uid } = this.state;
            }}
            onCancel={() => this.setState({ show_alert: false })}
          >
            {this.state.body}
          </SweetAlert>
        ) : null}

        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Currency Conversion"
              breadcrumbItem="Currency Conversion"
            />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Currency Conversion</CardTitle>

                    <div className="text-center">
                      {this.tab1()}

                      <Button
                        type="submit"
                        color="primary"
                        onClick={() => {
                          this.addShipper();
                        }}
                      >
                        Update Currency Pair
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default TasksCreate;
