import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Label,
  Input,
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  InputGroupAddon,
  InputGroup,
  Modal,
  Form,
  Button,
} from "reactstrap";
import { MDBDataTable } from "mdbreact";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  Image,
} from "@react-pdf/renderer";

import classnames from "classnames";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import logo from "../../assets/images/logo-dark.png";
import firebase from "firebase/app";
import "firebase/database";
/***/
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { shipping_data_read, detail_error } from "../../store/actions";
import Lottie from "react-lottie";
/***/

class CargoCollect extends Component {
  constructor() {
    super();
    this.state = {
      activeTab: "1",
      // rates_data_extracted: {},
      data_by_lot_no: [],
      ready: false,
      data_to_show_in_table: [],
    };
  }

  toggle() {
    this.setState(
      (prevState) => ({
        ready: false,
      }),
      () => {
        setTimeout(() => {
          this.setState({ ready: true });
        }, 1);
      }
    );
  }
  table_titles(data_taken) {
    var titles_to_show = [];

    var titles = ["HBL", "Lot No", "Quantity", "Weight"];

    return (
      <View
        style={{
          flexDirection: "row",
          borderBottomColor: "#CFD8DC",
          backgroundColor: "#CFD8DC",
          borderBottomWidth: 1,
          alignItems: "left",
          // height: 24,
          paddingTop: 10,
          paddingBotttom: 10,
          textAlign: "left",
          fontStyle: "bold",
        }}
      >
        <Text
          style={{
            width: "25%",
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 10,
            paddingLeft: 10,
          }}
        >
          {titles[0]}
        </Text>
        <Text
          style={{
            width: "25%",
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 10,
          }}
        >
          {titles[1]}
        </Text>

        <Text
          style={{
            width: "25%",
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 10,
          }}
        >
          {titles[2]}
        </Text>
        <Text
          style={{
            width: "25%",
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 10,
          }}
        >
          {titles[3]}
        </Text>
      </View>
    );
  }

  line_items(data_taken) {
    var list_to_show = [];

    data_taken.forEach((element, i) => {
      list_to_show.push(
        <View
          style={{
            flexDirection: "row",
            borderBottomColor: "#CFD8DC",
            borderBottomWidth: 1,
            alignItems: "center",
            fontStyle: "bold",
            paddingTop: 5,
          }}
          key={i}
        >
          <Text
            style={{
              width: "25%",
              textAlign: "left",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 10,
              flexWrap: "wrap",
            }}
          >
            {element.HBL}
          </Text>
          <Text
            style={{
              width: "25%",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              textAlign: "left",
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 10,
              flexWrap: "wrap",
            }}
          >
            {element.Lot_No}
          </Text>

          <Text
            style={{
              width: "25%",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              textAlign: "left",

              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 10,
              flexWrap: "wrap",
            }}
          >
            {element.Quantity}
          </Text>
          <Text
            style={{
              width: "25%",
              textAlign: "left",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,

              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 10,
              flexWrap: "wrap",
            }}
          >
            {element.Weight}
          </Text>
        </View>
      );
    });

    return list_to_show;
  }
  show_report() {
    const { ready, data_to_show_in_table } = this.state;

    if (data_to_show_in_table.length > 0) {
      var title = "Zainco Cargo Collect Report";
      var data_taken = data_to_show_in_table;
      var table_titles = this.table_titles(data_taken);

      var line_items = this.line_items(data_taken);

      return (
        <Document>
          <Page
            size="A4"
            style={{
              paddingTop: 35,
              paddingBottom: 65,
              paddingHorizontal: 35,
            }}
          >
            <Image
              style={{
                width: 74,
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={logo}
            />

            <View
              style={{
                flexDirection: "row",
                marginTop: 24,
              }}
            >
              <Text
                style={{
                  color: "#263238",
                  fontSize: 15,
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                {title}
              </Text>
            </View>

            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: 24,
                borderWidth: 1,
                borderColor: "#CFD8DC",
              }}
            >
              {table_titles}

              {line_items}
            </View>
          </Page>
        </Document>
      );
    } else {
      return (
        <Document>
          <Page
            size="A4"
            style={{
              paddingTop: 35,
              paddingBottom: 65,
              paddingHorizontal: 35,
            }}
          >
            <Image
              style={{
                width: 74,
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={logo}
            />
          </Page>
        </Document>
      );
    }
  }

  show_report_based_on_hbl() {
    const { data } = this.props;
    var data_to_show_in_table = [];
    var show_data = [];

    data.forEach((element) => {
      data_to_show_in_table.push({
        HBL: element.uid,
        Lot_No: element.Lot_No,
        Quantity: element.Qty,
        Weight: element.Weight,
      });
    });

    const table_data = {
      columns: [
        {
          label: "HBL",
          field: "HBL",
          sort: "asc",
          width: 150,
        },
        {
          label: "Lot No",
          field: "Lot_No",
          sort: "asc",
          width: 270,
        },
        {
          label: "Quantity",
          field: "Quantity",
          sort: "asc",
          width: 200,
        },
        {
          label: "Weight",
          field: "Weight",
          sort: "asc",
          width: 100,
        },
      ],
      rows: data_to_show_in_table,
    };
    return (
      <div
        style={{
          paddingTop: 20,
          paddingLeft: 15,
          paddingRight: 15,
        }}
      >
        <MDBDataTable responsive striped bordered data={table_data} />
      </div>
    );
  }

  processing() {
    // const { data } = this.props;

    // var snapshot = data;

    const { shipping_data } = this.props;

    var snapshot = shipping_data;

    var all_data_lot_no = _.map(snapshot, (val, uid) => {
      var data = {
        label: val.Lot_No,

        data: { uid, ...val },
      };

      return data;
    });

    var seen_all_data_lot_no = new Map();
    //
    all_data_lot_no = all_data_lot_no.filter((entry) => {
      var previous;
      if (seen_all_data_lot_no.hasOwnProperty(entry.label)) {
        previous = seen_all_data_lot_no[entry.label];
        previous.data.push(entry.data);

        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen_all_data_lot_no[entry.label] = entry;

      return true;
    });

    all_data_lot_no = _.fromPairs(
      _.map(all_data_lot_no, (i) => {
        return [i.label, i.data];
      })
    );

    return all_data_lot_no;
  }

  show_table_filtered_by_lot_no() {
    var data = this.state.data_by_lot_no;
    var data_to_show_in_table = [];
    var show_data = [];

    var total_quantity = 0;
    var total_weight = 0;
    data.forEach((element) => {
      data_to_show_in_table.push({
        HBL: element.uid,
        Lot_No: element.Lot_No,
        Quantity: element.Qty,
        Weight: element.Weight,
      });

      total_quantity = total_quantity + parseInt(element.Qty);
      total_weight = total_weight + parseInt(element.Weight);
    });

    const table_data = {
      columns: [
        {
          label: "HBL",
          field: "HBL",
          sort: "asc",
          width: 150,
        },
        {
          label: "Lot No",
          field: "Lot_No",
          sort: "asc",
          width: 270,
        },
        {
          label: "Quantity",
          field: "Quantity",
          sort: "asc",
          width: 200,
        },
        {
          label: "Weight",
          field: "Weight",
          sort: "asc",
          width: 100,
        },
      ],
      rows: data_to_show_in_table,
    };

    const { ready } = this.state;
    // const MyDocument = this.show_report(data_to_show_in_table);
    const MyDocument = this.show_report();

    return (
      <div>
        <div>
          {ready && (
            <PDFDownloadLink document={MyDocument} fileName="PDF">
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <Button onClick={() => this.setState({ ready: false })}>
                    Download Report
                  </Button>
                )
              }
            </PDFDownloadLink>
          )}
          {!ready && (
            <Button
              onClick={() => {
                this.setState({ data_to_show_in_table });
                this.toggle();
              }}
            >
              Generate Report
            </Button>
          )}
        </div>
        <div
          style={{
            paddingTop: 20,
            paddingLeft: 15,
            paddingRight: 15,
          }}
        >
          <div>
            <p>
              Total Quantity:{" "}
              {total_quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </p>
            <p>
              Total Weight:{" "}
              {total_weight.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} KG
            </p>
          </div>
          <MDBDataTable responsive striped bordered data={table_data} />
        </div>
      </div>
    );
  }
  show_report_based_on_lot_no() {
    var data_filtered = this.processing();

    var options_to_show = [];
    if (Object.keys(data_filtered).length > 0) {
      var data = _.map(data_filtered, (val, uid_lot) => {
        return { data: { ...val }, uid_lot };
      });

      var data_with_details = [];
      data.forEach((element) => {
        var first_element = element.data[0];
        data_with_details.push(element);
      });

      data_with_details.forEach((element, i) => {
        options_to_show.push(<option value={i}>{element.uid_lot}</option>);
      });
    }

    return (
      <div>
        <Form encType="multipart/form-data">
          <div data-repeater-list="group-a">
            <Row data-repeater-item>
              <Col lg="6" className="form-group">
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    <Label className="input-group-text">LOT NO</Label>
                  </InputGroupAddon>
                  <Input
                    className="form-control"
                    onChange={(event) => {
                      var value = event.target.value;

                      if (value !== "") {
                        if (data_with_details[value].data) {
                          var converted_data = _.map(
                            data_with_details[value].data,
                            (val) => {
                              return { ...val };
                            }
                          );

                          this.setState({
                            data_by_lot_no: converted_data,
                          });
                        }
                      }
                    }}
                    type="select"
                  >
                    <option value="">Select Lot No.</option>
                    {options_to_show}
                  </Input>
                </InputGroup>
              </Col>
            </Row>
          </div>
        </Form>

        {this.state.data_by_lot_no.length > 0 ? (
          <div>{this.show_table_filtered_by_lot_no()}</div>
        ) : null}
      </div>
    );
  }

  render() {
    const { loading } = this.props;
    if (loading === true) {
      const loadingjson = {
        loop: true,
        autoplay: true,
        animationData: require("./JSON/loading.json"),

        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };

      return (
        <div className="page-content">
          <Container fluid>
            <Row className="justify-content-center mt-lg-5">
              <div className="text-center">
                <Row className="justify-content-center">
                  <div className="text-center">
                    <Lottie options={loadingjson} height={300} width={300} />
                  </div>
                </Row>
              </div>
            </Row>
          </Container>
        </div>
      );
    }
    return <div>{this.show_report_based_on_lot_no()}</div>;
  }
}

const mapStatetoProps = (state) => {
  const { shipping_data, error, loading } = state.Database;

  return {
    shipping_data,
    error,
    loading,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    shipping_data_read,
    detail_error,
  })(withNamespaces()(CargoCollect))
);
