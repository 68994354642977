import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  Image,
} from "@react-pdf/renderer";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Label,
  Input,
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  InputGroupAddon,
  InputGroup,
  Modal,
  Form,
} from "reactstrap";
import { MDBDataTable } from "mdbreact";

import classnames from "classnames";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import logo from "../../assets/images/logo-dark.png";
import firebase from "firebase/app";
import "firebase/database";

class InvoiceDetail extends Component {
  constructor() {
    super();
    this.state = {
      //   activeTab: "1",
      activeTab: "2",
      // rates_data_extracted: {},
      data_by_lot_no: [],
      ready: false,
      data_to_show_in_table: [],
      selected_user_logs: {},
    };
  }

  toggle() {
    this.setState(
      (prevState) => ({
        ready: false,
      }),
      () => {
        setTimeout(() => {
          this.setState({ ready: true });
        }, 1);
      }
    );
  }
  table_titles(data_taken) {
    var titles_to_show = [];

    var titles = ["HBL", "Lot No", "Consignee", "Shipper", "Delivery"];

    titles.forEach((element, i) => {
      titles_to_show.push(
        <Text
          key={i}
          style={{
            width: "20%",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 15,
          }}
        >
          {element}
        </Text>
      );
    });
    return (
      <View
        style={{
          flexDirection: "row",
          borderBottomColor: "#CFD8DC",
          backgroundColor: "#CFD8DC",
          borderBottomWidth: 1,
          alignItems: "left",
          // height: 24,
          paddingTop: 10,
          paddingBotttom: 10,
          textAlign: "left",
          fontStyle: "bold",
        }}
      >
        {titles_to_show}
      </View>
    );
  }

  line_items(data_taken) {
    var list_to_show = [];

    data_taken.forEach((element, i) => {
      list_to_show.push(
        <View
          style={{
            flexDirection: "row",
            borderBottomColor: "#CFD8DC",
            borderBottomWidth: 1,
            alignItems: "center",
            // height: 24,
            fontStyle: "bold",
            paddingTop: 5,
          }}
          key={i}
        >
          <Text
            style={{
              width: "20%",
              textAlign: "left",
              // borderRightColor: "#CFD8DC",
              // borderRightWidth: 1,
              paddingLeft: 8,
              fontSize: 10,
            }}
          >
            {element.HBL}
          </Text>
          <Text
            style={{
              width: "20%",
              // borderRightColor: "#CFD8DC",
              // borderRightWidth: 1,
              textAlign: "left",
              paddingRight: 8,
              fontSize: 10,
            }}
          >
            {element.Lot_No}
          </Text>
          <Text
            style={{
              width: "20%",
              // borderRightColor: "#CFD8DC",
              // borderRightWidth: 1,
              // textAlign: "center",
              textAlign: "left",
              paddingRight: 8,
              fontSize: 10,
            }}
          >
            {element.Consignee}
          </Text>
          <Text
            style={{
              width: "20%",
              // borderRightColor: "#CFD8DC",
              // borderRightWidth: 1,
              // textAlign: "center",
              textAlign: "left",

              paddingRight: 8,
              fontSize: 10,
            }}
          >
            {element.Shipper}
          </Text>

          <Text
            style={{
              width: "20%",
              // textAlign: "center",
              textAlign: "left",

              paddingRight: 8,
              fontSize: 10,
            }}
          >
            {element.Delivery}
          </Text>
        </View>
      );
    });

    return list_to_show;
  }
  show_report() {
    const { ready, data_to_show_in_table } = this.state;

    if (data_to_show_in_table.length > 0) {
      var title = "Zainco Manifest Report";
      var data_taken = data_to_show_in_table;
      var table_titles = this.table_titles(data_taken);

      var line_items = this.line_items(data_taken);

      return (
        <Document>
          <Page
            size="A4"
            style={{
              paddingTop: 35,
              paddingBottom: 65,
              paddingHorizontal: 35,
            }}
          >
            <Image
              style={{
                width: 74,
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={logo}
            />

            <View
              style={{
                flexDirection: "row",
                marginTop: 24,
              }}
            >
              <Text
                style={{
                  color: "#263238",
                  fontSize: 15,
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                {title}
              </Text>
            </View>

            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: 24,
                borderWidth: 1,
                borderColor: "#CFD8DC",
              }}
            >
              {table_titles}

              {line_items}
            </View>
          </Page>
        </Document>
      );
    } else {
      return (
        <Document>
          <Page
            size="A4"
            style={{
              paddingTop: 35,
              paddingBottom: 65,
              paddingHorizontal: 35,
            }}
          >
            <Image
              style={{
                width: 74,
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={logo}
            />
          </Page>
        </Document>
      );
    }
  }

  show_report_based_on_hbl() {
    const { data } = this.props;
    var data_to_show_in_table = [];
    var show_data = [];

    data.forEach((element) => {
      data_to_show_in_table.push({
        HBL: element.uid,
        Lot_No: element.Lot_No,
        Consignee: element.Consignee,
        Shipper: element.Shipper,
        Delivery: element.Delivery,
      });
    });

    const table_data = {
      columns: [
        {
          label: "HBL",
          field: "HBL",
          sort: "asc",
          width: 150,
        },
        {
          label: "Lot No",
          field: "Lot_No",
          sort: "asc",
          width: 270,
        },
        {
          label: "Consignee",
          field: "Consignee",
          sort: "asc",
          width: 200,
        },
        {
          label: "Shipper",
          field: "Shipper",
          sort: "asc",
          width: 100,
        },

        {
          label: "Delivery",
          field: "Delivery",
          sort: "asc",
          width: 100,
        },
      ],
      rows: data_to_show_in_table,
    };

    const { ready } = this.state;
    // const MyDocument = this.show_report(data_to_show_in_table);
    const MyDocument = this.show_report();

    return (
      <div>
        <div>
          {ready && (
            <PDFDownloadLink document={MyDocument} fileName="PDF">
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <Button onClick={() => this.setState({ ready: false })}>
                    Download Report
                  </Button>
                )
              }
            </PDFDownloadLink>
          )}
          {!ready && (
            <Button
              onClick={() => {
                this.setState({ data_to_show_in_table });
                this.toggle();
              }}
            >
              Generate Report
            </Button>
          )}
        </div>
        <div
          style={{
            paddingTop: 20,
            paddingLeft: 15,
            paddingRight: 15,
          }}
        >
          <MDBDataTable responsive striped bordered data={table_data} />
        </div>
      </div>
    );
  }

  processing() {
    const { data } = this.props;

    var snapshot = data;
    var all_data_lot_no = _.map(snapshot, (val, uid) => {
      var data = {
        label: val.Lot_No,

        data: { uid, ...val },
      };

      return data;
    });

    var seen_all_data_lot_no = new Map();
    //
    all_data_lot_no = all_data_lot_no.filter((entry) => {
      var previous;
      if (seen_all_data_lot_no.hasOwnProperty(entry.label)) {
        previous = seen_all_data_lot_no[entry.label];
        previous.data.push(entry.data);

        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen_all_data_lot_no[entry.label] = entry;

      return true;
    });

    all_data_lot_no = _.fromPairs(
      _.map(all_data_lot_no, (i) => {
        return [i.label, i.data];
      })
    );

    return all_data_lot_no;
  }

  show_table_filtered_by_lot_no(all_data_in_array) {
    var data = all_data_in_array; ///this.state.selected_user_logs;
    var data_to_show_in_table = [];
    var show_data = [];

    data.forEach((element) => {
      data_to_show_in_table.push({
        by: element.by,
        comment: element.comment,
        date: element.date,
        hbl: element.hbl,
        lot_no: element.lot_no,
        // lot_no: element.Delivery,
      });
    });

    const table_data = {
      columns: [
        {
          label: "HBL",
          field: "hbl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Lot No",
          field: "lot_no",
          sort: "asc",
          width: 270,
        },
        {
          label: "Date",
          field: "date",
          sort: "asc",
          width: 200,
        },
        {
          label: "Action",
          field: "comment",
          sort: "asc",
          width: 100,
        },

        {
          label: "User",
          field: "by",
          sort: "asc",
          width: 100,
        },
      ],
      rows: data_to_show_in_table,
    };

    const { ready } = this.state;

    return (
      <div>
        <div
          style={{
            paddingTop: 20,
            paddingLeft: 15,
            paddingRight: 15,
          }}
        >
          <MDBDataTable responsive striped bordered data={table_data} />
        </div>
      </div>
    );
  }
  show_report_based_on_lot_no() {
    var data = [];
    var options_to_show = [];

    const { data_array_logs, data_logs } = this.props;

    // console.log(data_array_logs);

    var data_logs_array = _.map(data_logs, (val, uid) => {
      return { ...val };
    });

    var all_data_in_array = [];
    data_logs_array.forEach((element) => {
      var each_array = _.map(element, (val, uid) => {
        return { ...val, uid };
      });

      each_array.forEach((element) => {
        all_data_in_array.push(element);
      });
    });

    console.log(all_data_in_array);

    if (all_data_in_array.length > 0) {
      return (
        <div>
          <div>{this.show_table_filtered_by_lot_no(all_data_in_array)}</div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  render() {
    return <div>{this.show_report_based_on_lot_no()}</div>;
  }
}

export default InvoiceDetail;
