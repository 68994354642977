import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,

  ////////
  Label,
  Input,
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  InputGroupAddon,
  InputGroup,
  Modal,
  Form,
  Button,
  ///////
} from "reactstrap";
import { MDBDataTable } from "mdbreact";

import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  Image,
} from "@react-pdf/renderer";

import classnames from "classnames";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Image
import logo from "../../assets/images/logo-dark.png";

import firebase from "firebase/app";
// Add the Firebase products that you want to use
import "firebase/database";
/***/
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { container_data_read, detail_error } from "../../store/actions";
import Lottie from "react-lottie";
/***/

class CargoManifest extends Component {
  constructor() {
    super();
    this.state = {
      activeTab: "1",
      // rates_data_extracted: {},
      data_by_lot_no: [],
      old_container_data: [],
      ready: false,
      data_to_show_in_table: [],
    };
  }

  toggle() {
    this.setState(
      (prevState) => ({
        ready: false,
      }),
      () => {
        setTimeout(() => {
          this.setState({ ready: true });
        }, 1);
      }
    );
  }
  table_titles(data_taken) {
    var titles_to_show = [];

    var titles = [
      "Lot No",
      "Accepting Date",
      // "Arrival Date",
      "THE DAY CARGO ARRIVED AT OUR WAREHOUSE",
      "Closing Date",
      "Departure Date",
      //"Storage Date",
      "STORAGE BEGINS",
      "Details",
    ];

    return (
      <View
        style={{
          flexDirection: "row",
          borderBottomColor: "#CFD8DC",
          backgroundColor: "#CFD8DC",
          borderBottomWidth: 1,
          alignItems: "left",
          // height: 24,
          paddingTop: 10,
          paddingBotttom: 10,
          textAlign: "left",
          fontStyle: "bold",
        }}
      >
        <Text
          style={{
            width: "6%",
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 10,
            paddingLeft: 10,
          }}
        >
          {titles[0]}
        </Text>
        <Text
          style={{
            width: "12%",
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 10,
          }}
        >
          {titles[1]}
        </Text>

        <Text
          style={{
            width: "12%",
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 10,
          }}
        >
          {titles[2]}
        </Text>
        <Text
          style={{
            width: "12%",
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 10,
          }}
        >
          {titles[3]}
        </Text>
        <Text
          style={{
            width: "12%",
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 10,
          }}
        >
          {titles[4]}
        </Text>
        <Text
          style={{
            width: "12%",
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 10,
          }}
        >
          {titles[5]}
        </Text>
        <Text
          style={{
            width: "34%",
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 10,
          }}
        >
          {titles[6]}
        </Text>
      </View>
    );
  }

  line_items(data_taken) {
    var list_to_show = [];
    data_taken.forEach((element, i) => {
      list_to_show.push(
        <View
          style={{
            flexDirection: "row",
            borderBottomColor: "#CFD8DC",
            borderBottomWidth: 1,
            alignItems: "center",
            // height: 24,
            fontStyle: "bold",
            paddingTop: 5,
          }}
          key={i}
        >
          <Text
            style={{
              width: "6%",
              textAlign: "left",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 10,
              flexWrap: "wrap",
            }}
          >
            {element.lot}
          </Text>
          <Text
            style={{
              width: "12%",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              textAlign: "left",
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 10,
              flexWrap: "wrap",
            }}
          >
            {element.accepting_date}
          </Text>

          <Text
            style={{
              width: "12%",
              // textAlign: "center",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              textAlign: "left",

              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 10,
              flexWrap: "wrap",
            }}
          >
            {element.arrival_date}
          </Text>
          <Text
            style={{
              width: "12%",
              textAlign: "left",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              // borderRightColor: "#CFD8DC",
              // borderRightWidth: 1,
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 10,
              flexWrap: "wrap",
            }}
          >
            {element.closing_date}
          </Text>
          <Text
            style={{
              width: "12%",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              // borderRightColor: "#CFD8DC",
              // borderRightWidth: 1,
              textAlign: "left",
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 10,
              flexWrap: "wrap",
            }}
          >
            {element.departure_date}
          </Text>

          <Text
            style={{
              width: "12%",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              // textAlign: "center",
              textAlign: "left",

              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 10,
              flexWrap: "wrap",
            }}
          >
            {element.storage_date}
          </Text>
          <Text
            style={{
              width: "34%",
              // textAlign: "center",
              textAlign: "left",

              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 10,
              flexWrap: "wrap",
            }}
          >
            {element.other_details}
          </Text>
        </View>
      );
    });

    return list_to_show;
  }
  show_report() {
    const { ready, data_to_show_in_table } = this.state;

    if (data_to_show_in_table.length > 0) {
      var title = "Zainco Cargo Manifest Report";
      var data_taken = data_to_show_in_table;
      var table_titles = this.table_titles(data_taken);

      var line_items = this.line_items(data_taken);

      return (
        <Document>
          <Page
            size="A4"
            style={{
              paddingTop: 35,
              paddingBottom: 65,
              paddingHorizontal: 35,
            }}
          >
            <Image
              style={{
                width: 74,
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={logo}
            />

            <View
              style={{
                flexDirection: "row",
                marginTop: 24,
              }}
            >
              <Text
                style={{
                  color: "#263238",
                  fontSize: 15,
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                {title}
              </Text>
            </View>

            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: 24,
                borderWidth: 1,
                borderColor: "#CFD8DC",
              }}
            >
              {table_titles}

              {line_items}
            </View>
          </Page>
        </Document>
      );
    } else {
      return (
        <Document>
          <Page
            size="A4"
            style={{
              paddingTop: 35,
              paddingBottom: 65,
              paddingHorizontal: 35,
            }}
          >
            <Image
              style={{
                width: 74,
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={logo}
            />
          </Page>
        </Document>
      );
    }
  }
  show_report_based_on_hbl() {
    const { container_data } = this.props;

    var data = container_data;

    data = _.map(container_data, (val, uid) => {
      return { ...val, uid };
    });

    var data_to_show_in_table = [];
    var show_data = [];

    data.forEach((element) => {
      data_to_show_in_table.push({
        accepting_date: element.accepting_date,
        arrival_date: element.arrival_date,
        closing_date: element.closing_date,
        departure_date: element.departure_date,
        other_details: element.other_details,
        storage_date: element.storage_date,
        lot: element.lot,
        uid: element.uid,
      });
    });

    const table_data = {
      columns: [
        {
          label: "Lot No.",
          field: "lot",
          sort: "asc",
          width: 150,
        },
        {
          label: "Accepting Date",
          field: "accepting_date",
          sort: "asc",
          width: 270,
        },
        {
          label: "THE DAY CARGO ARRIVED AT OUR WAREHOUSE", //"Arrival Date",
          field: "arrival_date",
          sort: "asc",
          width: 200,
        },
        {
          label: "Closing Date",
          field: "closing_date",
          sort: "asc",
          width: 100,
        },

        {
          label: "Departure Date",
          field: "departure_date",
          sort: "asc",
          width: 100,
        },

        {
          label: "STORAGE BEGINS", //"Storage Date",
          field: "storage_date",
          sort: "asc",
          width: 100,
        },
        {
          label: "Details",
          field: "other_details",
          sort: "asc",
          width: 100,
        },
      ],
      rows: data_to_show_in_table,
    };

    const { ready } = this.state;
    const MyDocument = this.show_report();

    return (
      <div>
        <div>
          {ready && (
            <PDFDownloadLink document={MyDocument} fileName="PDF">
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <Button onClick={() => this.setState({ ready: false })}>
                    Download Report
                  </Button>
                )
              }
            </PDFDownloadLink>
          )}
          {!ready && (
            <Button
              onClick={() => {
                this.setState({ data_to_show_in_table });
                this.toggle();
              }}
            >
              Generate Report
            </Button>
          )}
        </div>
        <div
          style={{
            paddingTop: 20,
            paddingLeft: 15,
            paddingRight: 15,
          }}
        >
          <MDBDataTable responsive striped bordered data={table_data} />
        </div>
      </div>
    );
  }

  render() {
    const { loading } = this.props;
    if (loading === true) {
      const loadingjson = {
        loop: true,
        autoplay: true,
        animationData: require("./JSON/loading.json"),

        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };

      return (
        <div className="page-content">
          <Container fluid>
            <Row className="justify-content-center mt-lg-5">
              <div className="text-center">
                <Row className="justify-content-center">
                  <div className="text-center">
                    <Lottie options={loadingjson} height={300} width={300} />
                  </div>
                </Row>
              </div>
            </Row>
          </Container>
        </div>
      );
    }

    return <div>{this.show_report_based_on_hbl()}</div>;
  }
}

const mapStatetoProps = (state) => {
  const { container_data, error, loading } = state.Database;

  return {
    container_data,
    error,
    loading,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    container_data_read,
    detail_error,
  })(withNamespaces()(CargoManifest))
);
