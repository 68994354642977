/*import _ from "lodash";

import React, { Component } from "react";
import Main from "./Main";
import { Container, Row, Col } from "reactstrap";

//i18n
import { withNamespaces } from "react-i18next";

// Redux
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import {
  user_data_read,
  shipping_data_read,
  container_data_read,
  currency_data_read,
  rates_data_read,
  detail_error,
} from "../../store/actions";
import Lottie from "react-lottie";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // componentDidMount() {
  //   this.props.currency_data_read();
  //   this.props.user_data_read();
  //   this.props.shipping_data_read();
  //   this.props.container_data_read();
  //   this.props.rates_data_read();
  // }

  render() {
    const { user_details, shipping_data, currency_data, rates_data, loading } =
      this.props;

    if (loading === true) {
      const loadingjson = {
        loop: true,
        autoplay: true,
        animationData: require("./JSON/loading.json"),

        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };

      return (
        <div className="page-content">
          <Container fluid>
            <Row className="justify-content-center mt-lg-5">
              <div className="text-center">
                <Row className="justify-content-center">
                  <div className="text-center">
                    <Lottie options={loadingjson} height={300} width={300} />
                  </div>
                </Row>
              </div>
            </Row>
          </Container>
        </div>
      );
    } else {
      var existing_date = _.map(shipping_data, (val, uid) => {
        return { ...val, uid };
      });
      var container_data = _.map(this.props.container_data, (val, uid) => {
        return { ...val, uid };
      });

      return (
        <React.Fragment>
          <Main
            user_details={user_details}
            existing_date={existing_date}
            container_data={container_data}
            SAR_TO_KSH_RATE={currency_data}
            rates_data_extracted={rates_data}
          />
        </React.Fragment>
      );
    }
  }
}

const mapStatetoProps = (state) => {
  const {
    user_details,
    error,
    loading,
    shipping_data,
    container_data,
    currency_data,
    rates_data,
  } = state.Database;

  return {
    user_details,
    error,
    loading,
    shipping_data,
    container_data,
    currency_data,
    rates_data,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    user_data_read,
    detail_error,
    shipping_data_read,
    container_data_read,
    currency_data_read,
    rates_data_read,
  })(withNamespaces()(Index))
);
*/

import _ from "lodash";

import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,

  ////////
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  InputGroupAddon,
  InputGroup,
  Modal,
  Form,
  ///////
} from "reactstrap";

import classnames from "classnames";
import firebase from "firebase/app";
// Add the Firebase products that you want to use
import "firebase/database";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Image
import logo from "../../assets/images/logo-dark.png";

//i18n
import { withNamespaces } from "react-i18next";

// Redux
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import {
  user_data_read,
  shipping_data_read,
  container_data_read,
  currency_data_read,
  rates_data_read,
  detail_error,
} from "../../store/actions";

import CargoCollect from "./CargoCollect";
import CargoManifest from "./CargoManifest";
import DebtManifest from "./DebtManifest";
import Destination from "./Destination";
import Manifest from "./Manifest";
import Outstanding from "./Outstanding";
import ReportOfPayment from "./ReportOfPayment";
import VatReport from "./VatReport";
import Delivery from "./Delivery";
import Storage from "./Storage";
import Delivery_Slip from "./Delivery_Slip";
import Cash_Slip from "./Cash_Slip";
import SalesReport from "./SalesReport";
import Lottie from "react-lottie";

class Index extends Component {
  constructor() {
    super();
    this.state = {
      activeTab_MAIN_NAV: "1",
      existing_date: [],
      container_data: [],
      rates_data_extracted: {},
      SAR_TO_KSH_RATE: {},
      invoices: [],
    };
  }

  toggleTab_MAIN_NAV(tab) {
    if (this.state.activeTab_MAIN_NAV !== tab) {
      this.setState({
        activeTab_MAIN_NAV: tab,
      });
    }
  }

  main_tab_1({
    RAJHI_BANK_ACCOUNT,
    NCB_BANK_ACCOUNT,
    INMA_BANK_ACCOUNT,
    GULF_AFRICAN_BANK,
    MPESA,
    STCPAY,
    VAT_NO,
    MOBILE,
  }) {
    // const NCB_BANK_ACCOUNT = "####";
    // const INMA_BANK_ACCOUNT = "####";
    // const GULF_AFRICAN_BANK = "0330010801";
    // const MPESA = "PAYBILL 985050 ACCOUNT 0130002301";
    // const STCPAY = "0505691673 Zainco Cargo Services";
    // const VAT_NO = "010004185494";
    // const MOBILE = "+966505691673";

    return (
      <div>
        <Outstanding
          // data={existing_date}
          // container_data={container_data}
          // rates_data_extracted={rates_data_extracted}
          // SAR_TO_KSH_RATE={SAR_TO_KSH_RATE}
          rajhi_bank_account={RAJHI_BANK_ACCOUNT}
          ncb_bank_account={NCB_BANK_ACCOUNT}
          inma_bank_account={INMA_BANK_ACCOUNT}
          gulf_african_bank={GULF_AFRICAN_BANK}
          mpesa={MPESA}
          stcpay={STCPAY}
          vat_no={VAT_NO}
          mobile={MOBILE}
        />
      </div>
    );
  }
  main_tab_2({
    RAJHI_BANK_ACCOUNT,
    NCB_BANK_ACCOUNT,
    INMA_BANK_ACCOUNT,
    GULF_AFRICAN_BANK,
    MPESA,
    STCPAY,
    VAT_NO,
    MOBILE,
  }) {
    // const NCB_BANK_ACCOUNT = "####";
    // const INMA_BANK_ACCOUNT = "####";
    // const GULF_AFRICAN_BANK = "0330010801";
    // const MPESA = "PAYBILL 985050 ACCOUNT 0130002301";
    // const STCPAY = "0505691673 Zainco Cargo Services";
    // const VAT_NO = "010004185494";
    // const MOBILE = "+966505691673";

    return (
      <div>
        <Manifest
          // data={existing_date}
          // container_data={container_data}
          // rates_data_extracted={rates_data_extracted}
          // SAR_TO_KSH_RATE={SAR_TO_KSH_RATE}
          rajhi_bank_account={RAJHI_BANK_ACCOUNT}
          ncb_bank_account={NCB_BANK_ACCOUNT}
          inma_bank_account={INMA_BANK_ACCOUNT}
          gulf_african_bank={GULF_AFRICAN_BANK}
          mpesa={MPESA}
          stcpay={STCPAY}
          vat_no={VAT_NO}
          mobile={MOBILE}
        />
      </div>
    );
  }
  main_tab_3({
    RAJHI_BANK_ACCOUNT,
    NCB_BANK_ACCOUNT,
    INMA_BANK_ACCOUNT,
    GULF_AFRICAN_BANK,
    MPESA,
    STCPAY,
    VAT_NO,
    MOBILE,
  }) {
    // const NCB_BANK_ACCOUNT = "####";
    // const INMA_BANK_ACCOUNT = "####";
    // const GULF_AFRICAN_BANK = "0330010801";
    // const MPESA = "PAYBILL 985050 ACCOUNT 0130002301";
    // const STCPAY = "0505691673 Zainco Cargo Services";
    // const VAT_NO = "010004185494";
    // const MOBILE = "+966505691673";

    return (
      <div>
        <Destination
          // data={existing_date}
          // container_data={container_data}
          // rates_data_extracted={rates_data_extracted}
          // SAR_TO_KSH_RATE={SAR_TO_KSH_RATE}
          rajhi_bank_account={RAJHI_BANK_ACCOUNT}
          ncb_bank_account={NCB_BANK_ACCOUNT}
          inma_bank_account={INMA_BANK_ACCOUNT}
          gulf_african_bank={GULF_AFRICAN_BANK}
          mpesa={MPESA}
          stcpay={STCPAY}
          vat_no={VAT_NO}
          mobile={MOBILE}
        />
      </div>
    );
  }
  main_tab_4({
    RAJHI_BANK_ACCOUNT,
    NCB_BANK_ACCOUNT,
    INMA_BANK_ACCOUNT,
    GULF_AFRICAN_BANK,
    MPESA,
    STCPAY,
    VAT_NO,
    MOBILE,
  }) {
    // const NCB_BANK_ACCOUNT = "####";
    // const INMA_BANK_ACCOUNT = "####";
    // const GULF_AFRICAN_BANK = "0330010801";
    // const MPESA = "PAYBILL 985050 ACCOUNT 0130002301";
    // const STCPAY = "0505691673 Zainco Cargo Services";
    // const VAT_NO = "010004185494";
    // const MOBILE = "+966505691673";

    return (
      <div>
        <CargoManifest
          // data={existing_date}
          // container_data={container_data}
          // rates_data_extracted={rates_data_extracted}
          // SAR_TO_KSH_RATE={SAR_TO_KSH_RATE}
          rajhi_bank_account={RAJHI_BANK_ACCOUNT}
          ncb_bank_account={NCB_BANK_ACCOUNT}
          inma_bank_account={INMA_BANK_ACCOUNT}
          gulf_african_bank={GULF_AFRICAN_BANK}
          mpesa={MPESA}
          stcpay={STCPAY}
          vat_no={VAT_NO}
          mobile={MOBILE}
        />
      </div>
    );
  }
  main_tab_5({
    RAJHI_BANK_ACCOUNT,
    NCB_BANK_ACCOUNT,
    INMA_BANK_ACCOUNT,
    GULF_AFRICAN_BANK,
    MPESA,
    STCPAY,
    VAT_NO,
    MOBILE,
  }) {
    // const NCB_BANK_ACCOUNT = "####";
    // const INMA_BANK_ACCOUNT = "####";
    // const GULF_AFRICAN_BANK = "0330010801";
    // const MPESA = "PAYBILL 985050 ACCOUNT 0130002301";
    // const STCPAY = "0505691673 Zainco Cargo Services";
    // const VAT_NO = "010004185494";
    // const MOBILE = "+966505691673";

    return (
      <div>
        <DebtManifest
          // data={existing_date}
          // container_data={container_data}
          // rates_data_extracted={rates_data_extracted}
          // SAR_TO_KSH_RATE={SAR_TO_KSH_RATE}
          rajhi_bank_account={RAJHI_BANK_ACCOUNT}
          ncb_bank_account={NCB_BANK_ACCOUNT}
          inma_bank_account={INMA_BANK_ACCOUNT}
          gulf_african_bank={GULF_AFRICAN_BANK}
          mpesa={MPESA}
          stcpay={STCPAY}
          vat_no={VAT_NO}
          mobile={MOBILE}
        />
      </div>
    );
  }
  main_tab_6({
    RAJHI_BANK_ACCOUNT,
    NCB_BANK_ACCOUNT,
    INMA_BANK_ACCOUNT,
    GULF_AFRICAN_BANK,
    MPESA,
    STCPAY,
    VAT_NO,
    MOBILE,
  }) {
    // const NCB_BANK_ACCOUNT = "####";
    // const INMA_BANK_ACCOUNT = "####";
    // const GULF_AFRICAN_BANK = "0330010801";
    // const MPESA = "PAYBILL 985050 ACCOUNT 0130002301";
    // const STCPAY = "0505691673 Zainco Cargo Services";
    // const VAT_NO = "010004185494";
    // const MOBILE = "+966505691673";

    return (
      <div>
        <CargoCollect
          // data={existing_date}
          // container_data={container_data}
          // rates_data_extracted={rates_data_extracted}
          // SAR_TO_KSH_RATE={SAR_TO_KSH_RATE}
          rajhi_bank_account={RAJHI_BANK_ACCOUNT}
          ncb_bank_account={NCB_BANK_ACCOUNT}
          inma_bank_account={INMA_BANK_ACCOUNT}
          gulf_african_bank={GULF_AFRICAN_BANK}
          mpesa={MPESA}
          stcpay={STCPAY}
          vat_no={VAT_NO}
          mobile={MOBILE}
        />
      </div>
    );
  }
  main_tab_7({
    RAJHI_BANK_ACCOUNT,
    NCB_BANK_ACCOUNT,
    INMA_BANK_ACCOUNT,
    GULF_AFRICAN_BANK,
    MPESA,
    STCPAY,
    VAT_NO,
    MOBILE,
  }) {
    // const NCB_BANK_ACCOUNT = "####";
    // const INMA_BANK_ACCOUNT = "####";
    // const GULF_AFRICAN_BANK = "0330010801";
    // const MPESA = "PAYBILL 985050 ACCOUNT 0130002301";
    // const STCPAY = "0505691673 Zainco Cargo Services";
    // const VAT_NO = "010004185494";
    // const MOBILE = "+966505691673";

    return (
      <div>
        <VatReport
          // data={existing_date}
          // container_data={container_data}
          // rates_data_extracted={rates_data_extracted}
          // SAR_TO_KSH_RATE={SAR_TO_KSH_RATE}
          rajhi_bank_account={RAJHI_BANK_ACCOUNT}
          ncb_bank_account={NCB_BANK_ACCOUNT}
          inma_bank_account={INMA_BANK_ACCOUNT}
          gulf_african_bank={GULF_AFRICAN_BANK}
          mpesa={MPESA}
          stcpay={STCPAY}
          vat_no={VAT_NO}
          mobile={MOBILE}
        />
      </div>
    );
  }
  main_tab_8({
    RAJHI_BANK_ACCOUNT,
    NCB_BANK_ACCOUNT,
    INMA_BANK_ACCOUNT,
    GULF_AFRICAN_BANK,
    MPESA,
    STCPAY,
    VAT_NO,
    MOBILE,
  }) {
    // const NCB_BANK_ACCOUNT = "####";
    // const INMA_BANK_ACCOUNT = "####";
    // const GULF_AFRICAN_BANK = "0330010801";
    // const MPESA = "PAYBILL 985050 ACCOUNT 0130002301";
    // const STCPAY = "0505691673 Zainco Cargo Services";
    // const VAT_NO = "010004185494";
    // const MOBILE = "+966505691673";

    return (
      <div>
        <ReportOfPayment
          // data={existing_date}
          // container_data={container_data}
          // rates_data_extracted={rates_data_extracted}
          // SAR_TO_KSH_RATE={SAR_TO_KSH_RATE}
          rajhi_bank_account={RAJHI_BANK_ACCOUNT}
          ncb_bank_account={NCB_BANK_ACCOUNT}
          inma_bank_account={INMA_BANK_ACCOUNT}
          gulf_african_bank={GULF_AFRICAN_BANK}
          mpesa={MPESA}
          stcpay={STCPAY}
          vat_no={VAT_NO}
          mobile={MOBILE}
        />
      </div>
    );
  }

  main_tab_9({
    RAJHI_BANK_ACCOUNT,
    NCB_BANK_ACCOUNT,
    INMA_BANK_ACCOUNT,
    GULF_AFRICAN_BANK,
    MPESA,
    STCPAY,
    VAT_NO,
    MOBILE,
  }) {
    // const NCB_BANK_ACCOUNT = "####";
    // const INMA_BANK_ACCOUNT = "####";
    // const GULF_AFRICAN_BANK = "0330010801";
    // const MPESA = "PAYBILL 985050 ACCOUNT 0130002301";
    // const STCPAY = "0505691673 Zainco Cargo Services";
    // const VAT_NO = "010004185494";
    // const MOBILE = "+966505691673";

    return (
      <div>
        <Delivery
          // data={existing_date}
          // container_data={container_data}
          // rates_data_extracted={rates_data_extracted}
          // SAR_TO_KSH_RATE={SAR_TO_KSH_RATE}
          rajhi_bank_account={RAJHI_BANK_ACCOUNT}
          ncb_bank_account={NCB_BANK_ACCOUNT}
          inma_bank_account={INMA_BANK_ACCOUNT}
          gulf_african_bank={GULF_AFRICAN_BANK}
          mpesa={MPESA}
          stcpay={STCPAY}
          vat_no={VAT_NO}
          mobile={MOBILE}
        />
      </div>
    );
  }

  main_tab_10({
    RAJHI_BANK_ACCOUNT,
    NCB_BANK_ACCOUNT,
    INMA_BANK_ACCOUNT,
    GULF_AFRICAN_BANK,
    MPESA,
    STCPAY,
    VAT_NO,
    MOBILE,
  }) {
    // const NCB_BANK_ACCOUNT = "####";
    // const INMA_BANK_ACCOUNT = "####";
    // const GULF_AFRICAN_BANK = "0330010801";
    // const MPESA = "PAYBILL 985050 ACCOUNT 0130002301";
    // const STCPAY = "0505691673 Zainco Cargo Services";
    // const VAT_NO = "010004185494";
    // const MOBILE = "+966505691673";

    return (
      <div>
        <Storage
          // data={existing_date}
          // container_data={container_data}
          // rates_data_extracted={rates_data_extracted}
          // SAR_TO_KSH_RATE={SAR_TO_KSH_RATE}
          rajhi_bank_account={RAJHI_BANK_ACCOUNT}
          ncb_bank_account={NCB_BANK_ACCOUNT}
          inma_bank_account={INMA_BANK_ACCOUNT}
          gulf_african_bank={GULF_AFRICAN_BANK}
          mpesa={MPESA}
          stcpay={STCPAY}
          vat_no={VAT_NO}
          mobile={MOBILE}
        />
      </div>
    );
  }

  main_tab_11({
    RAJHI_BANK_ACCOUNT,
    NCB_BANK_ACCOUNT,
    INMA_BANK_ACCOUNT,
    GULF_AFRICAN_BANK,
    MPESA,
    STCPAY,
    VAT_NO,
    MOBILE,
  }) {
    // const NCB_BANK_ACCOUNT = "####";
    // const INMA_BANK_ACCOUNT = "####";
    // const GULF_AFRICAN_BANK = "0330010801";
    // const MPESA = "PAYBILL 985050 ACCOUNT 0130002301";
    // const STCPAY = "0505691673 Zainco Cargo Services";
    // const VAT_NO = "010004185494";
    // const MOBILE = "+966505691673";

    return (
      <div>
        <Cash_Slip
          // data={existing_date}
          // container_data={container_data}
          // rates_data_extracted={rates_data_extracted}
          // SAR_TO_KSH_RATE={SAR_TO_KSH_RATE}
          rajhi_bank_account={RAJHI_BANK_ACCOUNT}
          ncb_bank_account={NCB_BANK_ACCOUNT}
          inma_bank_account={INMA_BANK_ACCOUNT}
          gulf_african_bank={GULF_AFRICAN_BANK}
          mpesa={MPESA}
          stcpay={STCPAY}
          vat_no={VAT_NO}
          mobile={MOBILE}
        />
      </div>
    );
  }

  main_tab_12({
    RAJHI_BANK_ACCOUNT,
    NCB_BANK_ACCOUNT,
    INMA_BANK_ACCOUNT,
    GULF_AFRICAN_BANK,
    MPESA,
    STCPAY,
    VAT_NO,
    MOBILE,
  }) {
    // const NCB_BANK_ACCOUNT = "####";
    // const INMA_BANK_ACCOUNT = "####";
    // const GULF_AFRICAN_BANK = "0330010801";
    // const MPESA = "PAYBILL 985050 ACCOUNT 0130002301";
    // const STCPAY = "0505691673 Zainco Cargo Services";
    // const VAT_NO = "010004185494";
    // const MOBILE = "+966505691673";

    return (
      <div>
        <Delivery_Slip
          // data={existing_date}
          // container_data={container_data}
          // rates_data_extracted={rates_data_extracted}
          // SAR_TO_KSH_RATE={SAR_TO_KSH_RATE}
          rajhi_bank_account={RAJHI_BANK_ACCOUNT}
          ncb_bank_account={NCB_BANK_ACCOUNT}
          inma_bank_account={INMA_BANK_ACCOUNT}
          gulf_african_bank={GULF_AFRICAN_BANK}
          mpesa={MPESA}
          stcpay={STCPAY}
          vat_no={VAT_NO}
          mobile={MOBILE}
        />
      </div>
    );
  }

  main_tab_13({
    RAJHI_BANK_ACCOUNT,
    NCB_BANK_ACCOUNT,
    INMA_BANK_ACCOUNT,
    GULF_AFRICAN_BANK,
    MPESA,
    STCPAY,
    VAT_NO,
    MOBILE,
  }) {
    return (
      <SalesReport
        rajhi_bank_account={RAJHI_BANK_ACCOUNT}
        ncb_bank_account={NCB_BANK_ACCOUNT}
        inma_bank_account={INMA_BANK_ACCOUNT}
        gulf_african_bank={GULF_AFRICAN_BANK}
        mpesa={MPESA}
        stcpay={STCPAY}
        vat_no={VAT_NO}
        mobile={MOBILE}
      />
    );
  }

  render() {
    const {
      shipping_data,
      container_data,
      currency_data,
      rates_data,
      user_details,
    } = this.props;

    var role = "admin";
    if (user_details) {
      role = user_details.role;
    }
    var show = null;

    if (shipping_data === null || container_data === null) {
      const loadingjson = {
        loop: true,
        autoplay: true,
        animationData: require("./JSON/loading.json"),

        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };

      show = (
        <div className="page-content">
          <Container fluid>
            <Row className="justify-content-center mt-lg-5">
              <div className="text-center">
                <Row className="justify-content-center">
                  <div className="text-center">
                    <Lottie options={loadingjson} height={300} width={300} />
                  </div>
                </Row>
              </div>
            </Row>
          </Container>
        </div>
      );
    } else {
      const RAJHI_BANK_ACCOUNT =
        "0000800000072 IBAN  SA05 8000 0158 6080 1234123";
      const NCB_BANK_ACCOUNT =
        "0000800000072 IBAN SA46 1000 0075 4000 0142 3309";
      const INMA_BANK_ACCOUNT = "####";
      const GULF_AFRICAN_BANK = "0000800000072";
      const MPESA = "PAYBILL 23452345 ACCOUNT 234524555";
      const STCPAY = "523452345234 Doqli Cargo Services";
      // const VAT_NO = "103220002236175 || ۱۰۳۲۲۰۰۰۲۲۳٦۱۷٥"; //"010004185494";
      const VAT_NO = "23452352345234523";
      const MOBILE = "+1132404691673";
      //
      show = (
        <TabContent
          activeTab={this.state.activeTab_MAIN_NAV}
          className="crypto-buy-sell-nav-content p-4"
        >
          {this.state.activeTab_MAIN_NAV === "1" ? (
            <TabPane tabId="1">
              {this.main_tab_1({
                NCB_BANK_ACCOUNT,
                INMA_BANK_ACCOUNT,
                GULF_AFRICAN_BANK,
                MPESA,
                STCPAY,
                VAT_NO,
                MOBILE,
              })}
            </TabPane>
          ) : (
            <div></div>
          )}

          {this.state.activeTab_MAIN_NAV === "2" ? (
            <TabPane tabId="2">
              {this.main_tab_2({
                RAJHI_BANK_ACCOUNT,
                NCB_BANK_ACCOUNT,
                INMA_BANK_ACCOUNT,
                GULF_AFRICAN_BANK,
                MPESA,
                STCPAY,
                VAT_NO,
                MOBILE,
              })}
            </TabPane>
          ) : (
            <div></div>
          )}

          {this.state.activeTab_MAIN_NAV === "3" ? (
            <TabPane tabId="3">
              {this.main_tab_3({
                RAJHI_BANK_ACCOUNT,
                NCB_BANK_ACCOUNT,
                INMA_BANK_ACCOUNT,
                GULF_AFRICAN_BANK,
                MPESA,
                STCPAY,
                VAT_NO,
                MOBILE,
              })}
            </TabPane>
          ) : (
            <div></div>
          )}
          {this.state.activeTab_MAIN_NAV === "4" ? (
            <TabPane tabId="4">
              {this.main_tab_4({
                RAJHI_BANK_ACCOUNT,
                NCB_BANK_ACCOUNT,
                INMA_BANK_ACCOUNT,
                GULF_AFRICAN_BANK,
                MPESA,
                STCPAY,
                VAT_NO,
                MOBILE,
              })}
            </TabPane>
          ) : (
            <div></div>
          )}
          {this.state.activeTab_MAIN_NAV === "5" ? (
            <TabPane tabId="5">
              {this.main_tab_5({
                RAJHI_BANK_ACCOUNT,
                NCB_BANK_ACCOUNT,
                INMA_BANK_ACCOUNT,
                GULF_AFRICAN_BANK,
                MPESA,
                STCPAY,
                VAT_NO,
                MOBILE,
              })}
            </TabPane>
          ) : (
            <div></div>
          )}
          {this.state.activeTab_MAIN_NAV === "6" ? (
            <TabPane tabId="6">
              {this.main_tab_6({
                RAJHI_BANK_ACCOUNT,
                NCB_BANK_ACCOUNT,
                INMA_BANK_ACCOUNT,
                GULF_AFRICAN_BANK,
                MPESA,
                STCPAY,
                VAT_NO,
                MOBILE,
              })}
            </TabPane>
          ) : (
            <div></div>
          )}
          {this.state.activeTab_MAIN_NAV === "7" ? (
            <TabPane tabId="7">
              {this.main_tab_7({
                RAJHI_BANK_ACCOUNT,
                NCB_BANK_ACCOUNT,
                INMA_BANK_ACCOUNT,
                GULF_AFRICAN_BANK,
                MPESA,
                STCPAY,
                VAT_NO,
                MOBILE,
              })}
            </TabPane>
          ) : (
            <div></div>
          )}
          {this.state.activeTab_MAIN_NAV === "8" ? (
            <TabPane tabId="8">
              {this.main_tab_8({
                RAJHI_BANK_ACCOUNT,
                NCB_BANK_ACCOUNT,
                INMA_BANK_ACCOUNT,
                GULF_AFRICAN_BANK,
                MPESA,
                STCPAY,
                VAT_NO,
                MOBILE,
              })}
            </TabPane>
          ) : (
            <div></div>
          )}
          {this.state.activeTab_MAIN_NAV === "9" ? (
            <TabPane tabId="9">
              {this.main_tab_9({
                RAJHI_BANK_ACCOUNT,
                NCB_BANK_ACCOUNT,
                INMA_BANK_ACCOUNT,
                GULF_AFRICAN_BANK,
                MPESA,
                STCPAY,
                VAT_NO,
                MOBILE,
              })}
            </TabPane>
          ) : (
            <div></div>
          )}
          {this.state.activeTab_MAIN_NAV === "10" ? (
            <TabPane tabId="10">
              {this.main_tab_10({
                RAJHI_BANK_ACCOUNT,
                NCB_BANK_ACCOUNT,
                INMA_BANK_ACCOUNT,
                GULF_AFRICAN_BANK,
                MPESA,
                STCPAY,
                VAT_NO,
                MOBILE,
              })}
            </TabPane>
          ) : (
            <div></div>
          )}
          {this.state.activeTab_MAIN_NAV === "11" ? (
            <TabPane tabId="11">
              {this.main_tab_11({
                RAJHI_BANK_ACCOUNT,
                NCB_BANK_ACCOUNT,
                INMA_BANK_ACCOUNT,
                GULF_AFRICAN_BANK,
                MPESA,
                STCPAY,
                VAT_NO,
                MOBILE,
              })}
            </TabPane>
          ) : (
            <div></div>
          )}
          {this.state.activeTab_MAIN_NAV === "12" ? (
            <TabPane tabId="12">
              {this.main_tab_12({
                RAJHI_BANK_ACCOUNT,
                NCB_BANK_ACCOUNT,
                INMA_BANK_ACCOUNT,
                GULF_AFRICAN_BANK,
                MPESA,
                STCPAY,
                VAT_NO,
                MOBILE,
              })}
            </TabPane>
          ) : (
            <div></div>
          )}

          {this.state.activeTab_MAIN_NAV === "13" ? (
            <TabPane tabId="13">
              {this.main_tab_13({
                RAJHI_BANK_ACCOUNT,
                NCB_BANK_ACCOUNT,
                INMA_BANK_ACCOUNT,
                GULF_AFRICAN_BANK,
                MPESA,
                STCPAY,
                VAT_NO,
                MOBILE,
              })}
            </TabPane>
          ) : (
            <div></div>
          )}
        </TabContent>
      );
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Reports and Reciepts"
              breadcrumbItem="Reports and Reciepts"
            />
            {/*this.show_report()*/}
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="crypto-buy-sell-nav">
                      <Nav tabs className="nav-tabs-custom" role="tablist">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab_MAIN_NAV === "1",
                            })}
                            onClick={() => {
                              this.toggleTab_MAIN_NAV("1");
                            }}
                          >
                            Outstanding Balance
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab_MAIN_NAV === "2",
                            })}
                            onClick={() => {
                              this.toggleTab_MAIN_NAV("2");
                            }}
                          >
                            Manifest
                          </NavLink>
                        </NavItem>

                        {/***/}
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab_MAIN_NAV === "3",
                            })}
                            onClick={() => {
                              this.toggleTab_MAIN_NAV("3");
                            }}
                          >
                            Destination
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab_MAIN_NAV === "4",
                            })}
                            onClick={() => {
                              this.toggleTab_MAIN_NAV("4");
                            }}
                          >
                            Cargo Manifest
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab_MAIN_NAV === "5",
                            })}
                            onClick={() => {
                              this.toggleTab_MAIN_NAV("5");
                            }}
                          >
                            Debt Manifest
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab_MAIN_NAV === "6",
                            })}
                            onClick={() => {
                              this.toggleTab_MAIN_NAV("6");
                            }}
                          >
                            Cargo Collect
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab_MAIN_NAV === "7",
                            })}
                            onClick={() => {
                              this.toggleTab_MAIN_NAV("7");
                            }}
                          >
                            VAT Report
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab_MAIN_NAV === "9",
                            })}
                            onClick={() => {
                              this.toggleTab_MAIN_NAV("9");
                            }}
                          >
                            Delivery
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab_MAIN_NAV === "10",
                            })}
                            onClick={() => {
                              this.toggleTab_MAIN_NAV("10");
                            }}
                          >
                            Storage
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab_MAIN_NAV === "8",
                            })}
                            onClick={() => {
                              this.toggleTab_MAIN_NAV("8");
                            }}
                          >
                            Payment Receipt
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab_MAIN_NAV === "11",
                            })}
                            onClick={() => {
                              this.toggleTab_MAIN_NAV("11");
                            }}
                          >
                            Cash Slip
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab_MAIN_NAV === "12",
                            })}
                            onClick={() => {
                              this.toggleTab_MAIN_NAV("12");
                            }}
                          >
                            Delivery Slip
                          </NavLink>
                        </NavItem>

                        {role === "admin" ? (
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: this.state.activeTab_MAIN_NAV === "13",
                              })}
                              onClick={() => {
                                this.toggleTab_MAIN_NAV("13");
                              }}
                            >
                              Salesperson Report
                            </NavLink>
                          </NavItem>
                        ) : null}
                      </Nav>
                      {show}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const {
    user_details,
    error,
    loading,
    shipping_data,
    container_data,
    currency_data,
    rates_data,
  } = state.Database;

  return {
    user_details,
    error,
    loading,
    shipping_data,
    container_data,
    currency_data,
    rates_data,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    user_data_read,
    detail_error,
    shipping_data_read,
    container_data_read,
    currency_data_read,
    rates_data_read,
  })(withNamespaces()(Index))
);

// export default Index;
