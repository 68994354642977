import _ from "lodash";
import React, { Component, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
  UncontrolledTooltip,
  ////////
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  InputGroupAddon,
  InputGroup,
  Modal,
} from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";

import firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/database";
import SweetAlert from "react-bootstrap-sweetalert";

import classnames from "classnames";

// import "./CSS/surveyQR.css";
import "./CSS/surveyQR.css";

// Import Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//Import Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

class TasksCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      activeTab: "1",
      name: "",
      address: "",
      phone: "",
      town: "",
      country: "",
      email: "",
      //old
      old_data: [],
      old_name: "",
      old_address: "",
      old_phone: "",
      old_town: "",
      old_country: "",
      old_email: "",
      old_uid: "",
      body: <div></div>,
      show_alert: false,
      modal_standard: false,
      show_old_data: false,
    };
    this.startDateChange.bind(this);
    this.endDateChange.bind(this);

    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  startDateChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  endDateChange = (date) => {
    this.setState({
      endDate: date,
    });
  };

  search_fetch() {
    firebase
      .database()
      .ref("organization/zaincocargo/shipper/")
      .once("value")
      .then((snapshot) => {
        var data_array = _.map(snapshot.val(), (val, uid) => {
          return { ...val, uid };
        });

        this.setState({
          old_data: data_array,
        });
      });
  }
  reset() {
    this.setState({
      name: "",
      address: "",
      phone: "",
      town: "",
      country: "",
      //old
      old_data: [],
      old_name: "",
      old_address: "",
      old_phone: "",
      old_town: "",
      old_country: "",
      old_uid: "",
      body: <div></div>,
      show_alert: false,
      modal_standard: false,
      show_old_data: false,
    });
  }
  addShipper() {
    const { name, address, phone, town, country, email } = this.state;
    if (
      name === "" ||
      address === "" ||
      phone === "" ||
      town === "" ||
      country === "" ||
      email === ""
    ) {
      alert("Please ensure no field are empty!");
    } else {
      firebase
        .database()
        .ref("organization/zaincocargo/shipper/")
        .push({
          name,
          address,
          phone,
          town,
          country,
          email,
        })
        .then(() => {
          this.setState({
            name: "",
            address: "",
            phone: "",
            town: "",
            country: "",
            email: "",
          });
          alert("Your shipper details were successfully added!");
        })
        .catch(() => {
          alert(
            "Oops! There was an error while adding your shipper details. Please try again!"
          );
        });
    }
  }

  updateShipper() {
    const {
      old_name,
      old_address,
      old_phone,
      old_town,
      old_country,
      old_uid,
      old_email,
    } = this.state;
    if (
      old_name === "" ||
      old_address === "" ||
      old_phone === "" ||
      old_town === "" ||
      old_country === "" ||
      old_email === ""
    ) {
      alert("Please ensure no field are empty!");
    } else {
      firebase
        .database()
        .ref(`organization/zaincocargo/shipper/${old_uid}`)
        .update({
          name: old_name,
          address: old_address,
          phone: old_phone,
          town: old_town,
          country: old_country,
          email: old_email,
        })
        .then(() => {
          this.search_fetch();

          alert("Your shipper details were successfully updated!");
        })
        .catch(() => {
          alert(
            "Oops! There was an error while adding your shipper details. Please try again!"
          );
        });
    }
  }

  tab1() {
    return (
      <Row>
        <Col lg="12">
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Name</Label>
            </InputGroupAddon>
            <Input
              type="text"
              className="form-control"
              value={this.state.name}
              onChange={(event) => {
                this.setState({ name: event.target.value });
              }}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Address</Label>
            </InputGroupAddon>
            <Input
              type="text"
              className="form-control"
              value={this.state.address}
              onChange={(event) => {
                this.setState({ address: event.target.value });
              }}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Phone</Label>
            </InputGroupAddon>
            <Input
              type="text"
              className="form-control"
              value={this.state.phone}
              onChange={(event) => {
                this.setState({ phone: event.target.value });
              }}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Town</Label>
            </InputGroupAddon>
            <Input
              type="text"
              className="form-control"
              value={this.state.town}
              onChange={(event) => {
                this.setState({ town: event.target.value });
              }}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Country</Label>
            </InputGroupAddon>
            <Input
              type="text"
              className="form-control"
              value={this.state.country}
              onChange={(event) => {
                this.setState({ country: event.target.value });
              }}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Email</Label>
            </InputGroupAddon>
            <Input
              type="text"
              className="form-control"
              value={this.state.email}
              onChange={(event) => {
                this.setState({ email: event.target.value });
              }}
            />
          </InputGroup>
        </Col>
        {/*<Col lg="2">
          <div classname="align-me mb-3">
            <button
              type="button"
              className="btn btn-success waves-effect waves-light"
            >
              <i className="bx bx-search font-size-16 align-middle mr-2"></i>{" "}
              Search
            </button>
          </div>
        </Col>*/}
      </Row>
    );
  }

  tab2() {
    const {
      old_data,
      old_address,
      old_country,
      old_town,
      old_name,
      old_phone,
      old_email,
    } = this.state;
    var export_data = [];
    var show_to_edit = [];

    if (old_data.length !== 0) {
      export_data.push(["Name", "Address", "Phone", "Town", "Country"]);
      old_data.forEach((element) => {
        export_data.push([
          element.name,
          element.address,
          element.phone,
          element.town,
          element.country,
          element.email,
        ]);

        show_to_edit.push({
          name: element.name,
          address: element.address,
          phone: element.phone,
          town: element.town,
          country: element.country,
          email: element.email,
          edit: (
            <div>
              <Row>
                <Col lg="12" className="text-center">
                  <Link
                    onClick={() => {
                      this.setState({
                        old_address: element.address,
                        old_country: element.country,
                        old_name: element.name,
                        old_phone: element.phone,
                        old_town: element.town,
                        old_uid: element.uid,
                        old_email: element.email,
                        show_old_data: true,
                        modal_standard: false,
                      });
                    }}
                    className="mr-3 text-primary text-center"
                  >
                    <i
                      className="mdi mdi-pencil font-size-18 "
                      id="edittooltip"
                    ></i>
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                </Col>
              </Row>
            </div>
          ),
        });
      });
    }

    if (this.state.show_old_data === true) {
      return (
        <div>
          <Modal
            isOpen={this.state.modal_standard}
            size="lg"
            toggle={() => {
              this.setState((prevState) => ({
                modal_standard: !prevState.modal_standard,
              }));
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                List Of All Shippers
              </h5>
              <button
                type="button"
                onClick={() => this.setState({ modal_standard: false })}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>{this.state.body}</div>
            </div>
          </Modal>
          <Row>
            <Col lg="10">
              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text">Name</Label>
                </InputGroupAddon>
                <Input
                  type="text"
                  className="form-control"
                  value={old_name}
                  onChange={(event) => {
                    this.setState({
                      old_name: event.target.value,
                    });
                  }}
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text">Address</Label>
                </InputGroupAddon>
                <Input
                  type="text"
                  className="form-control"
                  value={old_address}
                  onChange={(event) => {
                    this.setState({
                      old_address: event.target.value,
                    });
                  }}
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text">Phone</Label>
                </InputGroupAddon>
                <Input
                  type="text"
                  className="form-control"
                  value={old_phone}
                  onChange={(event) => {
                    this.setState({
                      old_phone: event.target.value,
                    });
                  }}
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text">Town</Label>
                </InputGroupAddon>
                <Input
                  type="text"
                  className="form-control"
                  value={old_town}
                  onChange={(event) => {
                    this.setState({
                      old_town: event.target.value,
                    });
                  }}
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text">Country</Label>
                </InputGroupAddon>
                <Input
                  type="text"
                  className="form-control"
                  value={old_country}
                  onChange={(event) => {
                    this.setState({
                      old_country: event.target.value,
                    });
                  }}
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text">Email</Label>
                </InputGroupAddon>
                <Input
                  type="text"
                  className="form-control"
                  value={old_email}
                  onChange={(event) => {
                    this.setState({
                      old_email: event.target.value,
                    });
                  }}
                />
              </InputGroup>
            </Col>

            <Col lg="2">
              <div className="text-center">
                <p className="text-muted">
                  Use button below to search for your existing shipper to edit
                  or to export csv
                </p>
                <button
                  type="button"
                  onClick={() => {
                    // this.search_fetch();

                    const data = {
                      columns: [
                        {
                          label: "Name",
                          field: "name",
                          sort: "asc",
                          width: 150,
                        },
                        {
                          label: "Address",
                          field: "address",
                          sort: "asc",
                          width: 270,
                        },
                        {
                          label: "Phone",
                          field: "phone",
                          sort: "asc",
                          width: 200,
                        },
                        {
                          label: "Town",
                          field: "town",
                          sort: "asc",
                          width: 200,
                        },
                        {
                          label: "Country",
                          field: "country",
                          sort: "asc",
                          width: 200,
                        },
                        {
                          label: "Email",
                          field: "email",
                          sort: "asc",
                          width: 200,
                        },
                        {
                          label: "Edit",
                          field: "edit",
                          sort: "asc",
                          width: 200,
                        },
                      ],
                      rows: show_to_edit,
                    };

                    var body = (
                      <div>
                        <div className="row mt-12">
                          <div className="col-xl col-sm-12">
                            <div className="text-sm-right">
                              <CSVLink
                                filename={`All_Shippers.csv`}
                                data={export_data}
                              >
                                <Button
                                  type="button"
                                  color="primary"
                                  className="w-md"
                                >
                                  Export All Shippers
                                </Button>
                              </CSVLink>
                            </div>
                          </div>
                        </div>

                        <MDBDataTable responsive striped bordered data={data} />
                      </div>
                    );

                    this.setState({
                      modal_standard: true,
                      body,
                    });
                  }}
                  className="btn btn-primary waves-effect waves-light"
                  data-toggle="modal"
                  data-target="#myModal"
                >
                  Search Existing Shippers
                </button>
              </div>

              {/*<div classname="align-me mb-3">
            <button
              type="button"
              className="btn btn-success waves-effect waves-light"
            >
              <i className="bx bx-search font-size-16 align-middle mr-2"></i>{" "}
              Search
            </button>
          </div>*/}
            </Col>
          </Row>
          <div className="text-center">
            <Button
              type="submit"
              color="primary"
              onClick={() => {
                this.updateShipper();
              }}
            >
              Update Shipper
            </Button>
          </div>
        </div>
      );
    }

    return (
      <Row>
        <Col lg="12">
          <div classname="align-me mb-3">
            <Col lg="12" className="mt-4">
              <div className="text-center">
                <p className="text-muted">
                  Use button below to search for your existing shipper to edit
                  or to export csv
                </p>
                <button
                  type="button"
                  onClick={() => {
                    // this.search_fetch();

                    const data = {
                      columns: [
                        {
                          label: "Name",
                          field: "name",
                          sort: "asc",
                          width: 150,
                        },
                        {
                          label: "Address",
                          field: "address",
                          sort: "asc",
                          width: 270,
                        },
                        {
                          label: "Phone",
                          field: "phone",
                          sort: "asc",
                          width: 200,
                        },
                        {
                          label: "Town",
                          field: "town",
                          sort: "asc",
                          width: 200,
                        },
                        {
                          label: "Country",
                          field: "country",
                          sort: "asc",
                          width: 200,
                        },
                        {
                          label: "Email",
                          field: "email",
                          sort: "asc",
                          width: 200,
                        },
                        {
                          label: "Edit",
                          field: "edit",
                          sort: "asc",
                          width: 200,
                        },
                      ],
                      rows: show_to_edit,
                    };

                    var body = (
                      <div>
                        <div className="row mt-12">
                          <div className="col-xl col-sm-12">
                            <div className="text-sm-right">
                              <CSVLink
                                filename={`All_Shippers.csv`}
                                data={export_data}
                              >
                                <Button
                                  type="button"
                                  color="primary"
                                  className="w-md"
                                >
                                  Export All Shippers
                                </Button>
                              </CSVLink>
                            </div>
                          </div>
                        </div>

                        <MDBDataTable responsive striped bordered data={data} />
                      </div>
                    );

                    this.setState({
                      modal_standard: true,
                      body,
                    });
                  }}
                  className="btn btn-primary waves-effect waves-light"
                  data-toggle="modal"
                  data-target="#myModal"
                >
                  Search Existing Shippers
                </button>
              </div>

              <Modal
                isOpen={this.state.modal_standard}
                size="lg"
                toggle={() => {
                  this.setState((prevState) => ({
                    modal_standard: !prevState.modal_standard,
                  }));
                }}
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    List Of All Shippers
                  </h5>
                  <button
                    type="button"
                    onClick={() => this.setState({ modal_standard: false })}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div>{this.state.body}</div>
                </div>
              </Modal>
            </Col>
          </div>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.show_alert ? (
          <SweetAlert
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            showCancel={this.state.showCancel}
            title={this.state.title}
            warning={this.state.warning}
            error={this.state.error}
            success={this.state.success}
            confirmBtnText={this.state.confirm_button_text}
            onConfirm={() => {
              const { type, updated_status, uid } = this.state;
            }}
            onCancel={() => this.setState({ show_alert: false })}
          >
            {this.state.body}
          </SweetAlert>
        ) : null}

        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Shipper Details"
              breadcrumbItem="Shipper Details"
            />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Shipper Details</CardTitle>

                    <div className="mt-4">
                      <Nav pills className="bg-light rounded" role="tablist">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "1",
                            })}
                            onClick={() => {
                              this.reset();
                              this.toggleTab("1");
                            }}
                          >
                            Add New Shipper
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "2",
                            })}
                            onClick={() => {
                              this.reset();
                              this.toggleTab("2");
                              this.search_fetch();

                              /*  firebase
                                .database()
                                .ref("organization/zaincocargo/shipper/")
                                .once("value")
                                .then((snapshot) => {
                                  console.log(snapshot.val());

                                  var data_array = _.map(
                                    snapshot.val(),
                                    (val, uid) => {
                                      return { ...val, uid };
                                    }
                                  );

                                  console.log(snapshot.val());
                                  this.setState({
                                    old_data: data_array,
                                  });
                                });
                                */
                            }}
                          >
                            Edit Existing Shipper
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent
                        activeTab={this.state.activeTab}
                        className="mt-4"
                      >
                        <TabPane tabId="1" id="buy-tab">
                          {this.tab1()}

                          <div className="text-center">
                            <Button
                              type="submit"
                              color="primary"
                              onClick={() => {
                                this.addShipper();
                              }}
                            >
                              Add Shipper
                            </Button>
                          </div>
                        </TabPane>
                        <TabPane tabId="2" id="sell-tab">
                          {this.tab2()}
                        </TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default TasksCreate;
