//User
export const USER_DATA_READ_ASYNC = "USER_DATA_READ_ASYNC";
export const USER_DATA_READ = "USER_DATA_READ";
//Shipping
export const SHIPPING_DATA_READ_ASYNC = "SHIPPING_DATA_READ_ASYNC";
export const SHIPPING_DATA_READ = "SHIPPING_DATA_READ";
//Container
export const CONTAINER_DATA_READ_ASYNC = "CONTAINER_DATA_READ_ASYNC";
export const CONTAINER_DATA_READ = "CONTAINER_DATA_READ";
//Currency Converter
export const CURRENCY_DATA_READ_ASYNC = "CURRENCY_DATA_READ_ASYNC";
export const CURRENCY_DATA_READ = "CURRENCY_DATA_READ";
//Rates
export const RATES_DATA_READ_ASYNC = "RATES_DATA_READ_ASYNC";
export const RATES_DATA_READ = "RATES_DATA_READ";
//Limited Access Users Zaincocargo
export const LIMITED_ACCESS_USER_DATA_READ_ASYNC =
  "LIMITED_ACCESS_USER_DATA_READ_ASYNC";
export const LIMITED_ACCESS_USER_DATA_READ = "LIMITED_ACCESS_USER_DATA_READ";
//Limited Access Users Zaincocargo Logs
export const LIMITED_ACCESS_USER_LOGS_DATA_READ_ASYNC =
  "LIMITED_ACCESS_USER_LOGS_DATA_READ_ASYNC";
export const LIMITED_ACCESS_USER_LOGS_DATA_READ =
  "LIMITED_ACCESS_USER_LOGS_DATA_READ";
//
export const DETAIL_ERROR = "DETAIL_ERROR";
