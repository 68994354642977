import _ from "lodash";
import firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";

import { Badge, UncontrolledTooltip, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          localStorage.setItem("authUser", JSON.stringify(user));
        } else {
          localStorage.removeItem("authUser");
        }
      });
    }
  }

  /**
   * Registers the user with given details
   */
  registerUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          (user) => {
            resolve(firebase.auth().currentUser);
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Registers the user with given details
   */
  editProfileAPI = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          (user) => {
            resolve(firebase.auth().currentUser);
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          (user) => {
            resolve(firebase.auth().currentUser);
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = (email) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  /**_______________________________________Database Start___________________________________________**/

  getUser = async () => {
    return new Promise((resolve, reject) => {
      return firebase.auth().onAuthStateChanged((user) => {
        if (user) resolve(user);
        else reject("No User");
      });
    });
  };

  //-----------------USERS--------
  readUserData = async (user_uid) => {
    return firebase
      .database()
      .ref(`/users/${user_uid}`)
      .once("value")
      .then((snapshot) => {
        const userdata = snapshot.val();
        if (userdata) {
          return userdata;
        } else {
          return "No User";
        }
      })
      .catch((error) => {
        this._handleError(error);
        return "No User";
      });
  };

  // readShippingData = async (user_data) => {
  // if (user_data !== null) {
  // return firebase
  //   .database()
  //   .ref(`organization/zaincocargo/shipment_details`)
  //   .once("value")
  //   .then((snapshot) => {
  //     return snapshot.val();
  //   })
  //   .catch((error) => {
  //     this._handleError(error);
  //     return "No User";
  //   });
  // }
  // return user_data;
  // }

  //-----------------SHIPPING--------
  readShippingData = async (user_data) => {
    return new Promise((resolve, reject) => {
      return (
        firebase
          .database()
          .ref(`organization/zaincocargo/shipment_details`)
          // .ref(`organization/zaincocargo/currency_converter`)

          .on("value", (snap) => {
            if (snap.val()) resolve(snap.val());
            else reject("No Data");
          })
      );
    });
  };

  //-----------------CONTAINER--------
  readContainerData = async (user_data) => {
    return new Promise((resolve, reject) => {
      return firebase
        .database()
        .ref(`organization/zaincocargo/container`)
        .on("value", (snap) => {
          if (snap.val()) resolve(snap.val());
          else reject("No Data");
        });
    });
  };

  //-----------------Currency Converter--------
  readCurrencyConverterData = async (user_data) => {
    return new Promise((resolve, reject) => {
      return firebase
        .database()
        .ref(`organization/zaincocargo/currency_converter`)
        .on("value", (snap) => {
          if (snap.val()) resolve(snap.val());
          else reject("No Data");
        });
    });
  };

  //-----------------Rates--------
  readRatesData = async (user_data) => {
    return new Promise((resolve, reject) => {
      return firebase
        .database()
        .ref(`organization/zaincocargo/rates`)
        .on("value", (snap) => {
          if (snap.val()) resolve(snap.val());
          else reject("No Data");
        });
    });
  };

  //-----------------limited_access_users zaincocargo --------
  readlimited_access_users_zaincocargo = async (user_data) => {
    return new Promise((resolve, reject) => {
      return firebase
        .database()
        .ref(`limited_access_users/zaincocargo/`)
        .on("value", (snap) => {
          if (snap.val()) resolve(snap.val());
          else reject("No Data");
        });
    });
  };

  //-----------------limited_access_users zaincocargo_logs --------
  readlimited_access_users_zaincocargo_logs = async (user_data) => {
    return new Promise((resolve, reject) => {
      return firebase
        .database()
        .ref(`limited_access_users/zaincocargo_logs/`)
        .on("value", (snap) => {
          if (snap.val()) resolve(snap.val());
          else reject("No Data");
        });
    });
  };

  /**_______________________________________Database End___________________________________________**/

  setLoggeedInUser = (user) => {
    localStorage.setItem("authUser", JSON.stringify(user));
  };

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem("authUser")) return null;
    return JSON.parse(localStorage.getItem("authUser"));
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message;
    return errorMessage;
  }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend };
