import _ from "lodash";
import React, { Component, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
  UncontrolledTooltip,
  ////////
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  InputGroupAddon,
  InputGroup,
  Modal,
} from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import firebase from "firebase/app";
import SweetAlert from "react-bootstrap-sweetalert";
import "firebase/database";
import classnames from "classnames";
import "./CSS/surveyQR.css";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

/**********/

// Redux
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import {
  user_data_read,
  shipping_data_read,
  container_data_read,
  currency_data_read,
  rates_data_read,
  limited_users_data_read,
  limited_users_logs_data_read,
  detail_error,
} from "../../store/actions";
import { withNamespaces } from "react-i18next";

/*********/

class TasksCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm_both: false,
      startDate: new Date(),
      endDate: new Date(),
      activeTab: "1",

      lot: "",
      accepting_date: "",
      closing_date: "",
      departure_date: "",
      arrival_date: "",
      storage_date: "",
      other_details: "",

      old_data: [],

      old_lot: "",
      old_accepting_date: "",
      old_closing_date: "",
      old_departure_date: "",
      old_arrival_date: "",
      old_storage_date: "",
      old_other_details: "",
      old_uid: "",

      body: <div></div>,
      show_alert: false,
      modal_standard: false,
      show_old_data: false,
    };
    this.startDateChange.bind(this);
    this.endDateChange.bind(this);

    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  startDateChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  endDateChange = (date) => {
    this.setState({
      endDate: date,
    });
  };

  search_fetch() {
    const { container_data } = this.props;
    var data_array = _.map(container_data, (val, uid) => {
      return { ...val, uid };
    });
    this.setState({
      old_data: data_array,
    });
  }
  reset() {
    this.setState({
      lot: "",
      accepting_date: "",
      closing_date: "",
      departure_date: "",
      arrival_date: "",
      storage_date: "",
      other_details: "",

      //old
      old_data: [],
      old_name: "",
      old_address: "",
      old_phone: "",
      old_town: "",
      old_country: "",
      old_uid: "",
      body: <div></div>,
      show_alert: false,
      modal_standard: false,
      show_old_data: false,
    });
  }
  addShipper() {
    const {
      lot,
      accepting_date,
      closing_date,
      departure_date,
      arrival_date,
      storage_date,
      other_details,
    } = this.state;
    if (
      lot === "" //||
      // accepting_date === "" ||
      // closing_date === "" ||
      // departure_date === "" ||
      // arrival_date === "" ||
      // storage_date === "" ||
      // other_details === ""
    ) {
      alert("Please ensure no field are empty!");
    } else {
      firebase
        .database()
        .ref("organization/zaincocargo/container/")
        .push({
          lot,
          accepting_date,
          closing_date,
          departure_date,
          arrival_date,
          storage_date,
          other_details,
        })
        .then(() => {
          this.setState({
            lot: "",
            accepting_date: "",
            closing_date: "",
            departure_date: "",
            arrival_date: "",
            storage_date: "",
            other_details: "",
          });
          alert("Your container details were successfully added!");

          this.props.container_data_read();
        })
        .catch(() => {
          alert(
            "Oops! There was an error while adding your container details. Please try again!"
          );
        });
    }
  }

  updateShipper() {
    const {
      old_lot,
      old_accepting_date,
      old_closing_date,
      old_departure_date,
      old_arrival_date,
      old_storage_date,
      old_other_details,
      old_uid,
    } = this.state;
    if (old_lot === "") {
      alert("Please ensure no field are empty!");
    } else {
      firebase
        .database()
        .ref(`organization/zaincocargo/container/${old_uid}`)
        .update({
          lot: old_lot,
          accepting_date: old_accepting_date,
          closing_date: old_closing_date,
          departure_date: old_departure_date,
          arrival_date: old_arrival_date,
          storage_date: old_storage_date,
          other_details: old_other_details,
        })
        .then(() => {
          this.search_fetch();

          alert("Your container details were successfully updated!");

          this.props.container_data_read();
        })
        .catch(() => {
          alert(
            "Oops! There was an error while adding your container details. Please try again!"
          );
        });
    }
  }

  tab1() {
    return (
      <Row>
        <Col lg="12">
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Lot#</Label>
            </InputGroupAddon>
            <Input
              type="number"
              className="form-control"
              value={this.state.lot}
              onChange={(event) => {
                this.setState({ lot: event.target.value });
              }}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Accepting Date</Label>
            </InputGroupAddon>
            <Input
              type="date"
              className="form-control"
              value={this.state.accepting_date}
              onChange={(event) => {
                this.setState({ accepting_date: event.target.value });
              }}
            />
          </InputGroup>
          <p style={{ color: "#B71C1C", size: 8 }}>
            Accepting Date: The initial date that the container is available for
            loading
          </p>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Closing Date</Label>
            </InputGroupAddon>
            <Input
              type="date"
              className="form-control"
              value={this.state.closing_date}
              onChange={(event) => {
                this.setState({ closing_date: event.target.value });
              }}
            />
          </InputGroup>
          <p style={{ color: "#B71C1C", size: 8 }}>
            Closing Date: The date that no more orders are being loaded into the
            container
          </p>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Departure Date</Label>
            </InputGroupAddon>
            <Input
              type="date"
              className="form-control"
              value={this.state.departure_date}
              onChange={(event) => {
                this.setState({ departure_date: event.target.value });
              }}
            />
          </InputGroup>
          <p style={{ color: "#B71C1C", size: 8 }}>
            Departure Date: The date the ship heads out to the high seas
          </p>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">
                THE DAY CARGO ARRIVED AT OUR WAREHOUSE
              </Label>
            </InputGroupAddon>
            <Input
              type="date"
              className="form-control"
              value={this.state.arrival_date}
              onChange={(event) => {
                this.setState({ arrival_date: event.target.value });
              }}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">STORAGE BEGINS</Label>
            </InputGroupAddon>
            <Input
              type="date"
              className="form-control"
              value={this.state.storage_date}
              onChange={(event) => {
                this.setState({ storage_date: event.target.value });
              }}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Other Details</Label>
            </InputGroupAddon>
            <Input
              type="text"
              className="form-control"
              value={this.state.other_details}
              onChange={(event) => {
                this.setState({ other_details: event.target.value });
              }}
            />
          </InputGroup>
          <p style={{ color: "#B71C1C", size: 8 }}>
            Any extra details you (Optional)
          </p>
        </Col>
      </Row>
    );
  }

  tab2() {
    const {
      old_data,
      old_address,
      old_country,
      old_town,
      old_name,
      old_phone,
    } = this.state;
    var export_data = [];
    var show_to_edit = [];

    if (old_data.length !== 0) {
      export_data.push([
        "Lot",
        "Accepting_date",
        "Closing_date",
        "Departure_date",
        "Arrival_date",
        "Storage_date",
        "Other_details",
      ]);

      old_data.forEach((element) => {
        export_data.push([
          element.lot,
          element.accepting_date,
          element.closing_date,
          element.departure_date,
          element.arrival_date,
          element.storage_date,
          element.other_details,
        ]);

        show_to_edit.push({
          lot: element.lot,
          accepting_date: element.accepting_date,
          closing_date: element.closing_date,
          departure_date: element.departure_date,
          arrival_date: element.arrival_date,
          storage_date: element.storage_date,
          other_details: element.other_details,
          edit: (
            <div>
              <Row>
                <Col lg="12" className="text-center">
                  <Link
                    onClick={() => {
                      this.setState({
                        old_lot: element.lot,
                        old_accepting_date: element.accepting_date,
                        old_closing_date: element.closing_date,
                        old_departure_date: element.departure_date,
                        old_arrival_date: element.arrival_date,
                        old_storage_date: element.storage_date,
                        old_other_details: element.other_details,
                        old_uid: element.uid,
                        show_old_data: true,
                        modal_standard: false,
                      });
                    }}
                    className="mr-3 text-primary text-center"
                  >
                    <i
                      className="mdi mdi-pencil font-size-18 "
                      id="edittooltip"
                    ></i>
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                </Col>
              </Row>
            </div>
          ),
        });
      });
    }

    if (this.state.show_old_data === true) {
      return (
        <div>
          <Modal
            isOpen={this.state.modal_standard}
            size="lg"
            toggle={() => {
              this.setState((prevState) => ({
                modal_standard: !prevState.modal_standard,
              }));
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                List Of All containers
              </h5>
              <button
                type="button"
                onClick={() => this.setState({ modal_standard: false })}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>{this.state.body}</div>
            </div>
          </Modal>
          <Row>
            <Col lg="10">
              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text">Lot#</Label>
                </InputGroupAddon>
                <Input
                  type="number"
                  className="form-control"
                  value={this.state.old_lot}
                  onChange={(event) => {
                    this.setState({ old_lot: event.target.value });
                  }}
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text">Accepting Date</Label>
                </InputGroupAddon>
                <Input
                  type="date"
                  className="form-control"
                  value={this.state.old_accepting_date}
                  onChange={(event) => {
                    this.setState({ old_accepting_date: event.target.value });
                  }}
                />
              </InputGroup>
              <p style={{ color: "#B71C1C", size: 8 }}>
                Accepting Date: The initial date that the container is available
                for loading
              </p>

              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text">Closing Date</Label>
                </InputGroupAddon>
                <Input
                  type="date"
                  className="form-control"
                  value={this.state.old_closing_date}
                  onChange={(event) => {
                    this.setState({ old_closing_date: event.target.value });
                  }}
                />
              </InputGroup>
              <p style={{ color: "#B71C1C", size: 8 }}>
                Closing Date: The date that no more orders are being loaded into
                the container
              </p>

              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text">Departure Date</Label>
                </InputGroupAddon>
                <Input
                  type="date"
                  className="form-control"
                  value={this.state.old_departure_date}
                  onChange={(event) => {
                    this.setState({ old_departure_date: event.target.value });
                  }}
                />
              </InputGroup>
              <p style={{ color: "#B71C1C", size: 8 }}>
                Departure Date: The date the ship heads out to the high seas
              </p>

              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text">
                    THE DAY CARGO ARRIVED AT OUR WAREHOUSE
                  </Label>
                </InputGroupAddon>
                <Input
                  type="date"
                  className="form-control"
                  value={this.state.old_arrival_date}
                  onChange={(event) => {
                    this.setState({ old_arrival_date: event.target.value });
                  }}
                />
              </InputGroup>
              {/* <p style={{ color: "#B71C1C", size: 8 }}>
                Arrival Date: The date in which it arrives in the port of
                destination
              </p>*/}

              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text">STORAGE BEGINS</Label>
                </InputGroupAddon>
                <Input
                  type="date"
                  className="form-control"
                  value={this.state.old_storage_date}
                  onChange={(event) => {
                    this.setState({ old_storage_date: event.target.value });
                  }}
                />
              </InputGroup>
              {/* <p style={{ color: "#B71C1C", size: 8 }}>
                Storage Date: The date which it has arrived in our warehouse
              </p>*/}

              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text">Other Details</Label>
                </InputGroupAddon>
                <Input
                  type="text"
                  className="form-control"
                  value={this.state.old_other_details}
                  onChange={(event) => {
                    this.setState({ old_other_details: event.target.value });
                  }}
                />
              </InputGroup>
              <p style={{ color: "#B71C1C", size: 8 }}>
                Any extra details you (Optional)
              </p>
            </Col>

            <Col lg="2">
              <div className="text-center">
                <p className="text-muted">
                  Use button below to search for your existing container to edit
                  or to export csv
                </p>
                <button
                  type="button"
                  onClick={() => {
                    const data = {
                      columns: [
                        {
                          label: "Lot#",
                          field: "lot",
                          sort: "asc",
                          width: 150,
                        },
                        {
                          label: "Accepting Date",
                          field: "accepting_date",
                          sort: "asc",
                          width: 270,
                        },
                        {
                          label: "Closing Date",
                          field: "closing_date",
                          sort: "asc",
                          width: 200,
                        },
                        {
                          label: "Departure Date",
                          field: "departure_date",
                          sort: "asc",
                          width: 200,
                        },
                        {
                          label: "THE DAY CARGO ARRIVED AT OUR WAREHOUSE",
                          field: "arrival_date",
                          sort: "asc",
                          width: 200,
                        },

                        {
                          label: "STORAGE BEGINS",
                          field: "storage_date",
                          sort: "asc",
                          width: 200,
                        },

                        {
                          label: "Other Details",
                          field: "other_details",
                          sort: "asc",
                          width: 200,
                        },

                        {
                          label: "Edit",
                          field: "edit",
                          sort: "asc",
                          width: 200,
                        },
                      ],
                      rows: show_to_edit,
                    };

                    var body = (
                      <div>
                        <div className="row mt-12">
                          <div className="col-xl col-sm-12">
                            <div className="text-sm-right">
                              <CSVLink
                                filename={`All_Shippers.csv`}
                                data={export_data}
                              >
                                <Button
                                  type="button"
                                  color="primary"
                                  className="w-md"
                                >
                                  Export All Shippers
                                </Button>
                              </CSVLink>
                            </div>
                          </div>
                        </div>

                        <MDBDataTable responsive striped bordered data={data} />
                      </div>
                    );

                    this.setState({
                      modal_standard: true,
                      body,
                    });
                  }}
                  className="btn btn-primary waves-effect waves-light"
                  data-toggle="modal"
                  data-target="#myModal"
                >
                  Search Existing Containers
                </button>
              </div>
            </Col>
          </Row>
          <div className="text-center">
            <div style={{ padding: 5 }}>
              <Button
                type="submit"
                color="primary"
                onClick={() => {
                  this.updateShipper();
                }}
              >
                Update Container
              </Button>
            </div>

            <div style={{ padding: 5 }}>
              <Button
                type="submit"
                color="primary"
                onClick={() => {
                  this.setState({
                    confirm_both: true,
                  });
                }}
              >
                Delete Container
              </Button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <Row>
        <Col lg="12">
          <div classname="align-me mb-3">
            <Col lg="12" className="mt-4">
              <div className="text-center">
                <p className="text-muted">
                  Use button below to search for your existing shipper to edit
                  or to export csv
                </p>
                <button
                  type="button"
                  onClick={() => {
                    const data = {
                      columns: [
                        {
                          label: "Lot#",
                          field: "lot",
                          sort: "asc",
                          width: 150,
                        },
                        {
                          label: "Accepting Date",
                          field: "accepting_date",
                          sort: "asc",
                          width: 270,
                        },
                        {
                          label: "Closing Date",
                          field: "closing_date",
                          sort: "asc",
                          width: 200,
                        },
                        {
                          label: "Departure Date",
                          field: "departure_date",
                          sort: "asc",
                          width: 200,
                        },
                        {
                          label: "THE DAY CARGO ARRIVED AT OUR WAREHOUSE",
                          field: "arrival_date",
                          sort: "asc",
                          width: 200,
                        },

                        {
                          label: "STORAGE BEGINS",
                          field: "storage_date",
                          sort: "asc",
                          width: 200,
                        },

                        {
                          label: "Other Details",
                          field: "other_details",
                          sort: "asc",
                          width: 200,
                        },

                        {
                          label: "Edit",
                          field: "edit",
                          sort: "asc",
                          width: 200,
                        },
                      ],
                      rows: show_to_edit,
                    };

                    var body = (
                      <div>
                        <div className="row mt-12">
                          <div className="col-xl col-sm-12">
                            <div className="text-sm-right">
                              <CSVLink
                                filename={`All_Shippers.csv`}
                                data={export_data}
                              >
                                <Button
                                  type="button"
                                  color="primary"
                                  className="w-md"
                                >
                                  Export All Shippers
                                </Button>
                              </CSVLink>
                            </div>
                          </div>
                        </div>

                        <MDBDataTable responsive striped bordered data={data} />
                      </div>
                    );

                    this.setState({
                      modal_standard: true,
                      body,
                    });
                  }}
                  className="btn btn-primary waves-effect waves-light"
                  data-toggle="modal"
                  data-target="#myModal"
                >
                  Search Existing Containers
                </button>
              </div>

              <Modal
                isOpen={this.state.modal_standard}
                size="lg"
                toggle={() => {
                  this.setState((prevState) => ({
                    modal_standard: !prevState.modal_standard,
                  }));
                }}
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    List Of All Shippers
                  </h5>
                  <button
                    type="button"
                    onClick={() => this.setState({ modal_standard: false })}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div>{this.state.body}</div>
                </div>
              </Modal>
            </Col>
          </div>
        </Col>
      </Row>
    );
  }
  delete_container() {
    firebase
      .database()
      .ref(`organization/zaincocargo/container/${this.state.old_uid}`)

      .remove()
      .then(() => {
        alert("Container has successfully been removed!");

        this.setState({
          confirm_both: false,
          startDate: new Date(),
          endDate: new Date(),
          activeTab: "1",

          lot: "",
          accepting_date: "",
          closing_date: "",
          departure_date: "",
          arrival_date: "",
          storage_date: "",
          other_details: "",

          old_data: [],

          old_lot: "",
          old_accepting_date: "",
          old_closing_date: "",
          old_departure_date: "",
          old_arrival_date: "",
          old_storage_date: "",
          old_other_details: "",
          old_uid: "",

          body: <div></div>,
          show_alert: false,
          modal_standard: false,
          show_old_data: false,
        });

        this.setState({
          confirm_both: false,
        });

        this.props.container_data_read();
      })
      .catch(() => {
        alert("Oops! There was an error. Please try again!");

        this.setState({
          confirm_both: false,
        });
      });

    this.setState({
      confirm_both: false,
    });

    this.search_fetch();
  }
  render() {
    return (
      <React.Fragment>
        {this.state.confirm_both ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={async () => {
              /******* */
              this.delete_container();
            }}
            onCancel={() =>
              this.setState({
                confirm_both: false,
              })
            }
          ></SweetAlert>
        ) : null}

        {this.state.show_alert ? (
          <SweetAlert
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            showCancel={this.state.showCancel}
            title={this.state.title}
            warning={this.state.warning}
            error={this.state.error}
            success={this.state.success}
            confirmBtnText={this.state.confirm_button_text}
            onConfirm={() => {
              const { type, updated_status, uid } = this.state;
            }}
            onCancel={() => this.setState({ show_alert: false })}
          >
            {this.state.body}
          </SweetAlert>
        ) : null}

        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Shipper Details"
              breadcrumbItem="Shipper Details"
            />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Container Details</CardTitle>

                    <div className="mt-4">
                      <Nav pills className="bg-light rounded" role="tablist">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "1",
                            })}
                            onClick={() => {
                              this.reset();
                              this.toggleTab("1");
                            }}
                          >
                            Add New Container
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "2",
                            })}
                            onClick={() => {
                              this.reset();
                              this.toggleTab("2");
                              this.search_fetch();
                            }}
                          >
                            Edit Existing Container
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent
                        activeTab={this.state.activeTab}
                        className="mt-4"
                      >
                        <TabPane tabId="1" id="buy-tab">
                          {this.tab1()}

                          <div className="text-center">
                            <Button
                              type="submit"
                              color="primary"
                              onClick={() => {
                                this.addShipper();
                              }}
                            >
                              Add Container
                            </Button>
                          </div>
                        </TabPane>
                        <TabPane tabId="2" id="sell-tab">
                          {this.tab2()}
                        </TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

// export default TasksCreate;

const mapStatetoProps = (state) => {
  const {
    user_details,
    error,
    loading,
    shipping_data,
    container_data,
    currency_data,
    rates_data,
    limited_users_data,
  } = state.Database;

  return {
    user_details,
    error,
    loading,
    shipping_data,
    container_data,
    currency_data,
    rates_data,
    limited_users_data,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    user_data_read,
    shipping_data_read,
    container_data_read,
    currency_data_read,
    rates_data_read,
    limited_users_data_read,
    limited_users_logs_data_read,
    detail_error,
  })(withNamespaces()(TasksCreate))
);
