import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
  ModalBody,
  Label,
  Input,
  Form,
  ModalHeader,
  Alert,
} from "reactstrap";

import { withRouter, Link } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";

// Redux
import { connect } from "react-redux";

// actions
import {
  user_data_read,
  // shipping_data_read,
  detail_error,
} from "../../../store/actions";

// users
import firebase from "firebase/app";
import "firebase/database";

// users
// import user1 from "../../../assets/images/users/avatar-1.jpg";

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      name: "",
      password: "",
      re_type_password: "",
      modal: false,
      reLogin: false,
      success: "",
      error: "",
      show_message: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  componentDidMount() {
    // this.initMenu();
    this.props.user_data_read();
    // this.props.shipping_data_read();
    // this.props.upload_data_write();
  }

  onSubmit(event) {
    event.preventDefault();

    var user = firebase.auth().currentUser;
    const { password, re_type_password } = this.state;

    if (password === re_type_password) {
      user
        .updatePassword(password)
        .then(() => {
          // alert("Your password has been updated!");
          this.setState({
            success: "Your password has been updated!",
            show_message: true,
            password: "",
            re_type_password: "",
          });
        })
        .catch((error) => {
          var temp_error = JSON.stringify(error);
          //123456

          if (error.code === "auth/requires-recent-login") {
            this.setState({
              reLogin: true,
              password: "",
              re_type_password: "",
            });
          } else {
            alert(error.message);
          }

          //  alert(error);
        });
    } else {
      this.setState({
        error: "Passwords do not match. Please re-enter again.",
        show_message: true,
        password: "",
        re_type_password: "",
      });
      //  alert("Passwords do not match. Please re-enter again.");
    }
  }
  modal() {
    const { delete_user_data } = this.state;

    return (
      <Modal
        className="outline_class"
        isOpen={this.state.modal}
        role="dialog"
        size="medium"
        autoFocus={true}
        centered={true}
        id="verificationModal"
        tabIndex="-1"
        toggle={this.togglemodal}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              this.setState((prevState) => ({
                modal: !prevState.modal,
                menu: false,
                name: "",
                password: "",
                re_type_password: "",
                modal: false,
                reLogin: false,
                success: "",
                error: "",
                show_message: false,
              }));
            }}
          >
            Change Password
          </ModalHeader>

          <ModalBody>
            <div id="kyc-verify-wizard" className="twitter-bs-wizard">
              <div className="form-group">
                {this.state.reLogin !== true ? (
                  <div>
                    <Form onSubmit={this.onSubmit.bind(this)}>
                      <div>
                        <Label htmlFor="password">New Password : </Label>
                        <Input
                          minlength="6"
                          id="password"
                          placeholder="Enter New Password"
                          className="form-control"
                          required
                          type="password"
                          value={this.state.password}
                          onChange={(event) => {
                            event.preventDefault();
                            this.setState({
                              password: event.target.value,
                            });
                          }}
                        />
                      </div>

                      <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <Label htmlFor="repassword">
                          Re-type New Password :{" "}
                        </Label>
                        <Input
                          minlength="6"
                          id="repassword"
                          placeholder="Enter New Password"
                          className="form-control"
                          required
                          type="password"
                          value={this.state.re_type_password}
                          onChange={(event) => {
                            event.preventDefault();

                            this.setState({
                              re_type_password: event.target.value,
                            });
                          }}
                        />
                      </div>

                      <div>
                        <Button type="submit" color="primary">
                          Change Password
                        </Button>
                      </div>
                    </Form>

                    {/** show_message:true  */}

                    {this.state.show_message === true ? (
                      <div>
                        {this.state.success !== "" ||
                        this.state.error !== "" ? (
                          <div>
                            {this.state.success === "" ? (
                              <div>
                                <Alert
                                  color="danger"
                                  style={{ marginTop: "13px" }}
                                >
                                  {this.state.error}
                                </Alert>
                              </div>
                            ) : (
                              <div>
                                <Alert
                                  color="success"
                                  style={{ marginTop: "13px" }}
                                >
                                  {this.state.success}
                                </Alert>
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    {/* { <p style={{ color: "#B00020" }}>
                      ERROR: This is a sensitive operation which requires you to
                      Re-Login. Please login again and change your password!
                    </p>} */}

                    <Alert color="danger" style={{ marginTop: "13px" }}>
                      This is a sensitive operation which requires you to
                      Re-Login. Please login again and try again!
                    </Alert>

                    <Button
                      onClick={(event) => {
                        event.preventDefault();

                        window.location.href =
                          window.location.protocol +
                          "//" +
                          window.location.host +
                          "/logout";
                      }}
                      color="primary"
                    >
                      Logout and Re-Login
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal>
    );
  }

  componentDidMount() {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        this.setState({ name: obj.displayName });
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        this.setState({ name: obj.username });
      }
    }
  }

  render() {
    const { user_details } = this.props;
    var role = "";
    if (user_details) {
      role = user_details.role;
    }
    return (
      <React.Fragment>
        {this.modal()}
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
            tag="button"
          >
            {/*   <img
              className="rounded-circle header-profile-user"
              src={user1}
              alt="Header Avatar"
         />*/}
            <i className="bx bx-user font-size-20 align-middle mr-1 "></i>

            <span className="d-none d-xl-inline-block ml-2 mr-1">
              {this.state.name}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </DropdownToggle>
          <DropdownMenu right>
            {role === "limited" ? null : (
              <DropdownItem>
                <i className="bx bx-edit-alt font-size-16 align-middle mr-1"></i>
                <a
                  onClick={(event) => {
                    event.preventDefault();
                    this.setState((prevState) => ({
                      modal: !prevState.modal,
                    }));
                  }}
                >
                  <span style={{ color: "black" }}>Change Password</span>
                </a>
                {/*this.props.t("Change Password")*/}
              </DropdownItem>
            )}

            <DropdownItem>
              {/* <i className="bx bx-edit-alt font-size-16 align-middle mr-1"></i>
              <a
                onClick={(event) => {
                  event.preventDefault();
                  this.setState((prevState) => ({
                    modal: !prevState.modal,
                  }));
                }}
              >
         
              </a>

              */}
              <Link to="/add-edit-currency">
                <i
                  style={{ color: "black" }}
                  className="bx bx-dollar font-size-16 align-middle mr-1"
                ></i>

                <span style={{ color: "black" }}>
                  Add or Edit Currency Rates
                </span>
              </Link>
            </DropdownItem>

            <DropdownItem>
              <i className="bx bxs-ship font-size-16 align-middle mr-1"></i>
              <Link to="/add-edit-shipper">
                <span style={{ color: "black" }}>
                  Add or Edit Shipper Details
                </span>
              </Link>
            </DropdownItem>
            <DropdownItem>
              <Link to="/add-edit-consignee">
                <i
                  style={{ color: "black" }}
                  className="mdi mdi-fountain-pen-tip font-size-16 align-middle mr-1"
                ></i>
                <span style={{ color: "black" }}>
                  Add or Edit Consignee Details
                </span>
              </Link>
            </DropdownItem>

            <DropdownItem>
              <Link to="/add-edit-towns">
                <i
                  style={{ color: "black" }}
                  className="mdi mdi-fountain-pen-tip font-size-16 align-middle mr-1"
                ></i>
                <span style={{ color: "black" }}>Add or Edit City</span>
              </Link>
            </DropdownItem>

            {/*} <DropdownItem>
              <Link to="/add-edit-sales">
                <i
                  style={{ color: "black" }}
                  className="mdi mdi-fountain-pen-tip font-size-16 align-middle mr-1"
                ></i>
                <span style={{ color: "black" }}>Add or Edit Salespeople</span>
              </Link>
            </DropdownItem>*/}

            <div className="dropdown-divider"></div>
            <Link to="/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
              <span style={{ color: "black" }}>{this.props.t("Logout")}</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

// export default withRouter(withNamespaces()(ProfileMenu));

const mapStatetoProps = (state) => {
  const { user_details, shipping_data, upload_shipping_data, error, loading } =
    state.Database;

  return {
    user_details,
    shipping_data,
    upload_shipping_data,
    error,
    loading,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    user_data_read,
    // shipping_data_read,
    detail_error,
  })(withNamespaces()(ProfileMenu))
);
