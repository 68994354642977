import {
  //User
  USER_DATA_READ_ASYNC,
  USER_DATA_READ,
  //Shipping
  SHIPPING_DATA_READ_ASYNC,
  SHIPPING_DATA_READ,
  //Container
  CONTAINER_DATA_READ_ASYNC,
  CONTAINER_DATA_READ,
  //Currency Converter
  CURRENCY_DATA_READ_ASYNC,
  CURRENCY_DATA_READ,
  //Rates
  RATES_DATA_READ_ASYNC,
  RATES_DATA_READ,
  //Limited Access Users Zaincocargo
  LIMITED_ACCESS_USER_DATA_READ_ASYNC,
  LIMITED_ACCESS_USER_DATA_READ,
  //Limited Access Users Zaincocargo Logs
  LIMITED_ACCESS_USER_LOGS_DATA_READ_ASYNC,
  LIMITED_ACCESS_USER_LOGS_DATA_READ,
  //
  DETAIL_ERROR,
} from "./actionTypes";

//User
export const user_data_read_async = (payload) => {
  return {
    type: USER_DATA_READ_ASYNC,
    payload: payload,
  };
};

export const user_data_read = () => {
  return {
    type: USER_DATA_READ,
  };
};

//Shipping
export const shipping_data_read_async = (payload) => {
  return {
    type: SHIPPING_DATA_READ_ASYNC,
    payload: payload,
  };
};

export const shipping_data_read = () => {
  return {
    type: SHIPPING_DATA_READ,
  };
};

//Container
export const container_data_read_async = (payload) => {
  return {
    type: CONTAINER_DATA_READ_ASYNC,
    payload: payload,
  };
};

export const container_data_read = () => {
  return {
    type: CONTAINER_DATA_READ,
  };
};

//Currency Converter
export const currency_data_read_async = (payload) => {
  return {
    type: CURRENCY_DATA_READ_ASYNC,
    payload: payload,
  };
};

export const currency_data_read = () => {
  return {
    type: CURRENCY_DATA_READ,
  };
};

//Rates
export const rates_data_read_async = (payload) => {
  return {
    type: RATES_DATA_READ_ASYNC,
    payload: payload,
  };
};

export const rates_data_read = () => {
  return {
    type: RATES_DATA_READ,
  };
};

//Limited Access Users Zaincocargo
export const limited_users_data_read_async = (payload) => {
  return {
    type: LIMITED_ACCESS_USER_DATA_READ_ASYNC,
    payload: payload,
  };
};

export const limited_users_data_read = () => {
  return {
    type: LIMITED_ACCESS_USER_DATA_READ,
  };
};

//Limited Access Users Zaincocargo Logs
export const limited_users_logs_data_read_async = (payload) => {
  return {
    type: LIMITED_ACCESS_USER_LOGS_DATA_READ_ASYNC,
    payload: payload,
  };
};

export const limited_users_logs_data_read = () => {
  return {
    type: LIMITED_ACCESS_USER_LOGS_DATA_READ,
  };
};

/*  */

export const detail_error = (error) => {
  return {
    type: DETAIL_ERROR,
    payload: error,
  };
};
