import _ from "lodash";
import React, { Component } from "react";
import Lottie from "react-lottie";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Label,
  Form,
  Button,
  ModalHeader,
  Modal,
  ModalBody,
  Input,
  Alert,
  Card,
  CardBody,
  FormGroup,
  CardTitle,
  Badge,
} from "reactstrap";
import firebase from "firebase/app";
import "firebase/database";
import "./CSS/access.css";
import SweetAlert from "react-bootstrap-sweetalert";

//i18n
import { withNamespaces } from "react-i18next";

// Redux
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
// import { access_read } from "../../store/actions";

/************* */

//i18n

// Redux
import {
  user_data_read,
  detail_error,
  limited_users_data_read,
  limited_users_logs_data_read,
} from "../../store/actions";

/*********** */
class Access extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      activeTab: "1",
      specific_event_name: "",
      filter_by_specific_data: "",
      filter_by_all_data: "",
      last_filter_for_specific: "",
      last_filter_for_all: "",
      modal: false,
      modal_sure_delete: false,
      delete_user_data: {},
      full_name: "",
      email: "",
      loading_modal: false,
      error: "",
      success: "",
      limited_access_users: [],
      modal_options: false,
      transaction: {},
    };
  }
  // componentDidMount() {
  //   this.props.limited_users_data_read();
  // }
  renderModal() {
    var limited_access_users = _.map(
      this.props.limited_users_data,
      (val, uid) => {
        return { ...val, uid };
      }
    );

    return (
      <Modal
        className="outline_class"
        isOpen={this.state.modal}
        role="dialog"
        size="medium"
        autoFocus={true}
        centered={true}
        id="verificationModal"
        tabIndex="-1"
        toggle={this.togglemodal}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              this.setState((prevState) => ({
                modal: !prevState.modal,
              }));
            }}
          >
            User Creation
          </ModalHeader>

          {this.state.loading_modal === false ? (
            <ModalBody>
              <div id="kyc-verify-wizard" className="twitter-bs-wizard">
                <div className="form-group">
                  {this.state.success !== "" || this.state.error !== "" ? (
                    <div>
                      {this.state.success === "" ? (
                        <div>
                          <Alert color="danger" style={{ marginTop: "13px" }}>
                            {this.state.error}
                          </Alert>

                          <div
                            className="text-center"
                            onClick={(event) => {
                              event.preventDefault();
                              this.setState((prevState) => ({
                                modal: !prevState.modal,

                                specific_event_name: "",
                                filter_by_specific_data: "",
                                filter_by_all_data: "",
                                last_filter_for_specific: "",
                                last_filter_for_all: "",
                                modal_sure_delete: false,
                                delete_user_data: {},
                                full_name: "",
                                email: "",
                                loading_modal: false,
                                error: "",
                                success: "",
                              }));
                            }}
                          >
                            <Button color="primary">Okay</Button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <Alert color="success" style={{ marginTop: "13px" }}>
                            {this.state.success}
                          </Alert>
                          <div className="text-center">
                            <Button
                              color="primary"
                              onClick={(event) => {
                                event.preventDefault();
                                // this.props.access_reader_request();
                                // this.props.access_read();

                                this.setState((prevState) => ({
                                  modal: !prevState.modal,
                                  specific_event_name: "",
                                  filter_by_specific_data: "",
                                  filter_by_all_data: "",
                                  last_filter_for_specific: "",
                                  last_filter_for_all: "",
                                  modal_sure_delete: false,
                                  delete_user_data: {},
                                  full_name: "",
                                  email: "",
                                  loading_modal: false,
                                  error: "",
                                  success: "",
                                }));
                              }}
                            >
                              Okay
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Form>
                      <div>
                        <Label htmlFor="formname">Full Name : </Label>
                        <Input
                          type="text"
                          id="formname"
                          placeholder="Enter Full Name"
                          className="form-control"
                          required
                          onChange={(event) => {
                            event.preventDefault();
                            this.setState({
                              full_name: event.target.value,
                            });
                          }}
                        />
                      </div>

                      <div style={{ paddingTop: 10 }}>
                        <Button
                          type="submit"
                          color="primary"
                          onClick={(event) => {
                            event.preventDefault();

                            var userId = firebase.auth().currentUser.uid;
                            const { full_name } = this.state;

                            if (full_name !== "") {
                              var username = (
                                full_name.replace(/\s/g, "_") +
                                "@zaincocargo.com"
                              ).toLowerCase();

                              var password =
                                Math.floor(Math.random() * (1000 - 100 + 1)) +
                                100 +
                                full_name.replace(/\s/g, "") +
                                (Math.floor(Math.random() * (1000 - 100 + 1)) +
                                  100);
                              limited_access_users.forEach((element) => {
                                if (element.username === username) {
                                  username = (
                                    full_name.replace(/\s/g, "_") +
                                    (limited_access_users.length + 1) +
                                    "@zaincocargo.com"
                                  ).toLowerCase();
                                }
                              });

                              firebase
                                .database()
                                .ref(
                                  `/users/${userId}/limited_access_creation/`
                                )
                                .push({
                                  full_name,
                                  username,
                                  password,
                                })
                                .then(() => {
                                  alert(
                                    "Successful! User is being created. It may take a few seconds before you see changes."
                                  );
                                  // this.props.limited_users_data_read();

                                  setTimeout(() => {
                                    this.setState({ modal: false });

                                    // window.location.reload(false);
                                    this.props.limited_users_data_read();
                                  }, 1000);
                                })
                                .catch(() => {
                                  alert(
                                    "Oops! There was an error creating the user. Please try again!"
                                  );
                                });
                            } else {
                              alert("Please ensure all field are filled.");
                            }
                          }}
                        >
                          Create User
                        </Button>
                      </div>
                    </Form>
                  )}
                </div>
              </div>
            </ModalBody>
          ) : (
            <div> {this.render_loading()}</div>
          )}
        </div>
      </Modal>
    );
  }
  renderSweetAlert() {
    if (this.state.modal_options) {
      return (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={async () => {
            var userId = firebase.auth().currentUser.uid;

            firebase
              .database()
              .ref(`/users/${userId}/limited_access_deletion/`)
              .push(this.state.transaction)
              .then(() => {
                alert(
                  "Successful! User is being Deleted. It may take a few seconds before you see changes."
                );
                // window.location.reload(false);
                this.props.limited_users_data_read();

                setTimeout(() => {
                  this.setState({
                    modal_options: false,
                  });

                  // window.location.reload(false);
                  this.props.limited_users_data_read();
                }, 1000);
              })
              .catch(() => {
                this.setState({
                  modal_options: false,
                });

                alert(
                  "Oops! There was an error deleting the user. Please try again!"
                );
              });

            // }, 1000);
          }}
          onCancel={() => {
            this.setState({
              modal_options: false,
            });
          }}
        >
          <div className="dropzone-previews mt-3" id="file-previews">
            <div className="p-2">
              <Row className="align-items-center">
                <p className="mb-0">
                  All logs related to this use will also be deleted.
                </p>
              </Row>
            </div>
          </div>
        </SweetAlert>
      );
    } else {
      return null;
    }
  }
  render() {
    const { limited_users_data } = this.props;

    var limited_access_users = _.map(limited_users_data, (val, uid) => {
      return { ...val, uid };
    });

    // console.log(limited_access_users);
    return (
      <React.Fragment>
        {this.renderModal()}

        {this.renderSweetAlert()}

        <Card>
          <CardBody>
            <CardTitle className="mb-4">
              <div id="textbox">
                <p className="alignleft">User Access</p>
                <div className="alignright" style={{ paddingBottom: 10 }}>
                  <Button
                    type="button"
                    color="primary"
                    className="btn-md"
                    onClick={() => {
                      this.setState((prevState) => ({
                        modal: !prevState.modal,
                      }));
                    }}
                  >
                    Create Account (Limited Access Accounts)
                  </Button>
                </div>
              </div>
            </CardTitle>
            <div className="table-responsive">
              <table className="table table-centered table-nowrap mb-0">
                <thead className="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Username</th>
                    <th>Password</th>
                    <th>Delete Account</th>
                  </tr>
                </thead>

                <tbody>
                  {limited_access_users.map((transaction, key) => {
                    return (
                      <tr key={"_tr_" + key}>
                        <td>{key + 1}</td>

                        <td>{transaction.name}</td>
                        <td>{transaction.username}</td>
                        <td>{transaction.password}</td>
                        <td>
                          {" "}
                          <Button
                            type="button"
                            color="primary"
                            className="btn-md"
                            onClick={() => {
                              this.setState((prevState) => ({
                                modal_options: !prevState.modal,
                                transaction,
                              }));
                            }}
                          >
                            Delete Account
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

// export default Access;
const mapStatetoProps = (state) => {
  const {
    user_details,
    error,
    // loading,
    // limited_users_data,
    // limited_users_logs_data,
  } = state.Database;

  return {
    user_details,
    error,
    // loading,
    // limited_users_data,
    // limited_users_logs_data,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    user_data_read,
    detail_error,
    limited_users_data_read,
    limited_users_logs_data_read,
  })(withNamespaces()(Access))
);
