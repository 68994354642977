import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFViewer,
  BlobProvider,
  pdf,
  PDFDownloadLink,
} from "@react-pdf/renderer";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Label,
  Input,
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  InputGroupAddon,
  InputGroup,
  Modal,
  Form,
  Button,
} from "reactstrap";
import { MDBDataTable } from "mdbreact";

import classnames from "classnames";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import logo from "../../assets/images/logo-dark.png";

import firebase from "firebase/app";
import "firebase/database";
import moment from "moment";
import moment_time_zone from "moment-timezone";

/***/
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { shipping_data_read, detail_error } from "../../store/actions";
import Lottie from "react-lottie";
/***/
import vat_arabic from "../../assets/images/VAT_Arabic.png"; //"../../../assets/images/VAT_Arabic.png";

class ReportOfPayment extends Component {
  constructor() {
    super();
    this.state = {
      activeTab: "1",
      data_by_lot_no: [],
      start_date: "",
      end_date: "",
      ready: [], //false,
      data_to_download_pdf: {},
      results: {},
      old_container_data: [],

      conversion_selected: {},
      invoice_details: {},
    };
  }

  toggle(i) {
    var temp_ready = this.state.ready;
    temp_ready[i] = false;

    this.setState(
      {
        ready: temp_ready,
      },
      () => {
        setTimeout(() => {
          var second_temp = this.state.ready;
          second_temp[i] = true;
          this.setState({ ready: second_temp });
        }, 1);
      }
    );
  }

  table_titles() {
    return (
      <View
        style={{
          flexDirection: "row",
          borderBottomColor: "black",
          borderBottomWidth: 1,
          alignItems: "center",
          height: 24,
          textAlign: "center",
          fontStyle: "bold",
        }}
      >
        <View
          style={{
            width: "25%",
            borderRightColor: "black",
            borderRightWidth: 1,
            backgroundColor: "#E0E0E0",
            paddingTop: 2,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
            }}
          >
            DESCRIPTION
          </Text>
        </View>

        <View
          style={{
            width: "15%",
            borderRightColor: "black",
            borderRightWidth: 1,
            backgroundColor: "#E0E0E0",
            paddingTop: 2,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
            }}
          >
            PCS
          </Text>
        </View>

        <View
          style={{
            width: "15%",
            borderRightColor: "black",
            borderRightWidth: 1,
            backgroundColor: "#E0E0E0",
            paddingTop: 2,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
            }}
          >
            WEIGHT
          </Text>
        </View>

        <View
          style={{
            width: "15%",
            borderRightColor: "black",
            borderRightWidth: 1,
            backgroundColor: "#E0E0E0",
            paddingTop: 2,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
            }}
          >
            RATE
          </Text>
        </View>

        <View
          style={{
            width: "15%",
            borderRightColor: "black",
            borderRightWidth: 1,
            backgroundColor: "#E0E0E0",
            paddingTop: 2,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
            }}
          >
            AMOUNT
          </Text>
        </View>

        <View
          style={{
            width: "15%",
            borderRightColor: "black",
            borderRightWidth: 1,
            backgroundColor: "#E0E0E0",
            paddingTop: 2,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
            }}
          >
            REMARKS
          </Text>
        </View>
      </View>
    );
  }

  adjust_rate() {
    const { rates_data_extracted, cargo_details } = this.state;

    var rate = 0;
    var total_weight = 0,
      total_quantity = 0;

    cargo_details.forEach((element) => {
      if (element.quantity !== "") {
        total_quantity = total_quantity + parseFloat(element.quantity);
      }
      if (element.weight !== "") {
        total_weight = total_weight + parseFloat(element.weight);
      }
    });

    var rates = {};
    if (rates_data_extracted.rates_by_destination !== undefined) {
      var rates_by_destination = _.map(
        rates_data_extracted.rates_by_destination,
        (val, uid) => {
          return { ...val, uid };
        }
      );

      rates_by_destination.forEach((element) => {
        if (this.state.consignee_town === element.city) {
          rates = element;
        }
      });
    }

    if (total_weight < 50) {
      rate = rates.less_than_50_kg;
    } else if (total_weight >= 50 && total_weight < 100) {
      rate = rates.less_than_100_kg;
    } else {
      rate = rates.over_100_kg;
    }

    if (rate !== 0) {
      var new_cargo_cargo_details = [];
      cargo_details.forEach((element) => {
        var edit_element = element;
        edit_element.rate = rate;
        new_cargo_cargo_details.push(edit_element);
      });

      return new_cargo_cargo_details;
    } else {
      return cargo_details;
    }
  }
  line_items() {
    const { invoice_details } = this.state;
    var data = [];
    if (Object.keys(invoice_details).length > 0) {
      var cargo_details = invoice_details.cargo_details;

      cargo_details.forEach((element, i) => {
        data.push(
          <View
            style={{
              flexDirection: "row",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              alignItems: "center",
              height: 24,
              fontStyle: "bold",
            }}
            key={i}
          >
            <Text
              style={{
                width: "25%",
                textAlign: "left",
                borderBottomColor: "black",
                borderRightWidth: 1,
                paddingLeft: 8,
                fontSize: 9,
              }}
            >
              {element.description}
            </Text>
            <Text
              style={{
                width: "15%",
                borderBottomColor: "black",
                borderRightWidth: 1,
                textAlign: "center",
                paddingRight: 8,
                fontSize: 9,
              }}
            >
              {element.quantity}
            </Text>
            <Text
              style={{
                width: "15%",
                borderBottomColor: "black",
                borderRightWidth: 1,
                textAlign: "center",
                paddingRight: 8,
                fontSize: 9,
              }}
            >
              {element.weight}
            </Text>
            <Text
              style={{
                width: "15%",
                textAlign: "center",
                borderBottomColor: "black",
                borderRightWidth: 1,
                paddingRight: 8,
                fontSize: 9,
              }}
            >
              {element.rate}
            </Text>
            <Text
              style={{
                width: "15%",
                textAlign: "center",
                borderBottomColor: "#000",
                borderRightWidth: 1,

                paddingRight: 8,
                fontSize: 9,
                paddingLeft: 5,
              }}
            >
              {parseFloat(element.rate) * parseFloat(element.weight)}
            </Text>

            <Text
              style={{
                width: "14%",
                textAlign: "left",
                paddingRight: 8,
                fontSize: 8,
                paddingLeft: 5,
              }}
            >
              {/*Add Remarks*/}
            </Text>
          </View>
        );
      });
    }

    return data;
  }

  table_last_line() {
    const { data_for_reciept, invoice_details } = this.state;

    var view_to_show = <View></View>;

    if (invoice_details !== null) {
      if (Object.keys(invoice_details).length > 0) {
        var payments_details = invoice_details.payments_details;
        var calculations = invoice_details.calculations;
        var full_detail = invoice_details.full_detail;

        var payments_showing_array = [];

        payments_details.forEach((element, i) => {
          var date_to_show = moment_time_zone(element.date)
            .tz("Etc/GMT")
            .format("DD/MM/YYYY");

          payments_showing_array.push(
            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontSize: 12,
                fontStyle: "bold",
              }}
            >
              <Text
                style={{
                  width: "83.75%",
                  textAlign: "right",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingRight: 8,
                  fontSize: 12,
                }}
              >
                Payment #{i + 1}({date_to_show})
              </Text>
              <Text
                style={{
                  width: "16.25%",
                  textAlign: "left",
                  paddingRight: 8,
                  fontSize: 10,
                  paddingLeft: 5,
                }}
              >
                {parseFloat(element.amount).toFixed(2)}
              </Text>
            </View>
          );
        });

        var total_pieces = 0;
        var total_weight = 0;
        var rate = 0;

        if (full_detail.cargo_details !== undefined) {
          full_detail.cargo_details.forEach((element) => {
            if (element !== undefined) {
              if (element.quantity !== "") {
                total_pieces = total_pieces + parseFloat(element.quantity);
              }
              if (element.weight !== "") {
                total_weight = total_weight + parseFloat(element.weight);
              }

              rate = element.rate;
            }
          });
        }

        var storage_fee_sr = "0",
          penalty_fee = "0";
        if (full_detail.calculations.storage_fee_sr !== undefined) {
          if (full_detail.calculations.storage_fee_sr !== "") {
            storage_fee_sr = full_detail.calculations.storage_fee_sr;
          }
        }

        if (full_detail.calculations.penalty_fee !== undefined) {
          if (full_detail.calculations.penalty_fee !== "") {
            penalty_fee = full_detail.calculations.penalty_fee;
          }
        }

        var total_outstanding_balance_with_fees = 0,
          total_outstanding_balance_with_fees_kse = 0;

        total_outstanding_balance_with_fees =
          parseFloat(full_detail.calculations.total_outstanding_balance) +
          parseFloat(storage_fee_sr) +
          parseFloat(penalty_fee);

        total_outstanding_balance_with_fees_kse =
          parseFloat(full_detail.calculations.converted_price) +
          parseFloat(storage_fee_sr) *
            full_detail.calculations.conversion_selected_rate +
          parseFloat(penalty_fee) *
            full_detail.calculations.conversion_selected_rate;

        total_outstanding_balance_with_fees_kse =
          total_outstanding_balance_with_fees_kse.toFixed(2);
        total_outstanding_balance_with_fees_kse =
          total_outstanding_balance_with_fees_kse
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        total_outstanding_balance_with_fees =
          total_outstanding_balance_with_fees.toFixed(2);

        total_outstanding_balance_with_fees =
          total_outstanding_balance_with_fees
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        view_to_show = (
          <View>
            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  TOTAL
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {total_pieces}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {total_weight}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {rate}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {parseFloat(full_detail.calculations.total_price).toFixed(2)}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  -
                </Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  VAT
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {full_detail.calculations.vat_charges}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  HBL FEES
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {parseFloat(full_detail.other_charges.hbl).toFixed(2)}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  PACKING
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {parseFloat(
                    full_detail.other_charges.packing_charges
                  ).toFixed(2)}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  TRANSPORT
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {parseFloat(
                    full_detail.other_charges.transport_charges
                  ).toFixed(2)}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  MISCELLANEOUS
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {parseFloat(
                    full_detail.other_charges.miscellaneous_charges
                  ).toFixed(2)}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  PENALTY
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {full_detail.calculations.penalty_fee}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  STORAGE
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {full_detail.calculations.storage_fee_sr}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                {full_detail.calculations.storage_fee_sr === 0 ? (
                  <Text
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                  ></Text>
                ) : (
                  <Text
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                  >
                    {full_detail.calculations.penalty_days} DAYS
                  </Text>
                )}
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  TOTAL PAYABLE SR.
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {total_outstanding_balance_with_fees}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  KSH
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",

                  backgroundColor: "#E0E0E0",

                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {full_detail.calculations.conversion_selected_rate}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {total_outstanding_balance_with_fees_kse}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>
          </View>
        );

        //
      }
    }

    return view_to_show;
  }

  company_details() {
    return (
      <View
        style={{
          paddingTop: 5,
          paddingTop: 5,
          flexDirection: "row",
          flexWrap: "wrap",
          borderWidth: 1,
          borderColor: "#FFF",
        }}
      >
        <View
          style={{
            // width: "50%",
            width: "100%",
            // textAlign: "left",
            borderWidth: 1,
            borderColor: "#000",
          }}
        >
          <View style={{ padding: 5 }}>
            <View
              style={{ paddingBottom: 5, paddingTop: 5, alignItems: "center" }}
            >
              <Image
                style={{
                  width: 125,
                }}
                src={logo}
              />
            </View>

            <Text style={{ fontSize: 10, textAlign: "center" }}>
              info@doqli.com
            </Text>
            <Text style={{ fontSize: 10, textAlign: "center" }}>
              www.doqli.com
            </Text>
            <Text style={{ fontSize: 10, textAlign: "center" }}>
              Jeddah: 053-2303902, 055-4573323 || Riyadh: 050-3682739,
              055-4924417, 055-3444479
            </Text>
          </View>
        </View>
      </View>
    );
  }
  shipper_consignee_details({ shipper_details, consignee_details }) {
    return (
      <View
        style={{
          // paddingTop: 10,
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <View
          style={{
            borderWidth: 1,
            borderColor: "#000",
            width: "50%",
          }}
        >
          <View>{shipper_details}</View>
        </View>
        <View
          style={{
            borderWidth: 1,
            borderColor: "#000",
            borderLeftColor: "white",
            width: "50%",
          }}
        >
          <View>{consignee_details}</View>
        </View>
      </View>
    );
  }

  hbl_details(invoice_details) {
    var closing_date = "";

    var status = "Processing";
    if (invoice_details.status !== undefined) {
      status = invoice_details.status;
    }

    const { container_data } = this.props;

    var old_container_data = _.map(container_data, (val, uid) => {
      return { ...val, uid };
    });

    old_container_data.forEach((element) => {
      if (invoice_details.Lot_No === element.lot) {
        closing_date = element.closing_date;
      }
    });
    return (
      <View>
        <View style={{ paddingTop: 5 }}>
          <View
            style={{
              borderColor: "black",
              borderWidth: 1,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  HBL
                </Text>
              </View>

              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  DATE
                </Text>
              </View>

              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  LOT NO.
                </Text>
              </View>

              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  STATUS
                </Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  {invoice_details.hbl_number}
                </Text>
              </View>

              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  {invoice_details.date}
                </Text>
              </View>

              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  {invoice_details.Lot_No}
                </Text>
              </View>

              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  {status}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }

  payment_details(invoice_details) {
    var payment_details = [];

    var invoice_numbers = [];

    if (invoice_details.full_detail !== undefined) {
      payment_details = invoice_details.full_detail.payments_details;

      invoice_numbers = invoice_details.full_detail.invoice_numbers;
    }
    var full_detail = invoice_details.full_detail;

    var show_payment_details = [];

    if (payment_details.length > 0) {
      payment_details.forEach((element, i) => {
        var show_invoice_number = "";
        // if (invoice_numbers[i] !== undefined) {
        //   show_invoice_number = invoice_details.invoice_number; //invoice_numbers[i].invoice;
        // }

        if (element.payment_receipt_no !== undefined) {
          show_invoice_number = element.payment_receipt_no;
        }

        var formated_amount = "-";
        var formated_date = "-";

        if (element.date !== "") {
          formated_date = element.date;
        }
        if (element.amount !== "") {
          formated_amount = parseFloat(element.amount)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        show_payment_details.push(
          <View
            style={{
              flexDirection: "row",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              alignItems: "center",
              height: 24,
              fontStyle: "bold",
            }}
          >
            <View
              style={{
                width: "25%",
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 10,
                  paddingLeft: 10,
                  fontWeight: "bold",
                }}
              >
                {i + 1}
              </Text>
            </View>

            <View
              style={{
                width: "25%",
                borderRightColor: "black",
                borderRightWidth: 1,

                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {show_invoice_number}
              </Text>
            </View>

            <View
              style={{
                width: "25%",
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {formated_date}
              </Text>
            </View>

            <View
              style={{
                width: "25%",
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {formated_amount}
              </Text>
            </View>
          </View>
        );
      });
    }
    return (
      <View>
        <View
          style={{
            borderWidth: 1,
            borderColor: "#000",
            width: "100%",
          }}
        >
          <View style={{ paddingTop: 5, paddingRight: 5 }}>
            <Text style={{ paddingLeft: 5, fontSize: 10 }}>
              PAYMENT DETAILS
            </Text>
          </View>
        </View>

        <View style={{ paddingTop: 5 }}>
          <View
            style={{
              borderColor: "black",
              borderWidth: 1,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  No.
                </Text>
              </View>

              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  PAYMENT RECEIPT NO.
                </Text>
              </View>

              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  DATE
                </Text>
              </View>

              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  AMOUNT
                </Text>
              </View>
            </View>

            {show_payment_details}
          </View>
        </View>
      </View>
    );
  }
  contact_details() {
    const { vat_no, mobile } = this.props;

    return (
      <View>
        <View
          style={{
            borderWidth: 1,
            borderColor: "#000",
            width: "100%",
          }}
        >
          <View style={{ paddingTop: 5, paddingRight: 5 }}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                height: 45,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "50%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  VAT NO. {vat_no}
                </Text>
              </View>

              <View
                style={{
                  width: "50%",
                }}
              >
                <View
                  style={{
                    paddingBottom: 5,
                    paddingTop: 5,
                    alignItems: "right",
                  }}
                >
                  <Image
                    style={{
                      width: 150,
                    }}
                    src={vat_arabic}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    );

    /* return (
      <View>
        <View
          style={{
            borderWidth: 1,
            borderColor: "#000",
            width: "100%",
          }}
        >
          <View style={{ paddingTop: 5, paddingRight: 5 }}>
            <Text style={{ paddingLeft: 5, fontSize: 10 }}>
              VAT NO. {vat_no}
            </Text>

            <View
              style={{ paddingBottom: 5, paddingTop: 5, alignItems: "center" }}
            >
              <Image
                style={{
                  width: 150,
                }}
                src={vat_arabic}
              />
            </View>
          </View>
        </View>
      </View>
    );*/
    // return (
    //   <View>
    //     <View
    //       style={{
    //         borderWidth: 1,
    //         borderColor: "#000",
    //         width: "100%",
    //       }}
    //     >
    //       <View style={{ paddingTop: 5, paddingRight: 5 }}>
    //         <Text style={{ paddingLeft: 5, fontSize: 10 }}>
    //           MOBILE {mobile} JEDDAH, KING KHALID STREET VAT NO.
    //           {vat_no}
    //         </Text>
    //       </View>
    //     </View>
    //   </View>
    // );
  }
  total_charges_payments_outstanding(invoice_details) {
    var full_detail = invoice_details.full_detail;

    var converted_total_charges = 0,
      converted_total_payments = 0,
      converted_total_outstanding = 0,
      total_charges = 0,
      total_outstanding_balance = 0,
      total_payments = 0;
    if (full_detail !== undefined) {
      total_payments = parseFloat(full_detail.calculations.total_payments)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      total_charges = parseFloat(full_detail.calculations.total_charges)
        .toFixed(2)

        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      converted_total_charges = (
        parseFloat(full_detail.calculations.total_charges) *
        parseFloat(full_detail.calculations.conversion_selected_rate)
      )
        .toFixed(2)

        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      converted_total_payments = (
        parseFloat(full_detail.calculations.total_payments) *
        parseFloat(full_detail.calculations.conversion_selected_rate)
      )
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      total_outstanding_balance = parseFloat(
        full_detail.calculations.total_outstanding_balance
      )
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      converted_total_outstanding = (
        parseFloat(full_detail.calculations.total_outstanding_balance) *
        full_detail.calculations.conversion_selected_rate
      )

        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return (
      <View style={{ paddingTop: 15 }}>
        <View
          style={{
            borderColor: "black",
            borderWidth: 1,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              alignItems: "center",
              height: 24,
              fontStyle: "bold",
            }}
          >
            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 10,
                  paddingLeft: 10,
                  fontWeight: "bold",
                }}
              ></Text>
            </View>

            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                SAUDI RIYALS
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                KENYA SHILLINGS
              </Text>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              alignItems: "center",
              height: 24,
              fontStyle: "bold",
            }}
          >
            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 10,
                  paddingLeft: 10,
                  fontWeight: "bold",
                }}
              >
                TOTAL CHARGES
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {total_charges}
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {converted_total_charges}
              </Text>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              alignItems: "center",
              height: 24,
              fontStyle: "bold",
            }}
          >
            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 10,
                  paddingLeft: 10,
                  fontWeight: "bold",
                }}
              >
                TOTAL PAYMENTS
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {total_payments}
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {converted_total_payments}
              </Text>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              alignItems: "center",
              height: 24,
              fontStyle: "bold",
            }}
          >
            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
                backgroundColor: "#E0E0E0",
              }}
            >
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 10,
                  paddingLeft: 10,
                  fontWeight: "bold",
                }}
              >
                OUTSTANDING
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
                backgroundColor: "#E0E0E0",
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {total_outstanding_balance}
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
                backgroundColor: "#E0E0E0",
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {converted_total_outstanding}
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  }

  banking_details() {
    const {
      rajhi_bank_account,
      ncb_bank_account,
      inma_bank_account,
      gulf_african_bank,
      mpesa,
      stcpay,
      vat_no,
      mobile,
    } = this.props;

    return (
      <View style={{ paddingTop: 25 }}>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5, fontWeight: "bold" }}>
                RAJHI BANK ACCOUNT
              </Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 11, paddingLeft: 5 }}>
                {rajhi_bank_account}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5, fontWeight: "bold" }}>
                NCB BANK ACCOUNT
              </Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 11, paddingLeft: 5 }}>
                {ncb_bank_account}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            // paddingTop: 50,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5, fontWeight: "bold" }}>
                INMA BANK ACCOUNT
              </Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 11, paddingLeft: 5 }}>
                {inma_bank_account}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5, fontWeight: "bold" }}>
                GULF AFRICAN BANK
              </Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 11, paddingLeft: 5 }}>
                {gulf_african_bank}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5, fontWeight: "bold" }}>
                MPESA
              </Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 11, paddingLeft: 5 }}> {mpesa} </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5 }}>STCPAY</Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5, paddingLeft: 5 }}>
              <Text style={{ fontSize: 11 }}>{stcpay}</Text>
            </View>
          </View>
        </View>
      </View>
    );
  }

  desclaimer_other_details() {
    return (
      <View style={{ paddingTop: 25 }}>
        <View
          style={{
            // paddingTop: 50,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "5%",
              paddingLeft: 5,
              borderRightColor: "white",
              borderBottomColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text>1.</Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",

              width: "95%",
              borderLeftColor: "white",
              borderBottomColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 10 }}>
                Freight must be paid upfront during pickup of the packages or
                before arrival at ZAINCO warehouse Mombasa. A penalty of Sr 50
                will be levied on any HBL that has outstanding freight.
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            // paddingTop: 50,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "5%",
              paddingLeft: 5,
              borderRightColor: "white",
              borderTopColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text>2.</Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "95%",
              borderTopColor: "white",
              borderLeftColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 10 }}>
                Free time of 10 days from the date the packages arrives Mombasa
                warehouse, after which a tariff of Ksh. 1.50 or equivalent to
                0.0536 Saudi Riyals per kilo per day is applicable upto and
                until the day all charges are paid in full.
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  }
  show_report() {
    var details = {};
    const { data_for_reciept, invoice_details } = this.state;
    var size = Object.keys(invoice_details).length;

    var s_name = "",
      s_address = "",
      s_town_country = "",
      s_phone = "",
      c_address = "",
      c_town_country = "",
      c_phone = "",
      c_name = "",
      hbl_number = "",
      date = "",
      invoice_number = "",
      c_delivery_town = "",
      s_town = "";

    if (size > 0) {
      s_name = invoice_details.s_name;
      s_town_country = invoice_details.s_town_country;
      s_phone = invoice_details.s_phone;
      c_town_country = invoice_details.c_town_country;
      c_phone = invoice_details.c_phone;
      c_name = invoice_details.c_name;
      hbl_number = invoice_details.hbl_number;
      date = invoice_details.date;
      invoice_number = invoice_details.invoice_number;
      s_town = invoice_details.full_detail.shipper_details.town;
      c_delivery_town =
        invoice_details.full_detail.consignee_details.consignee_delivery;
    }

    var title = "ZAINCO INVOICE";
    var shipper_details = (
      <View style={{ padding: 5 }}>
        <Text
          style={{
            // marginTop: 20,

            paddingBottom: 3,
            fontFamily: "Helvetica-Oblique",
            fontSize: 10,
          }}
        >
          Shipper Details:
        </Text>
        <Text style={{ fontSize: 10 }}>{s_name}</Text>
        <Text style={{ fontSize: 10 }}>{s_town}</Text>
        <Text style={{ fontSize: 10 }}>{s_town_country}</Text>
        <Text style={{ fontSize: 10 }}>{s_phone}</Text>
      </View>
    );

    var consignee_details = (
      <View style={{ padding: 5 }}>
        <Text
          style={{
            // marginTop: 20,
            paddingBottom: 3,
            fontFamily: "Helvetica-Oblique",
            fontSize: 10,
          }}
        >
          Consignee Details:
        </Text>
        <Text style={{ fontSize: 10 }}>{c_name}</Text>
        <Text style={{ fontSize: 10 }}>{c_delivery_town}</Text>
        <Text style={{ fontSize: 10 }}>{c_town_country}</Text>
        <Text style={{ fontSize: 10 }}>{c_phone}</Text>
      </View>
    );

    var table_titles = this.table_titles();

    var line_items = this.line_items();

    var table_last_line = this.table_last_line();

    return (
      <Document>
        <Page
          size="A4"
          style={{
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35,
          }}
        >
          <View>{this.company_details()}</View>

          <View style={{ paddingTop: 10 }}>
            {this.hbl_details(invoice_details)}
          </View>

          <View style={{ paddingTop: 10 }}>
            {this.shipper_consignee_details({
              shipper_details,
              consignee_details,
            })}
          </View>
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              marginTop: 24,
              borderWidth: 1,
              borderColor: "black",
            }}
          >
            {table_titles}

            {line_items}
            {table_last_line}
          </View>
          <View style={{ paddingTop: 10 }}>
            {this.payment_details(invoice_details)}
            {this.total_charges_payments_outstanding(invoice_details)}

            <View style={{ paddingTop: 10 }}>
              {this.contact_details()}
              {this.banking_details()}
              {this.desclaimer_other_details()}
            </View>
          </View>
        </Page>

        <Page
          size="A4"
          style={{
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35,
          }}
        >
          {this.terms_and_conditions()}
        </Page>
      </Document>
    );
  }
  terms_and_conditions() {
    var terms = [
      "Packages and Items to be shipped must be well packed and have to be in good condition and labelled all contacts. Shipper agrees. to make him/her responsible for the standard of packing. In case packing done by ZAINCO staff the company does not take any responsibility for the packing. All fragile materials must be packed with Styrofoam.",
      "Fragile Items such as Electronics, Cutlery, Home Appliances etc. in case of breakage ZAINCO will not take any responsibility whatso ever and will not compensate. Wooden box is a must for all home appliance to safe your goods.",
      "Cash, Gold, Ammunitions, Dangerous goods, Chemicals etc are not allowed at any time. Goods with fluids must be indicated clearly by shipper and in the case of damages caused by goods shipper/consignee will be liable for all the damages caused.",
      "ZAINCO will deliver the aforesaid shipment to your door step as they were received not knowing either the contents or nature of the goods.",
      "ZAINCO is authorized by shipper to open any package(s) if requested by government authorities at any time.",
      "In the event of any package(s) delivered opened or unpacked due to routine customs verification the consignee s the right to refuse and returned on the spot, if accepted to collect weather not knowing the contents, ZAINCO will not accept any claim after that. ZAINCO will confirm the weight of the package as per system in case of any difference in weight the consignee will be compensated as per item no. (7) The claim must be forwarded by Shipper / Consignee by email immediately to the Head Office (zainkhitamy@gmail.com) verbal claim will not be entertain at all. Any claims will be dissolving as per our tariff.",
      "ZAINCO will compensate the shipper in case of lost package (s) or part of, with amount not exceeding2OO% of the shipping freight of lost package on prorata bases on cost of freight paid to ZAINCO or declared value which ever is less. In the event of commercial commodity compensation will not exceed USD 200 per package not less than 25 Kgs. ",
      "Freight charges must be paid upfront prior departure. Shipment will not be release if freight charges have not been paid in full, Ten Days is allowed as free time from the date your package (s) land in our warehouse after which storage charges will apply as per our tariff KSH. 1.50 Per kilo per day payable in Kenya prior release. And a penalty of equivalent to SR. 50/= will be charges in the event freight has not been fully paid and the shipment has arrived at our Mombasa warehouse.",
      "In the event of currency fluctuation, current market rate will be applied when making payment.",
      "ZAINCO has the right to auction your shipment at a force value to recover its freight and storage charges if the package not paid after 30 days from the date of arrival at our warehouse without giving any notice to the shipper or consignee.",
      "ZAINCO will not be responsible of any claim in case of civil unrest, mob destruction, anarchy, calamities etc. which may result in looting.",
      "Transit period is one month from the day the vessel departs Jeddah Port to arrival at our warehouse. ZAINCO is NOT responsible in the event of any delay caused by Shipping Line, Customs authority at departing port, or Custom authority at Port of destination but will make sure safe arrival of your cargo and ZAINCO will not be responsible for the loss of revenue due to the delay.",
      "ZAINCO reserves the right to confiscate all restricted/prohibited any point of transit without further warning to Shipper / Consignee. In such unlikely event that the restricted/prohibited consignments are shipped, ZAINCO will take legal action against the shipper and consignee. The Shipper/ Consignee will bear full costs of freight, Port charges, demurrage, transport expenses, Customs penalties, legal fees incurred etc",
      "ZAINCO is not a MUST to notify Shipper/Consignee upon arrival of his/her shipment and storage charges must be paid in case of freight not fully paid, no any excuse will be entertain.",
      "The above terms and conditions are fully effective for all packages and shipment carried.",
    ];

    var terms_array_show = [];
    terms.forEach((element, i) => {
      terms_array_show.push(
        <View
          style={{
            flexDirection: "row",
            borderColor: "white",
            flexWrap: "wrap",
            paddingTop: 10,
            // paddingBottom: 10,
          }}
        >
          <View style={{ paddingTop: 5, paddingRight: 5 }}>
            <Text style={{ fontSize: 10 }}>
              {i + 1}. {element}
            </Text>
          </View>
        </View>
      );
    });
    return (
      <View>
        <Text
          style={{
            fontSize: 24,
            textAlign: "center",
          }}
        >
          Terms and Conditions
        </Text>

        {terms_array_show}
      </View>
    );
  }

  show_report_based_on_hbl({ invoices }) {
    const {
      data,
      // invoices
    } = this.props;

    var vat_total = 0;
    var data_to_show_in_table = [];
    var show_data = [];
    var ready = [];

    invoices.forEach((element, i) => {
      if (element) {
        const { ready } = this.state;

        const MyDocument = this.show_report();
        data_to_show_in_table.push({
          HBL: element.hbl_number,
          Lot_No: element.Lot_No,
          Invoice_Number: element.invoice_number,
          Consignee: element.c_name,
          Shipper: element.s_name,
          receipt: (
            <div>
              {ready[i] && (
                <PDFDownloadLink document={MyDocument} fileName="PDF">
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      "Loading document..."
                    ) : (
                      <Button
                        onClick={() => {
                          var temp_ready = ready;

                          temp_ready[i] = false;

                          this.setState({ ready: temp_ready });
                        }}
                      >
                        Download Receipt
                      </Button>
                    )
                  }
                </PDFDownloadLink>
              )}
              {!ready[i] && (
                <Button
                  onClick={() => {
                    this.setState({ invoice_details: element });
                    this.toggle(i);
                  }}
                >
                  Generate Receipt
                </Button>
              )}
            </div>
          ),

          email_option: (
            <div>
              <Button
                onClick={() => {
                  firebase
                    .database()
                    .ref("email_receipt/")
                    .push({ invoice: element.invoice_number, hbl: element.uid })
                    .then(() => {
                      alert("Email Reciept was sent successfully!");
                    })
                    .catch(() => {
                      alert("Oops! There was an error. Please try again!");
                    });
                }}
              >
                Send Receipt via Email
              </Button>
            </div>
          ),
        });
      }
    });

    const table_data = {
      columns: [
        {
          label: "HBL",
          field: "HBL",
          sort: "asc",
          width: 150,
        },
        {
          label: "Lot No",
          field: "Lot_No",
          sort: "asc",
          width: 270,
        },
        {
          label: "Invoice No",
          field: "Invoice_Number",
          sort: "asc",
          width: 270,
        },
        {
          label: "Consignee",
          field: "Consignee",
          sort: "asc",
          width: 200,
        },
        {
          label: "Shipper",
          field: "Shipper",
          sort: "asc",
          width: 100,
        },

        {
          label: "Receipt",
          field: "receipt",
          sort: "asc",
          width: 100,
        },

        {
          label: "Email",
          field: "email_option",
          sort: "asc",
          width: 100,
        },
      ],
      rows: data_to_show_in_table,
    };

    return (
      <div>
        <div
          style={{
            paddingTop: 20,
            paddingLeft: 15,
            paddingRight: 15,
          }}
        >
          <MDBDataTable responsive striped bordered data={table_data} />
        </div>
      </div>
    );
  }

  processing({ invoices }) {
    //  const { invoices } = this.props;

    var data = invoices;

    var snapshot = data;

    var all_data_lot_no = _.map(snapshot, (val, uid) => {
      var data = {
        label: val.Lot_No,

        data: { uid, ...val },
      };

      return data;
    });

    var seen_all_data_lot_no = new Map();

    all_data_lot_no = all_data_lot_no.filter((entry) => {
      var previous;
      if (seen_all_data_lot_no.hasOwnProperty(entry.label)) {
        previous = seen_all_data_lot_no[entry.label];
        previous.data.push(entry.data);

        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen_all_data_lot_no[entry.label] = entry;

      return true;
    });

    all_data_lot_no = _.fromPairs(
      _.map(all_data_lot_no, (i) => {
        return [i.label, i.data];
      })
    );

    return all_data_lot_no;
  }

  show_table_filtered_by_lot_no() {
    var data = this.state.data_by_lot_no;
    var data_to_show_in_table = [];
    var show_data = [];

    data.forEach((element, i) => {
      if (element) {
        const { ready } = this.state;

        const MyDocument = this.show_report();
        data_to_show_in_table.push({
          HBL: element.hbl_number,
          Lot_No: element.Lot_No,
          Invoice_Number: element.invoice_number,
          Consignee: element.c_name,
          Shipper: element.s_name,
          receipt: (
            <div>
              {ready[i] && (
                <PDFDownloadLink document={MyDocument} fileName="PDF">
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      "Loading document..."
                    ) : (
                      <Button
                        onClick={() => {
                          var temp_ready = ready;

                          temp_ready[i] = false;

                          this.setState({ ready: temp_ready });
                        }}
                      >
                        Download Receipt
                      </Button>
                    )
                  }
                </PDFDownloadLink>
              )}
              {!ready[i] && (
                <Button
                  onClick={() => {
                    this.setState({ invoice_details: element });
                    this.toggle(i);
                  }}
                >
                  Generate Receipt
                </Button>
              )}
            </div>
          ),

          email_option: (
            <div>
              <Button
                onClick={() => {
                  firebase
                    .database()
                    .ref("email_receipt/")
                    .push({ invoice: element.invoice_number, hbl: element.uid })
                    .then(() => {
                      alert("Email Reciept was sent successfully!");
                    })
                    .catch(() => {
                      alert("Oops! There was an error. Please try again!");
                    });
                }}
              >
                Send Receipt via Email
              </Button>
            </div>
          ),
        });
      }
    });

    const table_data = {
      columns: [
        {
          label: "HBL",
          field: "HBL",
          sort: "asc",
          width: 150,
        },
        {
          label: "Lot No",
          field: "Lot_No",
          sort: "asc",
          width: 270,
        },
        {
          label: "Invoice No",
          field: "Invoice_Number",
          sort: "asc",
          width: 270,
        },
        {
          label: "Consignee",
          field: "Consignee",
          sort: "asc",
          width: 200,
        },
        {
          label: "Shipper",
          field: "Shipper",
          sort: "asc",
          width: 100,
        },

        {
          label: "Receipt",
          field: "receipt",
          sort: "asc",
          width: 100,
        },

        {
          label: "Email",
          field: "email_option",
          sort: "asc",
          width: 100,
        },
      ],
      rows: data_to_show_in_table,
    };

    return (
      <div>
        <div
          style={{
            paddingTop: 20,
            paddingLeft: 15,
            paddingRight: 15,
          }}
        >
          <MDBDataTable responsive striped bordered data={table_data} />
        </div>
      </div>
    );
  }
  show_report_based_on_lot_no({ invoices }) {
    var data_filtered = this.processing({ invoices });

    var options_to_show = [];
    var data_with_details = [];

    if (Object.keys(data_filtered).length > 0) {
      var data = _.map(data_filtered, (val, uid_lot) => {
        return { data: { ...val }, uid_lot };
      });

      data.forEach((element) => {
        data_with_details.push(element);
      });

      data_with_details.forEach((element, i) => {
        options_to_show.push(<option value={i}>{element.uid_lot}</option>);
      });
    }

    return (
      <div>
        <Form encType="multipart/form-data">
          <div data-repeater-list="group-a">
            <Row data-repeater-item>
              <Col lg="6" className="form-group">
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    <Label className="input-group-text">LOT NO</Label>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    className="form-control"
                    onChange={(event) => {
                      var value = event.target.value;
                      if (data_with_details[value].data) {
                        var converted_data = _.map(
                          data_with_details[value].data,
                          (val) => {
                            return { ...val };
                          }
                        );

                        this.setState({
                          data_by_lot_no: converted_data,
                        });
                      }
                    }}
                  >
                    <option value="">Select Lot No.</option>
                    {options_to_show}
                  </Input>
                </InputGroup>
              </Col>
            </Row>
          </div>
        </Form>

        {this.state.data_by_lot_no.length > 0 ? (
          <div>{this.show_table_filtered_by_lot_no()}</div>
        ) : null}
      </div>
    );
  }

  show_report_based_period({ invoices }) {
    var data_to_show_in_table = [];
    var show_data = [];

    invoices.forEach((element) => {
      var dateSplit = element.date.split("/");
      var date = moment(
        dateSplit[2] + dateSplit[1] + dateSplit[0],
        "YYYYMMDD"
      ).valueOf();

      if (this.state.start_date !== "" && this.state.end_date !== "") {
        if (
          moment(date).isSameOrAfter(
            moment_time_zone(this.state.start_date).valueOf()
          ) &&
          moment(date).isSameOrBefore(
            moment_time_zone(this.state.end_date).valueOf()
          )
        ) {
          show_data.push(element);
        }
      }
    });

    show_data.forEach((element, i) => {
      if (element) {
        const { ready } = this.state;

        const MyDocument = this.show_report();
        data_to_show_in_table.push({
          HBL: element.hbl_number,
          Lot_No: element.Lot_No,
          Invoice_Number: element.invoice_number,
          Consignee: element.c_name,
          Shipper: element.s_name,
          receipt: (
            <div>
              {ready[i] && (
                <PDFDownloadLink document={MyDocument} fileName="PDF">
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      "Loading document..."
                    ) : (
                      <Button
                        onClick={() => {
                          var temp_ready = ready;

                          temp_ready[i] = false;

                          this.setState({ ready: temp_ready });
                        }}
                      >
                        Download Receipt
                      </Button>
                    )
                  }
                </PDFDownloadLink>
              )}
              {!ready[i] && (
                <Button
                  onClick={() => {
                    this.setState({ invoice_details: element });
                    this.toggle(i);
                  }}
                >
                  Generate Receipt
                </Button>
              )}
            </div>
          ),

          email_option: (
            <div>
              <Button
                onClick={() => {
                  firebase
                    .database()
                    .ref("email_receipt/")
                    .push({ invoice: element.invoice_number, hbl: element.uid })
                    .then(() => {
                      alert("Email Reciept was sent successfully!");
                    })
                    .catch(() => {
                      alert("Oops! There was an error. Please try again!");
                    });
                }}
              >
                Send Receipt via Email
              </Button>
            </div>
          ),
        });
      }
    });

    const table_data = {
      columns: [
        {
          label: "HBL",
          field: "HBL",
          sort: "asc",
          width: 150,
        },
        {
          label: "Lot No",
          field: "Lot_No",
          sort: "asc",
          width: 270,
        },
        {
          label: "Invoice No",
          field: "Invoice_Number",
          sort: "asc",
          width: 270,
        },
        {
          label: "Consignee",
          field: "Consignee",
          sort: "asc",
          width: 200,
        },
        {
          label: "Shipper",
          field: "Shipper",
          sort: "asc",
          width: 100,
        },

        {
          label: "Receipt",
          field: "receipt",
          sort: "asc",
          width: 100,
        },

        {
          label: "Email",
          field: "email_option",
          sort: "asc",
          width: 100,
        },
      ],
      rows: data_to_show_in_table,
    };

    var show_options = false;
    if (this.state.start_date === "" || this.state.end_date === "") {
      show_options = false;
    } else {
      show_options = true;
    }
    return (
      <div>
        <Form className="repeater" encType="multipart/form-data">
          <div data-repeater-list="group-a">
            <Row data-repeater-item>
              <Col lg="6" className="form-group">
                <Label htmlFor="name">Start Date</Label>
                <Input
                  type="date"
                  id="name"
                  name="untyped-input"
                  className="form-control"
                  onChange={(event) => {
                    var value = event.target.value;

                    this.setState({
                      start_date: value,
                    });
                  }}
                  value={this.state.start_date}
                />
              </Col>
              <Col lg="6" className="form-group">
                <Label htmlFor="name">End Date</Label>
                <Input
                  type="date"
                  id="name"
                  name="untyped-input"
                  className="form-control"
                  onChange={(event) => {
                    var value = event.target.value;
                    // moment_time_zone().tz("Etc/GMT")
                    if (
                      moment(
                        moment_time_zone(this.state.start_date)
                          .tz("Etc/GMT")
                          .format("DD/MM/YYYY")
                        // .format("MM/DD/YYYY")
                      ).isAfter(
                        moment_time_zone(this.state.end_date)
                          .tz("Etc/GMT")
                          .format("DD/MM/YYYY")
                        // .format("MM/DD/YYYY")
                      ) === false
                    ) {
                      this.setState({
                        end_date: value,
                      });
                    } else {
                      alert(
                        "Please select End Date after the Start Date you have selected!"
                      );
                    }
                  }}
                  value={this.state.end_date}
                />
              </Col>
            </Row>
          </div>
        </Form>
        {show_options === true ? (
          <div
            style={{
              paddingTop: 20,
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            <MDBDataTable responsive striped bordered data={table_data} />
          </div>
        ) : null}
      </div>
    );
  }

  render() {
    const { loading } = this.props;
    if (loading === true) {
      const loadingjson = {
        loop: true,
        autoplay: true,
        animationData: require("./JSON/loading.json"),

        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };

      return (
        <div className="page-content">
          <Container fluid>
            <Row className="justify-content-center mt-lg-5">
              <div className="text-center">
                <Row className="justify-content-center">
                  <div className="text-center">
                    <Lottie options={loadingjson} height={300} width={300} />
                  </div>
                </Row>
              </div>
            </Row>
          </Container>
        </div>
      );
    }

    // const { data } = this.props;
    const { shipping_data } = this.props;
    var data = shipping_data;

    data = _.map(data, (val, uid) => {
      return { ...val, uid };
    });

    var invoices = [];

    if (data.length !== 0) {
      var filtered_data = [];
      data.forEach((element, x) => {
        if (element.full_detail !== undefined) {
          filtered_data.push(element);

          var s_name = "";
          var s_town_country = "";
          var s_phone = "";
          var email = "";

          var c_town_country = "";
          var c_phone = "";
          var c_name = "";

          if (element.full_detail.shipper_details !== undefined) {
            s_name = element.full_detail.shipper_details.name;
            s_town_country = element.full_detail.shipper_details.country;
            s_phone = element.full_detail.shipper_details.phone;
            email = element.full_detail.shipper_details.email;
          }

          if (element.full_detail.consignee_details !== undefined) {
            c_town_country =
              element.full_detail.consignee_details.consignee_country;
            c_phone = element.full_detail.consignee_details.consignee_phone;
            c_name = element.full_detail.consignee_details.consignee_name;
          }

          var status = "Processing";
          if (element.status !== undefined) {
            status = element.status;
          }

          var invoice_details = {
            status,
            full_detail: element.full_detail,
            Email: email,
            Lot_No: element.Lot_No,
            s_name: s_name,
            s_town_country: s_town_country,
            s_phone: s_phone,
            c_town_country: c_town_country,
            c_phone: c_phone,
            c_name: c_name,
            hbl_number: element.uid,
            date: element.Date,
            invoice_number: element.uid + "-" + "001", //String(x),
            payments_details: element.full_detail.payments_details,
            calculations: element.full_detail.calculations,
            cargo_details: element.full_detail.cargo_details,
          };
          invoices.push(invoice_details);
        }
      });
    }

    return (
      <div>
        <div>
          <Nav pills className="bg-light rounded" role="tablist">
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === "1",
                })}
                onClick={() => {
                  this.setState({
                    activeTab: "1",
                  });
                }}
              >
                All
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === "2",
                })}
                onClick={() => {
                  this.setState({
                    activeTab: "2",
                  });
                }}
              >
                Based on Lot No.
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === "3",
                })}
                onClick={() => {
                  this.setState({
                    activeTab: "3",
                  });
                }}
              >
                Based on Period
              </NavLink>
            </NavItem>
          </Nav>
        </div>

        <TabContent activeTab={this.state.activeTab} className="mt-4">
          <TabPane tabId="1" id="buy-tab">
            {this.show_report_based_on_hbl({ invoices })}
          </TabPane>
          <TabPane tabId="2" id="sell-tab">
            {this.show_report_based_on_lot_no({ invoices })}
          </TabPane>
          <TabPane tabId="3" id="sell-tab">
            {this.show_report_based_period({ invoices })}
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

// export default ReportOfPayment;
const mapStatetoProps = (state) => {
  const { shipping_data, error, loading } = state.Database;

  return {
    shipping_data,
    error,
    loading,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    shipping_data_read,
    detail_error,
  })(withNamespaces()(ReportOfPayment))
);
