import _ from "lodash";
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
// import moment from "moment";
import moment_time_zone from "moment-timezone";
// import { Container, Row, Col, Card, CardBody, Table } from "reactstrap";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  Image,
} from "@react-pdf/renderer";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Label,
  Input,
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  InputGroupAddon,
  InputGroup,
  Modal,
  Form,
} from "reactstrap";
import firebase from "firebase/app";
import moment from "moment";
// Add the Firebase products that you want to use
import "firebase/database";
//Import Breadcrumb
// import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Image
import logo from "../../../assets/images/logo-dark.png";
import vat_arabic from "../../../assets/images/VAT_Arabic.png";

class CryptoIcoLanding extends Component {
  constructor() {
    super();
    this.state = {
      redirect: "CHECKING",
      ready: false,
      data_to_download_pdf: {},
      // rates_data_extracted: {},
      conversion_selected: {},
      invoice_details: {},
      old_container_data: [],
    };
  }

  componentDidMount() {
    firebase
      .database()
      .ref("organization/zaincocargo/container/")
      .once("value")
      .then((snapshot) => {
        var data_array = _.map(snapshot.val(), (val, uid) => {
          return { ...val, uid };
        });

        this.setState({
          old_container_data: data_array,
        });
      });

    this.initial_load();
  }

  toggle() {
    this.setState(
      (prevState) => ({
        ready: false,
      }),
      () => {
        setTimeout(() => {
          this.setState({ ready: true });
        }, 1);
      }
    );
  }

  initial_load() {
    var str = window.location.href;

    let position = str.indexOf("=");
    var response = str.split("?receipt_id=");

    var res = str.substr(position + 1);
    if (response[1] !== undefined) {
      res = res.replace(".", "_");
      res = res.replace("#", "_");
      res = res.replace("$", "_");
      res = res.replace("[", "_");
      res = res.replace("]", "_");
      var title = res;

      var invoice_number = title;

      res = res.replace("-001", "");

      title = res;

      // console.log(` organization/zaincocargo/shipment_details/${title}`);
      // firebase
      //   .database()
      //   // .ref(`organization/zaincocargo/invoices/${title}`)
      //   .ref(` organization/zaincocargo/shipment_details/${title}`)
      //   .once("value", (snapshot) => {

      firebase
        .database()
        .ref(`organization/zaincocargo/shipment_details/${title}`)
        // .once("value")
        // .then((snapshot) => {
        .once("value", (snapshot) => {
          var data = snapshot.val();

          if (data === null) {
            console.log("NOTHING HERE");
            this.setState({
              redirect: true,
            });
          } else {
            var s_name = "";
            var s_town_country = "";
            var s_phone = "";
            var email = "";

            var c_town_country = "";
            var c_phone = "";
            var c_name = "";
            var invoice_details = {};

            if (data.full_detail !== undefined) {
              if (data.full_detail.shipper_details !== undefined) {
                s_name = data.full_detail.shipper_details.name;
                s_town_country = data.full_detail.shipper_details.country;
                s_phone = data.full_detail.shipper_details.phone;
                email = data.full_detail.shipper_details.email;
              }

              if (data.full_detail.consignee_details !== undefined) {
                c_town_country =
                  data.full_detail.consignee_details.consignee_country;
                c_phone = data.full_detail.consignee_details.consignee_phone;
                c_name = data.full_detail.consignee_details.consignee_name;
              }

              var status = "Processing";
              if (data.status !== undefined) {
                status = data.status;
              }
              invoice_details = {
                status,
                full_detail: data.full_detail,
                Email: email,
                Lot_No: data.Lot_No,
                s_name: s_name,
                s_town_country: s_town_country,
                s_phone: s_phone,
                c_town_country: c_town_country,
                c_phone: c_phone,
                c_name: c_name,
                hbl_number: title,
                date: data.Date,
                invoice_number: invoice_number, //data.uid + "-" + "001", //String(x),
                payments_details: data.full_detail.payments_details,
                calculations: data.full_detail.calculations,
                cargo_details: data.full_detail.cargo_details,
              };
            }
            this.setState({
              invoice_details,
              // invoice_details: data,
              redirect: false,
            });
            this.toggle();
          }
        });
    } else {
      //
      console.log("NOTHING HERE");
      this.setState({
        redirect: true,
      });
    }
  }

  table_titles() {
    return (
      <View
        style={{
          flexDirection: "row",
          borderBottomColor: "black",
          borderBottomWidth: 1,
          alignItems: "center",
          height: 24,
          textAlign: "center",
          fontStyle: "bold",
        }}
      >
        <View
          style={{
            width: "25%",
            borderRightColor: "black",
            borderRightWidth: 1,
            backgroundColor: "#E0E0E0",
            paddingTop: 2,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
            }}
          >
            DESCRIPTION
          </Text>
        </View>

        <View
          style={{
            width: "15%",
            borderRightColor: "black",
            borderRightWidth: 1,
            backgroundColor: "#E0E0E0",
            paddingTop: 2,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
            }}
          >
            PCS
          </Text>
        </View>

        <View
          style={{
            width: "15%",
            borderRightColor: "black",
            borderRightWidth: 1,
            backgroundColor: "#E0E0E0",
            paddingTop: 2,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
            }}
          >
            WEIGHT
          </Text>
        </View>

        <View
          style={{
            width: "15%",
            borderRightColor: "black",
            borderRightWidth: 1,
            backgroundColor: "#E0E0E0",
            paddingTop: 2,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
            }}
          >
            RATE
          </Text>
        </View>

        <View
          style={{
            width: "15%",
            borderRightColor: "black",
            borderRightWidth: 1,
            backgroundColor: "#E0E0E0",
            paddingTop: 2,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
            }}
          >
            AMOUNT
          </Text>
        </View>

        <View
          style={{
            width: "15%",
            borderRightColor: "black",
            borderRightWidth: 1,
            backgroundColor: "#E0E0E0",
            paddingTop: 2,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
            }}
          >
            REMARKS
          </Text>
        </View>
      </View>
    );
  }
  line_items() {
    const { invoice_details } = this.state;
    var data = [];
    if (Object.keys(invoice_details).length > 0) {
      var cargo_details = invoice_details.cargo_details;

      cargo_details.forEach((element, i) => {
        data.push(
          <View
            style={{
              flexDirection: "row",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              alignItems: "center",
              height: 24,
              fontStyle: "bold",
            }}
            key={i}
          >
            <Text
              style={{
                width: "25%",
                textAlign: "left",
                borderBottomColor: "black",
                borderRightWidth: 1,
                paddingLeft: 8,
                fontSize: 9,
              }}
            >
              {element.description}
            </Text>
            <Text
              style={{
                width: "15%",
                borderBottomColor: "black",
                borderRightWidth: 1,
                textAlign: "center",
                paddingRight: 8,
                fontSize: 9,
              }}
            >
              {element.quantity}
            </Text>
            <Text
              style={{
                width: "15%",
                borderBottomColor: "black",
                borderRightWidth: 1,
                textAlign: "center",
                paddingRight: 8,
                fontSize: 9,
              }}
            >
              {element.weight}
            </Text>
            <Text
              style={{
                width: "15%",
                textAlign: "center",
                borderBottomColor: "black",
                borderRightWidth: 1,
                paddingRight: 8,
                fontSize: 9,
              }}
            >
              {element.rate}
            </Text>
            <Text
              style={{
                width: "15%",
                textAlign: "center",
                borderBottomColor: "#000",
                borderRightWidth: 1,

                paddingRight: 8,
                fontSize: 9,
                paddingLeft: 5,
              }}
            >
              {parseFloat(element.rate) * parseFloat(element.weight)}
            </Text>

            <Text
              style={{
                width: "14%",
                textAlign: "left",
                paddingRight: 8,
                fontSize: 8,
                paddingLeft: 5,
              }}
            >
              {/*Add Remarks*/}
            </Text>
          </View>
        );
      });
    }

    return data;
  }

  table_last_line() {
    const { data_for_reciept, invoice_details } = this.state;

    var view_to_show = <View></View>;

    if (invoice_details !== null) {
      if (Object.keys(invoice_details).length > 0) {
        var payments_details = invoice_details.payments_details;
        var calculations = invoice_details.calculations;
        var full_detail = invoice_details.full_detail;

        var payments_showing_array = [];

        payments_details.forEach((element, i) => {
          // moment_time_zone().tz("Etc/GMT").format("MM/DD/YYYY");
          var date_to_show = moment_time_zone(element.date)
            .tz("Etc/GMT")
            .format("DD/MM/YYYY");
          // .format("DD-MM-YYYY"); //moment(element.date).format("DD-MM-YYYY");

          payments_showing_array.push(
            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontSize: 12,
                fontStyle: "bold",
              }}
            >
              <Text
                style={{
                  width: "83.75%",
                  textAlign: "right",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingRight: 8,
                  fontSize: 12,
                }}
              >
                Payment #{i + 1}({date_to_show})
              </Text>
              <Text
                style={{
                  width: "16.25%",
                  textAlign: "left",
                  paddingRight: 8,
                  fontSize: 10,
                  paddingLeft: 5,
                }}
              >
                {parseFloat(element.amount).toFixed(2)}
              </Text>
            </View>
          );
        });

        var total_pieces = 0;
        var total_weight = 0;
        var rate = 0;

        if (full_detail.cargo_details !== undefined) {
          full_detail.cargo_details.forEach((element) => {
            if (element !== undefined) {
              if (element.quantity !== "") {
                total_pieces = total_pieces + parseFloat(element.quantity);
              }
              if (element.weight !== "") {
                total_weight = total_weight + parseFloat(element.weight);
              }

              rate = element.rate;
            }
          });
        }

        var total_outstanding_balance_with_fees = 0,
          total_outstanding_balance_with_fees_kse = 0;

        //////////////////

        var storage_fee_sr = "0",
          penalty_fee = "0";
        if (full_detail.calculations.storage_fee_sr !== undefined) {
          if (full_detail.calculations.storage_fee_sr !== "") {
            storage_fee_sr = full_detail.calculations.storage_fee_sr;
          }
        }

        if (full_detail.calculations.penalty_fee !== undefined) {
          if (full_detail.calculations.penalty_fee !== "") {
            penalty_fee = full_detail.calculations.penalty_fee;
          }
        }
        ///

        //
        total_outstanding_balance_with_fees =
          parseFloat(full_detail.calculations.total_outstanding_balance) +
          parseFloat(storage_fee_sr) +
          parseFloat(penalty_fee); //.toFixed(2);

        total_outstanding_balance_with_fees_kse =
          parseFloat(full_detail.calculations.converted_price) +
          parseFloat(storage_fee_sr) *
            full_detail.calculations.conversion_selected_rate +
          parseFloat(penalty_fee) *
            full_detail.calculations.conversion_selected_rate; //.toFixed(2);

        total_outstanding_balance_with_fees_kse =
          total_outstanding_balance_with_fees_kse.toFixed(2);
        total_outstanding_balance_with_fees_kse =
          total_outstanding_balance_with_fees_kse
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        total_outstanding_balance_with_fees =
          total_outstanding_balance_with_fees.toFixed(2);

        total_outstanding_balance_with_fees =
          total_outstanding_balance_with_fees
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        view_to_show = (
          <View>
            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  TOTAL
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {total_pieces}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {total_weight}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {rate}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {parseFloat(full_detail.calculations.total_price).toFixed(2)}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  -
                </Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  VAT
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {full_detail.calculations.vat_charges}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  HBL FEES
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {parseFloat(full_detail.other_charges.hbl).toFixed(2)}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  PACKING
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {parseFloat(
                    full_detail.other_charges.packing_charges
                  ).toFixed(2)}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  TRANSPORT
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {parseFloat(
                    full_detail.other_charges.transport_charges
                  ).toFixed(2)}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  MISCELLANEOUS
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {parseFloat(
                    full_detail.other_charges.miscellaneous_charges
                  ).toFixed(2)}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  PENALTY
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {full_detail.calculations.penalty_fee}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  STORAGE
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {full_detail.calculations.storage_fee_sr}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                {full_detail.calculations.storage_fee_sr === 0 ? (
                  <Text
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                  ></Text>
                ) : (
                  <Text
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                  >
                    {full_detail.calculations.penalty_days} DAYS
                  </Text>
                )}
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  TOTAL PAYABLE SR.
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {total_outstanding_balance_with_fees}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  KSH
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",

                  backgroundColor: "#E0E0E0",

                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {full_detail.calculations.conversion_selected_rate}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {total_outstanding_balance_with_fees_kse}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>
          </View>
        );

        //
      }
    }

    return view_to_show;
  }

  /*

  table_titles() {
    return (
      <View
        style={{
          flexDirection: "row",
          borderBottomColor: "black",
          borderBottomWidth: 1,
          alignItems: "center",
          height: 24,
          textAlign: "center",
          fontStyle: "bold",
        }}
      >
        <View
          style={{
            width: "25%",
            borderRightColor: "black",
            borderRightWidth: 1,
            backgroundColor: "#E0E0E0",
            paddingTop: 2,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
            }}
          >
            DESCRIPTION
          </Text>
        </View>
     
        <View
          style={{
            width: "15%",
            borderRightColor: "black",
            borderRightWidth: 1,
            backgroundColor: "#E0E0E0",
            paddingTop: 2,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
            }}
          >
            PCS
          </Text>
        </View>
       
        <View
          style={{
            width: "15%",
            borderRightColor: "black",
            borderRightWidth: 1,
            backgroundColor: "#E0E0E0",
            paddingTop: 2,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
            }}
          >
            WEIGHT
          </Text>
        </View>

       

        <View
          style={{
            width: "15%",
            borderRightColor: "black",
            borderRightWidth: 1,
            backgroundColor: "#E0E0E0",
            paddingTop: 2,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
            }}
          >
            RATE
          </Text>
        </View>

       
        <View
          style={{
            width: "15%",
            borderRightColor: "black",
            borderRightWidth: 1,
            backgroundColor: "#E0E0E0",
            paddingTop: 2,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
            }}
          >
            AMOUNT
          </Text>
        </View>

    

        <View
          style={{
            width: "15%",
            borderRightColor: "black",
            borderRightWidth: 1,
            backgroundColor: "#E0E0E0",
            paddingTop: 2,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
            }}
          >
            REMARKS
          </Text>
        </View>
      </View>
    );

   
  }

  line_items() {
    const { invoice_details } = this.state;
    console.log(invoice_details);

    var data = [];

    if (invoice_details !== null) {
      if (Object.keys(invoice_details).length > 0) {
        var cargo_details = invoice_details.cargo_details;

        cargo_details.forEach((element, i) => {
          data.push(
            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
              key={i}
            >
              <Text
                style={{
                  width: "25%",
                  textAlign: "left",
                  borderBottomColor: "black",
                  borderRightWidth: 1,
                  paddingLeft: 8,
                  fontSize: 9,
                }}
              >
                {element.description}
              </Text>
              <Text
                style={{
                  width: "15%",
                  borderBottomColor: "black",
                  borderRightWidth: 1,
                  textAlign: "center",
                  paddingRight: 8,
                  fontSize: 9,
                }}
              >
                {element.quantity}
              </Text>
              <Text
                style={{
                  width: "15%",
                  borderBottomColor: "black",
                  borderRightWidth: 1,
                  textAlign: "center",
                  paddingRight: 8,
                  fontSize: 9,
                }}
              >
                {element.weight}
              </Text>
              <Text
                style={{
                  width: "15%",
                  textAlign: "center",
                  borderBottomColor: "black",
                  borderRightWidth: 1,
                  paddingRight: 8,
                  fontSize: 9,
                }}
              >
                {element.rate}
              </Text>
              <Text
                style={{
                  width: "15%",
                  textAlign: "center",
                  borderBottomColor: "#000",
                  borderRightWidth: 1,

                  paddingRight: 8,
                  fontSize: 9,
                  paddingLeft: 5,
                }}
              >
                {parseFloat(element.rate) * parseFloat(element.weight)}
              </Text>

              <Text
                style={{
                  width: "14%",
                  textAlign: "left",
                  paddingRight: 8,
                  fontSize: 8,
                  paddingLeft: 5,
                }}
              >
  
              </Text>
            </View>
          );
        });
      }
    }

    return data;
  }

  table_last_line() {
    const { data_for_reciept, invoice_details } = this.state;

    var view_to_show = <View></View>;

    if (invoice_details !== null) {
      if (Object.keys(invoice_details).length > 0) {
        var payments_details = invoice_details.payments_details;
        var calculations = invoice_details.calculations;
        var full_detail = invoice_details.full_detail;

        var payments_showing_array = [];

        payments_details.forEach((element, i) => {
          var date_to_show = moment(element.date).format("DD-MM-YYYY");

          payments_showing_array.push(
            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontSize: 12,
                fontStyle: "bold",
              }}
            >
              <Text
                style={{
                  width: "83.75%",
                  textAlign: "right",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingRight: 8,
                  fontSize: 12,
                }}
              >
                Payment #{i + 1}({date_to_show})
              </Text>
              <Text
                style={{
                  width: "16.25%",
                  textAlign: "left",
                  paddingRight: 8,
                  fontSize: 10,
                  paddingLeft: 5,
                }}
              >
                {parseFloat(element.amount).toFixed(2)}
              </Text>
            </View>
          );
        });

      

        var total_pieces = 0;
        var total_weight = 0;
        var rate = 0;

        full_detail.cargo_details.forEach((element) => {
          if (element.quantity !== "") {
            total_pieces = total_pieces + parseFloat(element.quantity);
          }
          if (element.weight !== "") {
            total_weight = total_weight + parseFloat(element.weight);
          }

          rate = element.rate;
        });

        var total_outstanding_balance_with_fees = 0,
          total_outstanding_balance_with_fees_kse = 0;

        //
        total_outstanding_balance_with_fees =
          parseFloat(full_detail.calculations.total_outstanding_balance) +
          parseFloat(full_detail.calculations.storage_fee_sr) +
          parseFloat(full_detail.calculations.penalty_fee); //.toFixed(2);

        total_outstanding_balance_with_fees_kse =
          parseFloat(full_detail.calculations.converted_price) +
          parseFloat(full_detail.calculations.storage_fee_sr) *
            full_detail.calculations.conversion_selected_rate +
          parseFloat(full_detail.calculations.penalty_fee) *
            full_detail.calculations.conversion_selected_rate; //.toFixed(2);

        total_outstanding_balance_with_fees_kse =
          total_outstanding_balance_with_fees_kse.toFixed(2);
        total_outstanding_balance_with_fees_kse =
          total_outstanding_balance_with_fees_kse
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        total_outstanding_balance_with_fees =
          total_outstanding_balance_with_fees.toFixed(2);

        total_outstanding_balance_with_fees =
          total_outstanding_balance_with_fees
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

   
        //total_outstanding_balance_with_fees_kse

        view_to_show = (
          <View>
            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  TOTAL
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {total_pieces}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {total_weight}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {rate}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {parseFloat(full_detail.calculations.total_price).toFixed(2)}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  -
                </Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  VAT
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {full_detail.calculations.vat_charges}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  HBL FEES
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {parseFloat(full_detail.other_charges.hbl).toFixed(2)}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  PACKING
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {parseFloat(
                    full_detail.other_charges.packing_charges
                  ).toFixed(2)}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  TRANSPORT
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {parseFloat(
                    full_detail.other_charges.transport_charges
                  ).toFixed(2)}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  MISCELLANEOUS
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  //backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {parseFloat(
                    full_detail.other_charges.miscellaneous_charges
                  ).toFixed(2)}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  PENALTY
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {full_detail.calculations.penalty_fee}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  STORAGE
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {full_detail.calculations.storage_fee_sr}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                {full_detail.calculations.storage_fee_sr === 0 ? (
                  <Text
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                  ></Text>
                ) : (
                  <Text
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                  >
                    {full_detail.calculations.penalty_days} DAYS
                  </Text>
                )}
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  TOTAL PAYABLE SR.
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {total_outstanding_balance_with_fees}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  KSH
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",

                  backgroundColor: "#E0E0E0",

                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {full_detail.calculations.conversion_selected_rate}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {total_outstanding_balance_with_fees_kse}
                </Text>
              </View>

              <View
                style={{
                  width: "15%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>
            </View>
          </View>
        );

        //
      }
    }

    return view_to_show;
  }

  company_details() {
    return (
      <View
        style={{
          paddingTop: 5,
          paddingTop: 5,
          flexDirection: "row",
          flexWrap: "wrap",
          borderWidth: 1,
          borderColor: "#FFF",
        }}
      >
        <View
          style={{
            width: "50%",
            textAlign: "center",
          }}
        ></View>

        <View
          style={{
            width: "50%",
            textAlign: "left",
            borderWidth: 1,
            borderColor: "#000",
          }}
        >
          <View style={{ padding: 5 }}>
            <View
              style={{ paddingBottom: 5, paddingTop: 5, alignItems: "center" }}
            >
              <Image
                style={{
                  width: 55,
                }}
                src={logo}
              />
            </View>
            <Text style={{ fontSize: 12, textAlign: "center" }}>
              ZAINCO CARGO SERVICES
            </Text>
            <Text style={{ fontSize: 10, textAlign: "center" }}>
              info@zaincocargo.com
            </Text>
            <Text style={{ fontSize: 10, textAlign: "center" }}>
              www.zaincocargo.com
            </Text>
            <Text style={{ fontSize: 10, textAlign: "center" }}>
              IG: zaincocargo
            </Text>
            <Text style={{ fontSize: 10, textAlign: "center" }}>
              FB: zaincocargo
            </Text>
            <Text style={{ fontSize: 10, textAlign: "center" }}>
              Twitter: Zaincocargo
            </Text>
          </View>
        </View>
      </View>
    );
  }
  shipper_consignee_details({ shipper_details, consignee_details }) {
    return (
      <View
        style={{
          // paddingTop: 10,
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <View
          style={{
            borderWidth: 1,
            borderColor: "#000",
            width: "50%",
          }}
        >
          <View>{shipper_details}</View>
        </View>
        <View
          style={{
            borderWidth: 1,
            borderColor: "#000",
            borderLeftColor: "white",
            width: "50%",
          }}
        >
          <View>{consignee_details}</View>
        </View>
      </View>
    );
  }

  hbl_details(invoice_details) {
    //invoice_details

    var closing_date = "";
    if (invoice_details !== null) {
      this.state.old_container_data.forEach((element) => {
        if (invoice_details.Lot_No === element.lot) {
          closing_date = element.closing_date;
        }
      });
    }
    return (
      <View>
        <View style={{ paddingTop: 5 }}>
          <View
            style={{
              borderColor: "black",
              borderWidth: 1,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  HBL
                </Text>
              </View>

              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  DATE
                </Text>
              </View>

              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  LOT NO.
                </Text>
              </View>

              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  CLOSING
                </Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                {invoice_details === null ? (
                  <Text
                    style={{
                      textAlign: "left",
                      fontSize: 10,
                      paddingLeft: 10,
                      fontWeight: "bold",
                    }}
                  ></Text>
                ) : (
                  <Text
                    style={{
                      textAlign: "left",
                      fontSize: 10,
                      paddingLeft: 10,
                      fontWeight: "bold",
                    }}
                  >
                    {invoice_details.hbl_number}
                  </Text>
                )}
              </View>

              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                {invoice_details === null ? (
                  <Text
                    style={{
                      textAlign: "left",
                      fontSize: 10,
                      paddingLeft: 10,
                      fontWeight: "bold",
                    }}
                  ></Text>
                ) : (
                  <Text
                    style={{
                      textAlign: "left",
                      fontSize: 10,
                      paddingLeft: 10,
                      fontWeight: "bold",
                    }}
                  >
                    {invoice_details.date}
                  </Text>
                )}
              </View>

              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                {invoice_details === null ? (
                  <Text
                    style={{
                      textAlign: "left",
                      fontSize: 10,
                      paddingLeft: 10,
                      fontWeight: "bold",
                    }}
                  ></Text>
                ) : (
                  <Text
                    style={{
                      textAlign: "left",
                      fontSize: 10,
                      paddingLeft: 10,
                      fontWeight: "bold",
                    }}
                  >
                    {invoice_details.Lot_No}
                  </Text>
                )}
              </View>

              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                {invoice_details === null ? (
                  <Text
                    style={{
                      textAlign: "left",
                      fontSize: 10,
                      paddingLeft: 10,
                      fontWeight: "bold",
                    }}
                  ></Text>
                ) : (
                  <Text
                    style={{
                      textAlign: "left",
                      fontSize: 10,
                      paddingLeft: 10,
                      fontWeight: "bold",
                    }}
                  >
                    {closing_date}
                  </Text>
                )}
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }

  payment_details(invoice_details) {
    var payment_details = [];

    var invoice_numbers = [];
    var show_payment_details = [];
    if (invoice_details !== null) {
      if (invoice_details.full_detail !== undefined) {
        payment_details = invoice_details.full_detail.payments_details;

        invoice_numbers = invoice_details.full_detail.invoice_numbers;
      }

      if (payment_details.length > 0) {
        payment_details.forEach((element, i) => {
          show_payment_details.push(
            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  {i + 1}
                </Text>
              </View>

              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  // backgroundColor: "#E0E0E0",
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {invoice_numbers[i].invoice}
                </Text>
              </View>

              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {element.date}
                </Text>
              </View>

              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {parseFloat(element.amount)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Text>
              </View>
            </View>
          );
        });
      }
    }
    return (
      <View>
        <View
          style={{
            borderWidth: 1,
            borderColor: "#000",
            width: "100%",
          }}
        >
          <View style={{ paddingTop: 5, paddingRight: 5 }}>
            <Text style={{ paddingLeft: 5, fontSize: 10 }}>
              PAYMENT DETAILS
            </Text>
          </View>
        </View>

        <View style={{ paddingTop: 5 }}>
          <View
            style={{
              borderColor: "black",
              borderWidth: 1,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  No.
                </Text>
              </View>

              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  RECEIPT NO.
                </Text>
              </View>

              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  DATE
                </Text>
              </View>

              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  AMOUNT
                </Text>
              </View>
            </View>

            {show_payment_details}
          </View>
        </View>
      </View>
    );
  }

  contact_details() {
    return (
      <View>
        <View
          style={{
            borderWidth: 1,
            borderColor: "#000",
            width: "100%",
          }}
        >
          <View style={{ paddingTop: 5, paddingRight: 5 }}>
            <Text style={{ paddingLeft: 5, fontSize: 10 }}>
              MOBILE +966505691673 JEDDAH, KING KHALID STREET VAT NO.
              310561094900003
            </Text>
          </View>
        </View>
      </View>
    );
  }

  total_charges_payments_outstanding(invoice_details) {
    if (invoice_details !== null) {
      var full_detail = invoice_details.full_detail;

      var converted_total_charges = 0,
        converted_total_payments = 0,
        converted_total_outstanding = 0,
        total_charges = 0,
        total_outstanding_balance = 0,
        total_payments = 0;
      if (full_detail !== undefined) {
        total_payments = full_detail.calculations.total_payments
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        total_charges =
          full_detail.calculations.total_charges +
          (
            parseFloat(full_detail.calculations.storage_fee_sr) +
            parseFloat(full_detail.calculations.penalty_fee)
          )
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        converted_total_charges = (
          parseFloat(full_detail.calculations.total_charges) *
            full_detail.calculations.conversion_selected_rate +
          (parseFloat(full_detail.calculations.storage_fee_sr) *
            full_detail.calculations.conversion_selected_rate +
            parseFloat(full_detail.calculations.penalty_fee) *
              full_detail.calculations.conversion_selected_rate)
        )
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        converted_total_payments = (
          parseFloat(full_detail.calculations.total_payments) *
          full_detail.calculations.conversion_selected_rate
        )
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        total_outstanding_balance = (
          parseFloat(full_detail.calculations.total_outstanding_balance) +
          (parseFloat(full_detail.calculations.storage_fee_sr) +
            parseFloat(full_detail.calculations.penalty_fee))
        )
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        converted_total_outstanding = (
          parseFloat(full_detail.calculations.total_outstanding_balance) *
            full_detail.calculations.conversion_selected_rate +
          (parseFloat(full_detail.calculations.storage_fee_sr) *
            full_detail.calculations.conversion_selected_rate +
            parseFloat(full_detail.calculations.penalty_fee) *
              full_detail.calculations.conversion_selected_rate)
        )
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return (
        <View style={{ paddingTop: 15 }}>
          <View
            style={{
              borderColor: "black",
              borderWidth: 1,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: `${100 / 3}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                ></Text>
              </View>

              <View
                style={{
                  width: `${100 / 3}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  SAUDI RIYALS
                </Text>
              </View>

              <View
                style={{
                  width: `${100 / 3}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  KENYA SHILLINGS
                </Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: `${100 / 3}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  TOTAL CHARGES
                </Text>
              </View>

              <View
                style={{
                  width: `${100 / 3}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {total_charges}
                </Text>
              </View>

              <View
                style={{
                  width: `${100 / 3}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {converted_total_charges}
                </Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: `${100 / 3}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  TOTAL PAYMENTS
                </Text>
              </View>

              <View
                style={{
                  width: `${100 / 3}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {total_payments}
                </Text>
              </View>

              <View
                style={{
                  width: `${100 / 3}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {converted_total_payments}
                </Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: `${100 / 3}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                  backgroundColor: "#E0E0E0",
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  OUTSTANDING
                </Text>
              </View>

              <View
                style={{
                  width: `${100 / 3}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                  backgroundColor: "#E0E0E0",
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {total_outstanding_balance}
                </Text>
              </View>

              <View
                style={{
                  width: `${100 / 3}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                  backgroundColor: "#E0E0E0",
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {converted_total_outstanding}
                </Text>
              </View>
            </View>
          </View>
        </View>
      );
    } else {
      return <View></View>;
    }
  }
  banking_details() {
    return (
      <View style={{ paddingTop: 25 }}>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5, fontWeight: "bold" }}>
                NCB BANK ACCOUNT
              </Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 11, paddingLeft: 5 }}>
                01400002599310 IBAN SA59 1000000 014 000 2599310
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            // paddingTop: 50,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5, fontWeight: "bold" }}>
                INMA BANK ACCOUNT
              </Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 11, paddingLeft: 5 }}>
                68203286107000 IBAN SA72 0500006 820 328 6107000
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            // paddingTop: 50,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5, fontWeight: "bold" }}>
                GULF AFRICAN BANK
              </Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 11, paddingLeft: 5 }}>0330010801</Text>
            </View>
          </View>
        </View>

        <View
          style={{
            // paddingTop: 50,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5, fontWeight: "bold" }}>
                MPESA
              </Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 11, paddingLeft: 5 }}>
                PAYBILL 985050 ACCOUNT 0130002301
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            // paddingTop: 50,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5 }}>STCPAY</Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5, paddingLeft: 5 }}>
              <Text style={{ fontSize: 11 }}>0555691673 TURKY AL ZAHRANI</Text>
            </View>
          </View>
        </View>
      </View>
    );
  }
  desclaimer_other_details() {
    return (
      <View style={{ paddingTop: 25 }}>
        <View
          style={{
            // paddingTop: 50,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "5%",
              paddingLeft: 5,
              borderRightColor: "white",
              borderBottomColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text>1.</Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",

              width: "95%",
              borderLeftColor: "white",
              borderBottomColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 10 }}>
                Freight must be paid upfront during pickup of the packages or
                before arrival at ZAINCO warehouse Mombasa. A penalty of Sr 50
                will be levied on any HBL that has outstanding freight.
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            // paddingTop: 50,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "5%",
              paddingLeft: 5,
              borderRightColor: "white",
              borderTopColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text>2.</Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "95%",
              borderTopColor: "white",
              borderLeftColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 10 }}>
                Free time of 10 days from the date the packages arrives Mombasa
                warehouse, after which a tariff of Ksh. 1.50 or equivalent to
                0.0536 Saudi Riyals per kilo per day is applicable upto and
                until the day all charges are paid in full.
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  }
  show_report() {
    var details = {};
    const { data_for_reciept, invoice_details } = this.state;

    var s_name = "",
      s_address = "",
      s_town_country = "",
      s_phone = "",
      c_address = "",
      c_town_country = "",
      c_phone = "",
      c_name = "",
      hbl_number = "",
      date = "",
      invoice_number = "",
      c_delivery_town = "",
      s_town = "";

    if (invoice_details !== null) {
      var size = Object.keys(invoice_details).length;

      if (size > 0) {
        s_name = invoice_details.s_name;
        s_town_country = invoice_details.s_town_country;
        s_phone = invoice_details.s_phone;
        c_town_country = invoice_details.c_town_country;
        c_phone = invoice_details.c_phone;
        c_name = invoice_details.c_name;
        hbl_number = invoice_details.hbl_number;
        date = invoice_details.date;
        invoice_number = invoice_details.invoice_number;
        s_town = invoice_details.full_detail.shipper_details.town;
        c_delivery_town =
          invoice_details.full_detail.consignee_details.consignee_delivery;
        //213569
      }
    }
    var title = "ZAINCO INVOICE";
    var shipper_details = (
      <View style={{ padding: 5 }}>
        <Text
          style={{
            // marginTop: 20,

            paddingBottom: 3,
            fontFamily: "Helvetica-Oblique",
            fontSize: 10,
          }}
        >
          Shipper Details:
        </Text>
        <Text style={{ fontSize: 10 }}>{s_name}</Text>
        <Text style={{ fontSize: 10 }}>{s_town}</Text>
        <Text style={{ fontSize: 10 }}>{s_town_country}</Text>
        <Text style={{ fontSize: 10 }}>{s_phone}</Text>
      </View>
    );

    var consignee_details = (
      <View style={{ padding: 5 }}>
        <Text
          style={{
            // marginTop: 20,
            paddingBottom: 3,
            fontFamily: "Helvetica-Oblique",
            fontSize: 10,
          }}
        >
          Consignee Details:
        </Text>
        <Text style={{ fontSize: 10 }}>{c_name}</Text>
        <Text style={{ fontSize: 10 }}>{c_delivery_town}</Text>
        <Text style={{ fontSize: 10 }}>{c_town_country}</Text>
        <Text style={{ fontSize: 10 }}>{c_phone}</Text>
      </View>
    );

    var table_titles = this.table_titles();

    var line_items = this.line_items();

    var table_last_line = this.table_last_line();

    return (
      <Document>
        <Page
          size="A4"
          style={{
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35,
          }}
        >
          <View>{this.company_details()}</View>

          <View style={{ paddingTop: 10 }}>
            {this.hbl_details(invoice_details)}
          </View>

          <View style={{ paddingTop: 10 }}>
            {this.shipper_consignee_details({
              shipper_details,
              consignee_details,
            })}
          </View>
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              marginTop: 24,
              borderWidth: 1,
              borderColor: "black",
            }}
          >
            {table_titles}

            {line_items}
            {table_last_line}
          </View>
          <View style={{ paddingTop: 10 }}>
            {this.payment_details(invoice_details)}
            {this.total_charges_payments_outstanding(invoice_details)}

            <View style={{ paddingTop: 10 }}>
              {this.contact_details()}
              {this.banking_details()}
              {this.desclaimer_other_details()}
            </View>
          </View>
        </Page>
        <Page
          size="A4"
          style={{
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35,
          }}
        >
          {this.terms_and_conditions()}
        </Page>
      </Document>
    );
  }
  terms_and_conditions() {
    var terms = [
      "Packages and Items to be shipped must be well packed and have to be in good condition and labelled all contacts. Shipper agrees. to make him/her responsible for the standard of packing. In case packing done by ZAINCO staff the company does not take any responsibility for the packing. All fragile materials must be packed with Styrofoam.",
      "Fragile Items such as Electronics, Cutlery, Home Appliances etc. in case of breakage ZAINCO will not take any responsibility whatso ever and will not compensate. Wooden box is a must for all home appliance to safe your goods.",
      "Cash, Gold, Ammunitions, Dangerous goods, Chemicals etc are not allowed at any time. Goods with fluids must be indicated clearly by shipper and in the case of damages caused by goods shipper/consignee will be liable for all the damages caused.",
      "ZAINCO will deliver the aforesaid shipment to your door step as they were received not knowing either the contents or nature of the goods.",
      "ZAINCO is authorized by shipper to open any package(s) if requested by government authorities at any time.",
      "In the event of any package(s) delivered opened or unpacked due to routine customs verification the consignee s the right to refuse and returned on the spot, if accepted to collect weather not knowing the contents, ZAINCO will not accept any claim after that. ZAINCO will confirm the weight of the package as per system in case of any difference in weight the consignee will be compensated as per item no. (7) The claim must be forwarded by Shipper / Consignee by email immediately to the Head Office (zainkhitamy@gmail.com) verbal claim will not be entertain at all. Any claims will be dissolving as per our tariff.",
      "ZAINCO will compensate the shipper in case of lost package (s) or part of, with amount not exceeding2OO% of the shipping freight of lost package on prorata bases on cost of freight paid to ZAINCO or declared value which ever is less. In the event of commercial commodity compensation will not exceed USD 200 per package not less than 25 Kgs. ",
      "Freight charges must be paid upfront prior departure. Shipment will not be release if freight charges have not been paid in full, Ten Days is allowed as free time from the date your package (s) land in our warehouse after which storage charges will apply as per our tariff KSH. 1.50 Per kilo per day payable in Kenya prior release. And a penalty of equivalent to SR. 50/= will be charges in the event freight has not been fully paid and the shipment has arrived at our Mombasa warehouse.",
      "In the event of currency fluctuation, current market rate will be applied when making payment.",
      "ZAINCO has the right to auction your shipment at a force value to recover its freight and storage charges if the package not paid after 30 days from the date of arrival at our warehouse without giving any notice to the shipper or consignee.",
      "ZAINCO will not be responsible of any claim in case of civil unrest, mob destruction, anarchy, calamities etc. which may result in looting.",
      "Transit period is one month from the day the vessel departs Jeddah Port to arrival at our warehouse. ZAINCO is NOT responsible in the event of any delay caused by Shipping Line, Customs authority at departing port, or Custom authority at Port of destination but will make sure safe arrival of your cargo and ZAINCO will not be responsible for the loss of revenue due to the delay.",
      "ZAINCO reserves the right to confiscate all restricted/prohibited any point of transit without further warning to Shipper / Consignee. In such unlikely event that the restricted/prohibited consignments are shipped, ZAINCO will take legal action against the shipper and consignee. The Shipper/ Consignee will bear full costs of freight, Port charges, demurrage, transport expenses, Customs penalties, legal fees incurred etc",
      "ZAINCO is not a MUST to notify Shipper/Consignee upon arrival of his/her shipment and storage charges must be paid in case of freight not fully paid, no any excuse will be entertain.",
      "The above terms and conditions are fully effective for all packages and shipment carried.",
    ];

    var terms_array_show = [];
    terms.forEach((element, i) => {
      terms_array_show.push(
        <View
          style={{
            flexDirection: "row",
            borderColor: "white",
            flexWrap: "wrap",
            paddingTop: 10,
            // paddingBottom: 10,
          }}
        >
          <View style={{ paddingTop: 5, paddingRight: 5 }}>
            <Text style={{ fontSize: 10 }}>
              {i + 1}. {element}
            </Text>
          </View>
        </View>
      );
    });
    return (
      <View>
        <Text
          style={{
            fontSize: 24,
            textAlign: "center",
          }}
        >
          Terms and Conditions
        </Text>

        {terms_array_show}
      </View>
    );
  }

  */

  company_details() {
    return (
      <View
        style={{
          paddingTop: 5,
          paddingTop: 5,
          flexDirection: "row",
          flexWrap: "wrap",
          borderWidth: 1,
          borderColor: "#FFF",
        }}
      >
        <View
          style={{
            // width: "50%",
            width: "100%",
            // textAlign: "left",
            borderWidth: 1,
            borderColor: "#000",
          }}
        >
          <View style={{ padding: 5 }}>
            <View
              style={{ paddingBottom: 5, paddingTop: 5, alignItems: "center" }}
            >
              <Image
                style={{
                  width: 125,
                }}
                src={logo}
              />
            </View>

            <Text style={{ fontSize: 10, textAlign: "center" }}>
              info@zaincocargo.com
            </Text>
            <Text style={{ fontSize: 10, textAlign: "center" }}>
              www.zaincocargo.com
            </Text>
            <Text style={{ fontSize: 10, textAlign: "center" }}>
              Jeddah: 053-2305902, 055-4577423 || Riyadh: 050-3682739,
              055-4925017, 055-3366479 || Dammam: 050-3670629, 054-8337045 ||
              Nairobi: 0721-243800, 0726-376253|| Mombasa: 041-2228393,
              041-2228064
            </Text>
          </View>
        </View>
      </View>
    );
  }
  shipper_consignee_details({ shipper_details, consignee_details }) {
    return (
      <View
        style={{
          // paddingTop: 10,
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <View
          style={{
            borderWidth: 1,
            borderColor: "#000",
            width: "50%",
          }}
        >
          <View>{shipper_details}</View>
        </View>
        <View
          style={{
            borderWidth: 1,
            borderColor: "#000",
            borderLeftColor: "white",
            width: "50%",
          }}
        >
          <View>{consignee_details}</View>
        </View>
      </View>
    );
  }

  hbl_details(invoice_details) {
    var status = "Processing";
    if (invoice_details.status !== undefined) {
      status = invoice_details.status;
    }
    var closing_date = "";
    this.state.old_container_data.forEach((element) => {
      if (invoice_details.Lot_No === element.lot) {
        closing_date = element.closing_date;
      }
    });
    return (
      <View>
        <View style={{ paddingTop: 5 }}>
          <View
            style={{
              borderColor: "black",
              borderWidth: 1,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  HBL
                </Text>
              </View>

              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  DATE
                </Text>
              </View>

              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  LOT NO.
                </Text>
              </View>

              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  STATUS
                </Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  {invoice_details.hbl_number}
                </Text>
              </View>

              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  {invoice_details.date}
                </Text>
              </View>

              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  {invoice_details.Lot_No}
                </Text>
              </View>

              <View
                style={{
                  // width: "20%",
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  {status}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }

  payment_details(invoice_details) {
    var payment_details = [];

    var invoice_numbers = [];

    if (invoice_details.full_detail !== undefined) {
      payment_details = invoice_details.full_detail.payments_details;

      invoice_numbers = invoice_details.full_detail.invoice_numbers;
    }
    var full_detail = invoice_details.full_detail;

    var show_payment_details = [];

    if (payment_details.length > 0) {
      payment_details.forEach((element, i) => {
        var show_invoice_number = "";

        // if (invoice_numbers[i] !== undefined) {
        //   show_invoice_number = invoice_details.invoice_number;
        // }

        if (element.payment_receipt_no !== undefined) {
          show_invoice_number = element.payment_receipt_no;
        }

        var formated_amount = "-";
        var formated_date = "-";

        if (element.date !== "") {
          formated_date = element.date;
        }
        if (element.amount !== "") {
          formated_amount = parseFloat(element.amount)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        show_payment_details.push(
          <View
            style={{
              flexDirection: "row",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              alignItems: "center",
              height: 24,
              fontStyle: "bold",
            }}
          >
            <View
              style={{
                width: "25%",
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 10,
                  paddingLeft: 10,
                  fontWeight: "bold",
                }}
              >
                {i + 1}
              </Text>
            </View>

            <View
              style={{
                width: "25%",
                borderRightColor: "black",
                borderRightWidth: 1,

                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {show_invoice_number}
              </Text>
            </View>

            <View
              style={{
                width: "25%",
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {formated_date}
              </Text>
            </View>

            <View
              style={{
                width: "25%",
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {formated_amount}
              </Text>
            </View>
          </View>
        );
      });
    }
    return (
      <View>
        <View
          style={{
            borderWidth: 1,
            borderColor: "#000",
            width: "100%",
          }}
        >
          <View style={{ paddingTop: 5, paddingRight: 5 }}>
            <Text style={{ paddingLeft: 5, fontSize: 10 }}>
              PAYMENT DETAILS
            </Text>
          </View>
        </View>

        <View style={{ paddingTop: 5 }}>
          <View
            style={{
              borderColor: "black",
              borderWidth: 1,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  No.
                </Text>
              </View>

              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  PAYMENT RECEIPT NO.
                </Text>
              </View>

              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  DATE
                </Text>
              </View>

              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  AMOUNT
                </Text>
              </View>
            </View>

            {show_payment_details}
          </View>
        </View>
      </View>
    );
  }

  contact_details() {
    // const VAT_NO = "103220002236175 || ۱۰۳۲۲۰۰۰۲۲۳٦۱۷٥"; //"010004185494";
    const VAT_NO = "311244697700003";
    const MOBILE = "+966505691673";

    return (
      <View>
        <View
          style={{
            borderWidth: 1,
            borderColor: "#000",
            width: "100%",
          }}
        >
          <View style={{ paddingTop: 5, paddingRight: 5 }}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                height: 45,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "50%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  VAT NO. {VAT_NO}
                </Text>
              </View>

              <View
                style={{
                  width: "50%",
                }}
              >
                <View
                  style={{
                    paddingBottom: 5,
                    paddingTop: 5,
                    alignItems: "right",
                  }}
                >
                  <Image
                    style={{
                      width: 150,
                    }}
                    src={vat_arabic}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    );

    /* return (
      <View>
        <View
          style={{
            borderWidth: 1,
            borderColor: "#000",
            width: "100%",
          }}
        >
          <View style={{ paddingTop: 5, paddingRight: 5 }}>
            <Text style={{ paddingLeft: 5, fontSize: 10 }}>
              VAT NO. {VAT_NO}
            </Text>

            <View
              style={{ paddingBottom: 5, paddingTop: 5, alignItems: "center" }}
            >
              <Image
                style={{
                  width: 150,
                }}
                src={vat_arabic}
              />
            </View>
          </View>
        </View>
      </View>
    );*/
  }
  // contact_details() {
  //   return (
  //     <View>
  //       <View
  //         style={{
  //           borderWidth: 1,
  //           borderColor: "#000",
  //           width: "100%",
  //         }}
  //       >
  //         <View style={{ paddingTop: 5, paddingRight: 5 }}>
  //           <Text style={{ paddingLeft: 5, fontSize: 10 }}>
  //             MOBILE +966505691673 JEDDAH, KING KHALID STREET VAT NO.
  //             310561094900003
  //           </Text>
  //         </View>
  //       </View>
  //     </View>
  //   );
  // }

  total_charges_payments_outstanding(invoice_details) {
    var full_detail = invoice_details.full_detail;

    var converted_total_charges = 0,
      converted_total_payments = 0,
      converted_total_outstanding = 0,
      total_charges = 0,
      total_outstanding_balance = 0,
      total_payments = 0;
    if (full_detail !== undefined) {
      total_payments = parseFloat(full_detail.calculations.total_payments)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      total_charges = parseFloat(full_detail.calculations.total_charges)
        .toFixed(2)

        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      converted_total_charges = (
        parseFloat(full_detail.calculations.total_charges) *
        parseFloat(full_detail.calculations.conversion_selected_rate)
      )
        .toFixed(2)

        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      converted_total_payments = (
        parseFloat(full_detail.calculations.total_payments) *
        parseFloat(full_detail.calculations.conversion_selected_rate)
      )
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      total_outstanding_balance = parseFloat(
        full_detail.calculations.total_outstanding_balance
      )
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      converted_total_outstanding = (
        parseFloat(full_detail.calculations.total_outstanding_balance) *
        full_detail.calculations.conversion_selected_rate
      )

        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return (
      <View style={{ paddingTop: 15 }}>
        <View
          style={{
            borderColor: "black",
            borderWidth: 1,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              alignItems: "center",
              height: 24,
              fontStyle: "bold",
            }}
          >
            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 10,
                  paddingLeft: 10,
                  fontWeight: "bold",
                }}
              ></Text>
            </View>

            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                SAUDI RIYALS
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                KENYA SHILLINGS
              </Text>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              alignItems: "center",
              height: 24,
              fontStyle: "bold",
            }}
          >
            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 10,
                  paddingLeft: 10,
                  fontWeight: "bold",
                }}
              >
                TOTAL CHARGES
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {total_charges}
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {converted_total_charges}
              </Text>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              alignItems: "center",
              height: 24,
              fontStyle: "bold",
            }}
          >
            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 10,
                  paddingLeft: 10,
                  fontWeight: "bold",
                }}
              >
                TOTAL PAYMENTS
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {total_payments}
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {converted_total_payments}
              </Text>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              alignItems: "center",
              height: 24,
              fontStyle: "bold",
            }}
          >
            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
                backgroundColor: "#E0E0E0",
              }}
            >
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 10,
                  paddingLeft: 10,
                  fontWeight: "bold",
                }}
              >
                OUTSTANDING
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
                backgroundColor: "#E0E0E0",
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {total_outstanding_balance}
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
                backgroundColor: "#E0E0E0",
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {converted_total_outstanding}
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  }
  banking_details() {
    // const { ncb_bank_account, inma_bank_account } = this.props;
    const NCB_BANK_ACCOUNT = "####";
    const INMA_BANK_ACCOUNT = "####";
    return (
      <View style={{ paddingTop: 25 }}>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5, fontWeight: "bold" }}>
                NCB BANK ACCOUNT
              </Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 11, paddingLeft: 5 }}>
                {NCB_BANK_ACCOUNT}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            // paddingTop: 50,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5, fontWeight: "bold" }}>
                INMA BANK ACCOUNT
              </Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 11, paddingLeft: 5 }}>
                {INMA_BANK_ACCOUNT}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            // paddingTop: 50,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5, fontWeight: "bold" }}>
                GULF AFRICAN BANK
              </Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 11, paddingLeft: 5 }}>0330010801</Text>
            </View>
          </View>
        </View>

        <View
          style={{
            // paddingTop: 50,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5, fontWeight: "bold" }}>
                MPESA
              </Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 11, paddingLeft: 5 }}>
                PAYBILL 985050 ACCOUNT 0130002301
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            // paddingTop: 50,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5 }}>STCPAY</Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5, paddingLeft: 5 }}>
              <Text style={{ fontSize: 11 }}>0555691673 TURKY AL ZAHRANI</Text>
            </View>
          </View>
        </View>
      </View>
    );
  }
  desclaimer_other_details() {
    return (
      <View style={{ paddingTop: 25 }}>
        <View
          style={{
            // paddingTop: 50,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "5%",
              paddingLeft: 5,
              borderRightColor: "white",
              borderBottomColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text>1.</Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",

              width: "95%",
              borderLeftColor: "white",
              borderBottomColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 10 }}>
                Freight must be paid upfront during pickup of the packages or
                before arrival at ZAINCO warehouse Mombasa. A penalty of Sr 50
                will be levied on any HBL that has outstanding freight.
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            // paddingTop: 50,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "5%",
              paddingLeft: 5,
              borderRightColor: "white",
              borderTopColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text>2.</Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "95%",
              borderTopColor: "white",
              borderLeftColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 10 }}>
                Free time of 10 days from the date the packages arrives Mombasa
                warehouse, after which a tariff of Ksh. 1.50 or equivalent to
                0.0536 Saudi Riyals per kilo per day is applicable upto and
                until the day all charges are paid in full.
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  }
  show_report() {
    var details = {};
    const { data_for_reciept, invoice_details } = this.state;
    var size = Object.keys(invoice_details).length;

    /*  var s_name = "",
      s_address = "",
      s_town_country = "",
      s_phone = "",
      c_address = "",
      c_town_country = "",
      c_phone = "",
      c_name = "",
      hbl_number = "",
      date = "",
      invoice_number = "",
      c_delivery_town = "",
      s_town = "";

    if (size > 0) {
      s_name = invoice_details.s_name;
      s_town_country = invoice_details.s_town_country;
      s_phone = invoice_details.s_phone;
      c_town_country = invoice_details.c_town_country;
      c_phone = invoice_details.c_phone;
      c_name = invoice_details.c_name;
      hbl_number = invoice_details.hbl_number;
      date = invoice_details.date;
      invoice_number = invoice_details.invoice_number;
      s_town = invoice_details.full_detail.shipper_details.town;
      c_delivery_town =
        invoice_details.full_detail.consignee_details.consignee_delivery;
    }
    */

    var s_name = "",
      s_address = "",
      s_town_country = "",
      s_phone = "",
      c_address = "",
      c_town_country = "",
      c_phone = "",
      c_name = "",
      hbl_number = "",
      date = "",
      invoice_number = "",
      c_delivery_town = "",
      s_town = "";

    if (invoice_details !== null) {
      var size = Object.keys(invoice_details).length;

      if (size > 0) {
        s_name = invoice_details.s_name;
        s_town_country = invoice_details.s_town_country;
        s_phone = invoice_details.s_phone;
        c_town_country = invoice_details.c_town_country;
        c_phone = invoice_details.c_phone;
        c_name = invoice_details.c_name;
        hbl_number = invoice_details.hbl_number;
        date = invoice_details.date;
        invoice_number = invoice_details.invoice_number;
        s_town = invoice_details.full_detail.shipper_details.town;
        c_delivery_town =
          invoice_details.full_detail.consignee_details.consignee_delivery;
        //213569
      }
    }

    var title = "ZAINCO INVOICE";
    var shipper_details = (
      <View style={{ padding: 5 }}>
        <Text
          style={{
            // marginTop: 20,

            paddingBottom: 3,
            fontFamily: "Helvetica-Oblique",
            fontSize: 10,
          }}
        >
          Shipper Details:
        </Text>
        <Text style={{ fontSize: 10 }}>{s_name}</Text>
        <Text style={{ fontSize: 10 }}>{s_town}</Text>
        <Text style={{ fontSize: 10 }}>{s_town_country}</Text>
        <Text style={{ fontSize: 10 }}>{s_phone}</Text>
      </View>
    );

    var consignee_details = (
      <View style={{ padding: 5 }}>
        <Text
          style={{
            // marginTop: 20,
            paddingBottom: 3,
            fontFamily: "Helvetica-Oblique",
            fontSize: 10,
          }}
        >
          Consignee Details:
        </Text>
        <Text style={{ fontSize: 10 }}>{c_name}</Text>
        <Text style={{ fontSize: 10 }}>{c_delivery_town}</Text>
        <Text style={{ fontSize: 10 }}>{c_town_country}</Text>
        <Text style={{ fontSize: 10 }}>{c_phone}</Text>
      </View>
    );

    var table_titles = this.table_titles();

    var line_items = this.line_items();

    var table_last_line = this.table_last_line();

    return (
      <Document>
        <Page
          size="A4"
          style={{
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35,
          }}
        >
          <View>{this.company_details()}</View>

          <View style={{ paddingTop: 10 }}>
            {this.hbl_details(invoice_details)}
          </View>

          <View style={{ paddingTop: 10 }}>
            {this.shipper_consignee_details({
              shipper_details,
              consignee_details,
            })}
          </View>
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              marginTop: 24,
              borderWidth: 1,
              borderColor: "black",
            }}
          >
            {table_titles}

            {line_items}
            {table_last_line}
          </View>
          <View style={{ paddingTop: 10 }}>
            {this.payment_details(invoice_details)}
            {this.total_charges_payments_outstanding(invoice_details)}

            <View style={{ paddingTop: 10 }}>
              {this.contact_details()}
              {this.banking_details()}
              {this.desclaimer_other_details()}
            </View>
          </View>
        </Page>

        <Page
          size="A4"
          style={{
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35,
          }}
        >
          {this.terms_and_conditions()}
        </Page>
      </Document>
    );
  }
  terms_and_conditions() {
    var terms = [
      "Packages and Items to be shipped must be well packed and have to be in good condition and labelled all contacts. Shipper agrees. to make him/her responsible for the standard of packing. In case packing done by ZAINCO staff the company does not take any responsibility for the packing. All fragile materials must be packed with Styrofoam.",
      "Fragile Items such as Electronics, Cutlery, Home Appliances etc. in case of breakage ZAINCO will not take any responsibility whatso ever and will not compensate. Wooden box is a must for all home appliance to safe your goods.",
      "Cash, Gold, Ammunitions, Dangerous goods, Chemicals etc are not allowed at any time. Goods with fluids must be indicated clearly by shipper and in the case of damages caused by goods shipper/consignee will be liable for all the damages caused.",
      "ZAINCO will deliver the aforesaid shipment to your door step as they were received not knowing either the contents or nature of the goods.",
      "ZAINCO is authorized by shipper to open any package(s) if requested by government authorities at any time.",
      "In the event of any package(s) delivered opened or unpacked due to routine customs verification the consignee s the right to refuse and returned on the spot, if accepted to collect weather not knowing the contents, ZAINCO will not accept any claim after that. ZAINCO will confirm the weight of the package as per system in case of any difference in weight the consignee will be compensated as per item no. (7) The claim must be forwarded by Shipper / Consignee by email immediately to the Head Office (zainkhitamy@gmail.com) verbal claim will not be entertain at all. Any claims will be dissolving as per our tariff.",
      "ZAINCO will compensate the shipper in case of lost package (s) or part of, with amount not exceeding2OO% of the shipping freight of lost package on prorata bases on cost of freight paid to ZAINCO or declared value which ever is less. In the event of commercial commodity compensation will not exceed USD 200 per package not less than 25 Kgs. ",
      "Freight charges must be paid upfront prior departure. Shipment will not be release if freight charges have not been paid in full, Ten Days is allowed as free time from the date your package (s) land in our warehouse after which storage charges will apply as per our tariff KSH. 1.50 Per kilo per day payable in Kenya prior release. And a penalty of equivalent to SR. 50/= will be charges in the event freight has not been fully paid and the shipment has arrived at our Mombasa warehouse.",
      "In the event of currency fluctuation, current market rate will be applied when making payment.",
      "ZAINCO has the right to auction your shipment at a force value to recover its freight and storage charges if the package not paid after 30 days from the date of arrival at our warehouse without giving any notice to the shipper or consignee.",
      "ZAINCO will not be responsible of any claim in case of civil unrest, mob destruction, anarchy, calamities etc. which may result in looting.",
      "Transit period is one month from the day the vessel departs Jeddah Port to arrival at our warehouse. ZAINCO is NOT responsible in the event of any delay caused by Shipping Line, Customs authority at departing port, or Custom authority at Port of destination but will make sure safe arrival of your cargo and ZAINCO will not be responsible for the loss of revenue due to the delay.",
      "ZAINCO reserves the right to confiscate all restricted/prohibited any point of transit without further warning to Shipper / Consignee. In such unlikely event that the restricted/prohibited consignments are shipped, ZAINCO will take legal action against the shipper and consignee. The Shipper/ Consignee will bear full costs of freight, Port charges, demurrage, transport expenses, Customs penalties, legal fees incurred etc",
      "ZAINCO is not a MUST to notify Shipper/Consignee upon arrival of his/her shipment and storage charges must be paid in case of freight not fully paid, no any excuse will be entertain.",
      "The above terms and conditions are fully effective for all packages and shipment carried.",
    ];

    var terms_array_show = [];
    terms.forEach((element, i) => {
      terms_array_show.push(
        <View
          style={{
            flexDirection: "row",
            borderColor: "white",
            flexWrap: "wrap",
            paddingTop: 10,
            // paddingBottom: 10,
          }}
        >
          <View style={{ paddingTop: 5, paddingRight: 5 }}>
            <Text style={{ fontSize: 10 }}>
              {i + 1}. {element}
            </Text>
          </View>
        </View>
      );
    });
    return (
      <View>
        <Text
          style={{
            fontSize: 24,
            textAlign: "center",
          }}
        >
          Terms and Conditions
        </Text>

        {terms_array_show}
      </View>
    );
  }
  render() {
    const { ready, redirect } = this.state;
    const MyDocument = this.show_report();
    if (redirect === "CHECKING") {
      return (
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col lg="4"></Col>
              <Col lg="4"></Col>
              <Col lg="4"></Col>
            </Row>
          </Container>
        </div>
      );
    } else {
      // if (redirect === true && redirect !== "CHECKING") {
      //   return <Redirect to={{ pathname: "/" }} />;
      // }
      return (
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              <Row>
                <Col lg="4"></Col>
                <Col lg="4">
                  <Card>
                    <CardBody>
                      <div className="d-print-none">
                        <div className="text-center">
                          <img src={logo} alt="logo" height="40" />
                        </div>
                      </div>
                      <div className="d-print-none">
                        <div className="text-center">
                          <p>
                            Please click "Download Now" to generate your invoice
                          </p>
                        </div>
                      </div>
                      <div className="d-print-none">
                        <div className="text-center">
                          {/*<Link
                          to="#"
                          className="btn btn-primary w-md waves-effect waves-light"
                        >
                          Download Now
                        </Link>*/}
                          <div>
                            {ready && (
                              <PDFDownloadLink
                                document={MyDocument}
                                fileName="PDF"
                              >
                                {({ blob, url, loading, error }) =>
                                  loading ? (
                                    "Loading document..."
                                  ) : (
                                    <Button
                                      onClick={() => {
                                        this.setState({ ready: false });
                                        this.toggle();
                                      }}
                                    >
                                      Download Now
                                    </Button>
                                  )
                                }
                              </PDFDownloadLink>
                            )}
                            {!ready && (
                              <Button
                                onClick={() => {
                                  // this.setState({ data_to_show_in_table });
                                  this.toggle();
                                }}
                              >
                                Generate Now
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4"></Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      );
    }
  }
}
//    return <Redirect to={{ pathname: "/" }} />;

export default CryptoIcoLanding;
