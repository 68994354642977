import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFViewer,
  BlobProvider,
  pdf,
  PDFDownloadLink,
} from "@react-pdf/renderer";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Label,
  Input,
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  InputGroupAddon,
  InputGroup,
  Modal,
  Form,
  Button,
} from "reactstrap";
import { MDBDataTable } from "mdbreact";

import classnames from "classnames";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import logo from "../../assets/images/logo-dark.png";

import firebase from "firebase/app";
import "firebase/database";
import moment from "moment";
import moment_time_zone from "moment-timezone";
//moment_time_zone().tz("Etc/GMT")

/***/
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { shipping_data_read, detail_error } from "../../store/actions";
import Lottie from "react-lottie";
/***/
import vat_arabic from "../../assets/images/VAT_Arabic.png"; //"../../../assets/images/VAT_Arabic.png";

class Cash_Slip extends Component {
  constructor() {
    super();
    this.state = {
      activeTab: "1",
      data_by_lot_no: [],
      start_date: "",
      end_date: "",
      ready: [], //false,
      data_to_download_pdf: {},
      results: {},
      old_container_data: [],

      conversion_selected: {},
      invoice_details: {},
    };
  }

  toggle(i) {
    var temp_ready = this.state.ready;
    temp_ready[i] = false;

    this.setState(
      {
        ready: temp_ready,
      },
      () => {
        setTimeout(() => {
          var second_temp = this.state.ready;
          second_temp[i] = true;
          this.setState({ ready: second_temp });
        }, 1);
      }
    );
  }
  // componentDidMount() {
  //   firebase
  //     .database()
  //     .ref("organization/zaincocargo/container/")
  //     .once("value")
  //     .then((snapshot) => {
  //       var data_array = _.map(snapshot.val(), (val, uid) => {
  //         return { ...val, uid };
  //       });

  //       this.setState({
  //         old_container_data: data_array,
  //       });
  //     });
  // }

  table_titles() {
    return (
      <View
        style={{
          flexDirection: "row",
          borderBottomColor: "black",
          borderBottomWidth: 1,
          alignItems: "center",
          height: 24,
          textAlign: "center",
          fontStyle: "bold",
        }}
      >
        <View
          style={{
            width: `${100 / 3}%`,
            borderRightColor: "black",
            borderRightWidth: 1,
            backgroundColor: "#E0E0E0",
            paddingTop: 2,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
            }}
          >
            DESCRIPTION
          </Text>
        </View>

        <View
          style={{
            width: `${100 / 3}%`,
            borderRightColor: "black",
            borderRightWidth: 1,
            backgroundColor: "#E0E0E0",
            paddingTop: 2,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
            }}
          >
            PCS
          </Text>
        </View>

        <View
          style={{
            width: `${100 / 3}%`,
            borderRightColor: "black",
            borderRightWidth: 1,
            backgroundColor: "#E0E0E0",
            paddingTop: 2,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
            }}
          >
            KGS
          </Text>
        </View>
      </View>
    );
  }

  adjust_rate() {
    const { rates_data_extracted, cargo_details } = this.state;

    //////////////////////////////
    var rate = 0;
    var total_weight = 0,
      total_quantity = 0;

    if (cargo_details !== undefined) {
      cargo_details.forEach((element) => {
        if (element.quantity !== "") {
          total_quantity = total_quantity + parseFloat(element.quantity);
        }
        if (element.weight !== "") {
          total_weight = total_weight + parseFloat(element.weight);
        }
      });
    }

    var rates = {};
    if (rates_data_extracted.rates_by_destination !== undefined) {
      var rates_by_destination = _.map(
        rates_data_extracted.rates_by_destination,
        (val, uid) => {
          return { ...val, uid };
        }
      );

      rates_by_destination.forEach((element) => {
        if (this.state.consignee_town === element.city) {
          rates = element;
        }
      });
    }

    if (total_weight < 50) {
      rate = rates.less_than_50_kg;
    } else if (total_weight >= 50 && total_weight < 100) {
      rate = rates.less_than_100_kg;
    } else {
      rate = rates.over_100_kg;
    }
    ////////////////////////////////////////////////////////

    if (rate !== 0) {
      var new_cargo_cargo_details = [];
      if (cargo_details !== undefined) {
        cargo_details.forEach((element) => {
          var edit_element = element;
          edit_element.rate = rate;
          new_cargo_cargo_details.push(edit_element);
        });
      }
      return new_cargo_cargo_details;
    } else {
      return cargo_details;
    }
  }
  line_items() {
    const { invoice_details } = this.state;
    var data = [];
    if (Object.keys(invoice_details).length > 0) {
      var cargo_details = invoice_details.cargo_details;

      cargo_details.forEach((element, i) => {
        data.push(
          <View
            style={{
              flexDirection: "row",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              alignItems: "center",
              height: 24,
              fontStyle: "bold",
            }}
            key={i}
          >
            <Text
              style={{
                width: `${100 / 3}%`,
                textAlign: "left",
                borderBottomColor: "black",
                borderRightWidth: 1,
                paddingLeft: 8,
                fontSize: 9,
              }}
            >
              {element.description}
            </Text>
            <Text
              style={{
                width: `${100 / 3}%`,
                borderBottomColor: "black",
                borderRightWidth: 1,
                textAlign: "center",
                paddingRight: 8,
                fontSize: 9,
              }}
            >
              {element.quantity}
            </Text>
            <Text
              style={{
                width: `${100 / 3}%`,
                borderBottomColor: "black",
                borderRightWidth: 1,
                textAlign: "center",
                paddingRight: 8,
                fontSize: 9,
              }}
            >
              {element.weight}
            </Text>
          </View>
        );
      });
    }

    return data;
  }

  table_last_line() {
    const { data_for_reciept, invoice_details } = this.state;

    var view_to_show = <View></View>;
    if (Object.keys(invoice_details).length > 0) {
      var payments_details = invoice_details.payments_details;
      var calculations = invoice_details.calculations;
      var full_detail = invoice_details.full_detail;

      var payments_showing_array = [];

      payments_details.forEach((element, i) => {
        var date_to_show = moment_time_zone(element.date)
          .tz("Etc/GMT")
          .format("DD/MM/YYYY");

        payments_showing_array.push(
          <View
            style={{
              flexDirection: "row",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              alignItems: "center",
              height: 24,
              fontSize: 12,
              fontStyle: "bold",
            }}
          >
            <Text
              style={{
                width: "83.75%",
                textAlign: "right",
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingRight: 8,
                fontSize: 12,
              }}
            >
              Payment #{i + 1}({date_to_show})
            </Text>
            <Text
              style={{
                width: "16.25%",
                textAlign: "left",
                paddingRight: 8,
                fontSize: 10,
                paddingLeft: 5,
              }}
            >
              {parseFloat(element.amount).toFixed(2)}
            </Text>
          </View>
        );
      });

      var total_pieces = 0;
      var total_weight = 0;
      var rate = 0;

      if (full_detail.cargo_details !== undefined) {
        full_detail.cargo_details.forEach((element) => {
          if (element.quantity !== "") {
            total_pieces = total_pieces + parseFloat(element.quantity);
          }
          if (element.weight !== "") {
            total_weight = total_weight + parseFloat(element.weight);
          }

          rate = element.rate;
        });
      }

      view_to_show = (
        <View>
          <View
            style={{
              flexDirection: "row",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              alignItems: "center",
              height: 24,
              fontStyle: "bold",
            }}
          >
            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                backgroundColor: "#E0E0E0",
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 10,
                  paddingLeft: 10,
                  fontWeight: "bold",
                }}
              >
                TOTAL
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                backgroundColor: "#E0E0E0",
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {total_pieces}
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                backgroundColor: "#E0E0E0",
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {total_weight}
              </Text>
            </View>
          </View>
        </View>
      );
    }

    return view_to_show;
  }

  company_details() {
    return (
      <View
        style={{
          paddingTop: 5,
          paddingTop: 5,
          flexDirection: "row",
          flexWrap: "wrap",
          borderWidth: 1,
          borderColor: "#FFF",
        }}
      >
        <View
          style={{
            // width: "50%",
            width: "100%",
            // textAlign: "left",
            borderWidth: 1,
            borderColor: "#000",
          }}
        >
          <View style={{ padding: 5 }}>
            <View
              style={{ paddingBottom: 5, paddingTop: 5, alignItems: "center" }}
            >
              <Image
                style={{
                  width: 125,
                }}
                src={logo}
              />
            </View>

            <Text style={{ fontSize: 10, textAlign: "center" }}>
              info@doqli.com
            </Text>
            <Text style={{ fontSize: 10, textAlign: "center" }}>
              www.doqli.com
            </Text>
            <Text style={{ fontSize: 10, textAlign: "center" }}>
              Jeddah: 053-2303902, 055-4573323 || Riyadh: 050-3682739,
              055-4924417, 055-3444479
            </Text>
          </View>
        </View>
      </View>
    );
  }
  shipper_consignee_details({ shipper_details, consignee_details }) {
    return (
      <View
        style={{
          // paddingTop: 10,
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <View
          style={{
            borderWidth: 1,
            borderColor: "#000",
            width: "50%",
          }}
        >
          <View>{shipper_details}</View>
        </View>
        <View
          style={{
            borderWidth: 1,
            borderColor: "#000",
            borderLeftColor: "white",
            width: "50%",
          }}
        >
          <View>{consignee_details}</View>
        </View>
      </View>
    );
  }

  hbl_details(invoice_details) {
    var closing_date = "";

    const { container_data } = this.props;

    var old_container_data = _.map(container_data, (val, uid) => {
      return { ...val, uid };
    });

    old_container_data.forEach((element) => {
      if (invoice_details.Lot_No === element.lot) {
        closing_date = element.closing_date;
      }
    });
    return (
      <View>
        <View style={{ paddingTop: 5 }}>
          <View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                  borderColor: "black",
                  borderWidth: 1,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  HBL
                </Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",

                alignItems: "center",
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  borderLeftColor: "black",
                  borderLeftWidth: 1,
                  borderBottomColor: "black",
                  borderBottomWidth: 1,

                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  {invoice_details.hbl_number}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }

  payment_details(invoice_details) {
    var payment_details = [];

    var invoice_numbers = [];

    if (invoice_details.full_detail !== undefined) {
      payment_details = invoice_details.full_detail.payments_details;

      invoice_numbers = invoice_details.full_detail.invoice_numbers;
    }
    var full_detail = invoice_details.full_detail;

    var show_payment_details = [];

    if (payment_details.length > 0) {
      payment_details.forEach((element, i) => {
        var show_invoice_number = "";
        // if (invoice_numbers[i] !== undefined) {
        //   show_invoice_number = invoice_details.invoice_number; //invoice_numbers[i].invoice;
        // }

        if (element.payment_receipt_no !== undefined) {
          show_invoice_number = element.payment_receipt_no;
        }

        show_payment_details.push(
          <View
            style={{
              flexDirection: "row",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              alignItems: "center",
              height: 24,
              fontStyle: "bold",
            }}
          >
            <View
              style={{
                width: "25%",
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 10,
                  paddingLeft: 10,
                  fontWeight: "bold",
                }}
              >
                {i + 1}
              </Text>
            </View>

            <View
              style={{
                width: "25%",
                borderRightColor: "black",
                borderRightWidth: 1,
                // backgroundColor: "#E0E0E0",
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {invoice_numbers[i].invoice}
              </Text>
            </View>

            <View
              style={{
                width: "25%",
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {element.date}
              </Text>
            </View>

            <View
              style={{
                width: "25%",
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {parseFloat(element.amount)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Text>
            </View>
          </View>
        );
      });
    }
    return (
      <View>
        <View
          style={{
            borderWidth: 1,
            borderColor: "#000",
            width: "100%",
          }}
        >
          <View style={{ paddingTop: 5, paddingRight: 5 }}>
            <Text style={{ paddingLeft: 5, fontSize: 10 }}>
              PAYMENT DETAILS
            </Text>
          </View>
        </View>

        <View style={{ paddingTop: 5 }}>
          <View
            style={{
              borderColor: "black",
              borderWidth: 1,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  No.
                </Text>
              </View>

              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  PAYMENT RECEIPT NO.
                </Text>
              </View>

              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  DATE
                </Text>
              </View>

              <View
                style={{
                  width: "25%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  AMOUNT
                </Text>
              </View>
            </View>

            {show_payment_details}
          </View>
        </View>
      </View>
    );
  }

  contact_details() {
    const { vat_no, mobile } = this.props;

    return (
      <View>
        <View
          style={{
            borderWidth: 1,
            borderColor: "#000",
            width: "100%",
          }}
        >
          <View style={{ paddingTop: 5, paddingRight: 5 }}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                height: 45,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: "50%",
                  borderRightColor: "black",
                  borderRightWidth: 1,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  VAT NO. {vat_no}
                </Text>
              </View>

              <View
                style={{
                  width: "50%",
                }}
              >
                <View
                  style={{
                    paddingBottom: 5,
                    paddingTop: 5,
                    alignItems: "right",
                  }}
                >
                  <Image
                    style={{
                      width: 150,
                    }}
                    src={vat_arabic}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    );

    /* return (
      <View>
        <View
          style={{
            borderWidth: 1,
            borderColor: "#000",
            width: "100%",
          }}
        >
          <View style={{ paddingTop: 5, paddingRight: 5 }}>
            <Text style={{ paddingLeft: 5, fontSize: 10 }}>
              VAT NO. {vat_no}
            </Text>

            <View
              style={{ paddingBottom: 5, paddingTop: 5, alignItems: "center" }}
            >
              <Image
                style={{
                  width: 150,
                }}
                src={vat_arabic}
              />
            </View>
          </View>
        </View>
      </View>
    );*/
    // return (
    //   <View>
    //     <View
    //       style={{
    //         borderWidth: 1,
    //         borderColor: "#000",
    //         width: "100%",
    //       }}
    //     >
    //       <View style={{ paddingTop: 5, paddingRight: 5 }}>
    //         <Text style={{ paddingLeft: 5, fontSize: 10 }}>
    //           MOBILE {mobile} JEDDAH, KING KHALID STREET VAT NO.
    //           {vat_no}
    //         </Text>
    //       </View>
    //     </View>
    //   </View>
    // );
  }

  total_charges_payments_outstanding(invoice_details) {
    var full_detail = invoice_details.full_detail;

    var converted_total_charges = 0,
      converted_total_payments = 0,
      converted_total_outstanding = 0,
      total_charges = 0,
      total_outstanding_balance = 0,
      total_payments = 0;
    if (full_detail !== undefined) {
      total_outstanding_balance =
        full_detail.calculations.total_outstanding_balance
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      total_payments = full_detail.calculations.total_payments
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      total_charges = full_detail.calculations.total_charges
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      converted_total_charges = (
        parseFloat(full_detail.calculations.total_charges) *
        full_detail.calculations.conversion_selected_rate
      )
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      converted_total_payments = (
        parseFloat(full_detail.calculations.total_payments) *
        full_detail.calculations.conversion_selected_rate
      )
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      converted_total_outstanding = (
        parseFloat(full_detail.calculations.total_outstanding_balance) *
        full_detail.calculations.conversion_selected_rate
      )
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return (
      <View style={{ paddingTop: 15 }}>
        <View
          style={{
            borderColor: "black",
            borderWidth: 1,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              alignItems: "center",
              height: 24,
              fontStyle: "bold",
            }}
          >
            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 10,
                  paddingLeft: 10,
                  fontWeight: "bold",
                }}
              ></Text>
            </View>

            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                SAUDI RIYALS
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                KENYA SHILLINGS
              </Text>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              alignItems: "center",
              height: 24,
              fontStyle: "bold",
            }}
          >
            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 10,
                  paddingLeft: 10,
                  fontWeight: "bold",
                }}
              >
                TOTALS CHARGES
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {total_charges}
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {converted_total_charges}
              </Text>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              alignItems: "center",
              height: 24,
              fontStyle: "bold",
            }}
          >
            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 10,
                  paddingLeft: 10,
                  fontWeight: "bold",
                }}
              >
                TOTAL PAYMENTS
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {total_payments}
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {converted_total_payments}
              </Text>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              alignItems: "center",
              height: 24,
              fontStyle: "bold",
            }}
          >
            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
                backgroundColor: "#E0E0E0",
              }}
            >
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 10,
                  paddingLeft: 10,
                  fontWeight: "bold",
                }}
              >
                OUTSTANDING
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
                backgroundColor: "#E0E0E0",
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {total_outstanding_balance}
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 3}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
                backgroundColor: "#E0E0E0",
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {converted_total_outstanding}
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  }

  signature_box(invoice_details) {
    var uid = "1234",
      hbl_number = "",
      PAYMENT_IN_SAUDI_RIYALS = "",
      PAYMENT_IN_KSH = "",
      date = "",
      mode = "";

    if (invoice_details !== undefined) {
      if (invoice_details.calculations !== undefined) {
        uid = invoice_details.uid;
        hbl_number = invoice_details.hbl_number;
        var payment_details = invoice_details.full_detail.payments_details;

        mode = payment_details[payment_details.length - 1].mode;
        if (mode === undefined) {
          mode = "";
        }
        PAYMENT_IN_SAUDI_RIYALS = parseFloat(
          invoice_details.calculations.total_payments
        )
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        PAYMENT_IN_KSH = (
          parseFloat(invoice_details.calculations.total_payments) *
          invoice_details.calculations.conversion_selected_rate
        )
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        date = invoice_details.date;
      }
    }

    var size = Object.keys(invoice_details).length;

    if (size > 0) {
      var payment_details = [];

      var invoice_numbers = [];

      if (invoice_details.full_detail !== undefined) {
        payment_details = invoice_details.full_detail.payments_details;

        invoice_numbers = invoice_details.full_detail.invoice_numbers;
      }

      var show_payment_details = [];

      if (payment_details.length > 0) {
        payment_details.forEach((element, i) => {
          var show_invoice_number = "";

          if (element.payment_receipt_no !== undefined) {
            show_invoice_number = element.payment_receipt_no;
          }

          PAYMENT_IN_SAUDI_RIYALS = parseFloat(element.amount)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          PAYMENT_IN_KSH = (
            parseFloat(element.amount) *
            invoice_details.calculations.conversion_selected_rate
          )
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          show_payment_details.push(
            <View
              style={{
                flexDirection: "row",
                borderBottomColor: "black",
                borderBottomWidth: 1,
                alignItems: "center",
                height: 24,
                fontStyle: "bold",
              }}
            >
              <View
                style={{
                  width: `${100 / 6}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    fontWeight: "bold",
                  }}
                >
                  {show_invoice_number}
                </Text>
              </View>

              <View
                style={{
                  width: `${100 / 6}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {date}
                </Text>
              </View>

              <View
                style={{
                  width: `${100 / 6}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {hbl_number}
                </Text>
              </View>

              <View
                style={{
                  width: `${100 / 6}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {PAYMENT_IN_SAUDI_RIYALS}
                </Text>
              </View>

              <View
                style={{
                  width: `${100 / 6}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {PAYMENT_IN_KSH}
                </Text>
              </View>

              <View
                style={{
                  width: `${100 / 6}%`,
                  borderRightColor: "black",
                  borderRightWidth: 1,
                  paddingTop: 2,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {element.mode}
                </Text>
              </View>
            </View>
          );

          /* (
          <View
            style={{
              flexDirection: "row",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              alignItems: "center",
              height: 24,
              fontStyle: "bold",
            }}
          >
            <View
              style={{
                width: "25%",
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 10,
                  paddingLeft: 10,
                  fontWeight: "bold",
                }}
              >
                {i + 1}
              </Text>
            </View>

            <View
              style={{
                width: "25%",
                borderRightColor: "black",
                borderRightWidth: 1,
                // backgroundColor: "#E0E0E0",
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {show_invoice_number}
                </Text>
                </View>
    
                <View
                  style={{
                    width: "25%",
                    borderRightColor: "black",
                    borderRightWidth: 1,
                    paddingTop: 2,
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                  >
                    {element.date}
                  </Text>
                </View>
    
                <View
                  style={{
                    width: "25%",
                    borderRightColor: "black",
                    borderRightWidth: 1,
                    paddingTop: 2,
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                  >
                    {parseFloat(element.amount)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Text>
                </View>
              </View>
            );*/
        });
      }
    }

    return (
      <View style={{ paddingTop: 15 }}>
        <View
          style={{
            borderColor: "black",
            borderWidth: 1,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              alignItems: "center",
              height: 24,
              fontStyle: "bold",
            }}
          >
            <View
              style={{
                width: `${100 / 6}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 10,
                  paddingLeft: 10,
                  fontWeight: "bold",
                }}
              >
                RECEIPT
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 6}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                DATE
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 6}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                HBL NO.
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 6}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                SAUDI RIYALS
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 6}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                KSH
              </Text>
            </View>

            <View
              style={{
                width: `${100 / 6}%`,
                borderRightColor: "black",
                borderRightWidth: 1,
                paddingTop: 2,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                MODE
              </Text>
            </View>
          </View>

          {show_payment_details}
        </View>
      </View>
    );
  }

  banking_details() {
    const {
      rajhi_bank_account,
      ncb_bank_account,
      inma_bank_account,
      gulf_african_bank,
      mpesa,
      stcpay,
      vat_no,
      mobile,
    } = this.props;

    return (
      <View style={{ paddingTop: 25 }}>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5, fontWeight: "bold" }}>
                RAJHI BANK ACCOUNT
              </Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 11, paddingLeft: 5 }}>
                {rajhi_bank_account}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5, fontWeight: "bold" }}>
                NCB BANK ACCOUNT
              </Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 11, paddingLeft: 5 }}>
                {ncb_bank_account}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            // paddingTop: 50,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5, fontWeight: "bold" }}>
                INMA BANK ACCOUNT
              </Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 11, paddingLeft: 5 }}>
                {inma_bank_account}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            // paddingTop: 50,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5, fontWeight: "bold" }}>
                GULF AFRICAN BANK
              </Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              {/*<Text style={{ fontSize: 11, paddingLeft: 5 }}>0330010801</Text>*/}
              <Text style={{ fontSize: 11, paddingLeft: 5 }}>
                {gulf_african_bank}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            // paddingTop: 50,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5, fontWeight: "bold" }}>
                MPESA
              </Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 11, paddingLeft: 5 }}> {mpesa} </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            // paddingTop: 50,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "23%",
              borderRightColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 9, paddingLeft: 5 }}>STCPAY</Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "77%",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5, paddingLeft: 5 }}>
              {/* <Text style={{ fontSize: 11 }}>0555691673 TURKY AL ZAHRANI</Text>*/}
              <Text style={{ fontSize: 11 }}>{stcpay}</Text>
            </View>
          </View>
        </View>
      </View>
    );
  }

  desclaimer_other_details() {
    return (
      <View style={{ paddingTop: 25 }}>
        <View
          style={{
            // paddingTop: 50,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "5%",
              paddingLeft: 5,
              borderRightColor: "white",
              borderBottomColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text>1.</Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",

              width: "95%",
              borderLeftColor: "white",
              borderBottomColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 10 }}>
                Freight must be paid upfront during pickup of the packages or
                before arrival at ZAINCO warehouse Mombasa. A penalty of Sr 50
                will be levied on any HBL that has outstanding freight.
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            // paddingTop: 50,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "5%",
              paddingLeft: 5,
              borderRightColor: "white",
              borderTopColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text>2.</Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#000",
              width: "95%",
              borderTopColor: "white",
              borderLeftColor: "white",
            }}
          >
            <View style={{ paddingTop: 5, paddingRight: 5 }}>
              <Text style={{ fontSize: 10 }}>
                Free time of 10 days from the date the packages arrives Mombasa
                warehouse, after which a tariff of Ksh. 1.50 or equivalent to
                0.0536 Saudi Riyals per kilo per day is applicable upto and
                until the day all charges are paid in full.
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  }
  show_report() {
    var details = {};

    const { data_for_reciept, invoice_details } = this.state;
    var size = Object.keys(invoice_details).length;

    var s_name = "",
      s_address = "",
      s_town_country = "",
      s_phone = "",
      c_address = "",
      c_town_country = "",
      c_phone = "",
      c_name = "",
      hbl_number = "",
      date = "",
      invoice_number = "";

    if (size > 0) {
      s_name = invoice_details.s_name;
      s_town_country = invoice_details.s_town_country;
      s_phone = invoice_details.s_phone;
      c_town_country = invoice_details.c_town_country;
      c_phone = invoice_details.c_phone;
      c_name = invoice_details.c_name;
      hbl_number = invoice_details.hbl_number;
      date = invoice_details.date;
      invoice_number = invoice_details.invoice_number;
    }

    var title = "ZAINCO INVOICE";
    var shipper_details = (
      <View style={{ padding: 5 }}>
        <Text
          style={{
            // marginTop: 20,

            paddingBottom: 3,
            fontFamily: "Helvetica-Oblique",
            fontSize: 10,
          }}
        >
          Shipper Details:
        </Text>
        <Text style={{ fontSize: 10 }}>{s_name}</Text>
        <Text style={{ fontSize: 10 }}>{s_town_country}</Text>
        <Text style={{ fontSize: 10 }}>{s_phone}</Text>
      </View>
    );

    var consignee_details = (
      <View style={{ padding: 5 }}>
        <Text
          style={{
            // marginTop: 20,
            paddingBottom: 3,
            fontFamily: "Helvetica-Oblique",
            fontSize: 10,
          }}
        >
          Consignee Details:
        </Text>
        <Text style={{ fontSize: 10 }}>{c_name}</Text>
        <Text style={{ fontSize: 10 }}>{c_town_country}</Text>
        <Text style={{ fontSize: 10 }}>{c_phone}</Text>
      </View>
    );

    var table_titles = this.table_titles();

    var line_items = this.line_items();

    var table_last_line = this.table_last_line();

    return (
      <Document>
        <Page
          size="A4"
          style={{
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35,
          }}
        >
          <View>{this.company_details()}</View>

          <View style={{ paddingTop: 10 }}>
            {/*this.hbl_details(invoice_details)*/}
            {this.signature_box(invoice_details)}
          </View>

          <View style={{ paddingTop: 10 }}>
            {this.shipper_consignee_details({
              shipper_details,
              consignee_details,
            })}
          </View>

          <View style={{ paddingTop: 10 }}>
            <View style={{ paddingTop: 10 }}>
              {this.contact_details()}
              {this.banking_details()}
              {this.desclaimer_other_details()}
            </View>
          </View>
        </Page>
      </Document>
    );
  }

  show_report_based_on_hbl({ invoices }) {
    const { data } = this.props;
    var vat_total = 0;
    var data_to_show_in_table = [];
    var show_data = [];
    var ready = [];

    invoices.forEach((element, i) => {
      if (element) {
        const { ready } = this.state;

        const MyDocument = this.show_report();
        data_to_show_in_table.push({
          HBL: element.hbl_number,
          Lot_No: element.Lot_No,
          Invoice_Number: element.invoice_number,
          Consignee: element.c_name,
          Shipper: element.s_name,
          receipt: (
            <div>
              {ready[i] && (
                <PDFDownloadLink document={MyDocument} fileName="PDF">
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      "Loading document..."
                    ) : (
                      <Button
                        onClick={() => {
                          var temp_ready = ready;

                          temp_ready[i] = false;

                          this.setState({ ready: temp_ready });
                        }}
                      >
                        Download Cash Slip
                      </Button>
                    )
                  }
                </PDFDownloadLink>
              )}
              {!ready[i] && (
                <Button
                  onClick={() => {
                    this.setState({ invoice_details: element });
                    this.toggle(i);
                  }}
                >
                  Generate Cash Slip
                </Button>
              )}
            </div>
          ),
        });
      }
    });

    const table_data = {
      columns: [
        {
          label: "HBL",
          field: "HBL",
          sort: "asc",
          width: 150,
        },
        {
          label: "Lot No",
          field: "Lot_No",
          sort: "asc",
          width: 270,
        },
        {
          label: "Invoice No",
          field: "Invoice_Number",
          sort: "asc",
          width: 270,
        },
        {
          label: "Consignee",
          field: "Consignee",
          sort: "asc",
          width: 200,
        },
        {
          label: "Shipper",
          field: "Shipper",
          sort: "asc",
          width: 100,
        },

        {
          label: "Cash Slip", //"Receipt",
          field: "receipt",
          sort: "asc",
          width: 100,
        },
      ],
      rows: data_to_show_in_table,
    };

    return (
      <div>
        <div
          style={{
            paddingTop: 20,
            paddingLeft: 15,
            paddingRight: 15,
          }}
        >
          <MDBDataTable responsive striped bordered data={table_data} />
        </div>
      </div>
    );
  }

  processing({ invoices }) {
    var data = invoices;

    var snapshot = data;

    var all_data_lot_no = _.map(snapshot, (val, uid) => {
      var data = {
        label: val.Lot_No,

        data: { uid, ...val },
      };

      return data;
    });

    var seen_all_data_lot_no = new Map();

    all_data_lot_no = all_data_lot_no.filter((entry) => {
      var previous;
      if (seen_all_data_lot_no.hasOwnProperty(entry.label)) {
        previous = seen_all_data_lot_no[entry.label];
        previous.data.push(entry.data);

        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen_all_data_lot_no[entry.label] = entry;

      return true;
    });

    all_data_lot_no = _.fromPairs(
      _.map(all_data_lot_no, (i) => {
        return [i.label, i.data];
      })
    );

    return all_data_lot_no;
  }

  show_table_filtered_by_lot_no() {
    var data = this.state.data_by_lot_no;
    var data_to_show_in_table = [];
    var show_data = [];

    data.forEach((element, i) => {
      if (element) {
        const { ready } = this.state;

        const MyDocument = this.show_report();
        data_to_show_in_table.push({
          HBL: element.hbl_number,
          Lot_No: element.Lot_No,
          Invoice_Number: element.invoice_number,
          Consignee: element.c_name,
          Shipper: element.s_name,
          receipt: (
            <div>
              {ready[i] && (
                <PDFDownloadLink document={MyDocument} fileName="PDF">
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      "Loading document..."
                    ) : (
                      <Button
                        onClick={() => {
                          var temp_ready = ready;

                          temp_ready[i] = false;

                          this.setState({ ready: temp_ready });
                        }}
                      >
                        Download Cash Slip
                      </Button>
                    )
                  }
                </PDFDownloadLink>
              )}
              {!ready[i] && (
                <Button
                  onClick={() => {
                    this.setState({ invoice_details: element });
                    this.toggle(i);
                  }}
                >
                  Generate Cash Slip
                </Button>
              )}
            </div>
          ),
        });
      }
    });

    const table_data = {
      columns: [
        {
          label: "HBL",
          field: "HBL",
          sort: "asc",
          width: 150,
        },
        {
          label: "Lot No",
          field: "Lot_No",
          sort: "asc",
          width: 270,
        },
        {
          label: "Invoice No",
          field: "Invoice_Number",
          sort: "asc",
          width: 270,
        },
        {
          label: "Consignee",
          field: "Consignee",
          sort: "asc",
          width: 200,
        },
        {
          label: "Shipper",
          field: "Shipper",
          sort: "asc",
          width: 100,
        },

        {
          label: "Receipt",
          field: "receipt",
          sort: "asc",
          width: 100,
        },
      ],
      rows: data_to_show_in_table,
    };

    return (
      <div>
        <div
          style={{
            paddingTop: 20,
            paddingLeft: 15,
            paddingRight: 15,
          }}
        >
          <MDBDataTable responsive striped bordered data={table_data} />
        </div>
      </div>
    );
  }
  show_report_based_on_lot_no({ invoices }) {
    var data_filtered = this.processing({ invoices });

    var options_to_show = [];
    var data_with_details = [];

    if (Object.keys(data_filtered).length > 0) {
      var data = _.map(data_filtered, (val, uid_lot) => {
        return { data: { ...val }, uid_lot };
      });

      data.forEach((element) => {
        data_with_details.push(element);
      });

      data_with_details.forEach((element, i) => {
        options_to_show.push(<option value={i}>{element.uid_lot}</option>);
      });
    }

    return (
      <div>
        <Form encType="multipart/form-data">
          <div data-repeater-list="group-a">
            <Row data-repeater-item>
              <Col lg="6" className="form-group">
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    <Label className="input-group-text">LOT NO</Label>
                  </InputGroupAddon>
                  <Input
                    className="form-control"
                    onChange={(event) => {
                      var value = event.target.value;
                      if (data_with_details[value].data) {
                        var converted_data = _.map(
                          data_with_details[value].data,
                          (val) => {
                            return { ...val };
                          }
                        );

                        this.setState({
                          data_by_lot_no: converted_data,
                        });
                      }
                    }}
                    type="select"
                  >
                    <option value="">Select Lot No.</option>
                    {options_to_show}
                  </Input>
                </InputGroup>
              </Col>
            </Row>
          </div>
        </Form>

        {this.state.data_by_lot_no.length > 0 ? (
          <div>{this.show_table_filtered_by_lot_no()}</div>
        ) : null}
      </div>
    );
  }

  show_report_based_period({ invoices }) {
    var data_to_show_in_table = [];
    var show_data = [];

    // invoices.forEach((element) => {
    //   if (this.state.start_date !== "" && this.state.end_date !== "") {
    //     if (
    //       moment(
    //         moment_time_zone(element.Date).tz("Etc/GMT").format("DD/MM/YYYY") //.format("MM/DD/YYYY")
    //       ).isSameOrAfter(
    //         moment_time_zone(this.state.start_date)
    //           .tz("Etc/GMT")
    //           .format("DD/MM/YYYY")
    //         //.format("MM/DD/YYYY")
    //       ) &&
    //       moment(
    //         moment_time_zone(element.Date).tz("Etc/GMT").format("DD/MM/YYYY") //.format("MM/DD/YYYY")
    //       ).isSameOrBefore(
    //         moment_time_zone(this.state.end_date)
    //           .tz("Etc/GMT")
    //           .format("DD/MM/YYYY")
    //         // .format("MM/DD/YYYY")
    //       )
    //     ) {
    //       show_data.push(element);
    //     }
    //   }
    // });

    invoices.forEach((element) => {
      var dateSplit = element.date.split("/");
      var date = moment(
        dateSplit[2] + dateSplit[1] + dateSplit[0],
        "YYYYMMDD"
      ).valueOf();

      if (this.state.start_date !== "" && this.state.end_date !== "") {
        if (
          moment(date).isSameOrAfter(
            moment_time_zone(this.state.start_date).valueOf()
          ) &&
          moment(date).isSameOrBefore(
            moment_time_zone(this.state.end_date).valueOf()
          )
        ) {
          show_data.push(element);
        }
      }
    });

    show_data.forEach((element, i) => {
      if (element) {
        const { ready } = this.state;

        const MyDocument = this.show_report();
        data_to_show_in_table.push({
          HBL: element.hbl_number,
          Lot_No: element.Lot_No,
          Invoice_Number: element.invoice_number,
          Consignee: element.c_name,
          Shipper: element.s_name,
          receipt: (
            <div>
              {ready[i] && (
                <PDFDownloadLink document={MyDocument} fileName="PDF">
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      "Loading document..."
                    ) : (
                      <Button
                        onClick={() => {
                          var temp_ready = ready;

                          temp_ready[i] = false;

                          this.setState({ ready: temp_ready });
                        }}
                      >
                        Download Cash Slip
                      </Button>
                    )
                  }
                </PDFDownloadLink>
              )}
              {!ready[i] && (
                <Button
                  onClick={() => {
                    this.setState({ invoice_details: element });
                    this.toggle(i);
                  }}
                >
                  Generate Cash Slip
                </Button>
              )}
            </div>
          ),
        });
      }
    });

    const table_data = {
      columns: [
        {
          label: "HBL",
          field: "HBL",
          sort: "asc",
          width: 150,
        },
        {
          label: "Lot No",
          field: "Lot_No",
          sort: "asc",
          width: 270,
        },
        {
          label: "Invoice No",
          field: "Invoice_Number",
          sort: "asc",
          width: 270,
        },
        {
          label: "Consignee",
          field: "Consignee",
          sort: "asc",
          width: 200,
        },
        {
          label: "Shipper",
          field: "Shipper",
          sort: "asc",
          width: 100,
        },

        {
          label: "Receipt",
          field: "receipt",
          sort: "asc",
          width: 100,
        },
      ],
      rows: data_to_show_in_table,
    };

    var show_options = false;
    if (this.state.start_date === "" || this.state.end_date === "") {
      show_options = false;
    } else {
      show_options = true;
    }
    return (
      <div>
        <Form className="repeater" encType="multipart/form-data">
          <div data-repeater-list="group-a">
            <Row data-repeater-item>
              <Col lg="6" className="form-group">
                <Label htmlFor="name">Start Date</Label>
                <Input
                  type="date"
                  id="name"
                  name="untyped-input"
                  className="form-control"
                  onChange={(event) => {
                    var value = event.target.value;

                    this.setState({
                      start_date: value,
                    });
                  }}
                  value={this.state.start_date}
                />
              </Col>
              <Col lg="6" className="form-group">
                <Label htmlFor="name">End Date</Label>
                <Input
                  type="date"
                  id="name"
                  name="untyped-input"
                  className="form-control"
                  onChange={(event) => {
                    var value = event.target.value;
                    // moment_time_zone().tz("Etc/GMT")
                    if (
                      moment(
                        moment_time_zone(this.state.start_date)
                          .tz("Etc/GMT")
                          .format("DD/MM/YYYY")
                        // .format("MM/DD/YYYY")
                      ).isAfter(
                        moment_time_zone(this.state.end_date)
                          .tz("Etc/GMT")
                          .format("DD/MM/YYYY")
                        // .format("MM/DD/YYYY")
                      ) === false
                    ) {
                      this.setState({
                        end_date: value,
                      });
                    } else {
                      alert(
                        "Please select End Date after the Start Date you have selected!"
                      );
                    }
                  }}
                  value={this.state.end_date}
                />
              </Col>
            </Row>
          </div>
        </Form>
        {show_options === true ? (
          <div
            style={{
              paddingTop: 20,
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            <MDBDataTable responsive striped bordered data={table_data} />
          </div>
        ) : null}
      </div>
    );
  }

  render() {
    const { loading } = this.props;
    if (loading === true) {
      const loadingjson = {
        loop: true,
        autoplay: true,
        animationData: require("./JSON/loading.json"),

        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };

      return (
        <div className="page-content">
          <Container fluid>
            <Row className="justify-content-center mt-lg-5">
              <div className="text-center">
                <Row className="justify-content-center">
                  <div className="text-center">
                    <Lottie options={loadingjson} height={300} width={300} />
                  </div>
                </Row>
              </div>
            </Row>
          </Container>
        </div>
      );
    }
    // const { data } = this.props;

    const { shipping_data } = this.props;
    var data = shipping_data;

    data = _.map(data, (val, uid) => {
      return { ...val, uid };
    });

    var invoices = [];

    if (data.length !== 0) {
      var filtered_data = [];
      data.forEach((element, x) => {
        if (element.full_detail !== undefined) {
          filtered_data.push(element);

          var s_name = "";
          var s_town_country = "";
          var s_phone = "";
          var email = "";

          var c_town_country = "";
          var c_phone = "";
          var c_name = "";

          if (element.full_detail.shipper_details !== undefined) {
            s_name = element.full_detail.shipper_details.name;
            s_town_country = element.full_detail.shipper_details.country;
            s_phone = element.full_detail.shipper_details.phone;
            email = element.full_detail.shipper_details.email;
          }

          if (element.full_detail.consignee_details !== undefined) {
            c_town_country =
              element.full_detail.consignee_details.consignee_country;
            c_phone = element.full_detail.consignee_details.consignee_phone;
            c_name = element.full_detail.consignee_details.consignee_name;
          }

          var invoice_details = {
            full_detail: element.full_detail,
            Email: email,
            Lot_No: element.Lot_No,
            s_name: s_name,
            s_town_country: s_town_country,
            s_phone: s_phone,
            c_town_country: c_town_country,
            c_phone: c_phone,
            c_name: c_name,
            hbl_number: element.uid,
            date: element.Date,
            invoice_number: element.uid + "-" + "001", //String(x),
            payments_details: element.full_detail.payments_details,
            calculations: element.full_detail.calculations,
            cargo_details: element.full_detail.cargo_details,
          };
          invoices.push(invoice_details);
        }
      });
    }

    return (
      <div>
        <div>
          <Nav pills className="bg-light rounded" role="tablist">
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === "1",
                })}
                onClick={() => {
                  this.setState({
                    activeTab: "1",
                  });
                }}
              >
                All
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === "2",
                })}
                onClick={() => {
                  this.setState({
                    activeTab: "2",
                  });
                }}
              >
                Based on Lot No.
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === "3",
                })}
                onClick={() => {
                  this.setState({
                    activeTab: "3",
                  });
                }}
              >
                Based on Period
              </NavLink>
            </NavItem>
          </Nav>
        </div>

        <TabContent activeTab={this.state.activeTab} className="mt-4">
          <TabPane tabId="1" id="buy-tab">
            {this.show_report_based_on_hbl({ invoices })}
          </TabPane>
          <TabPane tabId="2" id="sell-tab">
            {this.show_report_based_on_lot_no({ invoices })}
          </TabPane>
          <TabPane tabId="3" id="sell-tab">
            {this.show_report_based_period({ invoices })}
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

// export default Cash_Slip;
const mapStatetoProps = (state) => {
  const { shipping_data, error, loading } = state.Database;

  return {
    shipping_data,
    error,
    loading,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    shipping_data_read,
    detail_error,
  })(withNamespaces()(Cash_Slip))
);
