import {
  takeEvery,
  fork,
  put,
  all,
  call,
  take,
  takeLatest,
  //take,
} from "redux-saga/effects";
import {
  //User
  USER_DATA_READ,
  //Shipping
  SHIPPING_DATA_READ,
  //Container
  CONTAINER_DATA_READ,
  //Currency Converter
  CURRENCY_DATA_READ,
  //Rates
  RATES_DATA_READ,
  //Limited Access Users Zaincocargo
  LIMITED_ACCESS_USER_DATA_READ,
  //Limited Access Users Zaincocargo Logs
  LIMITED_ACCESS_USER_LOGS_DATA_READ,
  //
} from "./actionTypes";

import {
  user_data_read_async,
  shipping_data_read_async,
  container_data_read_async,
  currency_data_read_async,
  rates_data_read_async,
  limited_users_data_read_async,
  limited_users_logs_data_read_async,
  detail_error,
} from "./actions";

import { getFirebaseBackend } from "../../helpers/firebase_helper";
const fireBaseBackend = getFirebaseBackend();

//generators

//User
function* userDataRead() {
  try {
    const user = yield call(fireBaseBackend.getUser);
    if (user && user !== "No User") {
      var user_uid = user.uid;
      const response = yield call(fireBaseBackend.readUserData, user_uid);
      yield put(user_data_read_async(response));
    } else {
      yield put(detail_error("No User"));
    }
  } catch (error) {
    yield put(detail_error(error));
  }
}

//Shipping
function* shippingDataRead() {
  try {
    const user = yield call(fireBaseBackend.getUser);

    if (user !== "No User") {
      // var user_uid = user.uid;
      // const user_data = yield call(fireBaseBackend.readUserData, user_uid);
      // const response = yield call(fireBaseBackend.readShippingData, user_data);
      const response = yield call(fireBaseBackend.readShippingData);
      yield put(shipping_data_read_async(response));
    } else {
      yield put(detail_error("No User"));
    }
  } catch (error) {
    yield put(detail_error(error));
  }
}

//Container
function* containerDataRead() {
  try {
    const user = yield call(fireBaseBackend.getUser);

    if (user !== "No User") {
      const response = yield call(fireBaseBackend.readContainerData);
      yield put(container_data_read_async(response));
    } else {
      yield put(detail_error("No User"));
    }
  } catch (error) {
    yield put(detail_error(error));
  }
}

//Currency
function* currencyDataRead() {
  try {
    const user = yield call(fireBaseBackend.getUser);

    if (user !== "No User") {
      const response = yield call(fireBaseBackend.readCurrencyConverterData);
      yield put(currency_data_read_async(response));
    } else {
      yield put(detail_error("No User"));
    }
  } catch (error) {
    yield put(detail_error(error));
  }
}

//Rates
function* ratesDataRead() {
  try {
    const user = yield call(fireBaseBackend.getUser);

    if (user !== "No User") {
      const response = yield call(fireBaseBackend.readRatesData);
      yield put(rates_data_read_async(response));
    } else {
      yield put(detail_error("No User"));
    }
  } catch (error) {
    yield put(detail_error(error));
  }
}

//Limited Access Users Zaincocargo
function* limitedUsersDataRead() {
  try {
    const user = yield call(fireBaseBackend.getUser);

    if (user !== "No User") {
      const response = yield call(
        fireBaseBackend.readlimited_access_users_zaincocargo
      );
      yield put(limited_users_data_read_async(response));
    } else {
      yield put(detail_error("No User"));
    }
  } catch (error) {
    yield put(detail_error(error));
  }
}

//Limited Access Users Zaincocargo Logs
function* limitedUsersLogsDataRead() {
  try {
    const user = yield call(fireBaseBackend.getUser);

    if (user !== "No User") {
      const response = yield call(
        fireBaseBackend.readlimited_access_users_zaincocargo_logs
      );
      yield put(limited_users_logs_data_read_async(response));
    } else {
      yield put(detail_error("No User"));
    }
  } catch (error) {
    yield put(detail_error(error));
  }
}

//watchers

//Users
export function* watchUserDataRead() {
  // yield takeLatest(USER_DATA_READ, userDataRead);
  yield takeLatest(USER_DATA_READ, userDataRead);
}

//Shipping
export function* watchShippingDataRead() {
  yield takeLatest(SHIPPING_DATA_READ, shippingDataRead);
}
//Container
export function* watchContainerDataRead() {
  yield takeLatest(CONTAINER_DATA_READ, containerDataRead);
}

//Currency
export function* watchCurrencyDataRead() {
  yield takeLatest(CURRENCY_DATA_READ, currencyDataRead);
}

//Rates
export function* watchRatesDataRead() {
  yield takeLatest(RATES_DATA_READ, ratesDataRead);
}

//Limited Access Users Zaincocargo
export function* watchLimitedUsersDataRead() {
  yield takeLatest(LIMITED_ACCESS_USER_DATA_READ, limitedUsersDataRead);
}
//Limited Access Users Zaincocargo Logs
export function* watchLimitedUsersLogsDataRead() {
  yield takeLatest(
    LIMITED_ACCESS_USER_LOGS_DATA_READ,
    limitedUsersLogsDataRead
  );
}

//
function* ProfileSaga() {
  yield all([
    fork(watchUserDataRead),
    fork(watchShippingDataRead),
    fork(watchContainerDataRead),
    fork(watchCurrencyDataRead),
    fork(watchRatesDataRead),
    fork(watchLimitedUsersDataRead),
    fork(watchLimitedUsersLogsDataRead),
  ]);
}

export default ProfileSaga;
