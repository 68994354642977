import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

//Shipment Tracking
import ShipmentTracking from "../pages/ShipmentTracking/index";
import ShippingDetails from "../pages/ShippingDetails/index";
import Invoice from "../pages/Customer/Invoice/index";

//PHASE 2
import UserManagement from "../pages/UserManagement/index";
import ReportsAndReciepts from "../pages/ReportsAndReciepts/index";
import AddEditShipments from "../pages/AddEditShipments/index";
import AddEditShipper from "../pages/AddEditShipper/index";

import AddEditCurrency from "../pages/AddEditCurrency/index";
import AddEditTowns from "../pages/AddEditTowns/index";

import AddEditContainer from "../pages/AddEditContainer/index";

import AddEditConsignee from "../pages/AddEditConsignee/index";

// import AddEditSales from "../pages/AddEditSales/index";

const authProtectedRoutes = [
  { path: "/shipments", component: ShipmentTracking },
  // { path: "/upload-shipments", component: ShippingDetails },
  { path: "/add-edit-shipper", component: AddEditShipper },

  { path: "/add-edit-currency", component: AddEditCurrency },

  { path: "/add-edit-consignee", component: AddEditConsignee },

  { path: "/add-edit-container", component: AddEditContainer },

  { path: "/add-edit-towns", component: AddEditTowns },

  { path: "/add-edit-shipments", component: AddEditShipments },
  { path: "/reports-and-reciepts", component: ReportsAndReciepts },
  { path: "/user-management", component: UserManagement },

  // { path: "/add-edit-sales", component: AddEditSales },

  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/shipments" />,
  },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/invoice", component: Invoice },
];

export { authProtectedRoutes, publicRoutes };
