import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import Upload from "./Upload";

//i18n
import { withNamespaces } from "react-i18next";

// Redux
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import {
  user_data_read,
  shipping_data_read,
  detail_error,
} from "../../store/actions";
import Lottie from "react-lottie";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.user_data_read();
    this.props.shipping_data_read();
  }

  render_loading() {
    const loadingjson = {
      loop: true,
      autoplay: true,
      animationData: require("./JSON/loading.json"),

      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div className="page-content">
        <Container fluid>
          <Row className="justify-content-center mt-lg-5">
            <div className="text-center">
              <Row className="justify-content-center">
                <div className="text-center">
                  <Lottie options={loadingjson} height={300} width={300} />
                </div>
              </Row>
            </div>
          </Row>
        </Container>
      </div>
    );
  }

  render() {
    // console.log(this.props.user_details);
    return (
      <React.Fragment>
        <Upload user_details={this.props.user_details} />
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user_details, shipping_data, upload_shipping_data, error, loading } =
    state.Database;

  return {
    user_details,
    shipping_data,
    upload_shipping_data,
    error,
    loading,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    user_data_read,
    shipping_data_read,
    detail_error,
  })(withNamespaces()(Index))
);
