import _ from "lodash";
import React, { Component, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
  UncontrolledTooltip,
  ////////
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  InputGroupAddon,
  InputGroup,
  Modal,
} from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";

import firebase from "firebase/app";
import SweetAlert from "react-bootstrap-sweetalert";

// Add the Firebase products that you want to use
import "firebase/database";

import classnames from "classnames";

// import "./CSS/surveyQR.css";
import "./CSS/surveyQR.css";

// Import Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//Import Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

class TasksCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      activeTab: "1",
      name: "",
      address: "",
      phone: "",
      town: "",
      country: "",
      delivery: "",
      //old
      old_data: {}, //[],
      old_name: "",
      old_address: "",
      old_phone: "",
      old_town: "",
      old_country: "",
      old_uid: "",
      old_delivery: "",
      body: <div></div>,
      show_alert: false,
      modal_standard: false,
      show_old_data: false,
      ///////
      over_100_kg: "",
      less_than_100_kg: "",
      less_than_50_kg: "",
      city: "",
      ////////
      old_over_100_kg: "",
      old_less_than_100_kg: "",
      old_less_than_50_kg: "",
      old_city: "",
      rates_data_extracted: {},
    };
    this.startDateChange.bind(this);
    this.endDateChange.bind(this);

    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  startDateChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  endDateChange = (date) => {
    this.setState({
      endDate: date,
    });
  };

  componentDidMount() {
    // this.setState({
    //   rates_data_extracted: this.props.rates_data_extracted,
    // });
    firebase
      .database()
      .ref("organization/zaincocargo/rates/")
      .once("value")
      .then((snapshot) => {
        // var data_array = _.map(snapshot.val(), (val, uid) => {
        //   return { ...val, uid };
        // });

        this.setState({
          rates_data_extracted: snapshot.val(),
        });
      });
  }
  search_fetch() {
    firebase
      .database()
      .ref("organization/zaincocargo/rates/")
      .once("value")
      .then((snapshot) => {
        // var data_array = _.map(snapshot.val(), (val, uid) => {
        //   return { ...val, uid };
        // });

        this.setState({
          rates_data_extracted: snapshot.val(),
        });
      });
  }
  reset() {
    this.setState({
      name: "",
      address: "",
      phone: "",
      town: "",
      country: "",
      delivery: "",
      //old
      old_data: {}, //[],
      old_name: "",
      old_address: "",
      old_phone: "",
      old_town: "",
      old_country: "",
      old_uid: "",
      old_delivery: "",
      body: <div></div>,
      show_alert: false,
      modal_standard: false,
      show_old_data: false,
      ////////
      over_100_kg: "",
      less_than_100_kg: "",
      less_than_50_kg: "",
      city: "",
      /////
      ////////
      old_over_100_kg: "",
      old_less_than_100_kg: "",
      old_less_than_50_kg: "",
      old_city: "",
    });
  }
  addShipper() {
    const { over_100_kg, less_than_100_kg, less_than_50_kg, city } = this.state;

    if (
      over_100_kg === "" ||
      less_than_100_kg === "" ||
      less_than_50_kg === "" ||
      city === ""
    ) {
      alert("Please ensure no field are empty!");
    } else {
      firebase
        .database()
        .ref("organization/zaincocargo/rates/rates_by_destination")
        .push({
          over_100_kg,
          less_than_100_kg,
          less_than_50_kg,
          city,
        })
        .then(() => {
          this.setState({
            over_100_kg: "",
            less_than_100_kg: "",
            less_than_50_kg: "",
            city: "",
          });
          alert("Your city details were successfully added!");
        })
        .catch(() => {
          alert(
            "Oops! There was an error while adding your city details. Please try again!"
          );
        });
    }
  }

  updateShipper() {
    const { old_data } = this.state;
    firebase
      .database()
      .ref(
        `organization/zaincocargo/rates/rates_by_destination/${old_data.uid}`
      )
      .remove()
      .then(() => {
        this.search_fetch();
        this.reset();
        alert("Your city was deleted successfully!");
      })
      .catch(() => {
        alert("Oops! There was an deleting the city. Please try again!");
      });
    // const {
    //   old_over_100_kg,
    //   old_less_than_100_kg,
    //   old_less_than_50_kg,
    //   old_city,
    //   old_data,
    // } = this.state;
    // // const { over_100_kg, less_than_100_kg, less_than_50_kg, city } = this.state;
    // if (
    //   old_over_100_kg === "" ||
    //   old_less_than_100_kg === "" ||
    //   old_less_than_50_kg === "" ||
    //   old_city === ""
    // ) {
    //   alert("Please ensure no field are empty!");
    // } else {
    //   firebase
    //     .database()
    //     .ref(
    //       `organization/zaincocargo/rates/rates_by_destination/${old_data.uid}`
    //     )
    //     .update({
    //       old_over_100_kg,
    //       old_less_than_100_kg,
    //       old_less_than_50_kg,
    //       old_city,
    //     })
    //     .then(() => {
    //       this.search_fetch();
    //       alert("Your cities details were successfully updated!");
    //     })
    //     .catch(() => {
    //       alert(
    //         "Oops! There was an error while adding your city details. Please try again!"
    //       );
    //     });
    // }
  }

  tab1() {
    return (
      <Row>
        <Col lg="12">
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">City</Label>
            </InputGroupAddon>
            <Input
              type="text"
              className="form-control"
              value={this.state.city}
              onChange={(event) => {
                this.setState({ city: event.target.value });
              }}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Rate (Less than 50KG)</Label>
            </InputGroupAddon>
            <Input
              type="text"
              className="form-control"
              value={this.state.less_than_50_kg}
              onChange={(event) => {
                this.setState({ less_than_50_kg: event.target.value });
              }}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Rate (Less than 100KG)</Label>
            </InputGroupAddon>
            <Input
              type="text"
              className="form-control"
              value={this.state.less_than_100_kg}
              onChange={(event) => {
                this.setState({ less_than_100_kg: event.target.value });
              }}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text">Rate (Over than 100KG)</Label>
            </InputGroupAddon>
            <Input
              type="text"
              className="form-control"
              value={this.state.over_100_kg}
              onChange={(event) => {
                this.setState({ over_100_kg: event.target.value });
              }}
            />
          </InputGroup>
        </Col>
      </Row>
    );
  }

  tab2() {
    const {
      old_data,
      old_address,
      old_country,
      old_town,
      old_name,
      old_phone,
      old_delivery,
    } = this.state;
    var export_data = [];
    var show_to_edit = [];

    const { rates_data_extracted } = this.state; //this.props;

    var list_of_destinations = [];

    var rates_by_destination = [];
    var size = Object.keys(rates_data_extracted).length;

    if (size > 0) {
      rates_by_destination = _.map(
        rates_data_extracted.rates_by_destination,
        (val, uid) => {
          return { ...val, uid };
        }
      );
    }

    // console.log(rates_by_destination);

    //   var rates_by_destination = _.map(
    //     rates_data_extracted.rates_by_destination,
    //     (val, uid) => {
    //       return { ...val, uid };
    //     }
    //   );
    // if (rates_data_extracted.rates_by_destination !== undefined) {
    //   var rates_by_destination = _.map(
    //     rates_data_extracted.rates_by_destination,
    //     (val, uid) => {
    //       return { ...val, uid };
    //     }
    //   );

    //   rates_by_destination.sort((a, b) => {
    //     if (a.city < b.city) {
    //       return -1;
    //     }
    //     if (a.city > b.city) {
    //       return 1;
    //     }
    //     return 0;
    //   });

    //   rates_by_destination.forEach((element) => {
    //     list_of_destinations.push(
    //       <option value={element.city}>{element.city}</option>
    //     );
    //   });
    // }

    export_data.push([
      "City",
      "Less than 50 KG",
      "Less than 100 KG",
      "Over 100 KG",
      "Country",
    ]);
    rates_by_destination.forEach((element) => {
      export_data.push([
        element.city,
        element.less_than_50_kg,
        element.less_than_100_kg,
        element.over_100_kg,
      ]);

      show_to_edit.push({
        city: element.city,
        less_than_50_kg: element.less_than_50_kg,
        less_than_100_kg: element.less_than_100_kg,
        over_100_kg: element.over_100_kg,

        edit: (
          <div>
            <Row>
              <Col lg="12" className="text-center">
                <Link
                  onClick={() => {
                    this.setState({
                      old_city: element.city,
                      old_less_than_50_kg: element.less_than_50_kg,
                      old_less_than_100_kg: element.less_than_100_kg,
                      old_over_100_kg: element.over_100_kg,
                      old_data: element,

                      show_old_data: true,
                      modal_standard: false,
                    });
                  }}
                  className="mr-3 text-primary text-center"
                >
                  <i
                    className="mdi mdi-pencil font-size-18 "
                    id="edittooltip"
                  ></i>
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </Col>
            </Row>
          </div>
        ),
      });
    });

    if (this.state.show_old_data === true) {
      return (
        <div>
          <Modal
            isOpen={this.state.modal_standard}
            size="lg"
            toggle={() => {
              this.setState((prevState) => ({
                modal_standard: !prevState.modal_standard,
              }));
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                List Of All Cities
              </h5>
              <button
                type="button"
                onClick={() => this.setState({ modal_standard: false })}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>{this.state.body}</div>
            </div>
          </Modal>
          <Row>
            <Col lg="10">
              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text">City</Label>
                </InputGroupAddon>
                <Input
                  type="text"
                  className="form-control"
                  value={this.state.old_city}
                  disabled
                  onChange={(event) => {
                    this.setState({ old_city: event.target.value });
                  }}
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text">
                    Rate (Less than 50KG)
                  </Label>
                </InputGroupAddon>
                <Input
                  type="text"
                  className="form-control"
                  disabled
                  value={this.state.old_less_than_50_kg}
                  onChange={(event) => {
                    this.setState({ old_less_than_50_kg: event.target.value });
                  }}
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text">
                    Rate (Less than 100KG)
                  </Label>
                </InputGroupAddon>
                <Input
                  type="text"
                  className="form-control"
                  disabled
                  value={this.state.old_less_than_100_kg}
                  onChange={(event) => {
                    this.setState({ old_less_than_100_kg: event.target.value });
                  }}
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text">
                    Rate (Over than 100KG)
                  </Label>
                </InputGroupAddon>
                <Input
                  type="text"
                  className="form-control"
                  disabled
                  value={this.state.old_over_100_kg}
                  onChange={(event) => {
                    this.setState({ old_over_100_kg: event.target.value });
                  }}
                />
              </InputGroup>
            </Col>

            <Col lg="2">
              <div className="text-center">
                <p className="text-muted">
                  Use button below to search for your existing Cities to edit or
                  to export csv
                </p>
                <button
                  type="button"
                  onClick={() => {
                    // this.search_fetch();

                    const data = {
                      columns: [
                        {
                          label: "City",
                          field: "city",
                          sort: "asc",
                          width: 150,
                        },
                        {
                          label: "Less than 50 KG",
                          field: "less_than_50_kg",
                          sort: "asc",
                          width: 270,
                        },
                        {
                          label: "Less than 100 KG",
                          field: "less_than_100_kg",
                          sort: "asc",
                          width: 200,
                        },
                        {
                          label: "Over 100 KG",
                          field: "over_100_kg",
                          sort: "asc",
                          width: 200,
                        },

                        {
                          label: "Edit",
                          field: "edit",
                          sort: "asc",
                          width: 200,
                        },
                      ],
                      rows: show_to_edit,
                    };

                    var body = (
                      <div>
                        <div className="row mt-12">
                          <div className="col-xl col-sm-12">
                            <div className="text-sm-right">
                              <CSVLink
                                filename={`All_Cities.csv`}
                                data={export_data}
                              >
                                <Button
                                  type="button"
                                  color="primary"
                                  className="w-md"
                                >
                                  Export All Cities
                                </Button>
                              </CSVLink>
                            </div>
                          </div>
                        </div>

                        <MDBDataTable responsive striped bordered data={data} />
                      </div>
                    );

                    this.setState({
                      modal_standard: true,
                      body,
                    });
                  }}
                  className="btn btn-primary waves-effect waves-light"
                  data-toggle="modal"
                  data-target="#myModal"
                >
                  Search Existing Cities
                </button>
              </div>
            </Col>
          </Row>
          <div className="text-center">
            {/*} <Button
              type="submit"
              color="primary"
              onClick={() => {
                this.updateShipper();
              }}
            >
              Update City
            </Button>*/}
            <Button
              type="submit"
              color="primary"
              onClick={() => {
                this.updateShipper();
              }}
            >
              Delete City
            </Button>
          </div>
        </div>
      );
    }

    return (
      <Row>
        <Col lg="12">
          <div classname="align-me mb-3">
            <Col lg="12" className="mt-4">
              <div className="text-center">
                <p className="text-muted">
                  Use button below to search for your existing shipper to delete
                  or to export csv
                </p>
                <button
                  type="button"
                  onClick={() => {
                    // this.search_fetch();

                    const data = {
                      columns: [
                        {
                          label: "City",
                          field: "city",
                          sort: "asc",
                          width: 150,
                        },
                        {
                          label: "Less than 50 KG",
                          field: "less_than_50_kg",
                          sort: "asc",
                          width: 270,
                        },
                        {
                          label: "Less than 100 KG",
                          field: "less_than_100_kg",
                          sort: "asc",
                          width: 200,
                        },
                        {
                          label: "Over 100 KG",
                          field: "over_100_kg",
                          sort: "asc",
                          width: 200,
                        },

                        {
                          label: "Edit",
                          field: "edit",
                          sort: "asc",
                          width: 200,
                        },
                      ],
                      rows: show_to_edit,
                    };

                    var body = (
                      <div>
                        <div className="row mt-12">
                          <div className="col-xl col-sm-12">
                            <div className="text-sm-right">
                              <CSVLink
                                filename={`All_Cities.csv`}
                                data={export_data}
                              >
                                <Button
                                  type="button"
                                  color="primary"
                                  className="w-md"
                                >
                                  Export All Cities
                                </Button>
                              </CSVLink>
                            </div>
                          </div>
                        </div>

                        <MDBDataTable responsive striped bordered data={data} />
                      </div>
                    );

                    this.setState({
                      modal_standard: true,
                      body,
                    });
                  }}
                  className="btn btn-primary waves-effect waves-light"
                  data-toggle="modal"
                  data-target="#myModal"
                >
                  Search Existing Cities
                </button>
              </div>

              <Modal
                isOpen={this.state.modal_standard}
                size="lg"
                toggle={() => {
                  this.setState((prevState) => ({
                    modal_standard: !prevState.modal_standard,
                  }));
                }}
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    List Of All Cities
                  </h5>
                  <button
                    type="button"
                    onClick={() => this.setState({ modal_standard: false })}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div>{this.state.body}</div>
                </div>
              </Modal>
            </Col>
          </div>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.show_alert ? (
          <SweetAlert
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            showCancel={this.state.showCancel}
            title={this.state.title}
            warning={this.state.warning}
            error={this.state.error}
            success={this.state.success}
            confirmBtnText={this.state.confirm_button_text}
            onConfirm={() => {
              const { type, updated_status, uid } = this.state;
            }}
            onCancel={() => this.setState({ show_alert: false })}
          >
            {this.state.body}
          </SweetAlert>
        ) : null}

        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Cities" breadcrumbItem="Cities" />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">City Details</CardTitle>

                    <div className="mt-4">
                      <Nav pills className="bg-light rounded" role="tablist">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "1",
                            })}
                            onClick={() => {
                              this.reset();
                              this.toggleTab("1");
                            }}
                          >
                            Add New City
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "2",
                            })}
                            onClick={() => {
                              this.reset();
                              this.toggleTab("2");
                              this.search_fetch();
                            }}
                          >
                            Delete Existing City
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent
                        activeTab={this.state.activeTab}
                        className="mt-4"
                      >
                        <TabPane tabId="1" id="buy-tab">
                          {this.tab1()}

                          <div className="text-center">
                            <Button
                              type="submit"
                              color="primary"
                              onClick={() => {
                                this.addShipper();
                              }}
                            >
                              Add City
                            </Button>
                          </div>
                        </TabPane>
                        <TabPane tabId="2" id="sell-tab">
                          {this.tab2()}
                        </TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default TasksCreate;
