import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  Image,
} from "@react-pdf/renderer";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Label,
  Input,
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  InputGroupAddon,
  InputGroup,
  Modal,
  Form,
  Button,
} from "reactstrap";
import moment from "moment";
import { MDBDataTable } from "mdbreact";
import classnames from "classnames";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import logo from "../../assets/images/logo-dark.png";
import firebase from "firebase/app";
import "firebase/database";
import moment_time_zone from "moment-timezone";

/***/
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import {
  shipping_data_read,
  detail_error,
  container_data_read,
  currency_data_read,
} from "../../store/actions";
import Lottie from "react-lottie";
/***/

class Storage extends Component {
  constructor() {
    super();
    this.state = {
      activeTab: "1",
      data_by_lot_no: [],
      ready: false,
      data_to_show_in_table: [],
      data: [],
      container_data: [],
      show_shipments: false,
    };
  }

  async componentDidMount() {
    // this.update_data();
  }

  update_data() {
    firebase
      .database()
      .ref("organization/zaincocargo/container/")
      .once("value")
      .then((snapshot) => {
        var container_data = _.map(snapshot.val(), (val, uid) => {
          return { ...val, uid };
        });

        firebase
          .database()
          .ref("organization/zaincocargo/shipment_details/")
          .once("value")
          .then(async (snapshot) => {
            var existing_date = _.map(snapshot.val(), (val, uid) => {
              return { ...val, uid };
            });
            var first_filter = [];
            var already_data = [];

            existing_date.forEach(async (element) => {
              if (element.full_detail !== undefined) {
                if (element.full_detail.calculations !== undefined) {
                  container_data.forEach(async (second_element) => {
                    if (second_element.storage_date !== "") {
                      var todays_date = moment();
                      var storage_date = moment(second_element.storage_date);

                      var arrival_date_formatted = moment(
                        second_element.arrival_date
                      );

                      if (second_element.lot === element.Lot_No) {
                        if (
                          element.full_detail.calculations
                            .penalty_last_updated_date !== undefined
                        ) {
                          already_data.push(element);
                        } else {
                          var a = moment(storage_date);
                          var b = moment(todays_date);
                          var c = moment(arrival_date_formatted);
                          var difference_in_dates = b.diff(a, "days");
                          var total_outstanding_balance = parseFloat(
                            element.full_detail.calculations
                              .total_outstanding_balance
                          );
                          var arrival_difference = c.diff(a, "days");
                          var penalty_fee = 0;
                          var storage_fee_ksh = 0;
                          var storage_fee_sr = 0;
                          var container_arrival_date =
                            second_element.arrival_date;
                          var container_storage_date =
                            second_element.storage_date;
                          var penalty_last_updated_date = moment_time_zone()
                            .tz("Etc/GMT")
                            .format("DD/MM/YYYY");
                          var penalty_days = difference_in_dates;

                          var add = false;

                          if (
                            total_outstanding_balance >= 50 &&
                            difference_in_dates >= 0
                          ) {
                            penalty_fee = 50;

                            add = true;
                          }

                          if (add === true) {
                            var _element = {};
                            _element = element;

                            if (difference_in_dates >= 10) {
                              storage_fee_ksh = (
                                parseFloat(
                                  element.full_detail.calculations.total_weight
                                ) *
                                parseFloat(penalty_days) *
                                1.5
                              ).toFixed(2);
                              storage_fee_sr = (
                                (parseFloat(
                                  element.full_detail.calculations.total_weight
                                ) *
                                  parseFloat(penalty_days) *
                                  1.5) /
                                parseFloat(
                                  element.full_detail.calculations
                                    .conversion_selected_rate
                                )
                              ).toFixed(2);
                            } else {
                              storage_fee_ksh = 0.0;
                              storage_fee_sr = 0.0;
                            }

                            _element.full_detail.calculations.penalty_fee =
                              penalty_fee;
                            _element.full_detail.calculations.penalty_days =
                              penalty_days;
                            _element.full_detail.calculations.penalty_last_updated_date =
                              penalty_last_updated_date;
                            _element.full_detail.calculations.container_arrival_date =
                              container_arrival_date;
                            _element.full_detail.calculations.container_storage_date =
                              container_storage_date;

                            _element.full_detail.calculations.storage_fee_ksh =
                              storage_fee_ksh;
                            _element.full_detail.calculations.storage_fee_sr =
                              storage_fee_sr;

                            first_filter.push(_element);
                          }
                        }
                      }
                    }
                  });
                }
              }
            });

            var update_invoice_caculations = [];
            if (first_filter.length > 0) {
              first_filter.forEach((element) => {
                var calculations = element.full_detail.calculations;
                var invoices = element.full_detail.invoice_numbers;
                firebase
                  .database()
                  .ref(
                    `organization/zaincocargo/shipment_details/${element.uid}/full_detail/calculations`
                  )
                  .update(calculations)
                  .then((suc) => {
                    console.log("done");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              });
            }

            const combined_array = already_data.concat(first_filter);
            this.setState({
              data: combined_array,
            });
          });
      });
  }
  toggle() {
    this.setState(
      (prevState) => ({
        ready: false,
      }),
      () => {
        setTimeout(() => {
          this.setState({ ready: true });
        }, 1);
      }
    );
  }
  table_titles(data_taken) {
    var titles_to_show = [];

    var titles = [
      "HBL",
      "Lot No",
      //"Arrival Date",
      "THE DAY CARGO ARRIVED AT OUR WAREHOUSE",
      "Free Time",
      // "Storage Date",
      "STORAGE BEGINS",
      "Last Updated Date",
      "Number Of Days",
      "Amount (SAR)",
      "Amount (KSH)",
      "Outstanding Balance",
    ];

    titles.forEach((element, i) => {
      titles_to_show.push(
        <Text
          key={i}
          style={{
            width: "10%",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
          }}
        >
          {element}
        </Text>
      );
    });
    return (
      <View
        style={{
          flexDirection: "row",
          borderBottomColor: "#CFD8DC",
          backgroundColor: "#CFD8DC",
          borderBottomWidth: 1,
          alignItems: "center",
          paddingTop: 10,
          paddingBotttom: 10,
          textAlign: "center",
          fontStyle: "bold",
          fontSize: 10,
        }}
      >
        {titles_to_show}
      </View>
    );
  }

  line_items(data_taken) {
    var list_to_show = [];

    data_taken.forEach((element, i) => {
      list_to_show.push(
        <View
          style={{
            flexDirection: "row",
            borderBottomColor: "#CFD8DC",
            borderBottomWidth: 1,
            alignItems: "center",
            height: 24,
            fontStyle: "bold",
          }}
          key={i}
        >
          <Text
            style={{
              width: "10%",
              textAlign: "center",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              paddingLeft: 8,
              fontSize: 8,
            }}
          >
            {element.HBL}
          </Text>
          <Text
            style={{
              width: "10%",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              textAlign: "center",
              paddingRight: 8,
              fontSize: 8,
            }}
          >
            {element.Lot_No}
          </Text>
          <Text
            style={{
              width: "10%",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              textAlign: "center",
              paddingRight: 8,
              fontSize: 8,
            }}
          >
            {element.container_arrival_date}
          </Text>
          <Text
            style={{
              width: "10%",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              textAlign: "center",
              paddingRight: 8,
              fontSize: 8,
            }}
          >
            {element.free_time}
          </Text>

          <Text
            style={{
              width: "10%",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              textAlign: "center",
              paddingRight: 8,
              fontSize: 8,
            }}
          >
            {element.container_storage_date}
          </Text>
          <Text
            style={{
              width: "10%",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              textAlign: "center",
              paddingRight: 8,
              fontSize: 8,
            }}
          >
            {element.penalty_last_updated_date}
          </Text>
          <Text
            style={{
              width: "10%",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              textAlign: "center",
              paddingRight: 8,
              fontSize: 8,
            }}
          >
            {element.number_of_days_number} Days
          </Text>

          <Text
            style={{
              width: "10%",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              textAlign: "center",
              paddingRight: 8,
              fontSize: 8,
            }}
          >
            {element.penalty_amount_in_SAR}
          </Text>
          <Text
            style={{
              width: "10%",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              textAlign: "center",
              paddingRight: 8,
              fontSize: 8,
            }}
          >
            {element.penalty_amount_in_KSH}
          </Text>
          <Text
            style={{
              width: "10%",
              textAlign: "center",
              paddingRight: 8,
              fontSize: 8,
            }}
          >
            {element.outstanding_balance}
          </Text>
        </View>
      );
    });

    return list_to_show;
  }
  show_report() {
    const { ready, data_to_show_in_table } = this.state;
    if (data_to_show_in_table.length > 0) {
      var data_taken = data_to_show_in_table;
      var title = "Zainco Storage Penalty Fee Report";

      var table_titles = this.table_titles(data_taken);

      var line_items = this.line_items(data_taken);

      return (
        <Document>
          <Page
            size="A4"
            style={{
              fontFamily: "Helvetica",
              fontSize: 11,
              paddingTop: 30,
              paddingLeft: 60,
              paddingRight: 60,
              lineHeight: 1.5,
              flexDirection: "column",
            }}
          >
            <Image
              style={{
                width: 74,
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={logo}
            />

            <View
              style={{
                flexDirection: "row",
                marginTop: 24,
              }}
            >
              <Text
                style={{
                  color: "#263238",
                  fontSize: 15,
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                {title}
              </Text>
            </View>

            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: 24,
                borderWidth: 1,
                borderColor: "#CFD8DC",
              }}
            >
              {table_titles}

              {line_items}
            </View>
          </Page>
        </Document>
      );
    } else {
      return (
        <Document>
          <Page
            size="A4"
            style={{
              fontFamily: "Helvetica",
              fontSize: 11,
              paddingTop: 30,
              paddingLeft: 60,
              paddingRight: 60,
              lineHeight: 1.5,
              flexDirection: "column",
            }}
          >
            <Image
              style={{
                width: 74,
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={logo}
            />
          </Page>
        </Document>
      );
    }
  }

  update_all_data() {
    // firebase
    //   .database()
    //   .ref("organization/zaincocargo/container/")
    //   .once("value")
    //   .then((snapshot) => {
    // var container_data = _.map(snapshot.val(), (val, uid) => {

    var container_data = _.map(this.props.container_data, (val, uid) => {
      return { ...val, uid };
    });

    // firebase
    //   .database()
    //   .ref("organization/zaincocargo/shipment_details/")
    //   .once("value")
    //   .then(async (snapshot) => {
    // var existing_date = _.map(snapshot.val(), (val, uid) => {
    var existing_date = _.map(this.props.shipping_data, (val, uid) => {
      return { ...val, uid };
    });
    var first_filter = [];
    var already_data = [];

    existing_date.forEach(async (element) => {
      if (element.full_detail !== undefined) {
        if (element.full_detail.calculations !== undefined) {
          container_data.forEach(async (second_element) => {
            if (second_element.storage_date !== "") {
              var todays_date = moment();
              var storage_date = moment(second_element.storage_date);

              var arrival_date_formatted = moment(second_element.arrival_date);

              if (second_element.lot === element.Lot_No) {
                {
                  var a = moment(storage_date);
                  var b = moment(todays_date);
                  var c = moment(arrival_date_formatted);
                  var difference_in_dates = b.diff(a, "days");
                  var total_outstanding_balance = parseFloat(
                    element.full_detail.calculations.total_outstanding_balance
                  );
                  var arrival_difference = c.diff(a, "days");
                  var penalty_fee = 0;
                  var storage_fee_ksh = 0;
                  var storage_fee_sr = 0;
                  var container_arrival_date = second_element.arrival_date;
                  var container_storage_date = second_element.storage_date;
                  var penalty_last_updated_date = moment_time_zone()
                    .tz("Etc/GMT")
                    .format("DD/MM/YYYY");
                  var penalty_days = difference_in_dates;

                  var add = false;

                  if (
                    total_outstanding_balance >= 50 &&
                    difference_in_dates >= 0
                  ) {
                    penalty_fee = 50;

                    add = true;
                  }

                  if (add === true) {
                    var _element = {};
                    _element = element;

                    if (difference_in_dates >= 10) {
                      storage_fee_ksh = (
                        parseFloat(
                          element.full_detail.calculations.total_weight
                        ) *
                        parseFloat(penalty_days) *
                        1.5
                      ).toFixed(2);
                      storage_fee_sr = (
                        (parseFloat(
                          element.full_detail.calculations.total_weight
                        ) *
                          parseFloat(penalty_days) *
                          1.5) /
                        parseFloat(
                          element.full_detail.calculations
                            .conversion_selected_rate
                        )
                      ).toFixed(2);
                    } else {
                      storage_fee_ksh = 0.0;
                      storage_fee_sr = 0.0;
                    }

                    _element.full_detail.calculations.penalty_fee = penalty_fee;
                    _element.full_detail.calculations.penalty_days =
                      penalty_days;
                    _element.full_detail.calculations.penalty_last_updated_date =
                      penalty_last_updated_date;
                    _element.full_detail.calculations.container_arrival_date =
                      container_arrival_date;
                    _element.full_detail.calculations.container_storage_date =
                      container_storage_date;

                    _element.full_detail.calculations.storage_fee_ksh =
                      storage_fee_ksh;
                    _element.full_detail.calculations.storage_fee_sr =
                      storage_fee_sr;

                    first_filter.push(_element);
                  }
                }
              }
            }
          });
        }
      }
    });

    var update_invoice_caculations = [];
    if (first_filter.length > 0) {
      first_filter.forEach((element) => {
        var calculations = element.full_detail.calculations;
        var invoices = element.full_detail.invoice_numbers;
        firebase
          .database()
          .ref(
            `organization/zaincocargo/shipment_details/${element.uid}/full_detail/calculations`
          )
          .update(calculations)
          .then((suc) => {
            console.log("done");
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }

    const combined_array = already_data.concat(first_filter);
    this.setState({
      data: combined_array,
    });
    // });
    // });
  }
  update_specfic_element({ element, full_detail, calculations, i }) {
    // firebase
    //   .database()
    //   .ref("organization/zaincocargo/container/")
    //   .once("value")
    //   .then((snapshot) => {
    // var container_data = _.map(snapshot.val(), (val, uid) => {
    //   return { ...val, uid };
    // });
    var container_data = _.map(this.props.container_data, (val, uid) => {
      return { ...val, uid };
    });

    firebase
      .database()
      .ref(`organization/zaincocargo/shipment_details/${element.uid}/`)
      .once("value")
      .then(async (snapshot) => {
        var shippment_data = snapshot.val();

        container_data.forEach(async (second_element) => {
          var todays_date = moment();
          var storage_date = moment(second_element.storage_date);

          var arrival_date_formatted = moment(second_element.arrival_date);

          if (second_element.lot === shippment_data.Lot_No) {
            var a = moment(storage_date);
            var b = moment(todays_date);
            var c = moment(arrival_date_formatted);

            var difference_in_dates = b.diff(a, "days");
            var total_outstanding_balance = parseFloat(
              element.full_detail.calculations.total_outstanding_balance
            );
            var arrival_difference = c.diff(a, "days");
            var penalty_fee = 0;
            var storage_fee_ksh = 0;
            var storage_fee_sr = 0;
            var container_arrival_date = second_element.arrival_date;
            var container_storage_date = second_element.storage_date;
            var penalty_last_updated_date = moment_time_zone()
              .tz("Etc/GMT")
              .format("DD/MM/YYYY");
            var penalty_days = difference_in_dates;

            var add = false;
            if (total_outstanding_balance >= 50 && difference_in_dates >= 0) {
              penalty_fee = 50;

              add = true;
            }
            var _element = {};
            _element = shippment_data;
            if (add === true) {
              if (difference_in_dates >= 10) {
                storage_fee_ksh = (
                  parseFloat(_element.full_detail.calculations.total_weight) *
                  parseFloat(penalty_days) *
                  1.5
                ).toFixed(2);
                storage_fee_sr = (
                  (parseFloat(_element.full_detail.calculations.total_weight) *
                    parseFloat(penalty_days) *
                    1.5) /
                  parseFloat(
                    _element.full_detail.calculations.conversion_selected_rate
                  )
                ).toFixed(2);
              } else {
                storage_fee_ksh = 0.0;
                storage_fee_sr = 0.0;
              }
            }

            _element.full_detail.calculations.penalty_fee = penalty_fee;
            _element.full_detail.calculations.penalty_days = penalty_days;
            _element.full_detail.calculations.penalty_last_updated_date =
              penalty_last_updated_date;
            _element.full_detail.calculations.container_arrival_date =
              container_arrival_date;
            _element.full_detail.calculations.container_storage_date =
              container_storage_date;

            _element.full_detail.calculations.storage_fee_ksh = storage_fee_ksh;
            _element.full_detail.calculations.storage_fee_sr = storage_fee_sr;

            var temp_data = this.state.data;
            temp_data[i] = _element;
            temp_data[i].uid = element.uid;

            this.setState({
              data: temp_data,
            });

            var calculations = _element.full_detail.calculations;
            firebase
              .database()
              .ref(
                `organization/zaincocargo/shipment_details/${element.uid}/full_detail/calculations`
              )
              .update(calculations)
              .then((suc) => {
                console.log("done");
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
      });
    // });
  }
  show_report_based_on_hbl() {
    const { SAR_TO_KSH_RATE } = this.props;

    const { data } = this.state;

    // const { shipping_data } = this.props;
    // var data = shipping_data;

    // data = _.map(data, (val, uid) => {
    //   return { ...val, uid };
    // });

    var data_to_show_in_table = [];
    if (data.length > 0) {
      data.forEach((element, i) => {
        var full_detail = element.full_detail;

        if (full_detail !== undefined) {
          var calculations = full_detail.calculations;
          var number_of_days = calculations.penalty_days;
          var penalty_amount_in_SAR = (
            parseFloat(calculations.total_weight) *
            parseFloat(number_of_days) *
            (1.5 / SAR_TO_KSH_RATE.rate)
          ).toFixed(2);
          var penalty_amount_in_KSH = (
            parseFloat(calculations.total_weight) *
            parseFloat(number_of_days) *
            1.5
          ).toFixed(2);

          var last_updated_date_container = (
            <div>
              <p>{number_of_days} Days</p>
              <a
                style={{ color: "blue" }}
                onClick={() => {
                  this.update_specfic_element({
                    element,
                    full_detail,
                    calculations,
                    i,
                  });
                }}
              >
                Click to Update
              </a>
            </div>
          );

          data_to_show_in_table.push({
            HBL: element.uid,
            Lot_No: element.Lot_No,
            container_arrival_date: calculations.container_arrival_date,
            container_storage_date: calculations.container_storage_date,
            free_time: "10 Days",
            penalty_last_updated_date: calculations.penalty_last_updated_date,
            number_of_days: last_updated_date_container,
            number_of_days_number: number_of_days,
            penalty_fee: calculations.penalty_fee,
            outstanding_balance: calculations.total_outstanding_balance
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            penalty_amount_in_SAR: penalty_amount_in_SAR
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            penalty_amount_in_KSH: penalty_amount_in_KSH
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          });
        }
      });
    }

    const table_data = {
      columns: [
        {
          label: "HBL",
          field: "HBL",
          sort: "asc",
          width: 150,
        },
        {
          label: "Lot No",
          field: "Lot_No",
          sort: "asc",
          width: 270,
        },
        {
          label: "THE DAY CARGO ARRIVED AT OUR WAREHOUSE",
          field: "container_arrival_date",
          sort: "asc",
          width: 200,
        },
        {
          label: "Free time",
          field: "free_time",
          sort: "asc",
          width: 100,
        },

        {
          label: "STORAGE BEGINS",
          field: "container_storage_date",
          sort: "asc",
          width: 100,
        },

        {
          label: "Last Updated date",
          field: "penalty_last_updated_date",
          sort: "asc",
          width: 100,
        },

        {
          label: "Number Of Days",
          field: "number_of_days",
          sort: "asc",
          width: 100,
        },
        {
          label: "Penalty Fee (SAR)",
          field: "penalty_fee",
          sort: "asc",
          width: 100,
        },
        {
          label: "Amount (SAR)",
          field: "penalty_amount_in_SAR",
          sort: "asc",
          width: 100,
        },

        {
          label: "Amount (KSH)",
          field: "penalty_amount_in_KSH",
          sort: "asc",
          width: 100,
        },
        {
          label: "Outstanding Balance",
          field: "outstanding_balance",
          sort: "asc",
          width: 100,
        },
      ],
      rows: data_to_show_in_table,
    };

    const { ready } = this.state;

    const MyDocument = this.show_report();

    return (
      <div>
        <div>
          {ready && (
            <PDFDownloadLink document={MyDocument} fileName="PDF">
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <Button onClick={() => this.setState({ ready: false })}>
                    Download Report
                  </Button>
                )
              }
            </PDFDownloadLink>
          )}
          {!ready && (
            <Button
              onClick={() => {
                this.setState({ data_to_show_in_table });
                this.toggle();
              }}
            >
              Generate Report
            </Button>
          )}
        </div>

        <div>
          {this.state.show_shipments === false ? (
            <div style={{ paddingTop: 15, paddingBottom: 15 }}>
              <Button
                onClick={() => {
                  this.setState({
                    show_shipments: true,
                  });
                  this.update_all_data();
                }}
              >
                Show and Update All Shipments for Storage Cost
              </Button>
            </div>
          ) : (
            <div
              style={{
                paddingTop: 20,
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <MDBDataTable responsive striped bordered data={table_data} />
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { loading, SAR_TO_KSH_RATE } = this.props;

    if (loading === true || SAR_TO_KSH_RATE === null) {
      const loadingjson = {
        loop: true,
        autoplay: true,
        animationData: require("./JSON/loading.json"),

        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };

      return (
        <div className="page-content">
          <Container fluid>
            <Row className="justify-content-center mt-lg-5">
              <div className="text-center">
                <Row className="justify-content-center">
                  <div className="text-center">
                    <Lottie options={loadingjson} height={300} width={300} />
                  </div>
                </Row>
              </div>
            </Row>
          </Container>
        </div>
      );
    }
    return (
      <div>
        <TabContent activeTab={this.state.activeTab} className="mt-4">
          <TabPane tabId="1" id="buy-tab">
            {this.show_report_based_on_hbl()}
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { shipping_data, error, loading, container_data, currency_data } =
    state.Database;
  var SAR_TO_KSH_RATE = currency_data;
  return {
    shipping_data,
    error,
    loading,
    container_data,
    SAR_TO_KSH_RATE,
    // currency_data,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    shipping_data_read,
    container_data_read,
    detail_error,
    currency_data_read,
  })(withNamespaces()(Storage))
);
