/*import React, { Component } from "react";
import Main from "./Main";
import { Container, Row, Col } from "reactstrap";

//i18n
import { withNamespaces } from "react-i18next";

// Redux
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import {
  user_data_read,
  detail_error,
  limited_users_data_read,
  limited_users_logs_data_read,
} from "../../store/actions";
import Lottie from "react-lottie";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // this.props.user_data_read();
    // this.props.limited_users_data_read();
    this.props.limited_users_logs_data_read();
  }

  render() {
    const {
      user_details,
      limited_users_data,
      limited_users_logs_data,
      loading,
    } = this.props;

    if (loading === true) {
      const loadingjson = {
        loop: true,
        autoplay: true,
        animationData: require("./JSON/loading.json"),

        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };

      return (
        <div className="page-content">
          <Container fluid>
            <Row className="justify-content-center mt-lg-5">
              <div className="text-center">
                <Row className="justify-content-center">
                  <div className="text-center">
                    <Lottie options={loadingjson} height={300} width={300} />
                  </div>
                </Row>
              </div>
            </Row>
          </Container>
        </div>
      );
    } else {
      if (user_details) {
        if (user_details.role === "limited") {
          return <Redirect to={{ pathname: "/" }} />;
        } else {
          return (
            <React.Fragment>
              <Main
                user_details={user_details}
                limited_users_data={limited_users_data}
                limited_users_logs_data={limited_users_logs_data}
              />
            </React.Fragment>
          );
        }
      }
      return (
        <React.Fragment>
          <Main
            user_details={user_details}
            limited_users_data={limited_users_data}
            limited_users_logs_data={limited_users_logs_data}
          />
        </React.Fragment>
      );
    }
  }
}

const mapStatetoProps = (state) => {
  const {
    user_details,
    error,
    loading,
    limited_users_data,
    limited_users_logs_data,
  } = state.Database;

  return {
    user_details,
    error,
    loading,
    limited_users_data,
    limited_users_logs_data,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    user_data_read,
    detail_error,
    limited_users_data_read,
    limited_users_logs_data_read,
  })(withNamespaces()(Index))
);
*/

import _ from "lodash";
import React, { Component, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
  UncontrolledTooltip,
  ////////
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  InputGroupAddon,
  InputGroup,
  Modal,
} from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";

import firebase from "firebase/app";
import SweetAlert from "react-bootstrap-sweetalert";

// Add the Firebase products that you want to use
import "firebase/database";

import classnames from "classnames";

// Import Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//Import Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Access from "./Access";
import User_Logs from "./User_Logs";

//i18n
import { withNamespaces } from "react-i18next";

// Redux
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import {
  user_data_read,
  detail_error,
  limited_users_data_read,
  limited_users_logs_data_read,
} from "../../store/actions";
import Lottie from "react-lottie";
import User_Logs_All from "./User_Logs_All";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      activeTab: "1",

      lot: "",
      accepting_date: "",
      closing_date: "",
      departure_date: "",
      arrival_date: "",
      storage_date: "",
      other_details: "",

      old_data: [],

      old_lot: "",
      old_accepting_date: "",
      old_closing_date: "",
      old_departure_date: "",
      old_arrival_date: "",
      old_storage_date: "",
      old_other_details: "",
      old_uid: "",

      body: <div></div>,
      show_alert: false,
      modal_standard: false,
      show_old_data: false,
      data_array_logs: [],
      data_logs: [],
      limited_access_users: [],
    };
    this.startDateChange.bind(this);
    this.endDateChange.bind(this);

    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  startDateChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  endDateChange = (date) => {
    this.setState({
      endDate: date,
    });
  };

  componentDidMount() {
    this.props.limited_users_logs_data_read();
  }

  reset() {
    this.setState({
      lot: "",
      accepting_date: "",
      closing_date: "",
      departure_date: "",
      arrival_date: "",
      storage_date: "",
      other_details: "",

      //old
      old_data: [],
      old_name: "",
      old_address: "",
      old_phone: "",
      old_town: "",
      old_country: "",
      old_uid: "",
      body: <div></div>,
      show_alert: false,
      modal_standard: false,
      show_old_data: false,
    });
  }

  render() {
    const {
      user_details,
      limited_users_data,
      limited_users_logs_data,
      loading,
    } = this.props;

    if (loading === true) {
      const loadingjson = {
        loop: true,
        autoplay: true,
        animationData: require("./JSON/loading.json"),

        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };

      return (
        <div className="page-content">
          <Container fluid>
            <Row className="justify-content-center mt-lg-5">
              <div className="text-center">
                <Row className="justify-content-center">
                  <div className="text-center">
                    <Lottie options={loadingjson} height={300} width={300} />
                  </div>
                </Row>
              </div>
            </Row>
          </Container>
        </div>
      );
    } else {
      if (user_details.role === "limited") {
        return <Redirect to={{ pathname: "/" }} />;
      }

      const { limited_users_logs_data, limited_users_data } = this.props;
      var data_array_logs = _.map(limited_users_logs_data, (val, uid) => {
        return { ...val, uid };
      });

      // console.log(limited_users_data);

      // var limited_access_users = _.map(limited_users_data, (val, uid) => {
      //   return { ...val, uid };
      // });

      return (
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              <Breadcrumbs
                title="User Management"
                breadcrumbItem="User Management"
              />

              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">User Management</CardTitle>

                      <div className="mt-4">
                        <Nav pills className="bg-light rounded" role="tablist">
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: this.state.activeTab === "1",
                              })}
                              onClick={() => {
                                this.reset();
                                // this.setState({
                                //   limited_access_users: limited_access_users,
                                //   // this.props.limited_users_data,
                                //   data_array_logs: data_array_logs,
                                //   data_logs: limited_users_logs_data,
                                // });

                                this.toggleTab("1");
                              }}
                            >
                              Users
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: this.state.activeTab === "2",
                              })}
                              onClick={() => {
                                this.reset();

                                this.toggleTab("2");
                              }}
                            >
                              User Logs (By Users)
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: this.state.activeTab === "3",
                              })}
                              onClick={() => {
                                this.reset();

                                this.toggleTab("3");
                              }}
                            >
                              User Logs (All)
                            </NavLink>
                          </NavItem>
                        </Nav>

                        <TabContent
                          activeTab={this.state.activeTab}
                          className="mt-4"
                        >
                          <TabPane tabId="1" id="buy-tab">
                            <Access
                              limited_users_data={limited_users_data} //{limited_access_users}

                              // ={
                              //   this.state.limited_access_users
                              // }
                            />
                          </TabPane>
                          <TabPane tabId="2" id="sell-tab">
                            <User_Logs
                              data_array_logs={data_array_logs} //{this.state.data_array_logs}
                              data_logs={limited_users_logs_data} //{this.state.data_logs}
                            />
                          </TabPane>

                          <TabPane tabId="3" id="sell-tab">
                            <User_Logs_All
                              data_array_logs={data_array_logs} //{this.state.data_array_logs}
                              data_logs={limited_users_logs_data} //{this.state.data_logs}
                            />
                          </TabPane>
                        </TabContent>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      );
    }
  }
}

const mapStatetoProps = (state) => {
  const {
    user_details,
    error,
    loading,
    limited_users_data,
    limited_users_logs_data,
  } = state.Database;

  return {
    user_details,
    error,
    loading,
    limited_users_data,
    limited_users_logs_data,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    user_data_read,
    detail_error,
    limited_users_data_read,
    limited_users_logs_data_read,
  })(withNamespaces()(Index))
);
