import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Label,
  Input,
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  InputGroupAddon,
  InputGroup,
  Modal,
  Form,
  Button,
} from "reactstrap";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import { MDBDataTable } from "mdbreact";
import classnames from "classnames";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import logo from "../../assets/images/logo-dark.png";
import firebase from "firebase/app";
import "firebase/database";

/***/
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import {
  shipping_data_read,
  container_data_read,
  detail_error,
} from "../../store/actions";
import Lottie from "react-lottie";
/***/

class DebtManifest extends Component {
  constructor() {
    super();
    this.state = {
      // activeTab: "1",
      activeTab: "2",
      data_by_lot_no: [],
      ready: false,
      data_to_show_in_table: [],
    };
  }

  toggle() {
    this.setState(
      (prevState) => ({
        ready: false,
      }),
      () => {
        setTimeout(() => {
          this.setState({ ready: true });
        }, 1);
      }
    );
  }
  table_titles(data_taken) {
    var titles_to_show = [];
    var titles = [
      "HBL",
      "Lot No",
      "Shipper",
      "Shipper Phone No.",
      "Consignee",
      "Consignee Phone No.",
      "Quantity",
      "Weight",
      "Outstanding Balance",
      "Outstanding Balance (KSH)",
      "Amount Paid",
      "Amount Paid (KSH)",
      "Total Amount",
      "Total Amount (KSH)",
    ];

    return (
      <View
        style={{
          flexDirection: "row",
          borderBottomColor: "#CFD8DC",
          backgroundColor: "#CFD8DC",
          borderBottomWidth: 1,
          alignItems: "left",
          paddingTop: 10,
          paddingBotttom: 10,
          textAlign: "left",
          fontStyle: "bold",
        }}
      >
        <Text
          style={{
            // width: "8%",
            width: `${100 / 14}%`, //"8%",
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 7,
            paddingLeft: 10,
          }}
        >
          {titles[0]}
        </Text>
        <Text
          style={{
            // width: "8%",
            width: `${100 / 14}%`, //"8%",
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 7,
          }}
        >
          {titles[1]}
        </Text>

        <Text
          style={{
            // width: "9%",

            width: `${100 / 14}%`,
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 7,
          }}
        >
          {titles[2]}
        </Text>
        <Text
          style={{
            // width: "9%",
            width: `${100 / 14}%`,
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 7,
          }}
        >
          {titles[3]}
        </Text>
        <Text
          style={{
            // width: "9%",
            width: `${100 / 14}%`,
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 7,
          }}
        >
          {titles[4]}
        </Text>
        <Text
          style={{
            // width: "9%",
            width: `${100 / 14}%`,
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 7,
          }}
        >
          {titles[5]}
        </Text>
        <Text
          style={{
            // width: "9%",
            width: `${100 / 14}%`,
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 7,
          }}
        >
          {titles[6]}
        </Text>
        <Text
          style={{
            // width: "9%",
            width: `${100 / 14}%`,
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 7,
          }}
        >
          {titles[7]}
        </Text>
        <Text
          style={{
            // width: "10%",
            width: `${100 / 14}%`,
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 7,
          }}
        >
          {titles[8]}
        </Text>
        <Text
          style={{
            // width: "10%",
            width: `${100 / 14}%`,
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 7,
          }}
        >
          {titles[9]}
        </Text>
        <Text
          style={{
            // width: "10%",
            width: `${100 / 14}%`,
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 7,
          }}
        >
          {titles[10]}
        </Text>

        <Text
          style={{
            // width: "10%",
            width: `${100 / 14}%`,
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 7,
          }}
        >
          {titles[11]}
        </Text>
        <Text
          style={{
            // width: "10%",
            width: `${100 / 14}%`,
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 7,
          }}
        >
          {titles[12]}
        </Text>
        <Text
          style={{
            // width: "10%",
            width: `${100 / 14}%`,
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 7,
          }}
        >
          {titles[13]}
        </Text>
      </View>
    );
  }

  line_items(data_taken) {
    var list_to_show = [];
    data_taken.forEach((element, i) => {
      list_to_show.push(
        <View
          style={{
            flexDirection: "row",
            borderBottomColor: "#CFD8DC",
            borderBottomWidth: 1,
            alignItems: "center",
            fontStyle: "bold",
            paddingTop: 5,
          }}
          key={i}
        >
          <Text
            style={{
              // width: "7%",
              width: `${100 / 14}%`,
              textAlign: "left",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 8,
              flexWrap: "wrap",
            }}
          >
            {element.HBL}
          </Text>
          <Text
            style={{
              // width: "7%",
              width: `${100 / 14}%`,
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              textAlign: "left",
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 8,
              flexWrap: "wrap",
            }}
          >
            {element.Lot_No}
          </Text>

          <Text
            style={{
              // width: "9%",
              width: `${100 / 14}%`,
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              textAlign: "left",

              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 8,
              flexWrap: "wrap",
            }}
          >
            {element.Shipper}
          </Text>
          <Text
            style={{
              // width: "10%",
              width: `${100 / 14}%`,
              textAlign: "left",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,

              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 8,
              flexWrap: "wrap",
            }}
          >
            {element.Shipper_Phone}
          </Text>
          <Text
            style={{
              // width: "9%",
              width: `${100 / 14}%`,
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,

              textAlign: "left",
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 8,
              flexWrap: "wrap",
            }}
          >
            {element.Consignee}
          </Text>

          <Text
            style={{
              // width: "10%",
              width: `${100 / 14}%`,
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              textAlign: "left",
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 8,
              flexWrap: "wrap",
            }}
          >
            {element.Consignee_Phone}
          </Text>
          <Text
            style={{
              // width: "9%",
              width: `${100 / 14}%`,
              textAlign: "left",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 8,
              flexWrap: "wrap",
            }}
          >
            {element.Quantity}
          </Text>

          <Text
            style={{
              // width: "9%",
              width: `${100 / 14}%`,
              textAlign: "left",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 8,
              flexWrap: "wrap",
            }}
          >
            {element.Weight}
          </Text>

          <Text
            style={{
              // width: "10%",
              width: `${100 / 14}%`,
              textAlign: "left",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 8,
              flexWrap: "wrap",
            }}
          >
            {element.Total_outstanding_balance}
          </Text>

          <Text
            style={{
              // width: "10%",
              width: `${100 / 14}%`,
              textAlign: "left",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 8,
              flexWrap: "wrap",
            }}
          >
            {element.converted_total_outstanding_balance}
          </Text>

          <Text
            style={{
              // width: "10%",
              width: `${100 / 14}%`,
              textAlign: "left",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 8,
              flexWrap: "wrap",
            }}
          >
            {element.total_payments}
          </Text>

          <Text
            style={{
              // width: "10%",
              width: `${100 / 14}%`,
              textAlign: "left",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 8,
              flexWrap: "wrap",
            }}
          >
            {element.converted_total_payments}
          </Text>

          <Text
            style={{
              // width: "10%",
              width: `${100 / 14}%`,
              textAlign: "left",
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 8,
              flexWrap: "wrap",
            }}
          >
            {element.total_charges}
          </Text>

          <Text
            style={{
              // width: "10%",
              width: `${100 / 14}%`,
              textAlign: "left",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 8,
              flexWrap: "wrap",
            }}
          >
            {element.converted_total_charges}
          </Text>
        </View>
      );
    });

    return list_to_show;
  }
  show_report() {
    const { ready, data_to_show_in_table } = this.state;

    if (data_to_show_in_table.length > 0) {
      var title = "Zainco Debt Manifest Report";
      var data_taken = data_to_show_in_table;
      var table_titles = this.table_titles(data_taken);

      var line_items = this.line_items(data_taken);

      const styles = StyleSheet.create({
        page: {
          paddingTop: 35,
          paddingBottom: 65,
          paddingHorizontal: 35,
        },
      });

      return (
        <Document>
          <Page size="A4" orientation="landscape" style={styles.page}>
            <Image
              style={{
                width: 74,
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={logo}
            />

            <View
              style={{
                flexDirection: "row",
                marginTop: 24,
              }}
            >
              <Text
                style={{
                  color: "#263238",
                  fontSize: 15,
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                {title}
              </Text>
            </View>

            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: 24,
                borderWidth: 1,
                borderColor: "#CFD8DC",
              }}
            >
              {table_titles}

              {line_items}
            </View>
          </Page>
        </Document>
      );
    } else {
      return (
        <Document>
          <Page
            size="A4"
            style={{
              paddingTop: 35,
              paddingBottom: 65,
              paddingHorizontal: 35,
            }}
          >
            <Image
              style={{
                width: 74,
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={logo}
            />
          </Page>
        </Document>
      );
    }
  }

  show_report_based_on_hbl() {
    // const { data } = this.props;
    const { shipping_data } = this.props;
    var data = shipping_data;

    data = _.map(data, (val, uid) => {
      return { ...val, uid };
    });

    var data_to_show_in_table = [];
    var show_data = [];
    data.forEach((element) => {
      if (element.full_detail !== undefined) {
        show_data.push(element);
      }
    });

    show_data.forEach((element) => {
      const {
        cargo_details,
        consignee_details,
        other_charges,
        payments_details,
        shipper_details,
        calculations,
      } = element.full_detail;

      if (element.full_detail) {
        if (calculations) {
          data_to_show_in_table.push({
            HBL: element.uid,
            Lot_No: element.Lot_No,
            Consignee: element.Consignee,
            Consignee_Phone: element.C_Phone,

            Shipper: element.Shipper,
            Shipper_Phone: element.Phone,

            Quantity: calculations.total_quantity
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            Weight: calculations.total_weight
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),

            Total_outstanding_balance: calculations.total_outstanding_balance
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            total_payments: calculations.total_payments
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            total_charges: calculations.total_charges
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          });
        }
      }
    });
    const table_data = {
      columns: [
        {
          label: "HBL",
          field: "HBL",
          sort: "asc",
          width: 150,
        },
        {
          label: "Lot No",
          field: "Lot_No",
          sort: "asc",
          width: 270,
        },

        {
          label: "Shipper",
          field: "Shipper",
          sort: "asc",
          width: 100,
        },
        {
          label: "Shipper Phone No.",
          field: "Shipper_Phone",
          sort: "asc",
          width: 100,
        },
        {
          label: "Consignee",
          field: "Consignee",
          sort: "asc",
          width: 200,
        },

        {
          label: "Consignee Phone No.",
          field: "Consignee_Phone",
          sort: "asc",
          width: 200,
        },

        {
          label: "Quantity",
          field: "Quantity",
          sort: "asc",
          width: 200,
        },
        {
          label: "Weight",
          field: "Weight",
          sort: "asc",
          width: 200,
        },

        {
          label: "Outstanding Balance",
          field: "Total_outstanding_balance",
          sort: "asc",
          width: 100,
        },

        {
          label: "Amount Paid",
          field: "total_payments",
          sort: "asc",
          width: 100,
        },

        {
          label: "Total Amount",
          field: "total_charges",
          sort: "asc",
          width: 100,
        },
      ],
      rows: data_to_show_in_table,
    };
    const { ready } = this.state;
    const MyDocument = this.show_report();
    var data_csv = [];

    data_csv.push([
      "No",
      "QTY",
      "Weight",
      "Lot No.",
      "Date",
      "Shipper",
      "Phone",
      "S_Town",
      "Consignee",
      "C_Phone",
      // "C_Town",
      // "Delivery",

      "Delivery",
      "C_Town",
      "Delivery Status",
    ]);
    var export_data = data_csv;
    return (
      <div>
        <div>
          <div>
            {ready && (
              <PDFDownloadLink document={MyDocument} fileName="PDF">
                {({ blob, url, loading, error }) =>
                  loading ? (
                    "Loading document..."
                  ) : (
                    <Button onClick={() => this.setState({ ready: false })}>
                      Download Report
                    </Button>
                  )
                }
              </PDFDownloadLink>
            )}
            {!ready && (
              <Button
                onClick={() => {
                  this.setState({ data_to_show_in_table });
                  this.toggle();
                }}
              >
                Generate Report
              </Button>
            )}
          </div>

          <div>
            {export_data === null ? null : (
              <div className="row mt-4">
                <div className="col-xl col-sm-6">
                  <div className="text-sm-right">
                    <CSVLink filename="Debt_Manifest.csv" data={export_data}>
                      <Button type="button" color="primary" className="w-md">
                        Export Data in CSV
                      </Button>
                    </CSVLink>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            paddingTop: 20,
            paddingLeft: 15,
            paddingRight: 15,
          }}
        >
          <MDBDataTable responsive striped bordered data={table_data} />
        </div>
      </div>
    );
  }

  processing() {
    // const { data } = this.props;

    const { shipping_data } = this.props;

    var snapshot = shipping_data;

    var all_data_lot_no = _.map(snapshot, (val, uid) => {
      var data = {
        label: val.Lot_No,

        data: { uid, ...val },
      };

      return data;
    });

    var seen_all_data_lot_no = new Map();

    all_data_lot_no = all_data_lot_no.filter((entry) => {
      var previous;
      if (seen_all_data_lot_no.hasOwnProperty(entry.label)) {
        previous = seen_all_data_lot_no[entry.label];
        previous.data.push(entry.data);

        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen_all_data_lot_no[entry.label] = entry;

      return true;
    });

    all_data_lot_no = _.fromPairs(
      _.map(all_data_lot_no, (i) => {
        return [i.label, i.data];
      })
    );

    return all_data_lot_no;
  }

  show_table_filtered_by_lot_no() {
    var data = this.state.data_by_lot_no;
    var data_to_show_in_table = [];
    var data_to_show_in_excel = [];

    var show_data = [];
    data.forEach((element) => {
      if (element.full_detail !== undefined) {
        show_data.push(element);
      }
    });

    show_data.forEach((element) => {
      const {
        cargo_details,
        consignee_details,
        other_charges,
        payments_details,
        shipper_details,
        calculations,
      } = element.full_detail;

      if (element.full_detail) {
        if (calculations) {
          var conversion_selected_rate = parseFloat(
            calculations.conversion_selected_rate
          );

          var total_outstanding_balance = calculations.total_outstanding_balance
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          var total_payments = calculations.total_payments
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          var total_charges = calculations.total_charges
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          var converted_total_outstanding_balance = (
            parseFloat(calculations.total_outstanding_balance) *
            conversion_selected_rate
          )
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          var converted_total_payments = (
            parseFloat(calculations.total_payments) * conversion_selected_rate
          )
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          var converted_total_charges = (
            parseFloat(calculations.total_charges) * conversion_selected_rate
          )
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          data_to_show_in_table.push({
            HBL: element.uid,
            Lot_No: element.Lot_No,
            Shipper: element.Shipper,
            Shipper_Phone: element.Phone,
            Consignee: element.Consignee,
            Consignee_Phone: element.C_Phone,

            Quantity: calculations.total_quantity
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            Weight: calculations.total_weight
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),

            Total_outstanding_balance: total_outstanding_balance,
            converted_total_outstanding_balance:
              converted_total_outstanding_balance,

            total_payments: total_payments,
            converted_total_payments: converted_total_payments,
            total_charges: total_charges,
            converted_total_charges: converted_total_charges,
            //
          });

          data_to_show_in_excel.push([
            element.uid,
            element.Lot_No,
            element.Shipper,
            element.Phone,
            element.Consignee,
            element.C_Phone,

            calculations.total_quantity
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            calculations.total_weight
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),

            total_outstanding_balance,

            converted_total_outstanding_balance,

            total_payments,
            converted_total_payments,
            total_charges,
            converted_total_charges,
          ]);
        }
      }
    });

    const table_data = {
      columns: [
        {
          label: "HBL",
          field: "HBL",
          sort: "asc",
          width: 150,
        },
        {
          label: "Lot No",
          field: "Lot_No",
          sort: "asc",
          width: 270,
        },

        {
          label: "Shipper",
          field: "Shipper",
          sort: "asc",
          width: 100,
        },
        {
          label: "Shipper Phone No.",
          field: "Shipper_Phone",
          sort: "asc",
          width: 100,
        },
        {
          label: "Consignee",
          field: "Consignee",
          sort: "asc",
          width: 200,
        },

        {
          label: "Consignee Phone No.",
          field: "Consignee_Phone",
          sort: "asc",
          width: 200,
        },

        {
          label: "Quantity",
          field: "Quantity",
          sort: "asc",
          width: 200,
        },
        {
          label: "Weight",
          field: "Weight",
          sort: "asc",
          width: 200,
        },

        {
          label: "Outstanding Balance",
          field: "Total_outstanding_balance",
          sort: "asc",
          width: 100,
        },

        {
          label: "Outstanding Balance (KSH)",
          field: "converted_total_outstanding_balance",
          sort: "asc",
          width: 100,
        }, ///////////

        {
          label: "Amount Paid",
          field: "total_payments",
          sort: "asc",
          width: 100,
        },

        {
          label: "Amount Paid (KSH)",
          field: "converted_total_payments",
          sort: "asc",
          width: 100,
        }, ///////////

        {
          label: "Total Amount",
          field: "total_charges",
          sort: "asc",
          width: 100,
        },

        {
          label: "Total Amount (KSH)",
          field: "converted_total_charges",
          sort: "asc",
          width: 100,
        }, ///////////
      ],
      rows: data_to_show_in_table,
    };

    const { ready } = this.state;
    const MyDocument = this.show_report();
    var data_csv = [];

    data_csv.push([
      "HBL",
      "Lot No.",
      "Shipper",
      "Shipper Phone No.",
      "Consignee",
      "Consignee Phone No.",
      "Quantity",
      "Weight",
      "Outstanding Balance",
      "Outstanding Balance (KSH)",
      "Amount Paid",
      "Amount Paid (KSH)",
      "Total Amount",
      "Total Amount (KSH)",
    ]);

    if (data_to_show_in_excel.length > 0) {
      data_to_show_in_excel.forEach((element) => {
        data_csv.push(element);
      });
    }

    var export_data = data_csv;

    return (
      <div>
        <div>
          {ready && (
            <PDFDownloadLink document={MyDocument} fileName="PDF">
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <Button onClick={() => this.setState({ ready: false })}>
                    Download Report
                  </Button>
                )
              }
            </PDFDownloadLink>
          )}
          {!ready && (
            <Button
              onClick={() => {
                this.setState({ data_to_show_in_table });
                this.toggle();
              }}
            >
              Generate Report
            </Button>
          )}

          <div>
            {export_data === null ? null : (
              <div className="row mt-4">
                <div className="col-xl col-sm-6">
                  <div className="text-sm-right">
                    <CSVLink filename="Debt_Manifest.csv" data={export_data}>
                      <Button type="button" color="primary" className="w-md">
                        Export Data in CSV
                      </Button>
                    </CSVLink>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            paddingTop: 20,
            paddingLeft: 15,
            paddingRight: 15,
          }}
        >
          <MDBDataTable responsive striped bordered data={table_data} />
        </div>
      </div>
    );
  }
  show_report_based_on_lot_no() {
    var data_filtered = this.processing();

    var options_to_show = [];
    if (Object.keys(data_filtered).length > 0) {
      var data = _.map(data_filtered, (val, uid_lot) => {
        return { data: { ...val }, uid_lot };
      });

      var data_with_details = [];
      data.forEach((element) => {
        var first_element = element.data[0];
        if (first_element.full_detail !== undefined) {
          data_with_details.push(element);
        }
      });

      data_with_details.forEach((element, i) => {
        options_to_show.push(<option value={i}>{element.uid_lot}</option>);
      });
    }

    return (
      <div>
        <Form encType="multipart/form-data">
          <div data-repeater-list="group-a">
            <Row data-repeater-item>
              <Col lg="6" className="form-group">
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    <Label className="input-group-text">LOT NO</Label>
                  </InputGroupAddon>
                  <Input
                    className="form-control"
                    onChange={(event) => {
                      var value = event.target.value;
                      if (value !== "") {
                        if (data_with_details[value].data) {
                          var converted_data = _.map(
                            data_with_details[value].data,
                            (val) => {
                              return { ...val };
                            }
                          );

                          this.setState({
                            data_by_lot_no: converted_data,
                          });
                        }
                      }
                    }}
                    type="select"
                  >
                    <option value="">Select Lot No.</option>
                    {options_to_show}
                  </Input>
                </InputGroup>
                <p>
                  <span style={{ fontWeight: "bold" }}>NOTE:</span>{" "}
                  <span style={{ color: "red" }}>
                    This options is only for shipments created or updated using
                    this software.
                  </span>
                </p>
              </Col>
            </Row>
          </div>
        </Form>

        {this.state.data_by_lot_no.length > 0 ? (
          <div>{this.show_table_filtered_by_lot_no()}</div>
        ) : null}
      </div>
    );
  }

  render() {
    const { loading } = this.props;
    if (loading === true) {
      const loadingjson = {
        loop: true,
        autoplay: true,
        animationData: require("./JSON/loading.json"),

        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };

      return (
        <div className="page-content">
          <Container fluid>
            <Row className="justify-content-center mt-lg-5">
              <div className="text-center">
                <Row className="justify-content-center">
                  <div className="text-center">
                    <Lottie options={loadingjson} height={300} width={300} />
                  </div>
                </Row>
              </div>
            </Row>
          </Container>
        </div>
      );
    }

    return (
      <div>
        <TabContent activeTab={this.state.activeTab} className="mt-4">
          <TabPane tabId="1" id="buy-tab">
            {this.show_report_based_on_hbl()}
          </TabPane>
          <TabPane tabId="2" id="sell-tab">
            {this.show_report_based_on_lot_no()}
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

// export default DebtManifest;
const mapStatetoProps = (state) => {
  const { shipping_data, container_data, error, loading } = state.Database;

  return {
    shipping_data,
    container_data,
    error,
    loading,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    shipping_data_read,
    container_data_read,
    detail_error,
  })(withNamespaces()(DebtManifest))
);
