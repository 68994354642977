import {
  //User
  USER_DATA_READ_ASYNC,
  USER_DATA_READ,
  //Shipping
  SHIPPING_DATA_READ_ASYNC,
  SHIPPING_DATA_READ,
  //Container
  CONTAINER_DATA_READ_ASYNC,
  CONTAINER_DATA_READ,
  //Currency Converter
  CURRENCY_DATA_READ_ASYNC,
  CURRENCY_DATA_READ,
  //Rates
  RATES_DATA_READ_ASYNC,
  RATES_DATA_READ,
  //Limited Access Users Zaincocargo
  LIMITED_ACCESS_USER_DATA_READ_ASYNC,
  LIMITED_ACCESS_USER_DATA_READ,
  //Limited Access Users Zaincocargo Logs
  LIMITED_ACCESS_USER_LOGS_DATA_READ_ASYNC,
  LIMITED_ACCESS_USER_LOGS_DATA_READ,
  //
  DETAIL_ERROR,
} from "./actionTypes";

const initialState = {
  user_details: null,
  shipping_data: null,
  container_data: null,
  currency_data: null,
  rates_data: null,
  limited_users_data: null,
  limited_users_logs_data: null,

  error: "",
  loading: true,
};

const database = (state = initialState, action) => {
  switch (action.type) {
    //User
    case USER_DATA_READ:
      state = { ...state, loading: true };
      break;

    case USER_DATA_READ_ASYNC:
      state = { ...state, user_details: action.payload, loading: false };
      break;

    //Shipping
    case SHIPPING_DATA_READ:
      state = { ...state, loading: true };

      break;

    case SHIPPING_DATA_READ_ASYNC:
      state = { ...state, shipping_data: action.payload, loading: false };
      break;

    //Container
    case CONTAINER_DATA_READ:
      state = { ...state, loading: true };

      break;

    case CONTAINER_DATA_READ_ASYNC:
      state = { ...state, container_data: action.payload, loading: false };
      break;

    //Currency Converter
    case CURRENCY_DATA_READ:
      state = { ...state, loading: true };

      break;

    case CURRENCY_DATA_READ_ASYNC:
      state = { ...state, currency_data: action.payload, loading: false };
      break;

    //Rates
    case RATES_DATA_READ:
      state = { ...state, loading: true };

      break;

    case RATES_DATA_READ_ASYNC:
      state = { ...state, rates_data: action.payload, loading: false };
      break;

    //Limited Access Users Zaincocargo
    case LIMITED_ACCESS_USER_DATA_READ:
      state = { ...state, loading: true };

      break;

    case LIMITED_ACCESS_USER_DATA_READ_ASYNC:
      state = { ...state, limited_users_data: action.payload, loading: false };
      break;

    //Limited Access Users Zaincocargo Logs
    case LIMITED_ACCESS_USER_LOGS_DATA_READ:
      state = { ...state, loading: true };

      break;

    case LIMITED_ACCESS_USER_LOGS_DATA_READ_ASYNC:
      state = {
        ...state,
        limited_users_logs_data: action.payload,
        loading: false,
      };
      break;

    case DETAIL_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      };
      break;
    /* DEFAULT */
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default database;
