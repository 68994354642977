import _ from "lodash";
import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
} from "reactstrap";
import Dropzone from "react-dropzone";
import csv from "csv";
import SweetAlert from "react-bootstrap-sweetalert";

// Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Link } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/database";

class FormUpload extends Component {
  constructor(props) {
    super(props);
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
    this.state = {
      success_dlg: false,
      confirm_both: false,
      selectedFiles: [],
      save_data: [],
      wrong_format: false,
    };
  }

  handleAcceptedFiles = (files) => {
    // console.log(files);
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );

    this.setState({ selectedFiles: files });
    var new_array = [];
    var wrong_format = false;

    const reader = new FileReader();
    reader.onload = () => {
      csv.parse(reader.result, (err, data) => {
        if (data === undefined) {
          //  alert("You have choosen a wrongly formatted file. Please try again ");
          wrong_format = true;
        } else {
          var headings = [];
          data[0].forEach((element) => {
            var headings_filtered = element.replace(/[^\w\s]/gi, "");
            if (headings_filtered === "") {
              headings_filtered = null;
            }
            headings.push(headings_filtered);
          });

          data.shift();

          data.forEach((element, i) => {
            var temp_data = [];
            headings.forEach((heading, p) => {
              // console.log(p);
              // console.log(element);
              var new_heading = heading;
              if (new_heading === "Lot No") {
                new_heading = "Lot_No";
              }

              temp_data.push({
                [new_heading]: element[p],
              });
            });

            var revised_data = _.reduce(
              temp_data,
              function (memo, current) {
                return _.assign(memo, current);
              },
              {}
            );

            var number = revised_data.No.replace(/[^\w\s]/gi, "");
            number = number.replace(/\s/g, "");
            new_array.push({ [number]: revised_data });
            // new_array.push(revised_data);
          });
        }

        //   data.forEach((element) => {
        //     new_array.push({
        //       [NO]: element[0],
        //       [Qty]: element[1],
        //       [Weight]: element[2],
        //       [Lot_NO]: element[3],
        //       [Date]: element[4],
        //       [Shipper]: element[5],
        //       [Phone]: element[6],
        //       [S_Town]: element[7],
        //       [Consignee]: element[8],
        //       [C_Phone]: element[9],
        //       [C_Town]: element[10],
        //       [Delivery]: element[11],
        //     });
        //   });
        //   this.setState({
        //     save_data: new_array,
        //   });

        /****************/

        // var NO = data[0][0].replace(/[^a-zA-Z ]/g, "");
        // var Qty = data[0][1].replace(/[^a-zA-Z ]/g, "");
        // var Weight = data[0][2].replace(/[^a-zA-Z ]/g, "");
        // var Lot_NO = data[0][3].replace(/[^a-zA-Z ]/g, "");
        // var Date = data[0][4].replace(/[^a-zA-Z ]/g, "");
        // var Shipper = data[0][5].replace(/[^a-zA-Z ]/g, "");
        // var Phone = data[0][6].replace(/[^a-zA-Z ]/g, "");
        // var S_Town = data[0][7].replace(/[^a-zA-Z ]/g, "");
        // var Consignee = data[0][8].replace(/[^a-zA-Z ]/g, "");
        // var C_Phone = data[0][9].replace(/[^a-zA-Z ]/g, "");
        // var C_Town = data[0][10].replace(/[^a-zA-Z ]/g, "");
        // var Delivery = data[0][11].replace(/[^a-zA-Z ]/g, "");

        // if ((S_Town = "STown")) {
        //   S_Town = "S_Town";
        // }
        // if ((C_Phone = "CPhone")) {
        //   C_Phone = "C_Phone";
        // }
        // if ((C_Town = "CTown")) {
        //   C_Town = "C_Town";
        // }
        // if (Lot_NO === "Lot No") {
        //   Lot_NO = "Lot_No";
        // }

        // if (
        //   NO === "No" &&
        //   Qty === "Qty" &&
        //   Weight === "Weight" &&
        //   Lot_NO === "Lot_No" &&
        //   Date === "Date" &&
        //   Shipper === "Shipper" &&
        //   Phone === "Phone" &&
        //   S_Town === "S_Town" &&
        //   Consignee === "Consignee" &&
        //   C_Phone === "C_Phone" &&
        //   C_Town === "C_Town" &&
        //   Delivery === "Delivery"
        // ) {
        //   data.shift();
        //   data.forEach((element) => {
        //     new_array.push({
        //       [NO]: element[0],
        //       [Qty]: element[1],
        //       [Weight]: element[2],
        //       [Lot_NO]: element[3],
        //       [Date]: element[4],
        //       [Shipper]: element[5],
        //       [Phone]: element[6],
        //       [S_Town]: element[7],
        //       [Consignee]: element[8],
        //       [C_Phone]: element[9],
        //       [C_Town]: element[10],
        //       [Delivery]: element[11],
        //     });
        //   });
        //   this.setState({
        //     save_data: new_array,
        //   });
        // } else {
        //   alert("You have choosen a wrongly formatted file. Please try again ");
        // }

        // if (new_array.length > 0) {

        // console.log(
        //   _.reduce(
        //     new_array,
        //     function (memo, current) {
        //       return _.assign(memo, current);
        //     },
        //     {}
        //   )
        // );
        if (wrong_format === true) {
          this.setState({ wrong_format: true });
        } else {
          this.setState({
            confirm_both: true,
            save_data: _.reduce(
              new_array,
              function (memo, current) {
                return _.assign(memo, current);
              },
              {}
            ),

            // new_array,

            /* _.reduce(
              new_array,
              function (memo, current) {
                return _.assign(memo, current);
              },
              {}
            ),*/
          });
        }
        // }
      });
    };

    reader.readAsBinaryString(files[0]);
  };

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Upload" breadcrumbItem="Upload" />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle>Upload CSV</CardTitle>
                    <CardSubtitle className="mb-3">
                      Upload the CSV below with the shipment details.
                    </CardSubtitle>
                    <Form>
                      <Dropzone
                        onDrop={(acceptedFiles) =>
                          this.handleAcceptedFiles(acceptedFiles)
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick mt-2"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      {/* <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {this.state.selectedFiles.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                      </div>*/}
                    </Form>

                    {this.state.wrong_format ? (
                      <SweetAlert
                        title="Wrong Format!"
                        //warning
                        error
                        onConfirm={() => this.setState({ wrong_format: false })}
                      >
                        You have choosen a wrongly formatted file. Please try
                        again.
                      </SweetAlert>
                    ) : null}

                    {this.state.success_dlg ? (
                      <SweetAlert
                        success
                        title={this.state.dynamic_title}
                        onConfirm={() => this.setState({ success_dlg: false })}
                      >
                        {this.state.dynamic_description}
                      </SweetAlert>
                    ) : null}

                    {this.state.confirm_both ? (
                      <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={async () => {
                          var data = this.state.save_data;

                          try {
                            var all_data = _.map(data, (val, uid) => {
                              return { val, uid };
                            });

                            //added below
                            all_data.forEach(async (element) => {
                              try {
                                await firebase
                                  .database()
                                  .ref(
                                    `organization/${this.props.user_details.organization}/shipment_details/${element.uid}`
                                  )
                                  .update(element.val);
                              } catch (error) {
                                console.log(error);
                              }
                            });
                            //added on top

                            // await firebase
                            //   .database()
                            //   .ref(
                            //     `organization/${this.props.user_details.organization}/shipment_details`
                            //   )
                            //   .update(data);

                            this.setState({
                              confirm_both: false,
                              success_dlg: true,
                              dynamic_title: "Successful",
                              dynamic_description:
                                "Your file has been uploaded.",
                            });
                          } catch (error) {
                            this.setState({
                              confirm_both: false,
                            });
                            alert(error);
                          }
                        }}
                        onCancel={() =>
                          this.setState({
                            confirm_both: false,
                            error_dlg: true,
                            dynamic_title: "Cancelled",
                            dynamic_description:
                              "Your imaginary file is safe :)",
                          })
                        }
                      >
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {this.state.selectedFiles.map((f, i) => {
                            return (
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <p className="mb-0">
                                    {f.name} file will get uploaded. Any
                                    previous data with the same unique
                                    identifier will get updated.
                                  </p>

                                  {/* <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                            </Col>*/}
                                  {/* <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>*/}
                                </Row>
                              </div>
                            );
                          })}
                        </div>
                      </SweetAlert>
                    ) : null}

                    {/*<div className="text-center mt-4">
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={async () => {
                          var data = this.state.save_data;

                          console.log(data);

                          if (this.state.save_data.length > 0) {
                            // data.forEach(async (element) => {
                            //   try {
                            //     // await firebase
                            //     //   .database()
                            //     //   .ref(
                            //     //     `organization/zaincocargo/shipment_details/${element.No}`
                            //     //   )
                            //     //   .update(element);
                            //     await firebase
                            //       .database()
                            //       .ref(`/shipment_details/${element.No}`)
                            //       .update(element);
                            //   } catch (error) {
                            //     console.log(error);
                            //   }
                            // });
                          }

                          // alert("DONE");
                        }}
                      >
                        Save Data
                      </button>
                    </div>*/}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormUpload;
