import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Label,
  Input,
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  InputGroupAddon,
  InputGroup,
  Modal,
  Form,
  Button,
} from "reactstrap";
import { MDBDataTable } from "mdbreact";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  Image,
} from "@react-pdf/renderer";

import classnames from "classnames";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import logo from "../../assets/images/logo-dark.png";

import firebase from "firebase/app";
import "firebase/database";
import moment from "moment";
import moment_time_zone from "moment-timezone";

/***/
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { shipping_data_read, detail_error } from "../../store/actions";
import Lottie from "react-lottie";
/***/

class VatReport extends Component {
  constructor() {
    super();
    this.state = {
      // activeTab: "1",
      activeTab: "2",
      data_by_lot_no: [],
      start_date: "",
      end_date: "",
      ready: false,
      data_to_show_in_table: [],
    };
  }

  toggle() {
    this.setState(
      (prevState) => ({
        ready: false,
      }),
      () => {
        setTimeout(() => {
          this.setState({ ready: true });
        }, 1);
      }
    );
  }
  table_titles(data_taken) {
    var titles_to_show = [];

    var titles = ["HBL", "Lot No", "Consignee", "Shipper", "VAT"];

    return (
      <View
        style={{
          flexDirection: "row",
          borderBottomColor: "#CFD8DC",
          backgroundColor: "#CFD8DC",
          borderBottomWidth: 1,
          alignItems: "left",
          // height: 24,
          paddingTop: 10,
          paddingBotttom: 10,
          textAlign: "left",
          fontStyle: "bold",
        }}
      >
        <Text
          style={{
            width: "8%",
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 10,
            paddingLeft: 10,
          }}
        >
          {titles[0]}
        </Text>
        <Text
          style={{
            width: "8%",
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 10,
          }}
        >
          {titles[1]}
        </Text>

        <Text
          style={{
            width: "28%",
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 10,
          }}
        >
          {titles[2]}
        </Text>
        <Text
          style={{
            width: "28%",
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 10,
          }}
        >
          {titles[3]}
        </Text>
        <Text
          style={{
            width: "28%",
            textAlign: "left",
            borderRightColor: "#CFD8DC",
            borderRightWidth: 1,
            fontSize: 10,
          }}
        >
          {titles[4]}
        </Text>
      </View>
    );
  }

  line_items(data_taken) {
    var list_to_show = [];
    //HBL, Lot_No, Consignee, Shipper, Vat
    data_taken.forEach((element, i) => {
      list_to_show.push(
        <View
          style={{
            flexDirection: "row",
            borderBottomColor: "#CFD8DC",
            borderBottomWidth: 1,
            alignItems: "center",
            // height: 24,
            fontStyle: "bold",
            paddingTop: 5,
          }}
          key={i}
        >
          <Text
            style={{
              width: "8%",
              textAlign: "left",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 10,
              flexWrap: "wrap",
            }}
          >
            {element.HBL}
          </Text>
          <Text
            style={{
              width: "8%",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              textAlign: "left",
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 10,
              flexWrap: "wrap",
            }}
          >
            {element.Lot_No}
          </Text>

          <Text
            style={{
              width: "28%",
              // textAlign: "center",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              textAlign: "left",

              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 10,
              flexWrap: "wrap",
            }}
          >
            {element.Consignee}
          </Text>
          <Text
            style={{
              width: "28%",
              textAlign: "left",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              // borderRightColor: "#CFD8DC",
              // borderRightWidth: 1,
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 10,
              flexWrap: "wrap",
            }}
          >
            {element.Shipper}
          </Text>
          <Text
            style={{
              width: "28%",
              borderRightColor: "#CFD8DC",
              borderRightWidth: 1,
              // borderRightColor: "#CFD8DC",
              // borderRightWidth: 1,
              textAlign: "left",
              paddingLeft: 5,
              paddingRight: 5,
              fontSize: 10,
              flexWrap: "wrap",
            }}
          >
            {element.Vat}
          </Text>
        </View>
      );
    });

    return list_to_show;
  }
  show_report() {
    const { ready, data_to_show_in_table } = this.state;

    if (data_to_show_in_table.length > 0) {
      var title = "Zainco Desitination Report";
      var data_taken = data_to_show_in_table;
      var table_titles = this.table_titles(data_taken);

      var line_items = this.line_items(data_taken);

      return (
        <Document>
          <Page
            size="A4"
            style={{
              paddingTop: 35,
              paddingBottom: 65,
              paddingHorizontal: 35,
            }}
          >
            <Image
              style={{
                width: 74,
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={logo}
            />

            <View
              style={{
                flexDirection: "row",
                marginTop: 24,
              }}
            >
              <Text
                style={{
                  color: "#263238",
                  fontSize: 15,
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                {title}
              </Text>
            </View>

            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: 24,
                borderWidth: 1,
                borderColor: "#CFD8DC",
              }}
            >
              {table_titles}

              {line_items}
            </View>
          </Page>
        </Document>
      );
    } else {
      return (
        <Document>
          <Page
            size="A4"
            style={{
              paddingTop: 35,
              paddingBottom: 65,
              paddingHorizontal: 35,
            }}
          >
            <Image
              style={{
                width: 74,
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={logo}
            />
          </Page>
        </Document>
      );
    }
  }
  calculating({
    cargo_details,
    consignee_details,
    hbl_charges,
    packing_charges,
    transport_charges,
    miscellaneous_charges,

    payments_details,
    shipper_details,
  }) {
    var total_weight = 0;
    var total_quantity = 0;
    var total_price = 0;
    var other_charges = 0;
    var vat_charges = 0;
    var total_charges = 0;
    var total_payments = 0;
    var total_outstanding_balance = 0;
    var rate = 0;

    var { rates_data_extracted } = this.props;

    if (hbl_charges === "") {
      hbl_charges = 0;
    }
    if (packing_charges === "") {
      packing_charges = 0;
    }
    if (transport_charges === "") {
      transport_charges = 0;
    }
    if (miscellaneous_charges === "") {
      miscellaneous_charges = 0;
    }
    other_charges =
      parseFloat(hbl_charges) +
      parseFloat(packing_charges) +
      parseFloat(transport_charges) +
      parseFloat(miscellaneous_charges);

    total_quantity = 0;
    total_weight = 0;
    if (cargo_details !== undefined) {
      cargo_details.forEach((element) => {
        if (element.quantity !== "") {
          total_quantity = total_quantity + parseFloat(element.quantity);
        }
        if (element.weight !== "") {
          total_weight = total_weight + parseFloat(element.weight);
        }
      });
    }

    total_payments = 0;
    if (payments_details !== undefined) {
      payments_details.forEach((element) => {
        if (element.amount !== "") {
          total_payments = total_payments + parseFloat(element.amount);
        }
      });
    }

    if (rates_data_extracted.rates_by_weight !== undefined) {
      var rates_by_weight = _.map(
        rates_data_extracted.rates_by_weight,
        (val, uid) => {
          return { ...val, uid };
        }
      );

      var value = [];
      rates_by_weight.forEach((element) => {
        if (element.upper_bound_weight === undefined) {
          if (total_weight >= element.lower_bound_weight) {
            rate = element.rate;
          }
        } else {
          if (
            total_weight >= element.lower_bound_weight &&
            total_weight <= element.upper_bound_weight
          ) {
            rate = element.rate;
          }
        }
      });

      total_price = total_weight * rate;
      vat_charges = parseFloat(
        (total_price / rates_data_extracted.vat).toFixed(2)
      );
      total_charges = total_price + other_charges;

      total_outstanding_balance = total_charges - total_payments;

      return {
        total_price,
        vat_charges,
        total_charges,
        total_outstanding_balance,
        other_charges,
      };
    } else {
      return null;
    }
  }
  show_report_based_on_hbl() {
    const { data } = this.props;
    var vat_total = 0;
    var data_to_show_in_table = [];
    var show_data = [];
    data.forEach((element) => {
      show_data.push(element);
    });

    show_data.forEach((element) => {
      if (element.full_detail) {
        const {
          cargo_details,
          consignee_details,
          other_charges,
          payments_details,
          shipper_details,
          calculations,
        } = element.full_detail;

        var hbl_charges = 0,
          packing_charges = 0,
          transport_charges = 0,
          miscellaneous_charges = 0;

        if (other_charges !== undefined) {
          if (other_charges.hbl !== undefined) {
            hbl_charges = other_charges.hbl;
          }

          if (other_charges.packing_charges !== undefined) {
            packing_charges = other_charges.packing_charges;
          }

          if (other_charges.transport_charges !== undefined) {
            transport_charges = other_charges.transport_charges;
          }

          if (other_charges.miscellaneous_charges !== undefined) {
            miscellaneous_charges = other_charges.miscellaneous_charges;
          }
        }
        var results = this.calculating({
          cargo_details,
          consignee_details,
          hbl_charges,
          packing_charges,
          transport_charges,
          miscellaneous_charges,
          payments_details,
          shipper_details,
        });

        if (results) {
          if (calculations) {
            data_to_show_in_table.push({
              HBL: element.uid,
              Lot_No: element.Lot_No,
              Consignee: element.Consignee,
              Shipper: element.Shipper,
              Vat: calculations.vat_charges,
            });
            vat_total = parseFloat(calculations.vat_charges);
          } else {
            data_to_show_in_table.push({
              HBL: element.uid,
              Lot_No: element.Lot_No,
              Consignee: element.Consignee,
              Shipper: element.Shipper,
              Vat: 0,
            });
          }
        }
      } else {
        data_to_show_in_table.push({
          HBL: element.uid,
          Lot_No: element.Lot_No,
          Consignee: element.Consignee,
          Shipper: element.Shipper,
          Vat: "Not enough info provided",
        });
      }
    });

    const table_data = {
      columns: [
        {
          label: "HBL",
          field: "HBL",
          sort: "asc",
          width: 150,
        },
        {
          label: "Lot No",
          field: "Lot_No",
          sort: "asc",
          width: 270,
        },
        {
          label: "Consignee",
          field: "Consignee",
          sort: "asc",
          width: 200,
        },
        {
          label: "Shipper",
          field: "Shipper",
          sort: "asc",
          width: 100,
        },

        {
          label: "VAT",
          field: "Vat",
          sort: "asc",
          width: 100,
        },
      ],
      rows: data_to_show_in_table,
    };

    const { ready } = this.state;
    const MyDocument = this.show_report();

    return (
      <div>
        <div>
          {ready && (
            <PDFDownloadLink document={MyDocument} fileName="PDF">
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <Button onClick={() => this.setState({ ready: false })}>
                    Download Report
                  </Button>
                )
              }
            </PDFDownloadLink>
          )}
          {!ready && (
            <Button
              onClick={() => {
                this.setState({ data_to_show_in_table });
                this.toggle();
              }}
            >
              Generate Report
            </Button>
          )}
        </div>
        <div>
          <div>
            <p>
              <span style={{ fontWeight: "bold" }}>Total VAT:</span> {vat_total}
            </p>
          </div>
          <div
            style={{
              paddingTop: 20,
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            <MDBDataTable responsive striped bordered data={table_data} />
          </div>
        </div>
      </div>
    );
  }

  processing() {
    // const { data } = this.props;

    // var snapshot = data;

    const { shipping_data } = this.props;

    var snapshot = shipping_data;

    var all_data_lot_no = _.map(snapshot, (val, uid) => {
      var data = {
        label: val.Lot_No,

        data: { uid, ...val },
      };

      return data;
    });

    var seen_all_data_lot_no = new Map();
    //
    all_data_lot_no = all_data_lot_no.filter((entry) => {
      var previous;
      if (seen_all_data_lot_no.hasOwnProperty(entry.label)) {
        previous = seen_all_data_lot_no[entry.label];
        previous.data.push(entry.data);

        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen_all_data_lot_no[entry.label] = entry;

      return true;
    });

    all_data_lot_no = _.fromPairs(
      _.map(all_data_lot_no, (i) => {
        return [i.label, i.data];
      })
    );

    return all_data_lot_no;
  }

  show_table_filtered_by_lot_no() {
    var vat_total = 0;
    var data = this.state.data_by_lot_no;
    var data_to_show_in_table = [];
    var show_data = [];
    data.forEach((element) => {
      show_data.push(element);
    });

    show_data.forEach((element) => {
      if (element.full_detail) {
        const {
          cargo_details,
          consignee_details,
          other_charges,
          payments_details,
          shipper_details,
          calculations,
        } = element.full_detail;

        var hbl_charges = 0,
          packing_charges = 0,
          transport_charges = 0,
          miscellaneous_charges = 0;

        if (other_charges !== undefined) {
          if (other_charges.hbl !== undefined) {
            hbl_charges = other_charges.hbl;
          }

          if (other_charges.packing_charges !== undefined) {
            packing_charges = other_charges.packing_charges;
          }

          if (other_charges.transport_charges !== undefined) {
            transport_charges = other_charges.transport_charges;
          }

          if (other_charges.miscellaneous_charges !== undefined) {
            miscellaneous_charges = other_charges.miscellaneous_charges;
          }
        }
        var results = this.calculating({
          cargo_details,
          consignee_details,
          hbl_charges,
          packing_charges,
          transport_charges,
          miscellaneous_charges,
          payments_details,
          shipper_details,
        });

        if (results) {
          if (calculations) {
            data_to_show_in_table.push({
              HBL: element.uid,
              Lot_No: element.Lot_No,
              Consignee: element.Consignee,
              Shipper: element.Shipper,
              Vat: calculations.vat_charges,
            });
            vat_total = parseFloat(calculations.vat_charges);
          } else {
            data_to_show_in_table.push({
              HBL: element.uid,
              Lot_No: element.Lot_No,
              Consignee: element.Consignee,
              Shipper: element.Shipper,
              Vat: 0,
            });
          }
        }
      } else {
        data_to_show_in_table.push({
          HBL: element.uid,
          Lot_No: element.Lot_No,
          Consignee: element.Consignee,
          Shipper: element.Shipper,
          Vat: "Not enough provided",
        });
      }
    });

    const table_data = {
      columns: [
        {
          label: "HBL",
          field: "HBL",
          sort: "asc",
          width: 150,
        },
        {
          label: "Lot No",
          field: "Lot_No",
          sort: "asc",
          width: 270,
        },
        {
          label: "Consignee",
          field: "Consignee",
          sort: "asc",
          width: 200,
        },
        {
          label: "Shipper",
          field: "Shipper",
          sort: "asc",
          width: 100,
        },

        {
          label: "VAT",
          field: "Vat",
          sort: "asc",
          width: 100,
        },
      ],
      rows: data_to_show_in_table,
    };

    const { ready } = this.state;
    const MyDocument = this.show_report();

    return (
      <div>
        <div>
          {ready && (
            <PDFDownloadLink document={MyDocument} fileName="PDF">
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <Button onClick={() => this.setState({ ready: false })}>
                    Download Report
                  </Button>
                )
              }
            </PDFDownloadLink>
          )}
          {!ready && (
            <Button
              onClick={() => {
                this.setState({ data_to_show_in_table });
                this.toggle();
              }}
            >
              Generate Report
            </Button>
          )}
        </div>
        <div>
          <div>
            <p>
              <span style={{ fontWeight: "bold" }}>Total VAT:</span> {vat_total}
            </p>
          </div>
          <div
            style={{
              paddingTop: 20,
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            <MDBDataTable responsive striped bordered data={table_data} />
          </div>
        </div>
      </div>
    );
  }
  show_report_based_on_lot_no() {
    var data_filtered = this.processing();

    var options_to_show = [];
    if (Object.keys(data_filtered).length > 0) {
      var data = _.map(data_filtered, (val, uid_lot) => {
        return { data: { ...val }, uid_lot };
      });

      var data_with_details = [];
      data.forEach((element) => {
        var first_element = element.data[0];
        data_with_details.push(element);
      });

      data_with_details.forEach((element, i) => {
        options_to_show.push(<option value={i}>{element.uid_lot}</option>);
      });
    }

    return (
      <div>
        <Form encType="multipart/form-data">
          <div data-repeater-list="group-a">
            <Row data-repeater-item>
              <Col lg="6" className="form-group">
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    <Label className="input-group-text">LOT NO</Label>
                  </InputGroupAddon>
                  <Input
                    className="form-control"
                    onChange={(event) => {
                      var value = event.target.value;
                      if (data_with_details[value].data) {
                        var converted_data = _.map(
                          data_with_details[value].data,
                          (val) => {
                            return { ...val };
                          }
                        );

                        this.setState({
                          data_by_lot_no: converted_data,
                        });
                      }
                    }}
                    type="select"
                  >
                    <option value="">Select Lot No.</option>
                    {options_to_show}
                  </Input>
                </InputGroup>
              </Col>
            </Row>
          </div>
        </Form>

        {this.state.data_by_lot_no.length > 0 ? (
          <div>{this.show_table_filtered_by_lot_no()}</div>
        ) : null}
      </div>
    );
  }

  show_report_based_period() {
    // const { data } = this.props;

    const { shipping_data } = this.props;
    var data = shipping_data;

    data = _.map(data, (val, uid) => {
      return { ...val, uid };
    });

    var data_to_show_in_table = [];
    var show_data = [];
    var vat_total = 0;

    data.forEach((element) => {
      if (this.state.start_date !== "" && this.state.end_date !== "") {
        if (
          moment(
            moment_time_zone(element.Date).tz("Etc/GMT").format("DD/MM/YYYY")
          ).isSameOrAfter(
            moment_time_zone(this.state.start_date)
              .tz("Etc/GMT")
              .format("DD/MM/YYYY")
          ) &&
          moment(
            moment_time_zone(element.Date).tz("Etc/GMT").format("DD/MM/YYYY")
          ).isSameOrBefore(
            moment_time_zone(this.state.end_date)
              .tz("Etc/GMT")
              .format("DD/MM/YYYY")
          )
        ) {
          show_data.push(element);
        }
      }
    });

    show_data.forEach((element) => {
      if (element.full_detail) {
        const {
          cargo_details,
          consignee_details,
          other_charges,
          payments_details,
          shipper_details,
          calculations,
        } = element.full_detail;

        var hbl_charges = 0,
          packing_charges = 0,
          transport_charges = 0,
          miscellaneous_charges = 0;

        if (other_charges !== undefined) {
          if (other_charges.hbl !== undefined) {
            hbl_charges = other_charges.hbl;
          }

          if (other_charges.packing_charges !== undefined) {
            packing_charges = other_charges.packing_charges;
          }

          if (other_charges.transport_charges !== undefined) {
            transport_charges = other_charges.transport_charges;
          }

          if (other_charges.miscellaneous_charges !== undefined) {
            miscellaneous_charges = other_charges.miscellaneous_charges;
          }
        }

        var results = this.calculating({
          cargo_details,
          consignee_details,
          hbl_charges,
          packing_charges,
          transport_charges,
          miscellaneous_charges,
          payments_details,
          shipper_details,
        });

        if (results) {
          if (calculations) {
            data_to_show_in_table.push({
              HBL: element.uid,
              Lot_No: element.Lot_No,
              Consignee: element.Consignee,
              Shipper: element.Shipper,
              Vat: calculations.vat_charges,
            });
            vat_total = parseFloat(calculations.vat_charges);
          } else {
            data_to_show_in_table.push({
              HBL: element.uid,
              Lot_No: element.Lot_No,
              Consignee: element.Consignee,
              Shipper: element.Shipper,
              Vat: 0,
            });
          }
        }
      } else {
        data_to_show_in_table.push({
          HBL: element.uid,
          Lot_No: element.Lot_No,
          Consignee: element.Consignee,
          Shipper: element.Shipper,
          Vat: "Not enough provided",
        });
      }
    });

    const table_data = {
      columns: [
        {
          label: "HBL",
          field: "HBL",
          sort: "asc",
          width: 150,
        },
        {
          label: "Lot No",
          field: "Lot_No",
          sort: "asc",
          width: 270,
        },
        {
          label: "Consignee",
          field: "Consignee",
          sort: "asc",
          width: 200,
        },
        {
          label: "Shipper",
          field: "Shipper",
          sort: "asc",
          width: 100,
        },

        {
          label: "VAT",
          field: "Vat",
          sort: "asc",
          width: 100,
        },
      ],
      rows: data_to_show_in_table,
    };

    const { ready } = this.state;
    // const MyDocument = this.show_report(data_to_show_in_table);
    const MyDocument = this.show_report();

    var show_options = false;
    if (this.state.start_date === "" || this.state.end_date === "") {
      show_options = false;
    } else {
      show_options = true;
    }
    return (
      <div>
        {show_options === true ? (
          <div style={{ paddingBottom: 10 }}>
            {ready && (
              <PDFDownloadLink document={MyDocument} fileName="PDF">
                {({ blob, url, loading, error }) =>
                  loading ? (
                    "Loading document..."
                  ) : (
                    <Button onClick={() => this.setState({ ready: false })}>
                      Download Report
                    </Button>
                  )
                }
              </PDFDownloadLink>
            )}
            {!ready && (
              <Button
                onClick={() => {
                  this.setState({ data_to_show_in_table });
                  this.toggle();
                }}
              >
                Generate Report
              </Button>
            )}
          </div>
        ) : null}
        <div>
          <Form className="repeater" encType="multipart/form-data">
            <div data-repeater-list="group-a">
              <Row data-repeater-item>
                <Col lg="6" className="form-group">
                  <Label htmlFor="name">Start Date</Label>
                  <Input
                    type="date"
                    id="name"
                    name="untyped-input"
                    className="form-control"
                    onChange={(event) => {
                      var value = event.target.value;

                      this.setState({
                        start_date: value,
                      });
                    }}
                    value={this.state.start_date}
                  />
                </Col>
                <Col lg="6" className="form-group">
                  <Label htmlFor="name">End Date</Label>
                  <Input
                    type="date"
                    id="name"
                    name="untyped-input"
                    className="form-control"
                    onChange={(event) => {
                      var value = event.target.value;
                      if (
                        moment(
                          moment_time_zone(this.state.start_date)
                            .tz("Etc/GMT")
                            .format("DD/MM/YYYY")
                        ).isAfter(
                          moment_time_zone(this.state.end_date)
                            .tz("Etc/GMT")
                            .format("DD/MM/YYYY")
                        ) === false
                      ) {
                        this.setState({
                          end_date: value,
                        });
                      } else {
                        alert(
                          "Please select End Date after the Start Date you have selected!"
                        );
                      }
                    }}
                    value={this.state.end_date}
                  />
                </Col>
              </Row>
            </div>
          </Form>

          {show_options === true ? (
            <div>
              <div>
                <p>
                  <span style={{ fontWeight: "bold" }}>Total VAT:</span>{" "}
                  {vat_total}
                </p>
                <p>
                  <span style={{ fontWeight: "bold" }}>Start Date:</span>{" "}
                  {this.state.start_date}
                </p>
                <p>
                  <span style={{ fontWeight: "bold" }}>End Date:</span>{" "}
                  {this.state.end_date}
                </p>
              </div>
              <div
                style={{
                  paddingTop: 20,
                  paddingLeft: 15,
                  paddingRight: 15,
                }}
              >
                <MDBDataTable responsive striped bordered data={table_data} />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  render() {
    const { loading } = this.props;
    if (loading === true) {
      const loadingjson = {
        loop: true,
        autoplay: true,
        animationData: require("./JSON/loading.json"),

        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };

      return (
        <div className="page-content">
          <Container fluid>
            <Row className="justify-content-center mt-lg-5">
              <div className="text-center">
                <Row className="justify-content-center">
                  <div className="text-center">
                    <Lottie options={loadingjson} height={300} width={300} />
                  </div>
                </Row>
              </div>
            </Row>
          </Container>
        </div>
      );
    }
    return (
      <div>
        <div>
          <Nav pills className="bg-light rounded" role="tablist">
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === "2",
                })}
                onClick={() => {
                  this.setState({
                    activeTab: "2",
                  });
                }}
              >
                Based on Lot No.
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === "3",
                })}
                onClick={() => {
                  this.setState({
                    activeTab: "3",
                  });
                }}
              >
                Based on Period
              </NavLink>
            </NavItem>
          </Nav>
        </div>

        <TabContent activeTab={this.state.activeTab} className="mt-4">
          <TabPane tabId="2" id="sell-tab">
            {this.show_report_based_on_lot_no()}
          </TabPane>
          <TabPane tabId="3" id="sell-tab">
            {this.show_report_based_period()}
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

// export default VatReport;
const mapStatetoProps = (state) => {
  const { shipping_data, error, loading } = state.Database;

  return {
    shipping_data,
    error,
    loading,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    shipping_data_read,
    detail_error,
  })(withNamespaces()(VatReport))
);
